import React, { FC } from 'react';
import { Button } from '@strata/tempo/lib';
import FundIcon from '@strata/tempo/lib/icon/FundIcon';

interface IFetchMetricResultsButton {
  hasSelectedMetrics: boolean;
  hasLoadedMetricResults: boolean;
  onClick(): void;
  pleaseWait?: boolean;
}

const RunButton: FC<IFetchMetricResultsButton> = (props: IFetchMetricResultsButton) => {
  if (props.pleaseWait) {
    return (
      <Button type='primary' disabled>
        Please wait
      </Button>
    );
  }

  const shouldFetchResults = !props.hasLoadedMetricResults && props.hasSelectedMetrics;
  const icon = shouldFetchResults ? <FundIcon /> : undefined;
  const label = shouldFetchResults ? 'Run' : 'Close';

  return (
    <Button type='primary' icon={icon} onClick={props.onClick}>
      {label}
    </Button>
  );
};

export default RunButton;

import { IFilterGroup, toParams, toToken, useFilter, useGlobalFilterContext } from 'shared/filters';
import { careFamily, costClient, serviceLine } from 'kpi/filters/config';

export const useKpiFilterGroup = (): IFilterGroup<KpiFilterKeys> => {
  const globalFilterContext = useGlobalFilterContext();

  const filters = {
    compareWithinOwnHealthSystem:
      globalFilterContext.filterGroup.filters.compareWithinOwnHealthSystem,
    entity: globalFilterContext.filterGroup.filters.kpiEntity,
    timeline: globalFilterContext.filterGroup.filters.timeline,
    amcType: globalFilterContext.filterGroup.filters.amcType,
    bedSize: globalFilterContext.filterGroup.filters.bedSize,
    careFamily: useFilter(careFamily),
    censusRegion: globalFilterContext.filterGroup.filters.censusRegion,
    costClient: useFilter(costClient),
    healthSystemType: globalFilterContext.filterGroup.filters.healthSystemType,
    hospitalType: globalFilterContext.filterGroup.filters.hospitalType,
    operatingExpense: globalFilterContext.filterGroup.filters.operatingExpense,
    outlierExclusion: globalFilterContext.filterGroup.filters.outlierExclusion,
    serviceLine: useFilter(serviceLine),
    systemOperatingExpense: globalFilterContext.filterGroup.filters.systemOperatingExpense,
    urbanRural: globalFilterContext.filterGroup.filters.urbanRural
  };

  return {
    filters,
    params: toParams(filters),
    token: toToken(filters),
    ready: globalFilterContext.filterGroup.ready
  };
};

export type KpiFilterKeys =
  | 'compareWithinOwnHealthSystem'
  | 'entity'
  | 'timeline'
  | 'amcType'
  | 'bedSize'
  | 'careFamily'
  | 'censusRegion'
  | 'costClient'
  | 'healthSystemType'
  | 'hospitalType'
  | 'operatingExpense'
  | 'outlierExclusion'
  | 'serviceLine'
  | 'systemOperatingExpense'
  | 'urbanRural';

// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Card, Spacing, Text } from '@strata/tempo/lib';
import Controls from './CostTypeCard.Controls';
import ChartBoxWhisker from 'shared/charts/ChartBoxWhisker';

import { useCostContext } from 'cost/data/costContext';
import PropTypes from 'prop-types';

const CostTypeCard = (props) => {
  const costContext = useCostContext();
  const costs = costContext.items.filter((entry) => entry.rollupName === 'All');

  const excludeTotalCostComparison = (entry) =>
    !['sphTotCost_Case', 'sphTotCost_Day'].includes(entry.comparison.dataName);

  const cohortData = costs
    .filter(excludeTotalCostComparison)
    .reduce((reduction, { comparison }) => {
      const type = labelMap[comparison.dataName];
      const entries =
        comparison.allAverages?.map((value) => ({
          type,
          value
        })) ?? [];

      reduction.push(...entries.filter((entry) => entry.value !== comparison.userAverage));

      return reduction;
    }, []);

  const userData = costs.filter(excludeTotalCostComparison).map(({ comparison }) => {
    const type = labelMap[comparison.dataName];
    return {
      type,
      value: comparison.userAverage,
      isUser: true
    };
  });

  const plotOptions = {
    heightOffset: -25, // fixes a Plot.js height-bug that happens when x-axis ticks are rotated...
    marginLeft: 50,
    x: {
      tickRotate: -15,
      tickFormat: (type) => type.substring(3)
    },
    y: {
      tickFormat: '$.1s'
    }
  };

  return (
    <Card height={400} removeBodyPadding loading={costContext.costComparisons.loading}>
      <Spacing column height={400} padding='8px'>
        <Spacing column padding='8px'>
          <Spacing margin='0 0 8px 0' hAlign={'space-between'}>
            <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
              Cost Type Comparison
            </Text.Heading>
          </Spacing>
        </Spacing>
        <Controls withOutliers={props.withOutliers} isOnboarding={props.isOnboarding} />
        <ChartBoxWhisker
          x='type'
          y='value'
          data={{ cohortData, userData }}
          plotOptions={plotOptions}
          showOutliers={props.withOutliers}
        />
      </Spacing>
    </Card>
  );
};

CostTypeCard.propTypes = {
  withOutliers: PropTypes.bool,
  isOnboarding: PropTypes.bool
};

export default CostTypeCard;

// The numbers prefixed to these labels is a hack to fix sorting.
// The numbers are removed in the x-axis tickFormat function...
const labelMap = {
  sphTotDirectCost_Case: '1. Total Direct Cost',
  sphFixedDirectCost_Case: '2. Fixed Direct Cost',
  sphVarDirectCost_Case: '3. Variable Direct Cost',
  sphTotIndirectCost_Case: '4. Total Indirect Cost',
  sphFixedIndirectCost_Case: '5. Fixed Indirect Cost',
  sphVarIndirectCost_Case: '6. Variable Indirect Cost',
  sphTotDirectCost_Day: '1. Total Direct Cost',
  sphFixedDirectCost_Day: '2. Fixed Direct Cost',
  sphVarDirectCost_Day: '3. Variable Direct Cost',
  sphTotIndirectCost_Day: '4. Total Indirect Cost',
  sphFixedIndirectCost_Day: '5. Fixed Indirect Cost',
  sphVarIndirectCost_Day: '6. Variable Indirect Cost'
};

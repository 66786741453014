import { IFilterConfig } from 'shared/filters/IFilter';

export const healthSystemType: IFilterConfig<string[]> = {
  controlLabel: 'Health System Type',
  controlOptionsFromUiConfig: {
    source: 'System',
    fieldname: 'healthsystemtype'
  },
  controlType: 'checkboxgroup',
  controlWeight: 13,
  defaultValue: [],
  param: 'healthSystemType',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.healthSystemType);
  },
  scope: 'system',
  settingLabel: 'Health System Type',
  settingWeight: 13
};

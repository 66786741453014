import React, { FC } from 'react';
import { LayoutGrid } from '@strata/tempo/lib';

import { useVisualizationContext } from 'shared/data/visualizationContext';
import { MetricDataName } from 'shared/data/IBaseMetric';
import { useLaborContext } from './data/laborContext';

import GetStarted from 'shared/comparisons/GetStarted';
import ComparisonCard from 'shared/comparisons/cards/ComparisonCard';
import ComparisonTable from 'shared/comparisons/table/ComparisonTable';

const LaborComparisons: FC = () => {
  const visualizationContext = useVisualizationContext();
  const laborContext = useLaborContext();

  const handleInsightPanelClick = (value: MetricDataName) => {
    laborContext.selectableComparisons.select(value);
    laborContext.insightDrawer.open();
  };

  const renderTable = () => {
    return laborContext.selectableItems.length ? (
      <ComparisonTable
        data={laborContext.selectableItems}
        isLoading={laborContext.selectableComparisons.loading}
        onInsightPanelClick={handleInsightPanelClick}
        sortable
      />
    ) : (
      <GetStarted onStart={laborContext.compareDrawer.open} />
    );
  };

  const renderCards = () => {
    return (
      <>
        {laborContext.selectableItems.length ? (
          <LayoutGrid>
            <LayoutGrid.Row>
              {laborContext.selectableItems.map((entry) => (
                <LayoutGrid.Column
                  span={4}
                  spanLarge={4}
                  spanMedium={6}
                  spanSmall={12}
                  key={entry.metric.Data_Name}
                >
                  <ComparisonCard
                    ineligibleReason={
                      laborContext.outOfScopeMetrics.includes(entry.metric.Data_Name)
                        ? 'DepartmentDoesNotApply'
                        : undefined
                    }
                    isSelected={
                      laborContext.selectableComparisons.selected === entry.comparison?.dataName
                    }
                    isLoading={laborContext.selectableComparisons.loading && !entry.comparison}
                    metric={entry.metric}
                    metricResult={entry.comparison}
                    onSelect={handleInsightPanelClick}
                  />
                </LayoutGrid.Column>
              ))}
            </LayoutGrid.Row>
          </LayoutGrid>
        ) : (
          <GetStarted onStart={laborContext.compareDrawer.open} />
        )}
      </>
    );
  };

  return visualizationContext.selectedVisualization === 'Table' ? renderTable() : renderCards();
};

export default LaborComparisons;

// eslint-disable-next-line no-use-before-define
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Spacing, Text } from '@strata/tempo/lib';
import Controls from './CostTypeCard.Controls';
import ChartBoxWhisker from 'shared/charts/ChartBoxWhisker';

const CostByComponentCard = (props) => {
  const forTotalCostMetrics = (entry) =>
    ['sphTotCost_Case', 'sphTotCost_Day'].includes(entry.comparison.dataName);

  const cohortData = props.data
    .filter(forTotalCostMetrics)
    .reduce((reduction, { comparison, rollupName }) => {
      const entries =
        comparison.allAverages?.map((value) => ({
          type: labelMap[rollupName],
          value
        })) ?? [];

      reduction.push(...entries.filter((entry) => entry.value !== comparison.userAverage));

      return reduction;
    }, []);

  const userData = props.data.filter(forTotalCostMetrics).map(({ comparison, rollupName }) => ({
    type: labelMap[rollupName],
    value: comparison.userAverage,
    isUser: true
  }));

  const plotOptions = {
    heightOffset: -25,
    marginLeft: 50,
    x: {
      tickRotate: -15,
      tickFormat: (type) => type.substring(3)
    },
    y: {
      tickFormat: '$.1s'
    }
  };

  return (
    <Card height={400} removeBodyPadding loading={props.isLoading}>
      <Spacing column height={400} padding='8px'>
        <Spacing column padding='8px'>
          <Spacing margin='0 0 8px 0' hAlign={'space-between'}>
            <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
              Cost Component Rollup Comparison (Total Cost)
            </Text.Heading>
          </Spacing>
        </Spacing>
        <Controls withOutliers={props.withOutliers} isOnboarding={props.isOnboarding} />
        <ChartBoxWhisker
          x='type'
          y='value'
          data={{ cohortData, userData }}
          plotOptions={plotOptions}
          showOutliers={props.withOutliers}
        />
      </Spacing>
    </Card>
  );
};

CostByComponentCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      allAverages: PropTypes.arrayOf(PropTypes.number),
      dataName: PropTypes.string,
      userAverage: PropTypes.number
    })
  ),
  withOutliers: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default CostByComponentCard;

const labelMap = {
  Benefits: '1. Benefits',
  'Building and Equipment': '2. Building and Equipment',
  Drugs: '3. Drugs',
  Implants: '4. Implants',
  Salaries: '5. Salaries',
  Supplies: '6. Supplies',
  Other: '7. Other'
};

import { useHelpCenter } from '@strata/core/lib/help';
import { useLocation } from 'react-router-dom';

export const useCompareHelpCenter = () => {
  const { openHelpPage } = useHelpCenter();
  const location = useLocation();

  const openHelpPageAtRoute = () => {
    // https://jira.sdt.local/browse/BEN-4155
    const helpPageMap = {
      '/kpi': '1587',
      '/cost': '1588',
      '/labor': '1594',
      '/overhead': '1597',
      '/serviceline': '1720'
    };

    openHelpPage(helpPageMap[location.pathname as keyof typeof helpPageMap]);
  };

  return {
    openHelpPageAtRoute
  };
};

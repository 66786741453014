import React from 'react';
import { AuthProvider, IOidcConfig } from '@strata/core/lib';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary, TempoProvider } from '@strata/tempo/lib';
import CompareLayout from 'shared/layout/CompareLayout';

const App: React.FC = () => {
  const oidcConfig: Partial<IOidcConfig> = {
    post_logout_redirect_uri: window.origin
  };

  return (
    <TempoProvider>
      <ErrorBoundary disableGlobalJavascriptErrorHandling>
        <AuthProvider oidcConfig={oidcConfig}>
          <BrowserRouter>
            <CompareLayout />
          </BrowserRouter>
        </AuthProvider>
      </ErrorBoundary>
    </TempoProvider>
  );
};

export default App;

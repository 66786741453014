import React, { FC } from 'react';
import { ActionBar, Divider, Input, LayoutGrid, RadioChangeEvent, Toggle } from '@strata/tempo/lib';

import ServiceLineTable from './ServiceLineTable';
import ComparisonCard2 from 'shared/comparisons/cards/ComparisonCard2';
import { useServiceLineContext } from './data/ServiceLineContext';
import ServiceLineEncounterCard from './cards/ServiceLineEncounterCard';
import ServiceLineLosCard from './cards/ServiceLineLosCard';
import { ISlCfAverage, ISlCfTrend } from '../shared/comparisons/data/IComparison';

const ServiceLineComparisons: FC = () => {
  const slContext = useServiceLineContext();
  const columnProps = {
    span: 4,
    spanLarge: 4,
    spanMedium: 4,
    spanSmall: 12
  };

  const patientTypeLabel = slContext.filterGroup.filters.patientType.computed?.friendlyLabel;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    slContext.setTableFilter(e.target.value);
  };

  const handleDiffTypeChange = (e: RadioChangeEvent) => {
    slContext.setDiffType(e.target.value);
  };

  const selectedTableRowName = slContext.selectedTableRow?.value;
  const trends =
    slContext.selectedTableRow?.type === 'Care Family'
      ? slContext.careFamilyTable.data.trends?.filter((e) => e.name === selectedTableRowName)
      : slContext.serviceLineTable.data.trends?.filter((e) => e.name === selectedTableRowName);

  return (
    <>
      <LayoutGrid removeMargin>
        <LayoutGrid.Row>
          <LayoutGrid.Column {...columnProps}>
            <ServiceLineEncounterCard />
          </LayoutGrid.Column>
          <LayoutGrid.Column {...columnProps}>
            <ServiceLineLosCard />
          </LayoutGrid.Column>
          <LayoutGrid.Column {...columnProps}>
            <ComparisonCard2
              averageValueAccessor={(entry: ISlCfAverage) => ({
                isUser: entry.isUser,
                value: entry.costValue
              })}
              trendValueAccessor={(entry: ISlCfTrend) => ({
                dateId: entry.dateId,
                isUser: entry.isUser,
                q1: entry.costQ1,
                q2: entry.costQ2,
                q3: entry.costQ3
              })}
              chartOptions={{ marginLeft: 56 }}
              height={410}
              isLoading={slContext.serviceLineTable.loading || slContext.careFamilyTable.loading}
              messageNoDataLoaded=''
              metric={{
                Category: slContext.selectedTableRow?.value || 'Service Line',
                Data_Name: 'cost',
                Friendly_Name: `Direct Cost for ${patientTypeLabel}`,
                Format: {
                  type: 'currency',
                  sigDig: 0,
                  axis: '$.1s',
                  tooltip: '$,.0f'
                },
                GoodBound: 'lower'
              }}
              trends={trends}
              withControls
            />
          </LayoutGrid.Column>
        </LayoutGrid.Row>
      </LayoutGrid>
      <Divider extended={false} />
      <ActionBar
        filters={
          <>
            <Input search width={300} onChange={handleSearch} debounce />
          </>
        }
        viewToggle={
          <Toggle
            items={[
              { value: 'absolute', name: 'Absolute deltas' },
              { value: 'percentage', name: 'Percentage deltas' }
            ]}
            defaultValue={'absolute'}
            onChange={handleDiffTypeChange}
          />
        }
      />
      <ServiceLineTable />
    </>
  );
};

export default ServiceLineComparisons;

import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps } from 'shared/filters/IFilter';
import { DropDown, Form } from '@strata/tempo/lib';
import { useLaborContext } from 'labor/data/laborContext';

const JobCodeRollupControl: FC<IFilterControlProps<string>> = ({ filterValue, setFilterValue }) => {
  const laborContext = useLaborContext();

  const isDisabled =
    laborContext.filterGroup.filters.clinicalVsNonclinical.filterValue === 'ALL' ||
    laborContext.filterGroup.filters.entity.computed?.isSystemScope;

  const options = laborContext.filterGroup.filters.clinicalVsNonclinical.computed?.isClinical
    ? clinicalJobCodeOptions
    : nonClinicalJobCodeOptions;

  return (
    <Form.Item label='StrataSphere® Job Code Rollup'>
      <DropDown
        allowClear
        disabled={isDisabled}
        items={options}
        onChange={(val) => setFilterValue((val as string) ?? 'ALL')}
        value={filterValue}
      />
    </Form.Item>
  );
};

const clinicalJobCodeOptions = [
  { value: 'ALL', text: 'All' },
  {
    value: 'Advanced Practice Provider',
    text: 'Advanced Practice Provider'
  },
  {
    value: 'Care Coordination',
    text: 'Care Coordination'
  },
  {
    value: 'Clinical Leadership',
    text: 'Clinical Leadership'
  },
  { value: 'Clinical Support', text: 'Clinical Support' },
  { value: 'Nursing', text: 'Nursing' },
  { value: 'Pharmacist', text: 'Pharmacist' },
  { value: 'Physician', text: 'Physician' },
  { value: 'Quality', text: 'Quality' },
  { value: 'Research', text: 'Research' },
  { value: 'Resident and Fellow', text: 'Resident and Fellow' },
  { value: 'Therapist', text: 'Therapist' }
];
const nonClinicalJobCodeOptions = [
  { value: 'ALL', text: 'All' },
  { value: 'Administration', text: 'Administration' },
  { value: 'Courier', text: 'Courier' },
  { value: 'Education', text: 'Education' },
  {
    value: 'Environmental Service',
    text: 'Environmental Service'
  },
  { value: 'Finance', text: 'Finance' },
  { value: 'Food Service', text: 'Food Service' },
  { value: 'HR', text: 'HR' },
  { value: 'IT', text: 'IT' },
  { value: 'Marketing', text: 'Marketing' },
  { value: 'Other', text: 'Other' },
  { value: 'Revenue Cycle', text: 'Revenue Cycle' },
  { value: 'Supply Chain', text: 'Supply Chain' },
  { value: 'Transportation', text: 'Transportation' }
];

export const jobCodeRollup: IFilterConfig<string> = {
  Control: JobCodeRollupControl,
  controlWeight: 5.1,
  defaultValue: 'ALL',
  param: 'sphJobCodeRollup',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.sphJobCodeRollup);
  },
  settingLabel: 'StrataSphere® Job Code Rollup',
  settingWeight: 5.1
};

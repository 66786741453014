import { appConfig, getSecureService } from '@strata/core/lib';
const { httpGet } = getSecureService(appConfig.apiUrl);

export interface IClientDataNonDua {
  databaseGuid: string;
}

export interface IClientData {
  databaseGuid: string;
  isCompare: boolean;
  isSpecialAccessCost: boolean;
  isSpecialAccessLabor: boolean; // TODO not yet implemented in backend!
  isSpecialAccessOverhead: boolean; // TODO not yet implemented in backend!
  isProductionCost: boolean;
  isProductionLabor: boolean;
  isProductionOverhead: boolean;
  orgPin: string;
  strataId: number;
}

interface ISystemFlagsResponse {
  databaseGuid: string;
  systemFlags?: Omit<IClientData, 'databaseGuid'>;
}

export const clientDataService = {
  async fetchClientDataFromUiSystemFlags(): Promise<ISystemFlagsResponse> {
    return httpGet<ISystemFlagsResponse>('/client-data/ui-system-flags');
  }
};

import React, { FC, useState } from 'react';
import { ActionBar, Button, DataGrid, Modal, Toggle } from '@strata/tempo/lib';
import ConfirmDelete from './ConfirmDelete';
import dayjs from 'dayjs';
import { useViewContext } from './data/useViewContext';
import { useOidcSession } from '@strata/core/lib';

const LoadModal: FC = () => {
  const viewContext = useViewContext();
  const [idForDeletion, setIdForDeletion] = useState<string>();
  const session = useOidcSession();

  return (
    <Modal
      onCancel={viewContext.closeModal}
      title='Load Saved View'
      visible={viewContext.activeModal === 'load'}
      width={'large'}
      removeBodyPadding
    >
      <ActionBar
        viewToggle={
          <Toggle
            defaultValue={viewContext.shouldShowOnlyMyViews}
            onChange={viewContext.toggleShouldShowOnlyMyViews}
            items={[
              { value: true, name: 'My Views' },
              { value: false, name: 'All Views' }
            ]}
          />
        }
      />
      <DataGrid
        density={'dense'}
        paginator={viewContext.configEntries.length > 10}
        rows={10}
        sortField='savedAt'
        sortOrder={0}
        value={viewContext.configEntries}
      >
        <DataGrid.Column
          field='name'
          header='Name'
          width={175}
          sortable
          body={({ name, id }) => (
            <Button type='link' onClick={() => viewContext.load(id)}>
              {name}
            </Button>
          )}
        />
        <DataGrid.Column field='savedBy' width={175} header='Created By' sortable />
        <DataGrid.Column
          field='savedAt'
          width={135}
          header='Date Saved'
          sortable
          body={({ savedAt }) => <span>{dayjs(savedAt * 1000).format('MMM DD YYYY')}</span>}
        />
        <DataGrid.Column
          width={100}
          align='right'
          body={({ id, savedBy }) => {
            if (savedBy !== session.userName) {
              return null;
            }
            return (
              <Button type='link' onClick={() => setIdForDeletion(id)}>
                Delete
              </Button>
            );
          }}
        />
      </DataGrid>
      <ConfirmDelete
        id={idForDeletion}
        visible={Boolean(idForDeletion)}
        onCancel={() => setIdForDeletion(undefined)}
        onDelete={() => {
          viewContext.delete(idForDeletion as string);
          setIdForDeletion(undefined);
        }}
      />
    </Modal>
  );
};

export default LoadModal;

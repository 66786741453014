import { useEffect, useState } from 'react';
import { ICostComparisonService, ICacheableCostComparison } from 'cost/data/costComparisonService';
import { IFilterGroup } from 'shared/filters';
import { CostFilterKeys } from 'cost/filters/useCostFilterGroup';
import { makeUid } from '../../shared/utils';
import { useClientDataContext } from '../../shared/data/useClientDataContext';

export interface IUseCostComparisons {
  comparisons: ICacheableCostComparison[];
  loading: boolean;
  loadingCount: number;
  loadingDone: boolean;
  loadingRequests: Promise<ICacheableCostComparison[]>[];
  load(): void;
  cancelLoad(): void;
}

const useCostComparisons = (
  comparisonService: ICostComparisonService,
  filterGroup: IFilterGroup<CostFilterKeys>
): IUseCostComparisons => {
  const clientDataContext = useClientDataContext();

  const [comparisons, setComparisons] = useState([] as ICacheableCostComparison[]);
  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [loadingDone, setLoadingDone] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState(
    [] as Array<Promise<ICacheableCostComparison[]>>
  );
  const [abortControllers, setAbortControllers] = useState<AbortController[]>([]);

  // If the user changes a Filter Parameter,
  // flag that new comparisons should be loaded
  useEffect(() => {
    setLoadingDone(false);
  }, [filterGroup.token]);

  return {
    comparisons,
    loading,
    loadingCount,
    loadingDone,
    loadingRequests,
    load() {
      const controllers = [new AbortController(), new AbortController()];
      setAbortControllers(controllers);

      const comparisonId = makeUid(); // This id is used by DataDog to help create our app's usage Dashboard

      const allCostsRequest = comparisonService
        .fetchAllCosts(
          comparisonId,
          filterGroup.params,
          filterGroup.token,
          controllers[0].signal,
          clientDataContext.clientData
        )
        .then((comparisons: Array<ICacheableCostComparison>) => {
          setLoadingCount((count) => --count);
          return comparisons;
        });

      const rollupCostsRequest = comparisonService
        .fetchTotalCostOnly(
          comparisonId,
          filterGroup.params,
          filterGroup.token,
          controllers[1].signal,
          clientDataContext.clientData
        )
        .then((comparisons: Array<ICacheableCostComparison>) => {
          setLoadingCount((count) => --count);
          return comparisons;
        });

      const requests = [allCostsRequest, rollupCostsRequest];

      setLoading(true);
      setLoadingCount(requests.length);
      setLoadingRequests(requests);

      Promise.all(requests).then((allComparisons) => {
        setTimeout(() => {
          setComparisons(allComparisons.flat());
          setLoading(false);
          setLoadingDone(true);
          setLoadingRequests([]);
        }, 100); // delayed until the drawer closes, due to rendering issues in Chrome.
      });
    },
    cancelLoad() {
      abortControllers.forEach((controller) => controller.abort());
      setLoading(false);
      setLoadingCount(0);
      setLoadingRequests([]);
    }
  };
};

useCostComparisons.returnType = {
  comparisons: [] as ICacheableCostComparison[],
  loading: false,
  loadingCount: 0,
  loadingDone: false,
  loadingRequests: [],
  load: () => undefined,
  cancelLoad: () => undefined
} as IUseCostComparisons;

export default useCostComparisons;

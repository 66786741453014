import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { Form, Switch, Text } from '@strata/tempo/lib';

const OutlierExclusionControl: FC<IFilterControlProps<boolean>> = ({
  filterValue,
  setFilterValue
}) => {
  return (
    <>
      <Text.Heading level={4}>Cohort Filters</Text.Heading>
      <Form.Item label='Exclude Outliers'>
        <Switch checked={filterValue} onClick={setFilterValue} />
      </Form.Item>
    </>
  );
};

const OutlierExclusionSetting: FC<IFilterSettingProps<boolean>> = ({ filterValue }) => {
  const value = filterValue ? 'Excluded' : 'Included';
  return <Setting label='Outliers' value={value} />;
};

export const outlierExclusion: IFilterConfig<boolean> = {
  Control: OutlierExclusionControl,
  controlWeight: 6,
  defaultValue: true,
  param: (filterValue) => ({
    outlierExclusionActive: filterValue,
    outlierExclusionThreshold: 2.0
  }),
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.outlierExclusionActive);
  },
  Setting: OutlierExclusionSetting,
  settingWeight: 6
};

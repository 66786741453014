import { appConfig, getSecureService } from '@strata/core/lib';
import { groupBy } from 'lodash-es';
const { httpGet } = getSecureService(appConfig.apiUrl);

interface ISg2GroupingsData {
  sg2ServiceLine: string;
  sg2CareFamily: string;
}

export interface ISg2Groupings {
  [sg2ServiceLine: string]: string[]; // sg2CareFamily[]
}

export const sg2GroupingsService = {
  async fetchSg2Groupings(): Promise<ISg2Groupings> {
    const data = await httpGet<ISg2GroupingsData[]>('/sg2groupings');
    const groupedData = groupBy(data, 'sg2ServiceLine');

    return Object.entries(groupedData).reduce((acc: ISg2Groupings, [serviceLineName, grouping]) => {
      acc[serviceLineName] = grouping.map((g) => g.sg2CareFamily);
      return acc;
    }, {});
  }
};

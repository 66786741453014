import React, { FC } from 'react';
import { Spacing } from '@strata/tempo/lib';

interface IChartBeeswarmControlsProps {
  withOutliers: boolean;
  isOnboarding: boolean;
}

const Controls: FC<IChartBeeswarmControlsProps> = (props) => {
  return (
    <Spacing itemSpacing={8} hAlign='center'>
      {props.isOnboarding ? (
        <></>
      ) : (
        <div className={`bm-bar-legend-container`}>
          <div className='bm-bar-legend bm-bar-user' />
          You
        </div>
      )}
      <div className={`bm-bar-legend-container`}>
        <div className='bm-bar-legend bm-bar-median' />
        Median
      </div>
      <div className={`bm-bar-legend-container`}>
        <div className='bm-bar-legend bm-bar-inner' />
        25th percentile
      </div>
      <div className={`bm-bar-legend-container`}>
        <div className='bm-bar-legend bm-bar-inner' />
        75th percentile
      </div>
      {props.withOutliers ? (
        <div className='bm-bar-legend-container'>
          <div className='bm-bee-legend bm-bee-outer' />
          Outliers
        </div>
      ) : null}
    </Spacing>
  );
};

export default Controls;

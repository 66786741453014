import { IFilterGroup, toParams, toToken, useFilter, useGlobalFilterContext } from 'shared/filters';
import { minimumVolume, patientType } from 'serviceline2/filters/config';

export const useServiceLineFilterGroup = (): IFilterGroup<ServiceLineFilterKeys> => {
  const globalFilterContext = useGlobalFilterContext();

  const filters = {
    compareWithinOwnHealthSystem:
      globalFilterContext.filterGroup.filters.compareWithinOwnHealthSystem,
    entity: globalFilterContext.filterGroup.filters.costEntity,
    timeline: globalFilterContext.filterGroup.filters.timeline,
    patientType: useFilter(patientType),
    minimumVolume: useFilter(minimumVolume),
    amcType: globalFilterContext.filterGroup.filters.amcType,
    bedSize: globalFilterContext.filterGroup.filters.bedSize,
    censusRegion: globalFilterContext.filterGroup.filters.censusRegion,
    healthSystemType: globalFilterContext.filterGroup.filters.healthSystemType,
    hospitalType: globalFilterContext.filterGroup.filters.hospitalType,
    operatingExpense: globalFilterContext.filterGroup.filters.operatingExpense,
    outlierExclusion: globalFilterContext.filterGroup.filters.outlierExclusion,
    systemOperatingExpense: globalFilterContext.filterGroup.filters.systemOperatingExpense,
    urbanRural: globalFilterContext.filterGroup.filters.urbanRural
  };

  return {
    filters,
    params: toParams(filters),
    token: toToken(filters),
    ready: globalFilterContext.filterGroup.ready
  };
};

export type ServiceLineFilterKeys =
  | 'compareWithinOwnHealthSystem'
  | 'entity'
  | 'timeline'
  | 'patientType'
  | 'minimumVolume'
  | 'amcType'
  | 'bedSize'
  | 'censusRegion'
  | 'healthSystemType'
  | 'hospitalType'
  | 'operatingExpense'
  | 'outlierExclusion'
  | 'systemOperatingExpense'
  | 'urbanRural';

import React, { FC } from 'react';
import { Button, Text } from '@strata/tempo/lib';
import ProgressBar from './ProgressBar';
import SaveViewModal from 'shared/config/SaveModal';
import LoadViewModal from 'shared/config/LoadModal';

import 'old/src/components/ControlHeader/ControlHeader.css';
import { useViewContext } from '../config/data/useViewContext';
import LoadingIcon from '@strata/tempo/lib/icon/LoadingIcon';

interface IMetricTaskBarProps {
  loading: boolean;
  loadingProgress?: number;
  metricDrawerButtonText: string;
  openMetricDrawer(): void;
  cancelMetricResultsLoad(): void;
}

const TaskBar: FC<IMetricTaskBarProps> = (props: IMetricTaskBarProps) => {
  const viewContext = useViewContext();

  return (
    <>
      <div className='bm-control-header'>
        <div style={{ float: 'left', marginTop: 10, marginLeft: 0, minWidth: '130px' }}>
          <Button
            type='primary'
            block
            icon={props.loading ? <LoadingIcon /> : undefined}
            onClick={props.loading ? props.cancelMetricResultsLoad : props.openMetricDrawer}
          >
            {props.loading ? 'Cancel' : props.metricDrawerButtonText}
          </Button>
        </div>
        <div className='bm-header-name'>
          <Text.Heading level={2}>{viewContext.name}</Text.Heading>
        </div>
        <div style={{ float: 'right', marginTop: 10, marginRight: 0 }}>
          <Button disabled={props.loading} onClick={() => viewContext.openModal('save')}>
            Save View
          </Button>
          <Button
            type='tertiary'
            disabled={props.loading}
            onClick={() => viewContext.openModal('load')}
          >
            Load View
          </Button>
        </div>
      </div>
      <ProgressBar visible={props.loading} progress={props.loadingProgress} />
      <SaveViewModal />
      <LoadViewModal />
    </>
  );
};

export default TaskBar;

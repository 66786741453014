/*
This used to be the KpiOrganizer component.
It used to be shared between Configurations (now KpiDrawer)
and ConfigurationsLabor (not created yet, but will be LaborDrawer)
<KpiOrganizer
  listData={laborListData}
  switchAll={(isActive) => switchAll(isActive, 'laborListData')}
  setListData={setLaborListData}
/>
 */
import React, { CSSProperties } from 'react';
import {
  DragDropContext,
  Draggable,
  DraggableProvidedDraggableProps,
  Droppable,
  DropResult
} from 'react-beautiful-dnd';
import { Spacing } from '@strata/tempo/lib';

import IBaseMetric, { MetricDataName } from 'shared/data/IBaseMetric';
import MetricSelector from './MetricSelector';
import SelectableMetricCard from './SelectableMetricCard';
import { IUseSelectableMetrics } from 'shared/data/useSelectableMetrics';

import './MetricSelectorTabPane.css';
import ScrollKeeper from './ScrollKeeper';

const getItemStyle = (props: DraggableProvidedDraggableProps): CSSProperties => ({
  userSelect: 'none',
  padding: 4,
  margin: `0 0 ${4}px 0`,
  ...props.style
});

interface IMetricSelectorTabPaneProps<T extends IBaseMetric = IBaseMetric> {
  selectableMetrics: IUseSelectableMetrics<T>;
}

interface ISelectableMetric extends IBaseMetric {
  isSelected: boolean;
}

const MetricSelectorTabPane: React.FC<IMetricSelectorTabPaneProps> = (
  props: IMetricSelectorTabPaneProps
) => {
  const [trackScrolling, setTrackScrolling] = React.useState(false);
  const [filterByText, setFilterByText] = React.useState('');
  const [selectableMetrics, setSelectableMetrics] = React.useState<ISelectableMetric[]>(
    getSelectableMetrics(props.selectableMetrics.metrics, props.selectableMetrics.selected)
  );

  // Compute the selectableMetrics when a metric is
  // selected/deselected, or the filterByText changes
  React.useEffect(() => {
    const filteredMetrics = props.selectableMetrics.metrics.filter((metric) => {
      if (filterByText.length < 3) {
        return true;
      }
      const searchableText = [metric.Friendly_Name, metric.Category].join().toLowerCase();
      return searchableText.includes(filterByText.toLowerCase());
    });
    const selectableMetrics = getSelectableMetrics(
      filteredMetrics,
      props.selectableMetrics.selected
    );
    const sortedMetrics = selectableMetrics.sort((a, b) => +b.isSelected - +a.isSelected);
    setSelectableMetrics(sortedMetrics);
  }, [props.selectableMetrics.metrics, props.selectableMetrics.selected, filterByText]);

  // The drawer's scroll position is retained
  // between renders, but to prevent bugs we
  // can't track it until the initial render completes.
  React.useEffect(() => {
    setTrackScrolling(true);
  }, []);

  const handleMetricToggle = (toggledDataName: MetricDataName) => {
    const newSelectedMetrics = props.selectableMetrics.selected.includes(toggledDataName)
      ? props.selectableMetrics.selected.filter((dataName) => dataName !== toggledDataName)
      : props.selectableMetrics.selected.concat([toggledDataName]);
    props.selectableMetrics.select(newSelectedMetrics);
  };

  const handleDragEnd = (dropResult: DropResult) => {
    if (!dropResult.destination) {
      return;
    }

    const reorderedMetrics = reorderMetrics(
      selectableMetrics,
      dropResult.source.index,
      dropResult.destination.index
    );

    props.selectableMetrics.select(
      reorderedMetrics.filter((entry) => entry.isSelected).map((entry) => entry.Data_Name)
    );
  };

  const selectAllMetrics = () => {
    const newIds = selectableMetrics.map((entry) => entry.Data_Name);
    const existingIds = props.selectableMetrics.selected;
    const selected = existingIds.concat(newIds).reduce((all, curr) => {
      if (!all.includes(curr)) all.push(curr);
      return all;
    }, [] as string[]);
    props.selectableMetrics.select(selected);
  };
  const removeAllMetrics = () => {
    const metricIdsToDeselect = selectableMetrics.map((entry) => entry.Data_Name);
    props.selectableMetrics.select(
      props.selectableMetrics.selected.filter((entry) => !metricIdsToDeselect.includes(entry))
    );
  };

  return (
    <Spacing padding={'12px'} background={'gray-100'}>
      {trackScrolling && <ScrollKeeper selector='.ant-drawer-body' />}
      <div className='bm-flex'>
        <MetricSelector
          filterByText={filterByText}
          onFilterByTextChange={setFilterByText}
          onAddAll={selectAllMetrics}
          onRemoveAll={removeAllMetrics}
        />
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='activeKPI'>
            {(droppable) => (
              <div ref={droppable.innerRef} className='bm-kpiorg'>
                {selectableMetrics.map((metric, index) => (
                  <Draggable
                    key={metric.Data_Name}
                    draggableId={metric.Data_Name}
                    isDragDisabled={!metric.isSelected}
                    index={index}
                  >
                    {(draggable) => (
                      <div
                        ref={draggable.innerRef}
                        {...draggable.draggableProps}
                        {...draggable.dragHandleProps}
                        style={getItemStyle(draggable.draggableProps)}
                      >
                        <SelectableMetricCard
                          onToggle={handleMetricToggle}
                          active={props.selectableMetrics.selected.includes(metric.Data_Name)}
                          {...metric}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </Spacing>
  );
};

export default MetricSelectorTabPane;

// =======================
// Helpers
// =======================

const getSelectableMetrics = (
  metrics: Array<IBaseMetric>,
  selectedMetricDataNames: MetricDataName[]
): ISelectableMetric[] => {
  const selectedMetrics = selectedMetricDataNames
    .map((name) => {
      const found = metrics.find((metric) => metric.Data_Name === name);
      return found ? { ...found, isSelected: true } : null;
    })
    .filter((entry) => entry !== null) as ISelectableMetric[];

  const otherMetrics = metrics
    .filter((entry) => !selectedMetricDataNames.includes(entry.Data_Name))
    .map((entry) => ({ ...entry, isSelected: false }));

  return selectedMetrics.concat(otherMetrics);
};

const reorderMetrics = (metrics: ISelectableMetric[], startIndex: number, endIndex: number) => {
  const sortedMetrics = Array.from(metrics);
  const [removed] = sortedMetrics.splice(startIndex, 1);
  sortedMetrics.splice(endIndex, 0, removed);
  return sortedMetrics;
};

import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps } from 'shared/filters/IFilter';
import { DropDown, DropDownValue, Form } from '@strata/tempo/lib';
import { useLaborContext } from 'labor/data/laborContext';

const ClinicalVsNonclinicalControl: FC<IFilterControlProps<string>> = ({
  filterValue,
  setFilterValue
}) => {
  const laborContext = useLaborContext();

  const handleChange = (val: DropDownValue) => {
    setFilterValue((val as string) ?? 'ALL');
    laborContext.filterGroup.filters.jobCodeRollup.setFilterValue('ALL');
  };

  const isDisabled =
    laborContext.filterGroup.filters.departmentRollup.filterValue === 'ALL' ||
    laborContext.filterGroup.filters.entity.computed?.isSystemScope;

  return (
    <Form.Item label='Clinical vs. Non-Clinical'>
      <DropDown
        allowClear
        disabled={isDisabled}
        items={options}
        onChange={handleChange}
        value={filterValue}
      />
    </Form.Item>
  );
};

const options = [
  { value: 'ALL', text: 'All' },
  { value: 'Clinical', text: 'Clinical' },
  { value: 'Non Clinical', text: 'Non-Clinical' }
];

export const clinicalVsNonclinical: IFilterConfig<string> = {
  computed: {
    isClinical: (filterValue) => filterValue === 'Clinical'
  },
  Control: ClinicalVsNonclinicalControl,
  controlWeight: 5,
  defaultValue: 'ALL',
  param: 'clinicalvNonclinical',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.clinicalvNonclinical);
  },
  settingLabel: 'Clinical vs. Non-Clinical',
  settingWeight: 5
};

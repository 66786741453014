import React, { createContext, FC, useContext } from 'react';
import { IUseMappingReturn, useMapping } from './useMapping';
import { mappingsService } from './mappingsService';

interface IMappingContext {
  department: IUseMappingReturn;
  glAccount: IUseMappingReturn;
  jobCode: IUseMappingReturn;
  payCode: IUseMappingReturn;
  payor: IUseMappingReturn;
}

const context = createContext<IMappingContext>({} as IMappingContext);
const useMappingContext = (): IMappingContext => useContext(context);

const MappingProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const providedValue: IMappingContext = {
    department: useMapping(mappingsService.fetchDepartmentMappings),
    glAccount: useMapping(mappingsService.fetchGlAccountMappings),
    jobCode: useMapping(mappingsService.fetchJobCodeMappings),
    payCode: useMapping(mappingsService.fetchPayCodeMappings),
    payor: useMapping(mappingsService.fetchPayorMappings)
  };
  return <context.Provider value={providedValue}>{props.children}</context.Provider>;
};

export { useMappingContext, MappingProvider };

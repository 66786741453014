import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const DEFAULT_VIEW_NAME = 'Unsaved View';
const SEARCH_PARAM_NAME = 'configGUID';

const useView = () => {
  const [name, setName] = useState(DEFAULT_VIEW_NAME);
  const [id, setId] = useState<string>();

  const history = useHistory();
  const location = useLocation();

  const setIdFromSearchParam = () => {
    const param = new URLSearchParams(location.search).get(SEARCH_PARAM_NAME);
    if (param && id !== param) setId(param);
  };

  useEffect(setIdFromSearchParam, [location.search]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    name,
    setName,
    id,
    setId(id?: string) {
      if (id) {
        // Setting this triggers the side effect above
        // (which changes the state of the id).
        history.replace({ search: `?${SEARCH_PARAM_NAME}=${id}` });
      } else {
        setId(undefined);
        history.replace({ search: undefined });
      }
    }
  };
};

export { useView };

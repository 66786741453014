import { ICacheableStandardComparison, IStandardComparison } from 'shared/data/IStandardComparison';
import { ComparisonTransforms } from 'shared/utils/comparisonTransforms';
import { IDimMetric } from 'shared/data/uiConfigService';
import { cacheableHttpPost } from 'shared/data';

export const kpiSnowflakeComparisonService = {
  async fetchComparison(
    comparisonId: string,
    signal: AbortSignal,
    params: any,
    token: string,
    strataIdAsString: string,
    metric: IDimMetric
  ): Promise<ICacheableStandardComparison> {
    const getUrl = () => {
      const kpiNewUrls = {
        ENTITY: '/kpi-comparisons/entities',
        MY_ENTITIES: '/kpi-comparisons/my-entities',
        SYSTEM: '/kpi-comparisons/systems'
      };

      const isWithinOwnSystem = params.compareHealthSystem;
      const isSystem = params.selectedEntity?.split(' | ')[1] === 'Health System';

      if (isWithinOwnSystem) {
        return kpiNewUrls.MY_ENTITIES;
      }

      return isSystem ? kpiNewUrls.SYSTEM : kpiNewUrls.ENTITY;
    };

    const response = await cacheableHttpPost<IStandardComparison>(
      getUrl(),
      {
        ...params,
        strataId: parseInt(strataIdAsString, 10),
        metricId: metric.metricId,
        careFamily: params.careFamily || 'ALL',
        comparisonId
      },
      {
        signal
      }
    );

    // All percentage-based KPI metrics need to have their values multiplied by 100,
    // and same for readmission metrics.
    if (
      metric.axisFormat.includes('%') ||
      ['ThirtyDayReadmissionRate', 'NintyDayReadmissionRate'].includes(metric.metricAbbr)
    ) {
      ComparisonTransforms.multiplyValuesBy100(response);
    }

    return {
      ...response,
      token,
      dataName: metric.metricAbbr
    };
  }
};

import { IFilterConfig } from 'shared/filters/IFilter';

import { TimelineControl } from './TimelineControl';
import { TimelineSetting } from './TimelineSetting';
import { service } from './service';

export interface ITimelineData {
  startDate: string;
  endDate: string;
  histStartDate: string;
  prelimEndDate: string;
}

export const timeline: IFilterConfig<ITimelineData> = {
  Control: TimelineControl,
  controlWeight: 3,
  defaultValue: {} as ITimelineData,
  param: (filterValue) => ({
    startDate: filterValue.startDate,
    endDate: filterValue.endDate
  }),
  loadFromParams(params, setFilterValue, defaultFilterValue) {
    // The EndDate is optional. Users who don't save one will have filters that load the defaultEndDate (which changes each month)
    setFilterValue((prevValue) => ({
      ...prevValue,
      startDate: encodeDatalakeDateFormat(String(params.startDate)),
      endDate: params.endDate
        ? encodeDatalakeDateFormat(String(params.endDate))
        : defaultFilterValue.endDate
    }));
  },
  Setting: TimelineSetting,
  settingWeight: 1,
  async setup() {
    const response = await service.fetchTimelineDefaults();

    const defaultStartDate = String(response.defaultStartDate);
    const defaultEndDate = String(response.defaultEndDate);
    const histStartDate = String(response.histStartDate);
    const prelimEndDate = String(response.prelimEndDate);

    return {
      value: {
        startDate: defaultStartDate,
        endDate: defaultEndDate,
        histStartDate: histStartDate,
        prelimEndDate: prelimEndDate
      },
      data: {
        defaultStartDate: defaultStartDate,
        defaultEndDate: defaultEndDate,
        histStartDate: histStartDate,
        prelimEndDate: prelimEndDate
      }
    };
  }
};
// Converts legacy date format to datalake date format.
// "mm|yyyy" -> "yyyymm"
function encodeDatalakeDateFormat(date = ''): string {
  if (!date.includes('|')) {
    return date;
  }

  const parts = date.split('|');

  return parts[1] + parts[0];
}

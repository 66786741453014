import React, { FC } from 'react';
import { Spacing, Text } from '@strata/tempo/lib';

import { tempoChartColors } from 'shared/charts/style';
import './legend.scss';

interface ILegendProps {
  items: ILegendItemProps[];
}

const Legend: FC<ILegendProps> = (props) => {
  return (
    <div className='legend'>
      <Spacing itemSpacing={16} hAlign='center' margin='0 0 16px'>
        {props.items.map((item) => (
          <LegendItem key={item.text} {...item} />
        ))}
      </Spacing>
    </div>
  );
};

export interface ILegendItemProps {
  text: string;
  color: keyof typeof tempoChartColors;
  onClick?: (text: string) => unknown;
}

const LegendItem: FC<ILegendItemProps> = (props) => {
  const handleClick = () => props.onClick?.(props.text);
  return (
    <div className='legend-item' onClick={handleClick}>
      <Spacing>
        <div className='legend-tile' style={{ background: tempoChartColors[props.color] }} />
        <Text size='small'>{props.text}</Text>
      </Spacing>
    </div>
  );
};

export default Legend;

// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useWindowSize } from '@strata/tempo/lib';
import * as Plot from '@observablehq/plot';

import './plot.scss';

const Chart = (props) => {
  const ref = useRef(null);
  const settings = useWithDefaultOptions(props.options, ref);

  useEffect(() => {
    if (!settings.marks || !settings.width || !settings.height) return;
    const svg = Plot.plot(settings);
    const element = props.canvas ? drawInlineSVG(svg, settings) : svg;
    ref.current.replaceChildren(element);
    return () => element.remove();
  }, [settings]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={`plot ${props.className}`}
      ref={ref}
      style={{ height: '100%', ...props.style }}
    />
  );
};

Chart.propTypes = {
  canvas: PropTypes.bool,
  className: PropTypes.string,
  options: PropTypes.object,
  style: PropTypes.object
};

export default Chart;

/**
 * Merges props.options with sensible defaults,
 * and the chart's width & height are made responsive.
 */
const useWithDefaultOptions = (options, ref) => {
  const windowSize = useWindowSize();
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  // Make the chart respond to width/height changes
  useEffect(() => {
    if (ref.current) {
      setWidth(ref.current.offsetWidth);
      setHeight(ref.current.offsetHeight);
    }
  }, [windowSize, ref.current?.offsetWidth, ref.current?.offsetHeight]); // eslint-disable-line react-hooks/exhaustive-deps

  const calculatedHeight = height + (options.heightOffset || 0);

  return {
    ...options,
    height: calculatedHeight > 0 ? calculatedHeight : 0,
    marks: options.marks,
    marginRight: options.marginRight || 10,
    marginLeft: options.marginLeft || 40,
    style: {
      overflow: 'visible',
      background: 'transparent',
      color: '#777',
      ...options.style
    },
    width,
    x: {
      grid: true,
      label: null,
      color: '#000',
      ...options.x
    },
    y: {
      grid: true,
      label: null,
      color: '#000',
      ...options.y
    }
  };
};

function drawInlineSVG(svgElement, settings) {
  // settings.width = settings.width * 2;
  // settings.height = settings.height * 0.9;
  var canvas = document.createElement('canvas');
  canvas.width = settings.width;
  canvas.height = settings.height;
  var ctx = canvas.getContext('2d');
  var svgURL = new XMLSerializer().serializeToString(svgElement);
  var img = new Image();
  img.onload = function () {
    ctx.drawImage(this, 0, 0, settings.width, settings.height);
  };
  img.width = settings.width;
  img.height = settings.height;
  img.src = 'data:image/svg+xml; charset=utf8, ' + encodeURIComponent(svgURL);
  return canvas;
}

import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { DropDown, Form } from '@strata/tempo/lib';

const FiscalYearEndControl: FC<IFilterControlProps<string[]>> = ({
  filterValue,
  setFilterValue,
  filterConfig
}) => {
  return (
    <Form.Item label='Fiscal Year End'>
      <DropDown
        allowClear
        multiSelect
        items={filterConfig.controlOptions}
        value={filterValue}
        onChange={(val) => setFilterValue(val as typeof filterConfig.defaultValue)}
      />
    </Form.Item>
  );
};

const FiscalYearEndSetting: FC<IFilterSettingProps<string[]>> = ({ filterValue }) => {
  const selectedOptions = filterValue
    .sort((a, b) => Number(a) - Number(b))
    .map((value) => options?.find((entry) => entry.value === value)?.text);

  return (
    <Setting
      isVisible={filterValue.length > 0}
      label='Fiscal Year End'
      value={selectedOptions.join(', ')}
    />
  );
};

const options = [
  { value: '1', text: 'January' },
  { value: '2', text: 'February' },
  { value: '3', text: 'March' },
  { value: '4', text: 'April' },
  { value: '5', text: 'May' },
  { value: '6', text: 'June' },
  { value: '7', text: 'July' },
  { value: '8', text: 'August' },
  { value: '9', text: 'September' },
  { value: '10', text: 'October' },
  { value: '11', text: 'November' },
  { value: '12', text: 'December' }
];

export const fiscalYearEnd: IFilterConfig<string[]> = {
  Control: FiscalYearEndControl,
  controlOptions: options,
  controlWeight: 5.2,
  defaultValue: [],
  param: 'fiscalYearEnd',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.fiscalYearEnd);
  },
  Setting: FiscalYearEndSetting,
  settingWeight: 5.2
};

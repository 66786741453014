/* eslint-disable @typescript-eslint/no-explicit-any */

import { ICacheableStandardComparison, IStandardComparison } from 'shared/data/IStandardComparison';
import { IClientData } from 'shared/data/clientDataService';
import { cacheableHttpPost } from 'shared/data';

export interface ICostComparisonService {
  getUrl(params: any): string;
  fetchAllCosts(
    comparisonId: string,
    params: any,
    token: string,
    signal: AbortSignal,
    clientData: IClientData
  ): Promise<ICacheableCostComparison[]>;
  fetchTotalCostOnly(
    comparisonId: string,
    params: any,
    token: string,
    signal: AbortSignal,
    clientData: IClientData
  ): Promise<ICacheableCostComparison[]>;
}

// Cost Component Rollup names
export const rollupNames = [
  'Supplies',
  'Benefits',
  'Building and Equipment',
  'Drugs',
  'Implants',
  'Other',
  'Salaries',
  'All'
] as const;
export type RollupName = (typeof rollupNames)[number];

export const driverNames = [
  'Anesthesia and Recovery',
  'Imaging',
  'Laboratory',
  'LOS',
  'OR Time',
  'Other',
  'Pharmacy',
  'Supplies',
  'ALL'
] as const;
export type DriverName = (typeof driverNames)[number];

export interface ICacheableCostComparison extends ICacheableStandardComparison {
  rollupName: RollupName;
}

interface ICostDto {
  rollupName?: string;

  directCost: Omit<IStandardComparison, 'dataName'> | null;
  fixedDirectCost: Omit<IStandardComparison, 'dataName'> | null;
  fixedIndirectCost: Omit<IStandardComparison, 'dataName'> | null;
  indirectCost: Omit<IStandardComparison, 'dataName'> | null;
  totalCost: Omit<IStandardComparison, 'dataName'>;
  variableDirectCost: Omit<IStandardComparison, 'dataName'> | null;
  variableIndirectCost: Omit<IStandardComparison, 'dataName'> | null;

  sphTotDirectCost_Case: Omit<IStandardComparison, 'dataName'> | null;
  sphFixedDirectCost_Case: Omit<IStandardComparison, 'dataName'> | null;
  sphFixedIndirectCost_Case: Omit<IStandardComparison, 'dataName'> | null;
  sphTotIndirectCost_Case: Omit<IStandardComparison, 'dataName'> | null;
  sphTotCost_Case: Omit<IStandardComparison, 'dataName'>;
  sphVarDirectCost_Case: Omit<IStandardComparison, 'dataName'> | null;
  sphVarIndirectCost_Case: Omit<IStandardComparison, 'dataName'> | null;

  sphTotDirectCost_Day: Omit<IStandardComparison, 'dataName'> | null;
  sphFixedDirectCost_Day: Omit<IStandardComparison, 'dataName'> | null;
  sphFixedIndirectCost_Day: Omit<IStandardComparison, 'dataName'> | null;
  sphTotIndirectCost_Day: Omit<IStandardComparison, 'dataName'> | null;
  sphTotCost_Day: Omit<IStandardComparison, 'dataName'>;
  sphVarDirectCost_Day: Omit<IStandardComparison, 'dataName'> | null;
  sphVarIndirectCost_Day: Omit<IStandardComparison, 'dataName'> | null;
}

const costUrls = {
  ENTITY: '/cost-comparisons/entities',
  SYSTEM: '/cost-comparisons/systems',
  MY_ENTITIES: '/cost-comparisons/my-entities'
};

export const costComparisonService: ICostComparisonService = {
  getUrl(params) {
    if (params.compareHealthSystem) {
      return costUrls.MY_ENTITIES;
    }
    const isSystem = params.selectedEntity?.split(' | ')[1] === 'Health System';
    return isSystem ? costUrls.SYSTEM : costUrls.ENTITY;
  },
  async fetchAllCosts(comparisonId, params, token, signal, clientData) {
    const [response] = await cacheableHttpPost<ICostDto[]>(
      this.getUrl(params),
      {
        ...params,
        isRequestingTotalCostsOfComponentsOrDrivers: false,
        comparisonId,
        isOnboarding: clientData.isSpecialAccessCost && !clientData.isProductionCost,
        strataId: clientData.strataId
      },
      {
        signal
      }
    );
    return toCacheableCostComparisons(response, params, token);
  },
  async fetchTotalCostOnly(comparisonId, params, token, signal, clientData) {
    // Expect there to be a ICostDto for each entry within params.sphCostComponentRollup.
    const response = await cacheableHttpPost<ICostDto[]>(
      this.getUrl(params),
      {
        ...params,
        isRequestingTotalCostsOfComponentsOrDrivers: true,
        comparisonId,
        isOnboarding: clientData.isSpecialAccessCost && !clientData.isProductionCost,
        strataId: clientData.strataId
      },
      {
        signal
      }
    );

    return response.flatMap((costDto) => toCacheableCostComparisons(costDto, params, token));
  }
};

function toCacheableCostComparisons(
  costDto: ICostDto,
  params: any,
  token: string
): ICacheableCostComparison[] {
  const { rollupName, ...comparisons } = costDto;

  return Object.entries(comparisons)
    .map(([dataName, standardComparison]) => {
      if (!standardComparison) {
        return null;
      }

      return {
        ...standardComparison,
        dataName,
        rollupName: rollupName || 'All',
        token
      };
    })
    .filter((entry) => entry !== null) as ICacheableCostComparison[];
}

import React, { FC, useState } from 'react';
import { Button, Modal } from '@strata/tempo/lib';
import Header from '@strata/tempo/lib/header';
import Mappings from 'mappings/Mappings';
import { useCompareHelpCenter } from './useCompareHelpCenter';
import InfoCircleIcon from '@strata/tempo/lib/icon/InfoCircleIcon';

const CompareHeader: FC = () => {
  const { openHelpPageAtRoute } = useCompareHelpCenter();
  const [showingModal, setShowingModal] = useState(false);

  const openUserGuide = () =>
    window.open('https://secure.stratanetwork.com/helpcenter/compare/compare.htm');

  return (
    <>
      <Header
        title='StrataSphere Compare'
        extra={
          <div id='navigation-extra' style={{ display: 'flex', justifyContent: 'space-between' }}>
            {/* WalkMe prepends the Take a Tour button here */}
            <Button onClick={openUserGuide}>User Guide</Button>
            <Button onClick={() => setShowingModal(true)}>SPH Mappings</Button>
            <Button onClick={openHelpPageAtRoute} type='tertiary' icon={<InfoCircleIcon />} />
          </div>
        }
      />
      <Modal
        footer={null}
        removeBodyPadding
        onCancel={() => setShowingModal(false)}
        title='StrataSphere® Compare Mappings'
        visible={showingModal}
        width='extraLarge'
      >
        <Mappings />
      </Modal>
    </>
  );
};

export default CompareHeader;

import { useState, useEffect } from 'react';
import { IOverheadMetric } from './IOverheadMetric';
import { IFilterGroup } from 'shared/filters';
import { OverheadFilterKeys } from 'overhead/filters/useOverheadFilterGroup';
import { useUiConfigContext } from 'shared/data/useUiConfigContext';

export type UseOverheadMetrics = IOverheadMetric[];

const useOverheadMetrics = (filterGroup: IFilterGroup<OverheadFilterKeys>): UseOverheadMetrics => {
  const uiConfigContext = useUiConfigContext();
  const [metrics, setMetrics] = useState(uiConfigContext.overheadMetrics);
  const department = filterGroup.filters.departmentRollup.filterValue;

  // An Overhead Metric's "Friendly_Name" is computed
  // from the user's overhead department filter. And it's department field is set.
  useEffect(() => {
    const departmentLabel = department === 'All Rollups' ? 'Overhead' : department;
    setMetrics(
      uiConfigContext.overheadMetrics.map((entry) => {
        return {
          ...entry,
          department,
          Friendly_Name: departmentLabel + ' ' + entry.Friendly_Name
        };
      })
    );
  }, [department, uiConfigContext.overheadMetrics]);

  return metrics;
};

useOverheadMetrics.returnType = [] as UseOverheadMetrics;

export default useOverheadMetrics;

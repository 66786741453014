import {
  IFilterDictionary,
  IFilterGroup,
  toParams,
  toToken,
  useFilter,
  useGlobalFilterContext
} from 'shared/filters';
import { departmentRollup, medicalGroup } from 'overhead/filters/config';

export const useOverheadFilterGroup = (): IFilterGroup<OverheadFilterKeys> => {
  const globalFilterContext = useGlobalFilterContext();

  const filters: IFilterDictionary<OverheadFilterKeys> = {
    timeline: globalFilterContext.filterGroup.filters.timeline,
    departmentRollup: useFilter(departmentRollup),
    censusRegion: globalFilterContext.filterGroup.filters.censusRegion,
    healthSystemType: globalFilterContext.filterGroup.filters.healthSystemType,
    outlierExclusion: globalFilterContext.filterGroup.filters.outlierExclusion,
    systemOperatingExpense: globalFilterContext.filterGroup.filters.systemOperatingExpense,
    medicalGroup: useFilter(medicalGroup)
  };

  return {
    filters,
    params: toParams(filters),
    token: toToken(filters),
    ready: globalFilterContext.filterGroup.ready
  };
};

export type OverheadFilterKeys =
  | 'timeline'
  | 'departmentRollup'
  | 'censusRegion'
  | 'healthSystemType'
  | 'outlierExclusion'
  | 'systemOperatingExpense'
  | 'medicalGroup';

import React, { FC, useEffect, useRef, useState } from 'react';
import {
  DataGrid,
  Button,
  Text,
  DataHeaderCell,
  DataHeaderRow,
  DataHeaderGroup
} from '@strata/tempo/lib';
import { useServiceLineContext } from './data/ServiceLineContext';
import { IDataGridSelectionEvent } from '@strata/tempo/lib/datagrid/DataGrid';
import {
  asISlCfTableRows,
  datadogIdentifier,
  formatMoney,
  formatMoneyToNumber,
  ISlCfTableRow
} from './data/serviceLineService';
import CareFamilyTable from './CareFamilyTable';

import './service-line-care-family-table.scss';
import { logger } from '@strata/logging/lib';
import MinusSquareIcon from '@strata/tempo/lib/icon/MinusSquareIcon';
import PlusSquareIcon from '@strata/tempo/lib/icon/PlusSquareIcon';

export interface ISelectedTableRow {
  value: string;
  type: 'Care Family' | 'Service Line';
}

const ServiceLineTable: FC = (props) => {
  const slContext = useServiceLineContext();
  const ref = useRef<DataGrid>(null);

  const handleSelection = (e: IDataGridSelectionEvent) => {
    if (!e.value) {
      return;
    }

    logger.log('click', 'selected service line table row', {
      comparisonId: datadogIdentifier.getId()
    });

    slContext.setSelectedTableRow({
      value: e.value.name,
      type: 'Service Line'
    });
  };

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleExpansion = async (...args: any) =>
    alert('fix ServiceLineTable handleExpansion! Code removed for data conversion');
  // const handleExpansion = async (e: any, rowData: ISlCfData) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   if (expandedRowKeys.includes(rowData.name)) {
  //     setExpandedRowKeys([]);
  //     return;
  //   }
  //
  //   setExpandedRowKeys([rowData.name]);
  //   slContext.careFamilyTable.load(rowData.name);
  // };

  const tableData = asISlCfTableRows(slContext.serviceLineTable.data?.averages ?? []).filter(
    (row) => {
      const rowIsAboveMinimumVolumeFilter =
        row.volumeUser > slContext.filterGroup.filters.minimumVolume.filterValue;
      const rowIncludesFilterText = Object.values(row).some((data) =>
        String(data).toLowerCase().includes(slContext.tableFilter.toLowerCase())
      );
      return rowIncludesFilterText && rowIsAboveMinimumVolumeFilter;
    }
  );
  (window as any).tableData = tableData;
  (window as any).slContext = slContext;
  (window as any).asTableData = asISlCfTableRows;

  const emptyTableData = !tableData.length && slContext.serviceLineTable.loadingDone;
  const emptyMessage = emptyTableData ? 'Missing Cohort' : 'Loading';

  const getTableRowFromName = (name: string) => tableData.find((entry) => entry.name === name);

  const getLosRowData = (field: string) => (rowData: ISlCfTableRow) =>
    slContext.filterGroup.filters.patientType.filterValue === '3'
      ? rowData[field as keyof typeof rowData]
      : '--';

  // Select the first row every time the data loads
  useEffect(() => {
    const firstRow = tableData[0];
    if (slContext.serviceLineTable.loadingDone && firstRow) {
      setExpandedRowKeys([]);
      slContext.setSelectedTableRow({
        value: firstRow.name,
        type: 'Service Line'
      });
    }
  }, [slContext.serviceLineTable.loadingDone]);

  return (
    <div className='service-line-table'>
      <DataGrid
        ref={ref}
        columnHeaderGroup={
          <DataHeaderGroup>
            <DataHeaderRow>
              <DataHeaderCell width={50} isGap />
              <DataHeaderCell width={150} isGap />
              <DataHeaderCell width={120} isGap />
              <DataHeaderCell isGap />
              <DataHeaderCell
                colSpan={2}
                header='MS-DRG Case Mix Index'
                align='center'
                width={210}
              />
              <DataHeaderCell isGap />
              <DataHeaderCell colSpan={3} header='Length of Stay' align='center' width={300} />
              <DataHeaderCell isGap />
              <DataHeaderCell
                colSpan={3}
                header='Direct Cost per Case'
                align='center'
                width={360}
              />
              <DataHeaderCell isGap colSpan={3} />
            </DataHeaderRow>
            <DataHeaderRow>
              <DataHeaderCell width={50} isGap />
              <DataHeaderCell
                align='left'
                width={150}
                field='name'
                header='Service Line'
                sortable
              />
              <DataHeaderCell
                align='right'
                field='volumeUser'
                header='Your Average Monthly Volume'
                width={120}
                sortable
              />
              <DataHeaderCell isGap />
              <DataHeaderCell
                field='cmiUser'
                header='Your Average CMI'
                align='right'
                width={90}
                sortable
              />
              <DataHeaderCell
                field='cmiMedian'
                header='Cohort Median CMI'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell isGap />
              <DataHeaderCell
                field='losUser'
                header='Your Average LOS'
                align='right'
                width={90}
                sortable
              />
              <DataHeaderCell
                field='losMedian'
                header='Cohort Median LOS'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell
                field={slContext.diffType === 'absolute' ? 'losDiff' : 'losDiffPercentage'}
                header='LOS &#120491;'
                align='right'
                width={90}
                sortable
              />
              <DataHeaderCell isGap />
              <DataHeaderCell
                field='costUser'
                header='Your Average Direct Cost'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell
                field='costMedian'
                header='Cohort Median Direct Cost'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell
                field={slContext.diffType === 'absolute' ? 'costDiff' : 'costDiffPercentage'}
                header='Cost &#120491;'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell colSpan={3} isGap />
            </DataHeaderRow>
          </DataHeaderGroup>
        }
        dataKey='name'
        density='dense'
        emptyMessage={emptyMessage}
        expandedRowKeys={expandedRowKeys}
        itemName='Service Lines'
        loading={slContext.serviceLineTable.loading}
        onSelectionChange={handleSelection}
        rowExpansionTemplate={() => <CareFamilyTable />}
        selection={getTableRowFromName(slContext.selectedTableRow?.value ?? '')}
        sortOrder={-1}
        sortField='volumeUser'
        value={tableData}
        scrollable
        scrollHeight='500px'
      >
        <DataGrid.Column
          width={50}
          body={(rowData: ISlCfTableRow) => {
            const isExpanded = expandedRowKeys.includes(rowData.name);
            return (
              <Button
                {...{ title: isExpanded ? 'Hide Care Families' : 'Expand for Care Families' }}
                type='link'
                icon={isExpanded ? <MinusSquareIcon /> : <PlusSquareIcon />}
                onClick={(e) => handleExpansion(e, rowData)}
                {...{ className: isExpanded ? 'expanded' : '' }}
              />
            );
          }}
        />
        <DataGrid.Column
          key='name'
          width={150}
          header='Service Line'
          field='name'
          sortable
          align={'left'}
        />
        <DataGrid.Column
          field='volumeUser'
          key='volume'
          width={120}
          header='Average Monthly Volume'
          sortable
          align={'right'}
        />
        <DataGrid.GapColumn />
        <DataGrid.Column
          field='cmiUser'
          key='cmi'
          width={90}
          header='MS-DRG Case Mix Index My CMI'
          sortable
          align={'right'}
        />
        <DataGrid.Column
          field='cmiMedian'
          key='cmiMedian'
          width={120}
          header='MS-DRG Case Mix Index Median'
          sortable
          align={'right'}
        />
        <DataGrid.GapColumn />
        <DataGrid.Column
          field='losUser'
          key='los'
          width={90}
          header='Length of Stay My LOS'
          sortable
          align={'right'}
          body={getLosRowData('losUser')}
        />
        <DataGrid.Column
          field='losMedian'
          key='losMedian'
          width={120}
          header='Length of Stay Median'
          sortable
          align={'right'}
          body={getLosRowData('losMedian')}
        />
        {slContext.diffType === 'absolute' ? (
          <DataGrid.Column
            key='losDiff'
            field='losDiff'
            {...{ title: 'Length of Stay Delta' }}
            width={90}
            header='Length of Stay LOS &#120491;'
            sortable
            align={'right'}
            body={({ losDiff, losUser, losMedian }) => {
              if (slContext.filterGroup.filters.patientType.filterValue !== '3') {
                return '--';
              }
              return (
                <Text
                  color={
                    formatMoneyToNumber(losUser) <= formatMoneyToNumber(losMedian)
                      ? 'success'
                      : 'error'
                  }
                >
                  {losDiff}
                </Text>
              );
            }}
          />
        ) : (
          <DataGrid.Column
            key='losDiffPercentage'
            field='losDiffPercentage'
            {...{ title: 'Length of Stay Delta as %' }}
            width={90}
            header='Length of Stay LOS &#120491;'
            sortable
            align={'right'}
            body={({ losDiffPercentage, losUser, losMedian }) => {
              if (slContext.filterGroup.filters.patientType.filterValue !== '3') {
                return '--';
              }
              return (
                <Text
                  color={
                    formatMoneyToNumber(losUser) <= formatMoneyToNumber(losMedian)
                      ? 'success'
                      : 'error'
                  }
                >
                  {Number(losDiffPercentage).toFixed(2) + '%'}
                </Text>
              );
            }}
          />
        )}
        <DataGrid.GapColumn />
        <DataGrid.Column
          field='costUser'
          key='dirCost'
          width={120}
          header='Direct Cost My Cost'
          sortable
          align={'right'}
          body={({ costUser }) => {
            return formatMoney(costUser);
          }}
        />
        <DataGrid.Column
          field='costMedian'
          key='dirCostMedian'
          width={120}
          header='Direct Cost Median'
          sortable
          align={'right'}
          body={({ costMedian }) => {
            return formatMoney(costMedian);
          }}
        />
        {slContext.diffType === 'absolute' ? (
          <DataGrid.Column
            key='costDiff'
            field='costDiff'
            width={120}
            header='Direct Cost Direct Cost &#120491;'
            {...{ title: 'Direct Cost Delta' }}
            sortable
            sortField='costDiff'
            align={'right'}
            body={({ costDiff, costUser, costMedian }) => {
              return (
                <Text color={costUser <= costMedian ? 'success' : 'error'}>
                  {formatMoney(costDiff)}
                </Text>
              );
            }}
          />
        ) : (
          <DataGrid.Column
            key='costDiffPercentage'
            field='costDiffPercentage'
            width={120}
            header='Direct Cost Direct Cost &#120491; %'
            {...{ title: 'Direct Cost Delta %' }}
            sortable
            sortField='costDiffPercentage'
            align={'right'}
            body={({ costDiffPercentage, costUser, costMedian }) => {
              return (
                <Text color={costUser <= costMedian ? 'success' : 'error'}>
                  {Number(costDiffPercentage).toFixed(2) + '%'}
                </Text>
              );
            }}
          />
        )}
        <DataGrid.GapColumn />
        <DataGrid.SelectionColumn selectionMode='single' />
        <DataGrid.GapColumn />
      </DataGrid>
    </div>
  );
};

export default ServiceLineTable;

import React, { FC } from 'react';
import { Toggle, RadioChangeEvent } from '@strata/tempo/lib';
import { useVisualizationContext, Visualization } from 'shared/data/visualizationContext';

type ToggleItems = { value: Visualization; name: string }[];

const ComparisonViewToggle: FC = () => {
  const visualizationContext = useVisualizationContext();

  const items: ToggleItems = [
    { value: 'Line Chart', name: 'Monthly Trend' },
    { value: 'Histogram', name: 'Histogram' },
    { value: 'Table', name: 'Table' }
  ];

  const handleChange = (event: RadioChangeEvent) => {
    visualizationContext.setSelectedVisualization(event.target.value);
  };

  return (
    <div className='toggle-container'>
      <Toggle
        defaultValue={visualizationContext.selectedVisualization}
        items={items}
        onChange={handleChange}
      />
    </div>
  );
};

export default ComparisonViewToggle;

import React from 'react';
import ChartHistogram from 'shared/charts/ChartHistogram';
import { useCostContext } from '../data/costContext';
import { Card, DropDown, Spacing, Text } from '@strata/tempo/lib';
import IncompleteCard from 'shared/comparisons/cards/IncompleteCard';
import { IncompleteReason } from 'shared/data/IBaseComparison';
import NoDataLoadedCard from 'shared/comparisons/cards/NoDataLoadedCard';

const CostHistogramCard = () => {
  const costContext = useCostContext();

  const selectedCostTypeItem = costContext.items
    .filter((entry) => entry.rollupName === 'All')
    .find((entry) => entry.metric.Data_Name === costContext.selectableMetrics.selected[0]);

  let content;
  if (!selectedCostTypeItem?.comparison) {
    content = <NoDataLoadedCard />;
  } else if (selectedCostTypeItem.comparison.incomplete) {
    content = (
      <IncompleteCard
        reason={selectedCostTypeItem.comparison.incompleteReason as IncompleteReason}
      />
    );
  } else {
    content = (
      <ChartHistogram
        key={selectedCostTypeItem.metric.Friendly_Name}
        data={selectedCostTypeItem.comparison}
        format={selectedCostTypeItem.metric.Format}
        options={{
          marginRight: 25,
          marginLeft: 40,
          y: {
            label: 'Frequency',
            labelOffset: 32
          }
        }}
        isOnboarding={costContext.isOnboarding}
      />
    );
  }

  const dropdownOptions = costContext.selectableMetrics.metrics
    // Hide metrics that aren't in the selected submodule (ie, show either cost per day or cost per case metrics)
    .filter((m) => m.submodule === costContext.filterGroup.filters.submodule.filterValue)
    .map(({ Friendly_Name, Data_Name }) => ({
      text: Friendly_Name,
      value: Data_Name
    }));

  return (
    <Card height={400} removeBodyPadding loading={costContext.costComparisons.loading}>
      <Spacing column height={400} padding='8px'>
        <Spacing column padding='8px'>
          <Spacing margin='0 0 8px 0' hAlign={'space-between'}>
            <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
              Histogram
            </Text.Heading>
            <DropDown
              width={250}
              onChange={(newMetric) => costContext.selectableMetrics.select([newMetric as string])}
              value={selectedCostTypeItem?.metric.Friendly_Name}
              items={dropdownOptions}
            />
          </Spacing>
        </Spacing>
        {content}
      </Spacing>
    </Card>
  );
};

export default CostHistogramCard;

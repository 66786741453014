import React, { FC, createContext, useContext, useEffect } from 'react';
import useDrawer, { IUseDrawer } from 'shared/drawers/data/useDrawer';
import useSelectableMetrics, { IUseSelectableMetrics } from 'shared/data/useSelectableMetrics';
import useSelectableComparisons, {
  IUseSelectableComparisons
} from 'shared/comparisons/data/useSelectableComparisons';
import { kpiSnowflakeComparisonService } from './kpiComparisonService';
import pairMetricsAndComparisons, {
  IPairedMetricsAndComparisons
} from 'shared/data/pairedMetricsAndComparisons';
import IBaseMetric, { MetricDataName } from 'shared/data/IBaseMetric';
import { IFilterGroup } from 'shared/filters/IFilter';
import { useKpiFilterGroup, KpiFilterKeys } from '../filters/useKpiFilterGroup';
import { useUiConfigContext } from 'shared/data/useUiConfigContext';
import { ICacheableStandardComparison } from '../../shared/data/IStandardComparison';

export interface IKpiContext {
  compareDrawer: IUseDrawer;
  filterGroup: IFilterGroup<KpiFilterKeys>;
  insightDrawer: IUseDrawer;
  loadComparisons: () => void;
  selectableComparisons: IUseSelectableComparisons;
  selectableMetrics: IUseSelectableMetrics<IBaseMetric>;

  // computed
  selectableItems: IPairedMetricsAndComparisons[];
  selectedItem?: ISelectedItem;
  outOfScopeMetrics: MetricDataName[];
}

export interface ISelectedItem {
  metric: IBaseMetric;
  comparison: ICacheableStandardComparison;
}

const context = createContext({} as IKpiContext);

const KpiProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const compareDrawer = useDrawer();
  const filterGroup = useKpiFilterGroup();
  const insightDrawer = useDrawer();
  const selectableComparisons = useSelectableComparisons(
    kpiSnowflakeComparisonService,
    filterGroup
  );
  const uiConfigContext = useUiConfigContext();
  const selectableMetrics = useSelectableMetrics(uiConfigContext.kpiMetrics);
  const selectableItems = pairMetricsAndComparisons(selectableMetrics, selectableComparisons);
  const isFilteringOnServiceLine = Boolean(filterGroup.filters.serviceLine.filterValue);
  const outOfScopeMetrics = isFilteringOnServiceLine
    ? selectableMetrics.metrics
        .filter((entry) => !entry.Scope.includes('Sg2ServiceLine'))
        .map((entry) => entry.Data_Name)
    : [];

  const provided = {
    compareDrawer,
    filterGroup,
    insightDrawer,
    loadComparisons: () => {
      const eligibleMetrics = selectableMetrics.selected.filter(
        (entry) => !outOfScopeMetrics.includes(entry)
      );
      selectableComparisons.load(eligibleMetrics);
    },
    outOfScopeMetrics,
    selectableComparisons,
    selectableMetrics,
    selectableItems,
    selectedItem: selectableItems.find(
      (item) => item.comparison?.dataName === selectableComparisons.selected
    ) as ISelectedItem
  };

  useEffect(() => {
    if (provided.selectableItems.find((item) => !item.comparison)) {
      selectableComparisons.setLoadingDone(false);
    }
  }, [provided.selectableItems]); // eslint-disable-line react-hooks/exhaustive-deps

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

const useKpiContext = (): IKpiContext => useContext(context);

export { useKpiContext, KpiProvider };

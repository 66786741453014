import React from 'react';
import { useLocation } from 'react-router-dom';
import { createContext, FC, useContext } from 'react';
import { flagsConfig } from './flagsConfig';

const context = createContext(flagsConfig);

const FlagsProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const location = useLocation();
  const providedValue = Object.keys(flagsConfig).reduce(
    (value, key) => {
      value[key as keyof typeof flagsConfig] =
        location.search.includes(key) || flagsConfig[key as keyof typeof flagsConfig];

      // The value of each flag is either what's hardcoded in flagsConfig or is turned on by appearing in the url!
      return value;
    },
    {} as typeof flagsConfig
  );

  return <context.Provider value={providedValue}>{props.children}</context.Provider>;
};

const useFlagsContext = () => useContext(context);

export { useFlagsContext, FlagsProvider };

import React, { FC } from 'react';
import { LayoutGrid } from '@strata/tempo/lib';

import CostTable from './CostTable';
import { useCostContext } from './data/costContext';

import CostHistogramCard from './cards/CostHistogramCard';
import CostMonthlyTrendCard from './cards/CostMonthlyTrendCard';
import CostByComponentCard from './cards/CostByComponentCard';
import CostTypeCard from './cards/CostTypeCard';
import CostByDriverCard from './cards/CostByDriverCard';

const CostComparisons: FC = () => {
  const costContext = useCostContext();

  // Items in the cost context are a mix of component (/driver) level costs, and the aggregation of that set.
  const aggregatedCosts = costContext.items.filter((entry) => entry.rollupName === 'All');

  const componentCosts = costContext.items
    .filter(
      (entry) =>
        entry.isComponent &&
        costContext.filterGroup.filters.componentRollup.filterValue.includes(
          entry.rollupName as string
        ) &&
        Boolean(entry.comparison)
    )
    // sort alphabetically, but with "other" being last
    .sort((a, b) => {
      return b.rollupName === 'Other'
        ? -1
        : (a.rollupName as string).localeCompare(b.rollupName as string);
    });

  const driverCosts = costContext.items
    .filter(
      (entry) =>
        entry.isDriver &&
        costContext.filterGroup.filters.costDriver.filterValue.includes(
          entry.rollupName as string
        ) &&
        Boolean(entry.comparison)
    )
    // sort alphabetically, but with "other" being last
    .sort((a, b) => {
      return b.rollupName === 'Other'
        ? -1
        : (a.rollupName as string).localeCompare(b.rollupName as string);
    });

  const columnProps = {
    span: 6,
    spanLarge: 6,
    spanMedium: 6,
    spanSmall: 12
  };

  return (
    <>
      <CostTable data={aggregatedCosts} />
      <LayoutGrid removeMargin>
        <LayoutGrid.Row>
          <LayoutGrid.Column {...columnProps}>
            <CostTypeCard
              withOutliers={!costContext.filterGroup.filters.outlierExclusion.filterValue}
              isOnboarding={costContext.isOnboarding}
            />
          </LayoutGrid.Column>

          <LayoutGrid.Column {...columnProps}>
            <CostMonthlyTrendCard />
          </LayoutGrid.Column>
        </LayoutGrid.Row>

        <LayoutGrid.Row>
          <LayoutGrid.Column {...columnProps}>
            {costContext.isViewingCostComponents ? (
              <CostByComponentCard
                data={componentCosts}
                withOutliers={!costContext.filterGroup.filters.outlierExclusion.filterValue}
                isOnboarding={costContext.isOnboarding}
                isLoading={costContext.costComparisons.loading}
              />
            ) : (
              <CostByDriverCard
                data={driverCosts}
                withOutliers={!costContext.filterGroup.filters.outlierExclusion.filterValue}
                isOnboarding={costContext.isOnboarding}
                isLoading={costContext.costComparisons.loading}
              />
            )}
          </LayoutGrid.Column>

          <LayoutGrid.Column {...columnProps}>
            <CostHistogramCard />
          </LayoutGrid.Column>
        </LayoutGrid.Row>
      </LayoutGrid>
    </>
  );
};

export default CostComparisons;

import { IFilterConfig, IFilterControlProps } from 'shared/filters/IFilter';
import { Form, Switch, Text } from '@strata/tempo/lib';
import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { useCostContext } from '../../../cost/data/costContext';
import { useServiceLineContext } from '../../../serviceline/data/ServiceLineContext';

const CompareWithinOwnHealthSystemFilterControl: FC<IFilterControlProps<boolean>> = ({
  filterValue,
  setFilterValue
}) => {
  const costContext = useCostContext();
  const serviceLineContext = useServiceLineContext();
  const location = useLocation();

  const isOnboarding = serviceLineContext.isOnboarding || costContext.isOnboarding;

  const isDisabled =
    (location.pathname.includes('cost') && isOnboarding) ||
    (location.pathname.includes('serviceline') && isOnboarding);

  return (
    <>
      <Text.Heading level={4}>Hospital Selection</Text.Heading>
      <Form.Item label='Compare Within My Health System'>
        <Switch checked={filterValue} onClick={setFilterValue} disabled={isDisabled} />
      </Form.Item>
    </>
  );
};

export const compareWithinOwnHealthSystem: IFilterConfig<boolean> = {
  Control: CompareWithinOwnHealthSystemFilterControl,
  controlWeight: 1,
  defaultValue: false,
  param: 'compareHealthSystem',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.compareHealthSystem);
  },
  Setting: () => null
};

import React, { FC, createContext, useContext } from 'react';
import { IFilterGroup } from 'shared/filters/IFilter';
import { useGlobalFilterGroup, GlobalFilterKeys } from './useGlobalFilterGroup';

export interface IGlobalFilterContext {
  filterGroup: IFilterGroup<GlobalFilterKeys>;
}

const context = createContext({} as IGlobalFilterContext);

const GlobalFilterProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const provided = {
    filterGroup: useGlobalFilterGroup()
  };

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

const useGlobalFilterContext = (): IGlobalFilterContext => useContext(context);

export { useGlobalFilterContext, GlobalFilterProvider };

import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { DropDown, Form, Text } from '@strata/tempo/lib';

const PatientTypeControl: FC<IFilterControlProps<string>> = ({ filterValue, setFilterValue }) => (
  <>
    <Text.Heading level={3}>Encounter Filters</Text.Heading>
    <Form.Item label='Patient Type'>
      <DropDown
        items={options}
        onChange={(val) => {
          setFilterValue((val as string) ?? '');
        }}
        value={filterValue}
      />
    </Form.Item>
  </>
);

const PatientTypeSetting: FC<IFilterSettingProps<string>> = (props) => {
  const selectedOptionText = props.filterComputed?.selectedOptionText;
  return (
    <Setting
      isVisible={Boolean(props.filterValue)}
      label='Patient Type'
      value={selectedOptionText}
    />
  );
};

const options = [
  { value: '3', text: 'Inpatient (cost per encounter)' },
  { value: '2', text: 'Observation (cost per encounter)' },
  { value: '0', text: 'Outpatient (cost per encounter)' }
];

export const patientType: IFilterConfig<string> = {
  computed: {
    selectedOptionText: (filterValue): string => {
      return options.find((entry) => entry.value === filterValue)?.text || '';
    },
    friendlyLabel: (filterValue): string => {
      const friendlyLabels = {
        [options[0].value]: 'Inpatients',
        [options[1].value]: 'Observations',
        [options[2].value]: 'Outpatients'
      };
      return friendlyLabels[filterValue];
    }
  },
  Control: PatientTypeControl,
  controlWeight: 5.1,
  defaultValue: options[0].value,
  param: 'patientType',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.patientType);
  },
  Setting: PatientTypeSetting,
  settingWeight: 5.1
};

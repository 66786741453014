import { IStandardComparison } from 'shared/data/IStandardComparison';
import { ICacheableOverheadComparison } from 'overhead/data/pairedMetricsAndOverheadComparisons';
import { ComparisonTransforms } from '../../shared/utils/comparisonTransforms';
import { cacheableHttpPost } from 'shared/data';

export interface IOverheadComparisonService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fetchComparison(
    comparisonId: string,
    params: any,
    token: string,
    signal: AbortSignal,
    strataId: number
  ): Promise<ICacheableOverheadComparison[]>;
}

interface IOverheadComparisonResponse {
  costOverExpenses: IStandardComparison;
  costOverRevenue: IStandardComparison;
}

export const overheadComparisonService: IOverheadComparisonService = {
  async fetchComparison(comparisonId, params, token, signal, strataId) {
    const { costOverExpenses, costOverRevenue } =
      await cacheableHttpPost<IOverheadComparisonResponse>(
        `/overhead-comparisons/systems`,
        {
          ...sanitizeParams(params),
          comparisonId,
          strataId,
          selectedEntity: 'Client | Health System'
        },
        {
          signal
        }
      );

    ComparisonTransforms.multiplyValuesBy100(costOverRevenue);
    ComparisonTransforms.multiplyValuesBy100(costOverExpenses);

    return [
      {
        ...costOverRevenue,
        dataName: 'costOverRevenue',
        metricId: 92,
        department: params.sphDepartmentRollupOverhead,
        token
      },
      {
        ...costOverExpenses,
        dataName: 'costOverExpenses',
        metricId: 91,
        department: params.sphDepartmentRollupOverhead,
        token
      }
    ];
  }
};

/*
This function only exists until a bug can be fixed on the backend.
The UI keeps the Overhead and Labor Department Rollup parameters separate,
but the API only uses the labor department's parameter. The bug is that it
ignores the field for Overhead.
This sanitizer takes the overhead department value and sends it to the
api under the labor department param.
TODO let the backend use the sphDepartmentRollupOverhead field during the overhead calculation, instead of sphDepartmentRollup
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sanitizeParams(params: any) {
  const { sphDepartmentRollupOverhead, ...rest } = params;
  return {
    ...rest,
    sphDepartmentRollup: sphDepartmentRollupOverhead
  };
}

import React, { FC, createContext, useContext } from 'react';
import useDrawer, { IUseDrawer } from 'shared/drawers/data/useDrawer';
import useOverheadComparisons, {
  IUseOverheadComparisons
} from 'overhead/data/useOverheadComparisons';
import { overheadComparisonService } from './overheadComparisonService';
import useOverheadMetrics, { UseOverheadMetrics } from './useOverheadMetrics';

import {
  pairMetricsAndOverheadComparisons,
  IPairedOverheadMetricsAndComparisons
} from 'overhead/data/pairedMetricsAndOverheadComparisons';
import { IFilterGroup } from 'shared/filters/IFilter';
import {
  OverheadFilterKeys,
  useOverheadFilterGroup
} from 'overhead/filters/useOverheadFilterGroup';

export interface IOverheadContext {
  compareDrawer: IUseDrawer;
  comparisons: IUseOverheadComparisons;
  filterGroup: IFilterGroup<OverheadFilterKeys>;
  metrics: UseOverheadMetrics;
  items: IPairedOverheadMetricsAndComparisons[];
}

const context = createContext({} as IOverheadContext);

const OverheadProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const compareDrawer = useDrawer();
  const filterGroup = useOverheadFilterGroup();
  const comparisons = useOverheadComparisons(overheadComparisonService, filterGroup);
  const metrics = useOverheadMetrics(filterGroup);
  const items = pairMetricsAndOverheadComparisons(metrics, comparisons.comparisons);

  const provided = {
    compareDrawer,
    comparisons,
    filterGroup,
    metrics,
    items
  };

  (window as any).overheadContext = provided;

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

const useOverheadContext = (): IOverheadContext => useContext(context);

export { useOverheadContext, OverheadProvider };

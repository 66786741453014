export const flagsConfig = {
  /**
   * Displays unfiltered SPH Mappings for the Demo OrgPin (not to be used in demos)
   * (Unfiltered values are in the DB. Filtered values come from a curated Excel Spreadsheet on the server!)
   */
  useDemoDb: false,

  /**
   * No Calc Engine
   */
  noCalcEngine: false
};

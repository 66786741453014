import React, { FC, useEffect } from 'react';
import OverheadTaskBar from './OverheadTaskBar';
import OverheadDrawer from './drawer/OverheadDrawer';
import OverheadComparisons from './OverheadComparisons';
import { useOverheadContext } from 'overhead/data/overheadContext';
import LegalNotice from 'shared/LegalNotice';
import FilterGroupSettings from 'shared/filters/FilterGroupSettings';
import { useGlobalFilterContext } from '../shared/filters';
import Setting from 'shared/filters/settings/individual/Setting';
import { useClientDataContext } from 'shared/data/useClientDataContext';

const Overhead: FC = () => {
  const globalFilterContext = useGlobalFilterContext();
  const overheadContext = useOverheadContext();
  const clientDataContext = useClientDataContext();

  useEffect(() => {
    if (!overheadContext.filterGroup.ready || !clientDataContext.clientData) {
      return;
    }

    const currentToken = overheadContext.filterGroup.token;
    const comparisonIsOutOfSyncWithFilters = ({ token }: any) => token !== currentToken;
    const { comparisons, load } = overheadContext.comparisons;
    const isOutOfSync = comparisons.some(comparisonIsOutOfSyncWithFilters);
    const isNotLoaded = comparisons.length === 0;

    if (isNotLoaded || isOutOfSync) {
      load();
    }
  }, [overheadContext.filterGroup.ready, clientDataContext.clientData]); // eslint-disable-line react-hooks/exhaustive-deps

  const SystemSetting = () => (
    <Setting
      label='Customer'
      value={globalFilterContext.filterGroup.filters.kpiEntity.computed?.systemName}
    />
  );

  return (
    <>
      <OverheadTaskBar />
      <FilterGroupSettings
        filterGroup={overheadContext.filterGroup}
        filterScope='system'
        extra={<SystemSetting />}
      />
      <OverheadComparisons />
      <OverheadDrawer />
      <LegalNotice />
    </>
  );
};

export default Overhead;

import React, { FC } from 'react';
import { LayoutGrid } from '@strata/tempo/lib';
import { useVisualizationContext } from 'shared/data/visualizationContext';
import GetStarted from 'shared/comparisons/GetStarted';
import { useKpiContext } from './data/kpiContext';
import ComparisonCard from 'shared/comparisons/cards/ComparisonCard';
import ComparisonTable from 'shared/comparisons/table/ComparisonTable';
import { MetricDataName } from 'shared/data/IBaseMetric';

const KpiComparisons: FC = () => {
  const visualizationContext = useVisualizationContext();
  const kpiContext = useKpiContext();

  const handleInsightPanelClick = (id: MetricDataName) => {
    kpiContext.selectableComparisons.select(id);
    kpiContext.insightDrawer.open();
  };

  const renderTable = () => {
    return kpiContext.selectableItems.length ? (
      <ComparisonTable
        data={kpiContext.selectableItems}
        isLoading={kpiContext.selectableComparisons.loading}
        onInsightPanelClick={handleInsightPanelClick}
        sortable
      />
    ) : (
      <GetStarted onStart={kpiContext.compareDrawer.open} />
    );
  };

  const renderCards = () => {
    return (
      <>
        {kpiContext.selectableItems.length ? (
          <LayoutGrid>
            <LayoutGrid.Row>
              {kpiContext.selectableItems.map((entry) => (
                <LayoutGrid.Column
                  span={4}
                  spanLarge={4}
                  spanMedium={6}
                  spanSmall={12}
                  key={entry.metric.Data_Name}
                >
                  <ComparisonCard
                    ineligibleReason={
                      kpiContext.outOfScopeMetrics.includes(entry.metric.Data_Name)
                        ? 'ServiceLineDoesNotApply'
                        : undefined
                    }
                    isSelected={
                      kpiContext.selectableComparisons.selected === entry.comparison?.dataName
                    }
                    isLoading={kpiContext.selectableComparisons.loading && !entry.comparison}
                    metric={entry.metric}
                    metricResult={entry.comparison}
                    onSelect={handleInsightPanelClick}
                  />
                </LayoutGrid.Column>
              ))}
            </LayoutGrid.Row>
          </LayoutGrid>
        ) : (
          <GetStarted onStart={kpiContext.compareDrawer.open} />
        )}
      </>
    );
  };

  return visualizationContext.selectedVisualization === 'Table' ? renderTable() : renderCards();
};

export default KpiComparisons;

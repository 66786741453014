export class Timer {
  startTime: Date;
  constructor() {
    this.startTime = new Date();
  }
  getElapsed() {
    // eslint-disable-next-line
    // @ts-ignore
    console.log(`Elapsed time: ${(new Date() - this.startTime) / 1000}`);
  }
}

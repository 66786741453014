import React, { FC } from 'react';
import { Card, Spacing, Tag, Text } from '@strata/tempo/lib';
import { IStandardComparison } from 'shared/data/IStandardComparison';
import IBaseMetric, { MetricDataName } from 'shared/data/IBaseMetric';
import IncompleteCard from './IncompleteCard';
import NoDataLoadedCard from './NoDataLoadedCard';
import { useVisualizationContext } from 'shared/data/visualizationContext';
import UserScore from 'shared/user-score/UserScore';

import { IneligibleReason } from 'shared/data/IBaseComparison';

import NewChartTrend from 'shared/charts/NewChartTrend';
import ChartHistogram from 'shared/charts/ChartHistogram';

import 'serviceline/costbar.old.css';
import { IAverage, IComparison, ITrend } from '../data/IComparison';

type Visualization = 'Trend' | 'Distribution';

interface IComparisonCardProps {
  averageValueAccessor: (entry: any) => IAverage;
  chartOptions?: any;
  averages?: any;
  trends?: any;
  ineligibleReason?: IneligibleReason;
  isLoading?: boolean;
  isSelected?: boolean;
  height?: number;
  hideUserScore?: boolean;
  messageNoDataLoaded?: string;
  metric: IBaseMetric;
  onSelect?(value: MetricDataName): void;
  trendValueAccessor: (entry: any) => ITrend;
  visualization?: Visualization;
  withControls?: boolean;
}

const ComparisonCard: FC<IComparisonCardProps> = (props: IComparisonCardProps) => {
  const background = props.isLoading || (!props.averages && !props.trends) ? 'gray-100' : undefined;

  let handleClick;
  // if (props.metricResult && !props.metricResult.incompleteReason && props.onSelect) {
  //   handleClick = () => {
  //     props.onSelect?.(props.metric.Data_Name);
  //   };
  // }

  const renderUserScore = () => {
    // if (
    //   props.hideUserScore ||
    //   props.isLoading ||
    //   !props.metricResult ||
    //   props.metricResult.incompleteReason
    // ) {
    //   return;
    // }
    //
    // return <UserScore metric={props.metric} metricResult={props.metricResult} withCohortSize />;
    return null;
  };

  const renderPlotContent = () => {
    if (props.ineligibleReason) {
      return <IncompleteCard reason={props.ineligibleReason} />;
    }
    // if (props.comparison?.incompleteReason) {
    //   return <IncompleteCard reason={props.metricResult.incompleteReason} />;
    // }
    if (!props.trends) {
      return <NoDataLoadedCard message={props.messageNoDataLoaded} />;
    } else {
      const visualization = props.visualization || 'Trend';

      const chartMap = {
        Trend: (
          <NewChartTrend
            trends={props.trends}
            valueAccessor={props.trendValueAccessor}
            format={props.metric.Format}
            withControls={props.withControls ?? false}
            options={props.chartOptions}
          />
        )
        // Histogram: <ChartHistogram data={props.metricResult} format={props.metric.Format} />
      };

      return chartMap[visualization as keyof typeof chartMap];
    }
  };

  return (
    <div>
      <Card
        removeBodyPadding
        selected={props.isSelected}
        onClick={handleClick}
        loading={props.isLoading}
      >
        <Spacing column height={props.height || 350} background={background} padding='8px'>
          <Spacing column padding='8px'>
            <Spacing hAlign='space-between' margin='0 0 8px 0' vAlign='center'>
              <Text.Heading ellipsis={{ rows: 1 }} marginBottom={0} level={4}>
                <Tag color='blue-700'>{props.metric.Category}</Tag>
              </Text.Heading>
              {renderUserScore()}
            </Spacing>
            <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
              {props.metric.Friendly_Name}
            </Text.Heading>
          </Spacing>
          {renderPlotContent()}
        </Spacing>
      </Card>
    </div>
  );
};

export default ComparisonCard;

import React, { FC } from 'react';
import ComparisonCard from 'shared/comparisons/cards/ComparisonCard';
import { useServiceLineContext } from 'serviceline/data/ServiceLineContext';
import { IStandardComparison } from '../../shared/data/IStandardComparison';

const ServiceLineCostCard: FC = () => {
  const slContext = useServiceLineContext();
  const patientTypeLabel = slContext.filterGroup.filters.patientType.computed?.friendlyLabel;
  const isInpatientType = slContext.filterGroup.filters.patientType.filterValue === 'Inpatient';
  const { data, loading, loadingDone } = slContext.getCurrentTableContext();

  let metricResult;
  if (loadingDone && !data.length) {
    metricResult = { incomplete: true, incompleteReason: 'MissingCohort' } as IStandardComparison;
  } else {
    metricResult = slContext.selectedTableRow?.value.cost;
  }

  const messageNoDataLoaded = isInpatientType
    ? ''
    : `LOS does not apply to ${patientTypeLabel.toLowerCase()}`;

  return (
    <ComparisonCard
      chartOptions={{ marginLeft: 56 }}
      height={410}
      hideUserData={slContext.isOnboarding}
      isLoading={loading}
      forceVisualization='Line Chart'
      messageNoDataLoaded=''
      metric={{
        Category: slContext.selectedTableRow?.value.name || 'Service Line',
        Data_Name: 'cost',
        Friendly_Name: `Direct Cost for ${patientTypeLabel}`,
        Format: {
          type: 'currency',
          sigDig: 0,
          axis: '$.1s',
          tooltip: '$,.0f'
        },
        GoodBound: 'lower'
      }}
      metricResult={metricResult}
      withControls
    />
  );
};

export default ServiceLineCostCard;

import React, { FC } from 'react';
import { Spacing, Text } from '@strata/tempo/lib';

interface INoDataLoadedCardProps {
  message?: string;
}

const NoDataLoadedCard: FC<INoDataLoadedCardProps> = (props) => {
  return (
    <Spacing hAlign='center' vAlign='center' height='100%'>
      <Text color='secondary' size='large'>
        {props.message || 'No Data Loaded'}
      </Text>
    </Spacing>
  );
};

export default NoDataLoadedCard;

// eslint-disable-next-line no-use-before-define
import React from 'react';
import PropTypes from 'prop-types';
import * as d3 from 'd3';
import * as Plot from '@observablehq/plot';
import Chart from 'shared/charts/Chart';
import { style } from './style';

const ChartBoxWhisker = (props) => {
  const {
    cohort: { interquartile, median },
    outlier
  } = style;

  const x = props.x ?? null;
  const y = props.y ?? { transform: (x) => x };
  const group = x == null ? Plot.groupZ : Plot.groupX;

  const medianOptions = props.medianOptions || median;
  const interquartileOptions = props.interquartileOptions || interquartile;
  const outlierOptions = props.outlierOptions || { fill: outlier };

  const { y: plotOptionsY, ...validPlotOptions } = props.plotOptions;

  const options = {
    ...validPlotOptions,
    y: {
      grid: true,
      label: '',
      ...plotOptionsY
    },
    marks: [
      Plot.ruleX(
        props.data.cohortData,
        group({ y1: iqr1, y2: quartile1 }, { x, y, ...interquartileOptions })
      ),
      Plot.ruleX(
        props.data.cohortData,
        group({ y1: quartile3, y2: iqr2 }, { x, y, ...interquartileOptions })
      ),

      Plot.barY(
        props.data.cohortData,
        group({ y1: quartile1, y2: quartile3 }, { x, y, ...interquartileOptions })
      ),

      Plot.tickY(props.data.cohortData, group({ y: 'median' }, { x, y, ...medianOptions })),

      Plot.tickY(props.data.cohortData, group({ y: iqr1 }, { x, y, ...interquartileOptions })),
      Plot.tickY(props.data.cohortData, group({ y: iqr2 }, { x, y, ...interquartileOptions })),

      props.showOutliers
        ? Plot.dot(
            props.data.cohortData,
            Plot.map({ y: outliers }, { x, y, z: x, ...outlierOptions })
          )
        : undefined,

      Plot.tickY(props.data.userData, { x, y, ...style.user }),
      Plot.barY(
        [...props.data.cohortData, ...props.data.userData],
        group(
          {
            y1: iqr1,
            y2: iqr2,
            title: (d) => {
              const cohortData = d.filter((entry) => !entry.isUser).map((entry) => entry.value);
              const userData = d.filter((entry) => entry.isUser).map((entry) => entry.value);
              const q1 = quartile1(cohortData);
              const q2 = quartile3(cohortData);
              const format = (entry) => d3.format('$,.0f')(entry);
              return `You: ${format(userData)}  |  25%: ${format(q1)}  |  Median: ${format(
                d3.median(cohortData)
              )}  |  75%: ${format(q2)}`;
            }
          },
          { x, y, fill: '#00000000' }
        )
      )
    ]
  };

  return <Chart className='box-whisker-plot' options={options} />;
};

ChartBoxWhisker.propTypes = {
  data: PropTypes.shape({
    cohortData: PropTypes.array,
    userData: PropTypes.array
  }),
  x: PropTypes.string,
  y: PropTypes.string,
  medianOptions: PropTypes.object,
  interquartileOptions: PropTypes.object,
  outlierOptions: PropTypes.object,
  plotOptions: PropTypes.object,
  showOutliers: PropTypes.bool,
  extraMarks: PropTypes.arrayOf(PropTypes.object)
};

export default ChartBoxWhisker;

function outliers(values) {
  const r1 = iqr1(values);
  const r2 = iqr2(values);
  return values.map((v) => (v < r1 || v > r2 ? v : NaN));
}

function iqr1(V) {
  const hi = quartile1(V);
  const lo = hi - 1.5 * (quartile3(V) - hi);
  return d3.min(V, (v) => (lo <= v && v <= hi ? v : NaN));
}

function iqr2(V) {
  const lo = quartile3(V);
  const hi = lo + 1.5 * (lo - quartile1(V));
  return d3.max(V, (v) => (lo <= v && v <= hi ? v : NaN));
}

const quartile1 = (V) => d3.quantile(V, 0.25);

const quartile3 = (V) => d3.quantile(V, 0.75);

import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { DropDown, Form, Text } from '@strata/tempo/lib';

const PatientTypeControl: FC<IFilterControlProps<string>> = ({ filterValue, setFilterValue }) => (
  <>
    <Text.Heading level={4}>Encounter Filters</Text.Heading>
    <Form.Item label='Patient Type'>
      <DropDown
        items={options}
        onChange={(val) => {
          setFilterValue((val as string) ?? '');
        }}
        value={filterValue}
      />
    </Form.Item>
  </>
);

const PatientTypeSetting: FC<IFilterSettingProps<string>> = (props) => {
  const label = props.filterComputed?.selectedOptionText;
  return <Setting isVisible={Boolean(props.filterValue)} label='Patient Type' value={label} />;
};

const options = [
  {
    value: 'Inpatient',
    text: 'Inpatient (cost per case)'
  },
  {
    value: 'Observation',
    text: 'Observation (cost per case)'
  },
  {
    value: 'Outpatient',
    text: 'Outpatient (cost per case)'
  }
];

export const patientType: IFilterConfig<string> = {
  computed: {
    selectedOptionText: (filterValue): string =>
      options.find((option) => option.value === filterValue)?.text || '',
    friendlyLabel: (filterValue): string => {
      const friendlyLabels = {
        [options[0].value]: 'Inpatients',
        [options[1].value]: 'Observations',
        [options[2].value]: 'Outpatients'
      };
      return friendlyLabels[filterValue];
    }
  },
  Control: PatientTypeControl,
  controlWeight: 5.1,
  defaultValue: options[0].value,
  param: 'sphPatientType',
  loadFromParams(params, setFilterValue) {
    // In June 2023, Cost was moved to the Marts, and the old param
    // changed from "patientType" to "sphPatientType".
    // This mapping allows old user views to continue working, despite the param name change.
    // Note the old system didn't have "Emergency" patient types.
    if (params.patientType) {
      const mapping = {
        '0': 'Outpatient',
        '2': 'Observation',
        '3': 'Inpatient' // cost per encounter
      };
      params.sphPatientType = mapping[params.patientType as keyof typeof mapping];
    }
    setFilterValue(params.sphPatientType);
  },
  Setting: PatientTypeSetting,
  settingWeight: 5.1
};

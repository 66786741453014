import { ICacheableCostComparison, RollupName } from 'cost/data/costComparisonService';
import { ICostMetric } from 'cost/data/ICostMetric';

export interface IPairedMetricsAndCostComparisons {
  metric: ICostMetric;
  comparison?: ICacheableCostComparison;
  rollupName?: RollupName;
  isComponent: boolean;
  isDriver: boolean;
}

/**
 * Returns entries that group the metric, rollupName, and comparison
 * @param metrics
 * @param comparisons
 */
export function pairMetricsAndComparisons(
  metrics: ICostMetric[],
  comparisons: ICacheableCostComparison[]
): IPairedMetricsAndCostComparisons[] {
  const pairs: IPairedMetricsAndCostComparisons[] = [];

  metrics.forEach((metric) => {
    metric.applicableRollups.forEach((rollupName) => {
      const comparison = comparisons.find(
        (comparison) =>
          comparison.dataName.toLowerCase() === metric.Data_Name.toLowerCase() &&
          comparison.rollupName === rollupName
      );
      pairs.push({
        metric,
        comparison,
        rollupName: comparison?.rollupName,
        isComponent: true,
        isDriver: false
      });
    });

    metric.applicableDrivers.forEach((rollupName) => {
      const comparison = comparisons.find(
        (comparison) =>
          comparison.dataName.toLowerCase() === metric.Data_Name.toLowerCase() &&
          comparison.rollupName === rollupName
      );
      pairs.push({
        metric,
        comparison,
        rollupName: comparison?.rollupName,
        isComponent: false,
        isDriver: true
      });
    });
  });

  return pairs;
}

import { IFilterConfig } from 'shared/filters/IFilter';

export const systemOperatingExpense: IFilterConfig<string[]> = {
  controlLabel: 'System Operating Expense',
  controlOptionsFromUiConfig: {
    source: 'System',
    fieldname: 'operatingexpense'
  },
  controlType: 'checkboxgroup',
  controlWeight: 12,
  defaultValue: [],
  param: 'systemOperatingExpense',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.systemOperatingExpense);
  },
  scope: 'system',
  settingLabel: 'System Operating Expense',
  settingWeight: 12
};

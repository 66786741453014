import React, { FC, useState } from 'react';
import { ActionBar, Button, Toggle } from '@strata/tempo/lib';

import DepartmentsDataGrid from './datagrids/DepartmentsDataGrid';
import JobCodeDataGrid from './datagrids/JobCodeDataGrid';
import GlAccountDataGrid from './datagrids/GlAccountDataGrid';
import PayCodeDataGrid from './datagrids/PayCodeDataGrid';
import PayorDataGrid from './datagrids/PayorDataGrid';

import { mappingsService } from './data/mappingsService';
import { useFlagsContext } from 'shared/flags/useFlagsContext';
import DownloadIcon from '@strata/tempo/lib/icon/DownloadIcon';

const Mappings: FC = () => {
  const [view, setView] = useState(0);
  const { useDemoDb } = useFlagsContext();

  const dataGridMap = [
    <DepartmentsDataGrid key='1' />,
    <JobCodeDataGrid key='2' />,
    <GlAccountDataGrid key='3' />,
    <PayCodeDataGrid key='4' />,
    <PayorDataGrid key='5' />
  ];

  const handleExport = () => {
    mappingsService.export(useDemoDb);
  };

  return (
    <div>
      <ActionBar
        actions={
          <Button type='tertiary' icon={<DownloadIcon />} onClick={handleExport}>
            Export Excel
          </Button>
        }
        viewToggle={
          <Toggle
            defaultValue={view}
            items={[
              { value: 0, name: 'Department Mappings' },
              { value: 1, name: 'Job Code Mappings' },
              { value: 2, name: 'GL Account Mappings' },
              { value: 3, name: 'Pay Code Mappings' },
              { value: 4, name: 'Payor Mappings' }
            ]}
            onChange={(event) => setView(event.target.value)}
          />
        }
      />
      {dataGridMap[view]}
    </div>
  );
};

export default Mappings;

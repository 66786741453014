import { appConfig, getSecureService } from '@strata/core/lib';
const { httpGet } = getSecureService(appConfig.apiUrl);

export interface IDimMetric {
  metricId: number;
  metricAbbr: string;
  metricName: string;
  metricCategory: string;
  componentIdNumerator: number;
  componentIdDenominator: number;
  moduleId: number;
  axisFormat: string;
  axisMin: number;
  axisStep: number;
  tooltipFormat: string;
  goodRanking: string | null;
  formula: string;
  levelsOfDetail: string[];
  weight?: string;
  submodule?: string;
}

type cohortFilterOptionSourceType = 'Entity' | 'System';
export interface ICohortFilterOptions {
  source: cohortFilterOptionSourceType;
  fieldName: string;
  label: string;
  value: string;
  weight: number;
}

export interface IUiConfig {
  metrics: IDimMetric[];
  cohortFilterOptions: ICohortFilterOptions[];
  // TODO we'll add more as we consolidate all of our bootstrapping apis into this one!
}

export const uiConfigService = {
  async fetchUiConfig(): Promise<IUiConfig> {
    return httpGet<IUiConfig>('/ui-config/defaults').then((c) => {
      // TODO The API is emitting "[\"System\", \"Entity\"]". Let it emit a proper JSON array, then remove the bandaid below.
      c.metrics.forEach(
        (m) => (m.levelsOfDetail = JSON.parse(m.levelsOfDetail as unknown as string))
      );
      return c;
    });
  }
};

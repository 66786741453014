import { appConfig, getSecureService } from '@strata/core/lib';
import { FilterScope, IFilterGroup } from 'shared/filters';
import { ServiceLineFilterKeys } from 'serviceline2/filters/useServiceLineFilterGroup';
// eslint-disable-next-line
// @ts-ignore
import { format, median } from 'd3';
import { groupBy } from 'lodash-es';

import { Timer } from 'shared/utils/timer';
import { IComparison, ISlCfAverage, ISlCfTrend } from 'shared/comparisons/data/IComparison';
import { makeUid } from '../../shared/utils';

const { httpPost } = getSecureService(appConfig.apiUrl);

export type SlCfType = 'Service Line' | 'Care Family';

interface ISlCfComparisonCached {
  token: string;
  data: IComparison<ISlCfAverage, ISlCfTrend>;
}

export const datadogIdentifier = (function () {
  let id: string;
  const getId = () => id;
  const makeId = () => (id = makeUid());
  return {
    getId,
    makeId
  };
})();

const slCache: ISlCfComparisonCached[] = [];
export const fetchServiceLines = async function (
  filterGroup: IFilterGroup<ServiceLineFilterKeys>,
  databaseGuid: string,
  signal: AbortSignal
): Promise<IComparison<ISlCfAverage, ISlCfTrend>> {
  const { token, params } = filterGroup;
  let data = slCache.find((entry) => entry.token === token)?.data;

  if (data) {
    return Promise.resolve(data);
  }

  let path;
  if (filterGroup.filters.compareWithinOwnHealthSystem.filterValue) {
    path = 'my-entities-precalculated';
  } else if (filterGroup.filters.entity.computed?.scope === ('system' as FilterScope)) {
    path = 'systems-precalculated-by-metric';
  } else {
    path = 'entities-precalculated-by-metric';
  }

  const timer = new Timer();
  data = await httpPost<IComparison<ISlCfAverage, ISlCfTrend>>(
    `/service-line-comparisons/${path}`,
    {
      ...params,
      databaseGuid,
      comparisonId: datadogIdentifier.makeId()
    },
    {
      signal
    }
  );
  timer.getElapsed();

  slCache.push({ token, data });

  return data;
};

const cfCache: ISlCfComparisonCached[] = [];
export const fetchCareFamilies = async function (
  filterGroup: IFilterGroup<ServiceLineFilterKeys>,
  databaseGuid: string,
  signal: AbortSignal,
  serviceLineName: string
): Promise<IComparison<ISlCfAverage, ISlCfTrend>> {
  const cacheToken = filterGroup.token + serviceLineName;
  let data = cfCache.find((entry) => entry.token === cacheToken)?.data;

  if (data) {
    return Promise.resolve(data);
  }

  let path;
  if (filterGroup.filters.compareWithinOwnHealthSystem.filterValue) {
    path = 'my-entities-precalculated';
  } else if (filterGroup.filters.entity.computed?.scope === ('system' as FilterScope)) {
    path = 'systems-precalculated';
  } else {
    path = 'entities-precalculated';
  }

  data = await httpPost<IComparison<ISlCfAverage, ISlCfTrend>>(
    `/care-family-comparisons/${path}`,
    {
      ...filterGroup.params,
      databaseGuid,
      serviceLineName,
      comparisonId: datadogIdentifier.getId()
    },
    {
      signal
    }
  );

  cfCache.push({ token: cacheToken, data });

  return data;
};

export interface ISlCfTableRow {
  name: string;
  volumeUser: string;
  cmiUser: string;
  cmiMedian: string;
  losUser: string;
  losMedian: string;
  losDiff: string;
  losDiffPercentage: number;
  costUser: number;
  costMedian: number;
  costDiff: number;
  costDiffPercentage: number;
}

export const formatMoney = format('($,.0f');
export const formatPretty = format(',.2f');
export const formatPrettyWholeNum = format(',.0f');

export const asISlCfTableRows = (d: ISlCfAverage[]): ISlCfTableRow[] => {
  const averagesByServiceLineName = groupBy(d, 'name');
  return Object.values(averagesByServiceLineName)
    .reduce((rows, current) => {
      const userAverage = current.find((entry) => entry.isUser);

      const losMedian = median(current, (entry: ISlCfAverage) => entry.losValue);
      const losDiff = (userAverage?.losValue ?? 0) - losMedian;
      const costMedian = median(current, (entry: ISlCfAverage) => entry.costValue);
      const costDiff = (userAverage?.costValue ?? 0) - costMedian;

      rows.push({
        name: current[0].name,
        volumeUser: formatPrettyWholeNum(userAverage?.volumeValue ?? 0),
        cmiUser: formatPretty(userAverage?.cmiValue ?? 0),
        cmiMedian: formatPretty(median(current, (entry: ISlCfAverage) => entry.cmiValue)),
        losUser: formatPretty(userAverage?.losValue ?? 0),
        losMedian: formatPretty(losMedian),
        losDiff: formatPretty(losDiff),
        losDiffPercentage: (losDiff / losMedian) * 100,
        costUser: userAverage?.costValue ?? 0,
        costMedian,
        costDiff,
        costDiffPercentage: (costDiff / costMedian) * 100
      });

      return rows;
    }, [] as ISlCfTableRow[])
    .sort((a, b) => Number(a.volumeUser) - Number(b.volumeUser));
};

// TODO fix data! and bring this back!
// export const tableRowToSlCfData = (name: string, data: ISlCfComparison[]): ISlCfComparison => {
//   return data.find((entry) => entry.averages[0].name === name) as ISlCfComparison;
// };

export const formatMoneyToNumber = (moneyFormattedString: string) =>
  Number(moneyFormattedString.replace(/[^0-9\-.]/g, ''));

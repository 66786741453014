import { appConfig, getSecureService } from '@strata/core/lib';

const { httpGet, httpPost } = getSecureService(appConfig.apiUrl);

export const service = {
  fetchDisclaimer: (): Promise<string[]> => httpGet('/user-legal-terms-acceptance/disclaimer'),
  fetchLegalTermsAcceptanceStatus: (): Promise<boolean> => httpGet('/user-legal-terms-acceptance'),
  saveLegalTermsAcceptance: (): Promise<boolean> => httpPost('/user-legal-terms-acceptance', {}),
  saveLegalTermsRejection: (): Promise<void> =>
    httpPost('/user-legal-terms-acceptance/rejection', {})
};

import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps } from 'shared/filters/IFilter';
import { DropDown, Form } from '@strata/tempo/lib';
import { useKpiContext } from 'kpi/data/kpiContext';
import { useCostContext } from 'cost/data/costContext';
import { useSg2GroupingsContext } from 'shared/data/useSg2GroupingsContext';

const CareFamilyControl: FC<IFilterControlProps<string>> = ({ filterValue, setFilterValue }) => {
  const kpiContext = useKpiContext();
  const costContext = useCostContext();
  const sg2Groupings = useSg2GroupingsContext();

  return (
    <Form.Item label='Care Family'>
      <DropDown
        allowClear
        items={sg2Groupings.getCareFamilyOptions(
          kpiContext.filterGroup.filters.serviceLine.filterValue
        )}
        value={filterValue}
        onChange={(val) => {
          setFilterValue((val as string) ?? '');
          costContext.filterGroup.filters.careFamily.setFilterValue((val as string) ?? '');
        }}
        showSearch
      />
    </Form.Item>
  );
};

export const careFamily: IFilterConfig<string> = {
  Control: CareFamilyControl,
  controlWeight: 5,
  defaultValue: '',
  param: 'careFamily',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.careFamily);
  },
  Setting: () => null, // This is rendered as a part of the Service Line Setting
  settingWeight: 5
};

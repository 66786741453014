import { IFilterDictionary } from './IFilter';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toToken = (dictionary: IFilterDictionary<any>) => {
  const filterValueMap = Object.entries(dictionary).reduce((map, [key, filter]) => {
    return {
      ...map,
      [key]: filter.filterValue
    };
  }, {});

  return btoa(JSON.stringify(filterValueMap));
};

export const toValues = (token: string) => {
  try {
    return JSON.parse(atob(token));
  } catch (e) {
    console.warn('Invalid FilterDictionary token. Cannot convert.');
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toParams = (dictionary: IFilterDictionary<any>) => {
  return Object.values(dictionary).reduce((reduced, entry) => {
    return {
      ...reduced,
      ...entry.param
    };
  }, {});
};

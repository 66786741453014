import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps } from 'shared/filters/IFilter';
import { Form, RadioChangeEvent, Toggle } from '@strata/tempo/lib';
import { componentRollup } from './componentRollup';
import { costDriver } from './costDriver';
import { useCostContext } from 'cost/data/costContext';

const ComponentOrDriverControl: FC<IFilterControlProps<string>> = ({
  filterValue,
  setFilterValue
}) => {
  const costContext = useCostContext();

  const handleChange = (e: RadioChangeEvent) => {
    setFilterValue(e.target.value);

    // One of two filters has been selected.
    // Reset the value of the other filter.
    if (e.target.value === 'drivers') {
      costContext.filterGroup.filters.componentRollup.setFilterValue(componentRollup.defaultValue);
    } else {
      costContext.filterGroup.filters.costDriver.setFilterValue(costDriver.defaultValue);
    }
  };

  return (
    <Form.Item label='Level of Detail'>
      <Toggle defaultValue={filterValue} onChange={handleChange} items={options} />
    </Form.Item>
  );
};

const options = [
  {
    name: 'Cost Component Rollups',
    value: 'components'
  },
  {
    name: 'Cost Drivers',
    value: 'drivers'
  }
];

export const componentOrDriverSelector: IFilterConfig<string> = {
  computed: {
    isComponents: (filterValue): boolean => filterValue === 'components',
    isDrivers: (filterValue): boolean => filterValue === 'drivers'
  },
  Control: ComponentOrDriverControl,
  controlWeight: 5.101,
  defaultValue: options[0].value,
  param: (filterValue) => ({
    isRequestingCostComponents: filterValue === 'components',
    isRequestingCostDrivers: filterValue === 'drivers'
  }),
  loadFromParams(params, setFilterValue) {
    const { isRequestingCostComponents = true } = params;
    setFilterValue(isRequestingCostComponents ? 'components' : 'drivers');
  }
};

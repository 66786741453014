import { IOverheadMetric } from 'overhead/data/IOverheadMetric';
import { ICacheableStandardComparison } from 'shared/data/IStandardComparison';

export interface ICacheableOverheadComparison extends ICacheableStandardComparison {
  metricId: number;
  department: string;
}

export interface IPairedOverheadMetricsAndComparisons {
  metric: IOverheadMetric;
  comparison?: ICacheableOverheadComparison;
}

export function pairMetricsAndOverheadComparisons(
  metrics: IOverheadMetric[],
  comparisons: ICacheableOverheadComparison[]
): IPairedOverheadMetricsAndComparisons[] {
  const pairs: IPairedOverheadMetricsAndComparisons[] = [];

  // Filters-out comparisons that don't currently match the selected overhead metrics
  // (It does this by looking at the overhead department of the metric)
  metrics.forEach((metric) => {
    const comparison = comparisons.find((comparison) => {
      const matchesDataName = comparison.metricId === metric.dlId;
      const matchesDepartment = comparison.department === metric.department;

      return matchesDataName && matchesDepartment;
    });
    pairs.push({ metric, comparison });
  });

  return pairs;
}

import { appConfig, getSecureService } from '@strata/core/lib';

const { httpPost } = getSecureService(appConfig.apiUrl);

export interface ICacheEntry<T> {
  id: string;
  data: T;
}

const cache = [] as ICacheEntry<unknown>[];

const cacheableHttpPost = async <T>(url: string, body: any, args: RequestInit = {}): Promise<T> => {
  const { comparisonId, ...cleanBody } = body; // don't cache on the comparisonId because it's randomly generated prior to every request!
  const cacheEntryId = btoa(JSON.stringify({ url, cleanBody }));
  const cacheEntry = cache.find((entry) => entry.id === cacheEntryId);

  if (cacheEntry) {
    if (cacheEntry.data instanceof Array) {
      cacheEntry.data.forEach((entry) => {
        if (entry instanceof Object) {
          entry = {
            ...entry,
            fromCache: true
          };
        }
      });
    } else if (cacheEntry.data instanceof Object) {
      cacheEntry.data = {
        ...cacheEntry.data,
        fromCache: true
      };
    }
    return Promise.resolve<T>(cacheEntry.data as T);
  }

  return httpPost<T>(url, body, args).then((data) => {
    cache.push({
      id: cacheEntryId,
      data
    });
    console.log('Cached!');
    return data;
  });
};

export { cacheableHttpPost };

import React from 'react';
import { Card, DropDown, Spacing, Text } from '@strata/tempo/lib';
import { useCostContext } from 'cost/data/costContext';
import ChartMonthlyTrend from 'shared/charts/ChartMonthlyTrend';
import IncompleteCard from 'shared/comparisons/cards/IncompleteCard';
import { IncompleteReason } from 'shared/data/IBaseComparison';
import NoDataLoadedCard from 'shared/comparisons/cards/NoDataLoadedCard';

const CostMonthlyTrendCard = () => {
  const costContext = useCostContext();

  const selectedCostTypeItem = costContext.items
    .filter((entry) => entry.rollupName === 'All')
    .find(
      (entry) =>
        entry.metric.Data_Name.toLowerCase() ===
        costContext.selectableMetrics.selected[0].toLowerCase()
    );

  let content;
  if (!selectedCostTypeItem?.comparison) {
    content = <NoDataLoadedCard />;
  } else if (selectedCostTypeItem.comparison.incomplete) {
    content = (
      <IncompleteCard
        reason={selectedCostTypeItem.comparison.incompleteReason as IncompleteReason}
      />
    );
  } else {
    content = (
      <ChartMonthlyTrend
        data={selectedCostTypeItem.comparison}
        format={{ axis: '$.1s', tooltip: '$,.0f' }}
        options={{ marginRight: 20, marginLeft: 50 }}
        withControls
        isOnboarding={costContext.isOnboarding}
      />
    );
  }

  const dropdownOptions = costContext.selectableMetrics.metrics
    // Hide metrics that aren't in the selected submodule (ie, show either cost per day or cost per case metrics)
    .filter((m) => m.submodule === costContext.filterGroup.filters.submodule.filterValue)
    .map(({ Friendly_Name, Data_Name }) => ({
      text: Friendly_Name,
      value: Data_Name
    }));

  return (
    <Card height={400} removeBodyPadding loading={costContext.costComparisons.loading}>
      <Spacing column height={400} padding='8px'>
        <Spacing column padding='8px'>
          <Spacing margin='0 0 8px 0' hAlign={'space-between'}>
            <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
              Monthly Trend
            </Text.Heading>
            <DropDown
              width={250}
              onChange={(newMetric) => costContext.selectableMetrics.select([newMetric as string])}
              value={selectedCostTypeItem?.metric.Friendly_Name}
              items={dropdownOptions}
            />
          </Spacing>
        </Spacing>
        {content}
      </Spacing>
    </Card>
  );
};

export default CostMonthlyTrendCard;

import React from 'react';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { sg2GroupingsService, ISg2Groupings } from './sg2GroupingsService';

interface ISg2GroupingsContext {
  groupings: ISg2Groupings;
  getServiceLineOptions: () => { text: string; value: string }[];
  getCareFamilyOptions: (serviceLineId: string) => { text: string; value: string }[];
}

const context = createContext({} as ISg2GroupingsContext);

const Sg2GroupingsProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const [sg2Groupings, setSg2Groupings] = useState<ISg2Groupings>({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await sg2GroupingsService.fetchSg2Groupings();
      setSg2Groupings(data);
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const providedValue = {
    groupings: sg2Groupings,
    getServiceLineOptions: () => Object.keys(sg2Groupings).map(toOption),
    getCareFamilyOptions: (serviceLineId: string) =>
      sg2Groupings[serviceLineId as keyof typeof sg2Groupings]?.map(toOption)
  };

  return (
    <context.Provider value={providedValue as ISg2GroupingsContext}>
      {props.children}
    </context.Provider>
  );
};

const useSg2GroupingsContext = () => useContext(context);

export { useSg2GroupingsContext, Sg2GroupingsProvider };

function toOption(key: string) {
  return {
    text: key,
    value: key
  };
}

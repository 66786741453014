import { useEffect, useState } from 'react';
import { IOverheadComparisonService } from 'overhead/data/overheadComparisonService';
import { ICacheableOverheadComparison } from 'overhead/data/pairedMetricsAndOverheadComparisons';
import { IFilterGroup } from 'shared/filters';
import { OverheadFilterKeys } from 'overhead/filters/useOverheadFilterGroup';
import { makeUid } from 'shared/utils';
import { useClientDataContext } from 'shared/data/useClientDataContext';

export interface IUseOverheadComparisons {
  comparisons: ICacheableOverheadComparison[];
  loading: boolean;
  loadingCount: number;
  loadingDone: boolean;
  loadingRequests: Array<Promise<void>>;
  load(): void;
  cancelLoad(): void;
}

const useOverheadComparisons = (
  comparisonService: IOverheadComparisonService,
  filterGroup: IFilterGroup<OverheadFilterKeys>
): IUseOverheadComparisons => {
  const [comparisons, setComparisons] = useState([] as ICacheableOverheadComparison[]);
  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [loadingDone, setLoadingDone] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState([] as Array<Promise<void>>);
  const [abortController, setAbortController] = useState(new AbortController());

  const clientDataContext = useClientDataContext();

  // If the user changes a Filter Parameter,
  // flag that new comparisons should be loaded
  useEffect(() => {
    setLoadingDone(false);
  }, [filterGroup.token]);

  return {
    comparisons,
    loading,
    loadingCount,
    loadingDone,
    loadingRequests,
    load() {
      const controller = new AbortController();
      setAbortController(controller);
      setLoading(true);
      setLoadingCount(1);
      setComparisons([]);

      const comparisonId = makeUid(); // This id is used by DataDog to help create our app's usage Dashboard

      const request = comparisonService
        .fetchComparison(
          comparisonId,
          filterGroup.params,
          filterGroup.token,
          controller.signal,
          clientDataContext.clientData.strataId as number
        )
        .then((comparisons: Array<ICacheableOverheadComparison>) => {
          setComparisons((entries) => entries.concat(comparisons));
          setLoading(false);
          setLoadingCount(0);
          setLoadingDone(true);
          setLoadingRequests([]);
        });

      setLoadingRequests([request]);
    },
    cancelLoad() {
      abortController.abort();
      setLoading(false);
      setLoadingCount(0);
      setLoadingRequests([]);
    }
  };
};

useOverheadComparisons.returnType = {
  comparisons: [] as ICacheableOverheadComparison[],
  loading: false,
  loadingCount: 0,
  loadingDone: false,
  loadingRequests: [],
  load: () => undefined,
  cancelLoad: () => undefined
} as IUseOverheadComparisons;

export default useOverheadComparisons;

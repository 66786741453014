import { IStandardComparison, UserPercentileRank } from 'shared/data/IStandardComparison';
import IBaseMetric from 'shared/data/IBaseMetric';
import { IPairedMetricsAndComparisons } from 'shared/data/pairedMetricsAndComparisons';
import { getFriendlyReason } from 'shared/comparisons/cards/IncompleteCard';

// eslint-disable-next-line
// @ts-ignore
import { format } from 'd3';

export interface IUseComparisonTable {
  tableData: ITableRowData[];
}

export interface ITableRowData {
  name: string;
  value: string;
  category?: string;
  rank?: UserPercentileRank;
  cohortSize?: number;
  min?: string;
  q25?: string;
  median?: string;
  q75?: string;
  max?: string;
  metric?: IBaseMetric;
  metricResult?: IStandardComparison;
}

const getTableRowData = () => {
  return {
    isIncomplete: (entry: IPairedMetricsAndComparisons): ITableRowData => ({
      name: entry.metric.Friendly_Name,
      value: getFriendlyReason(entry.comparison?.incompleteReason).title,
      category: entry.metric.Category
    }),
    isLoading: (entry: IPairedMetricsAndComparisons): ITableRowData => ({
      name: entry.metric.Friendly_Name,
      value: 'Inactive'
    }),
    isReady: (entry: IPairedMetricsAndComparisons): ITableRowData => {
      const maybeDivideBy100 = (val?: number) => {
        return val && entry.metric.Format.tooltip?.includes('%') ? val / 100 : val;
      };

      return {
        name: entry.metric.Friendly_Name,
        value: format(entry.metric.Format.tooltip)(maybeDivideBy100(entry.comparison?.userAverage)),
        category: entry.metric.Category,
        rank: entry.comparison?.userPercentileRank,
        cohortSize: entry.comparison && entry.comparison.allAverages.length - 1,
        q25: format(entry.metric.Format.tooltip)(maybeDivideBy100(entry.comparison?.stats.q25)),
        median: format(entry.metric.Format.tooltip)(
          maybeDivideBy100(entry.comparison?.stats.median)
        ),
        q75: format(entry.metric.Format.tooltip)(maybeDivideBy100(entry.comparison?.stats.q75)),
        metric: entry.metric,
        metricResult: entry.comparison
      };
    }
  };
};

const useComparisonTable = (
  pairedMetricsAndResults: Array<IPairedMetricsAndComparisons>
): IUseComparisonTable => {
  const tableData = pairedMetricsAndResults.map((entry) => {
    const tableRowData = getTableRowData();
    if (!entry.comparison) {
      return tableRowData.isLoading(entry);
    }

    return entry.comparison.incomplete
      ? tableRowData.isIncomplete(entry)
      : tableRowData.isReady(entry);
  });

  return {
    tableData
  };
};

useComparisonTable.returnType = {
  tableData: []
} as IUseComparisonTable;

export default useComparisonTable;

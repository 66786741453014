import React, { FC, useEffect, useState } from 'react';
import { Input, Modal, Text, Form } from '@strata/tempo/lib';
import { useViewContext } from './data/useViewContext';
import { DEFAULT_VIEW_NAME } from './data/useView';
import { useOidcSession } from '@strata/core/lib';

const SaveModal: FC = () => {
  const viewContext = useViewContext();
  const session = useOidcSession();
  const [name, setName] = useState(viewContext.name);

  const okText = name === viewContext.name ? 'Save' : 'Save As';
  const entryWithSameName = viewContext.configEntries.find((entry) => entry.name === name);
  const isOriginalCreator = entryWithSameName
    ? entryWithSameName.savedBy === session.userName
    : true;

  // Users can't name something that someone else named. And they can't name it the default name.
  const isValidName = name && name !== DEFAULT_VIEW_NAME && isOriginalCreator;

  // We warn the user if they're about to make another view with the same name
  const willOverwrite = entryWithSameName && isOriginalCreator;

  let helpText;
  if (willOverwrite) {
    helpText = 'This will overwrite an existing view!';
  }
  if (entryWithSameName && !isOriginalCreator) {
    helpText = 'Another user has a view with this same name.';
  }

  useEffect(() => {
    setName(viewContext.name);
  }, [viewContext.name]);

  const saveAndClose = () => {
    viewContext.saveAs(name, entryWithSameName?.id);
    viewContext.closeModal();
  };

  return (
    <Modal
      width='normal'
      title='Save View'
      visible={viewContext.activeModal === 'save'}
      okButtonProps={{
        disabled: !isValidName
      }}
      okText={okText}
      onCancel={viewContext.closeModal}
      onOk={saveAndClose}
    >
      <Form>
        <Form.Item label='Name' help={helpText}>
          <Input
            autoFocus
            onPressEnter={saveAndClose}
            width={'100%'}
            placeholder='View Name'
            onChange={(event) => setName(event.target.value)}
            onFocus={(event) => event.target.select()}
            value={name}
          />
          {willOverwrite ? <Text color='error'></Text> : null}
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SaveModal;

import React, { FC } from 'react';
import { Form } from '@strata/tempo/lib';
import { FilterScope, IFilterGroup } from 'shared/filters/IFilter';

interface IDrawerFiltersProps {
  filterGroup: IFilterGroup<string>;
  filterScope: FilterScope;
}

const DrawerFilters: FC<IDrawerFiltersProps> = (props: IDrawerFiltersProps) => {
  const controls = Object.values(props.filterGroup.filters)
    .filter(({ scope }) => scope === 'all' || scope === props.filterScope)
    .sort((a, b) => a.controlWeight - b.controlWeight)
    .map((filter, index) => <React.Fragment key={index}>{filter.Control}</React.Fragment>);

  return <Form>{controls}</Form>;
};

export default DrawerFilters;

import React, { FC } from 'react';
import { Text } from '@strata/tempo/lib';
import IBaseMetric, { MetricGoodBound } from 'shared/data/IBaseMetric';
import {
  CohortMonthlyTrend,
  IStandardComparison,
  UserMonthlyTrend,
  UserPercentileRank
} from 'shared/data/IStandardComparison';
import TrophyIcon from '@strata/tempo/lib/icon/TrophyIcon';
import WarningIcon from '@strata/tempo/lib/icon/WarningIcon';

export enum UserScoreType {
  strong,
  weak,
  average,
  deadHeat
}

export interface IUserScore {
  isDeadHeat: boolean;
  isWeak: boolean;
  isStrong: boolean;
  type: UserScoreType;
  value: number;
}

export const getUserScore = (
  valence: MetricGoodBound,
  userPercentileRank: UserPercentileRank,
  cohortMonthlyTrend: CohortMonthlyTrend = [],
  userMonthlyTrend: UserMonthlyTrend = []
): IUserScore => {
  const isDeadHeat = cohortMonthlyTrend?.every((cohortEntry) => {
    if (cohortEntry.q25 !== cohortEntry.q75) {
      return false;
    }

    const userEntry = userMonthlyTrend?.find((entry) => entry.dateId === cohortEntry.dateId);
    return userEntry ? userEntry.value === cohortEntry.q25 : true;
  });

  const isWeak =
    !isDeadHeat &&
    userPercentileRank !== null &&
    ((valence === 'lower' && userPercentileRank >= 0.75) ||
      (valence === 'upper' && userPercentileRank <= 0.25));

  const isStrong =
    !isDeadHeat &&
    userPercentileRank !== null &&
    ((valence === 'lower' && userPercentileRank <= 0.25) ||
      (valence === 'upper' && userPercentileRank >= 0.75));

  let type = UserScoreType.average;
  if (isDeadHeat) type = UserScoreType.deadHeat;
  if (isWeak) type = UserScoreType.weak;
  if (isStrong) type = UserScoreType.strong;

  return {
    isDeadHeat,
    isWeak,
    isStrong,
    type,
    value: userPercentileRank
  };
};

interface IUserScoreProps {
  metric: IBaseMetric;
  metricResult: IStandardComparison;
  withCohortSize?: boolean;
}

const UserScore: FC<IUserScoreProps> = (props: IUserScoreProps) => {
  const score = getUserScore(
    props.metric.GoodBound,
    props.metricResult.userPercentileRank,
    props.metricResult.cohortMonthlyTrend,
    props.metricResult.userMonthlyTrend
  );

  // Onboarding customers will have a value of null, and should thus can't have a meaningful percentile ranking.
  if (score.value === null) {
    return <span>--</span>;
  }

  const getText = () => {
    const scorePercentage = (score.value * 100).toFixed(0) + '%';
    const cohortSize = props.metricResult.allAverages.length - 1;
    return props.withCohortSize ? `${scorePercentage} OF ${cohortSize}` : scorePercentage;
  };
  switch (score.type) {
    case UserScoreType.strong:
      return (
        <Text color='success' strong noWrap>
          <TrophyIcon color='success' />
          &nbsp;
          {getText()}
        </Text>
      );
    case UserScoreType.weak:
      return (
        <Text color='error' strong noWrap>
          <WarningIcon color='error' />
          &nbsp;
          {getText()}
        </Text>
      );
    case UserScoreType.deadHeat:
      return null;
    default:
      return (
        <Text strong noWrap>
          {getText()}
        </Text>
      );
  }
};

export default UserScore;

import IBaseMetric from './IBaseMetric';
import { ICacheableStandardComparison } from './IStandardComparison';
import { IUseSelectableMetrics } from './useSelectableMetrics';
import { IUseSelectableComparisons } from 'shared/comparisons/data/useSelectableComparisons';

export interface IPairedMetricsAndComparisons {
  metric: IBaseMetric;
  comparison?: ICacheableStandardComparison;
}

export default function pairSelectableMetricsAndSelectableComparisons(
  selectableMetrics: IUseSelectableMetrics<IBaseMetric>,
  selectableComparisons: IUseSelectableComparisons
): IPairedMetricsAndComparisons[] {
  const pairs: IPairedMetricsAndComparisons[] = [];

  selectableMetrics.selected?.forEach((dataName) => {
    const metric = selectableMetrics.metrics.find((metric) => metric.Data_Name === dataName);

    const comparison = selectableComparisons.comparisons.find(
      (comparison) => comparison.dataName === dataName
    );

    if (metric) {
      pairs.push({ metric, comparison });
    }
  });

  return pairs;
}

export function pairMetricsAndComparisons(
  metrics: IBaseMetric[],
  comparisons: ICacheableStandardComparison[]
): IPairedMetricsAndComparisons[] {
  const pairs: IPairedMetricsAndComparisons[] = [];

  metrics.forEach((metric) => {
    const comparison = comparisons.find((comparison) => comparison.dataName === metric.Data_Name);
    pairs.push({ metric, comparison });
  });

  return pairs;
}

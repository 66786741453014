import React, { FC, Fragment, ReactElement } from 'react';
import { FilterScope, IFilterGroup } from './IFilter';
import { Card, Spacing } from '@strata/tempo/lib';
import ComparisonViewToggle from './settings/ComparisonViewToggle';

import './settings/settings.scss';

interface IFilterGroupSettingsProps {
  extra?: ReactElement;
  filterGroup: IFilterGroup<string>;
  filterScope: FilterScope;
  withViewToggle?: boolean;
}

const FilterGroupSettings: FC<IFilterGroupSettingsProps> = (props) => {
  const settings = Object.values(props.filterGroup.filters)
    .filter((entry) => [props.filterScope, 'all'].includes(entry.scope))
    .filter((entry) => !entry.settingHidden)
    .sort((a, b) => a.settingWeight - b.settingWeight)
    .map((filter, index) => <Fragment key={index}>{filter.Setting}</Fragment>);

  return (
    <div className='filter-group-settings'>
      <Spacing padding='0px 0px 0' width='100%'>
        <Card loading={!props.filterGroup.ready}>
          <Spacing padding={'0 8px'} hAlign={'space-between'}>
            <div className='filter-description'>
              <div style={{ flex: 1 }} className='settings'>
                <Spacing itemSpacing={16} wrap>
                  {props.extra}
                  {settings}
                </Spacing>
              </div>
              {props.withViewToggle ? <ComparisonViewToggle /> : <></>}
            </div>
          </Spacing>
        </Card>
      </Spacing>
    </div>
  );
};

export default FilterGroupSettings;

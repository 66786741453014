/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { CheckboxGroup, DropDown, Form, Switch } from '@strata/tempo/lib';
import { IFilterSettingProps, IFilterControlProps } from './IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { useUiConfigContext } from 'shared/data/useUiConfigContext';

export const DefaultControl: FC<IFilterControlProps<any>> = ({
  filterConfig,
  filterValue,
  setFilterValue
}: IFilterControlProps<any>) => {
  const uiConfigContext = useUiConfigContext();
  const options =
    filterConfig.controlOptions ||
    uiConfigContext.cohortFilterOptions.filter(
      (o) =>
        o.fieldName === filterConfig.controlOptionsFromUiConfig?.fieldname &&
        o.source === filterConfig.controlOptionsFromUiConfig?.source
    );

  let control;
  switch (filterConfig.controlType) {
    case 'checkboxgroup':
      control = <CheckboxGroup onChange={setFilterValue} options={options} value={filterValue} />;
      break;
    case 'dropdown':
    case 'multiselect':
      control = (
        <DropDown
          multiSelect={filterConfig.controlType === 'multiselect'}
          items={options}
          value={filterValue}
          onChange={(val) => setFilterValue(val as typeof filterConfig.defaultValue)}
        />
      );
      break;
    case 'switch':
      control = <Switch checked={filterValue} onClick={setFilterValue} />;
      break;
  }

  return <Form.Item label={filterConfig.controlLabel}>{control}</Form.Item>;
};

export const DefaultSetting: FC<IFilterSettingProps<any>> = ({ filterValue, filterConfig }) => {
  const isVisible = filterValue instanceof Array ? filterValue.length > 0 : Boolean(filterValue);
  const settingValue = filterValue instanceof Array ? filterValue.join(', ') : String(filterValue);

  return (
    <Setting
      isVisible={isVisible}
      label={filterConfig.settingLabel as string}
      value={settingValue}
    />
  );
};

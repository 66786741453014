import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { Form, InputNumber } from '@strata/tempo/lib';

const MinimumVolumeControl: FC<IFilterControlProps<number>> = ({ filterValue, setFilterValue }) => {
  const handleChange = (value: number | string | undefined | null) => {
    if (!value || value < 0) {
      return setFilterValue(0);
    }
    const allowedValue = (value as number) <= 500 ? Math.round(value as number) : 500;
    setFilterValue(allowedValue);
  };

  return (
    <Form.Item
      label='Minimum Average Monthly Volume'
      extra='This filter applies to your data as well as the comparison cohort at the service line level. (It does not apply to care families.)'
    >
      <InputNumber value={filterValue} onChange={handleChange} precision={0} />
    </Form.Item>
  );
};

const MinimumVolumeSetting: FC<IFilterSettingProps<number>> = ({ filterValue }) => {
  return (
    <Setting
      isVisible={Boolean(filterValue)}
      label='Minimum Average Monthly Volume'
      value={filterValue.toString()}
    />
  );
};

export const minimumVolume: IFilterConfig<number> = {
  Control: MinimumVolumeControl,
  controlWeight: 6,
  defaultValue: 0,
  param: 'minimumVolume',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.minimumVolume);
  },
  Setting: MinimumVolumeSetting,
  settingWeight: 7.0
};

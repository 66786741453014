import { Modal } from '@strata/tempo/lib';
import { usePopupContext } from './usePopupContext';

interface IUseAlert {
  alertType?: 'info' | 'success' | 'error';
  title?: string;
  content: string;
  onClose?(): void;
}

const useAlert = () => {
  const popupContext = usePopupContext();

  return (options: IUseAlert) => {
    if (popupContext.isShowing) {
      return;
    }

    popupContext.setIsShowing(true);

    Modal.alert({
      alertType: options.alertType || 'error',
      title: options.title || 'Error',
      content: options.content,
      onClose() {
        popupContext.setIsShowing(false);
        options.onClose?.();
      }
    });
  };
};

export { useAlert };

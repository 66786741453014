import React, { FC } from 'react';
import { Spacing, Text } from '@strata/tempo/lib';

export interface ISettingProps {
  isVisible?: boolean;
  label: string;
  value?: string;
}

const Setting: FC<ISettingProps> = (props: ISettingProps) => {
  // To hide this component set "isVisible" to false.
  // (Undefined values should render this component!)
  if (props.isVisible === false) {
    return null;
  }

  return (
    <div>
      <Spacing margin='0 0 8px 0'>
        <Text>
          {props.label}:&nbsp;
          <strong>{props.value}</strong>
        </Text>
      </Spacing>
    </div>
  );
};

export default Setting;

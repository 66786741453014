import React, { FC, useEffect } from 'react';
import ServiceLineTaskBar from './ServiceLineTaskBar';
import ServiceLineDrawer from './ServiceLineDrawer';
import ServiceLineComparisons from './ServiceLineComparisons';

import LegalNotice from 'shared/LegalNotice';
import FilterGroupSettings from 'shared/filters/FilterGroupSettings';
import { useServiceLineContext } from './data/ServiceLineContext';
import { ISlCfData } from './data/serviceLineService';

const ServiceLineModule: FC = () => {
  const serviceLineContext = useServiceLineContext();

  useEffect(() => {
    if (!(serviceLineContext.isEnabled || serviceLineContext.isOnboarding)) {
      return;
    }

    if (serviceLineContext.filterGroup.ready) {
      serviceLineContext.serviceLineTable.load();
    }

    return serviceLineContext.serviceLineTable.cancelLoad;
  }, [
    serviceLineContext.filterGroup.ready,
    serviceLineContext.isEnabled,
    serviceLineContext.isOnboarding
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const comparisonIsOutOfSyncWithFilters = (x: ISlCfData) =>
      x.token !== serviceLineContext.filterGroup.token;

    if (serviceLineContext.serviceLineTable.data.some(comparisonIsOutOfSyncWithFilters)) {
      serviceLineContext.serviceLineTable.load();
    }
  }, []);

  return (
    <>
      <ServiceLineTaskBar />
      <FilterGroupSettings
        filterGroup={serviceLineContext.filterGroup}
        filterScope={serviceLineContext.filterGroup.filters.entity.computed?.scope}
      />
      <ServiceLineComparisons />
      <ServiceLineDrawer />
      <LegalNotice />
    </>
  );
};

export default ServiceLineModule;

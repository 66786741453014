import { IFilterConfig } from 'shared/filters/IFilter';

export const bedSize: IFilterConfig<string[]> = {
  controlLabel: 'Bed Size',
  controlOptionsFromUiConfig: {
    source: 'Entity',
    fieldname: 'bedsize'
  },
  controlType: 'checkboxgroup',
  controlWeight: 11,
  defaultValue: [],
  param: 'bedSize',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.bedSize);
  },
  scope: 'entity',
  settingLabel: 'Bed Size',
  settingWeight: 11
};

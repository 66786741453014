import React, { FC } from 'react';
import { Spacing, Text } from '@strata/tempo/lib';
import { IncompleteReason, IneligibleReason } from 'shared/data/IBaseComparison';

interface IIncompleteCardProps {
  reason: IncompleteReason | IneligibleReason;
}

export const getFriendlyReason = (
  reason: IncompleteReason | IneligibleReason | null | undefined
): { title: string; message: string } => {
  if (!reason) {
    return { title: '', message: '' };
  }
  const map = {
    MissingCohort: {
      title: 'Missing Cohort',
      message: 'There are not enough entities to compare against.'
    },
    MissingUser: {
      title: 'Missing User Data',
      message: 'Your entity is missing data for this metric.'
    },
    ServiceLineDoesNotApply: {
      title: 'Service Line Does Not Apply',
      message: 'Service Line filters do not work on this metric.'
    },
    DepartmentDoesNotApply: {
      title: 'Department Filtering Does Not Apply',
      message: 'Department filters do not work on this metric.'
    }
  };

  return map[reason];
};

const IncompleteCard: FC<IIncompleteCardProps> = (props: IIncompleteCardProps) => {
  const friendlyReason = getFriendlyReason(props.reason);

  return (
    <Spacing hAlign='center' vAlign='center' height='100%' column>
      <Text size='large' color='secondary'>
        {friendlyReason.title}
      </Text>
      <Text color='secondary'>{friendlyReason.message}</Text>
    </Spacing>
  );
};
export default IncompleteCard;

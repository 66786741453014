import { IFilterGroup, toParams, toToken, useFilter, useGlobalFilterContext } from 'shared/filters';
import {
  careFamily,
  serviceLine,
  patientType,
  componentRollup,
  costDriver,
  msdrgCode,
  fiscalYearEnd,
  submodule
} from 'cost/filters/config';
import { componentOrDriverSelector } from './config/componentOrDriverSelector';

export const useCostFilterGroup = (): IFilterGroup<CostFilterKeys> => {
  const globalFilterContext = useGlobalFilterContext();

  const filters = {
    compareWithinOwnHealthSystem:
      globalFilterContext.filterGroup.filters.compareWithinOwnHealthSystem,
    entity: globalFilterContext.filterGroup.filters.costEntity,
    timeline: globalFilterContext.filterGroup.filters.timeline,
    patientType: useFilter(patientType),
    costDriver: useFilter(costDriver),
    componentRollup: useFilter(componentRollup),
    msdrgCode: useFilter(msdrgCode),
    fiscalYearEnd: useFilter(fiscalYearEnd),
    amcType: globalFilterContext.filterGroup.filters.amcType,
    bedSize: globalFilterContext.filterGroup.filters.bedSize,
    careFamily: useFilter(careFamily),
    censusRegion: globalFilterContext.filterGroup.filters.censusRegion,
    healthSystemType: globalFilterContext.filterGroup.filters.healthSystemType,
    hospitalType: globalFilterContext.filterGroup.filters.hospitalType,
    operatingExpense: globalFilterContext.filterGroup.filters.operatingExpense,
    outlierExclusion: globalFilterContext.filterGroup.filters.outlierExclusion,
    serviceLine: useFilter(serviceLine),
    systemOperatingExpense: globalFilterContext.filterGroup.filters.systemOperatingExpense,
    urbanRural: globalFilterContext.filterGroup.filters.urbanRural,
    submodule: useFilter(submodule),
    componentOrDriver: useFilter(componentOrDriverSelector)
  };

  return {
    filters,
    params: toParams(filters),
    token: toToken(filters),
    ready: globalFilterContext.filterGroup.ready
  };
};

export type CostFilterKeys =
  | 'compareWithinOwnHealthSystem'
  | 'entity'
  | 'timeline'
  | 'patientType'
  | 'costDriver'
  | 'componentRollup'
  | 'msdrgCode'
  | 'fiscalYearEnd'
  | 'amcType'
  | 'bedSize'
  | 'careFamily'
  | 'censusRegion'
  | 'healthSystemType'
  | 'hospitalType'
  | 'operatingExpense'
  | 'outlierExclusion'
  | 'serviceLine'
  | 'systemOperatingExpense'
  | 'urbanRural'
  | 'submodule' // submodule refers to the set of CostPerDay or CostPerCase metrics
  | 'componentOrDriver';

import React, { FC } from 'react';
import { Spacing, Text } from '@strata/tempo/lib';

interface IHistogramControlsProps {
  isOnboarding: boolean;
}

const Controls: FC<IHistogramControlsProps> = (props) => {
  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Text size='small'>Frequency distribution of values over selected timeline</Text>
      </div>
      <Spacing itemSpacing={8} hAlign='center'>
        {props.isOnboarding ? (
          <></>
        ) : (
          <div className='bm-bee-legend-container' style={{ cursor: 'default' }}>
            <div className='bm-bee-legend bm-bee-user' />
            You
          </div>
        )}
      </Spacing>
    </>
  );
};

export default Controls;

import { IFilterConfig, IFilterSettingProps } from 'shared/filters/IFilter';
import React, { FC } from 'react';
import Setting from 'shared/filters/settings/individual/Setting';

export type MedicalGroupControlValue = '0' | '1';

const MedicalGroupSetting: FC<IFilterSettingProps<MedicalGroupControlValue[]>> = ({
  filterValue
}) => {
  const isVisible = filterValue.length === 1;
  const value = filterValue[0] === '1' ? 'Yes' : 'No';
  return <Setting isVisible={isVisible} label='Has Medical Group' value={value} />;
};

export const medicalGroup: IFilterConfig<MedicalGroupControlValue[]> = {
  controlLabel: 'Medical Group',
  controlType: 'checkboxgroup',
  controlOptionsFromUiConfig: {
    source: 'System',
    fieldname: 'hasmedicalgroup'
  },
  controlWeight: 13,
  defaultValue: [],
  param: 'hasMedicalGroup',
  loadFromParams(params, setFilterValue) {
    // Transform legacy filter values (booleans)
    const value =
      typeof params.hasMedicalGroup === 'boolean'
        ? [params.hasMedicalGroup ? '1' : null]
        : params.hasMedicalGroup;

    setFilterValue(value);
  },
  Setting: MedicalGroupSetting,
  settingWeight: 13
};

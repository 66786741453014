import { IStandardComparison } from 'shared/data/IStandardComparison';
import { FilterScope, IFilterGroup } from 'shared/filters';
import { ServiceLineFilterKeys } from 'serviceline/filters/useServiceLineFilterGroup';
import { cacheableHttpPost } from 'shared/data';

// eslint-disable-next-line
// @ts-ignore
import { format } from 'd3';
import { makeUid } from 'shared/utils';

import { Timer } from 'shared/utils/timer';

export interface ISlCfData {
  name: string; // a Service Line or Care Family name
  cmi: IStandardComparison;
  cost: IStandardComparison;
  los: IStandardComparison;
  volume: IStandardComparison;
  token: string; // filterGroup token
}

export type SlCfType = 'Service Line' | 'Care Family';

interface ISlCfDataCached {
  token: string;
  data: ISlCfData[];
}

export const datadogIdentifier = (function () {
  let id: string;
  const getId = () => id;
  const makeId = () => (id = makeUid());
  return {
    getId,
    makeId
  };
})();

const slCache: ISlCfDataCached[] = [];
export const fetchServiceLines = async function (
  filterGroup: IFilterGroup<ServiceLineFilterKeys>,
  strataId: number,
  isOnboarding: boolean,
  signal: AbortSignal
): Promise<ISlCfData[]> {
  const { token, params } = filterGroup;
  let data = slCache.find((entry) => entry.token === token)?.data;

  if (data) {
    return Promise.resolve(data);
  }

  let path;
  if (filterGroup.filters.compareWithinOwnHealthSystem.filterValue) {
    path = 'my-entities';
  } else if (filterGroup.filters.entity.computed?.scope === ('system' as FilterScope)) {
    path = 'systems';
  } else {
    path = 'entities';
  }

  const timer = new Timer();
  data = await cacheableHttpPost<ISlCfData[]>(
    `/service-line-comparisons/${path}`,
    {
      ...params,
      strataId,
      comparisonId: datadogIdentifier.makeId(),
      isOnboarding
    },
    {
      signal
    }
  );
  timer.getElapsed();

  // Sorting by our stakeholder's preferred method must happen here, not in the DataGrid.
  // Doing it here allows us to properly select the first row every time data changes.
  data.sort((a, b) => b.volume.userAverage - a.volume.userAverage);

  slCache.push({ token, data });

  return data;
};

const cfCache: ISlCfDataCached[] = [];
export const fetchCareFamilies = async function (
  filterGroup: IFilterGroup<ServiceLineFilterKeys>,
  strataId: number,
  isOnboarding: boolean,
  signal: AbortSignal,
  serviceLineName: string
): Promise<ISlCfData[]> {
  const cacheToken = filterGroup.token + serviceLineName;
  let data = cfCache.find((entry) => entry.token === cacheToken)?.data;

  if (data) {
    return Promise.resolve(data);
  }

  let path;
  if (filterGroup.filters.compareWithinOwnHealthSystem.filterValue) {
    path = 'my-entities';
  } else if (filterGroup.filters.entity.computed?.scope === ('system' as FilterScope)) {
    path = 'systems';
  } else {
    path = 'entities';
  }

  data = await cacheableHttpPost<ISlCfData[]>(
    `/care-family-comparisons/${path}`,
    {
      ...filterGroup.params,
      strataId,
      serviceLineName,
      comparisonId: datadogIdentifier.getId(),
      isOnboarding
    },
    {
      signal
    }
  );

  data = data.map((entry) => ({
    ...entry,
    token: filterGroup.token
  }));

  // Sorting by our stakeholder's preferred method must happen here, not in the DataGrid.
  // Doing it here allows us to properly select the first row every time data changes.
  data.sort((a, b) => b.volume.userAverage - a.volume.userAverage);

  cfCache.push({ token: cacheToken, data });

  return data;
};

export interface ISlCfTableRow {
  name: string;
  volumeUserUnformatted: number;
  volumeUser: string;
  cmiUser: string;
  cmiMedian: string;
  losUser: string;
  losMedian: string;
  losDiff: string;
  losDiffPercentage: number;
  costUser: number;
  costMedian: number;
  costDiff: number;
  costDiffPercentage: number;
}

export const formatMoney = format('($,.0f');
export const formatPretty = format(',.2f');
export const formatPrettyWholeNum = format(',.0f');

export const slCfToTableRow = (d: ISlCfData): ISlCfTableRow => {
  return {
    name: d.name,
    volumeUserUnformatted: d.volume.userAverage,
    volumeUser: formatPrettyWholeNum(d.volume.userAverage),
    cmiUser: formatPretty(d.cmi.userAverage),
    cmiMedian: formatPretty(d.cmi.stats?.median || 0),
    losUser: formatPretty(d.los.userAverage),
    losMedian: formatPretty(d.los.stats?.median || 0),
    losDiff: formatPretty(d.los.userAverage - d.los.stats?.median || 0),
    losDiffPercentage:
      ((d.los.userAverage - d.los.stats?.median || 0) / (d.los.stats?.median || 1)) * 100,
    costUser: d.cost.userAverage || 0,
    costMedian: d.cost.stats?.median || 0,
    costDiff: d.cost.userAverage - d.cost.stats?.median || 0,
    costDiffPercentage:
      ((d.cost.userAverage - d.cost.stats?.median || 0) / (d.cost.stats?.median || 1)) * 100
  };
};

export const tableRowToSlCfData = (name: string, data: ISlCfData[]): ISlCfData => {
  return data.find((entry) => entry.name === name) as ISlCfData;
};

export const formatMoneyToNumber = (moneyFormattedString: string) =>
  Number(moneyFormattedString.replace(/[^0-9\-.]/g, ''));

export const formatPrettyWholeNumberToNumber = (str: string) => str.replace(/,/g, '');

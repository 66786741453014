import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { DropDown, DropDownValue, Form, Text } from '@strata/tempo/lib';
import { useCostContext } from 'cost/data/costContext';

const PatientTypeControl: FC<IFilterControlProps<string>> = ({ filterValue, setFilterValue }) => {
  const costContext = useCostContext();

  const handleChange = (val: DropDownValue) => {
    const selectedOption = options.find((o) => o.value === val);

    costContext.setSelectedPatientTypeOption(selectedOption as option);
    setFilterValue(selectedOption?.filterValue ?? '');

    // If the submodule has changed, select the proper metric
    // (swap out the selected "_Case" metric with its "_Day" variant and vice versa)
    if (
      selectedOption?.submodule &&
      costContext.filterGroup.filters.submodule.filterValue !== selectedOption.submodule
    ) {
      const selectedMetricNameParts = costContext.selectableMetrics.selected[0].split('_');
      const newMetricNameSuffix = selectedMetricNameParts[1] === 'Case' ? 'Day' : 'Case';
      costContext.selectableMetrics.select([
        `${selectedMetricNameParts[0]}_${newMetricNameSuffix}`
      ]);
    }

    // Changing the Patient Type dropdown affects the submodule's value. ("Submodule" is technically a hidden filter.)
    costContext.filterGroup.filters.submodule.setFilterValue(
      selectedOption?.submodule ?? 'CostPerCase'
    );
  };

  return (
    <>
      <Text.Heading level={4}>Cost Filters</Text.Heading>
      <Form.Item label='Patient Type'>
        <DropDown
          items={options}
          value={costContext.selectedPatientTypeOption.value}
          onChange={handleChange}
        />
      </Form.Item>
    </>
  );
};

const PatientTypeSetting: FC<IFilterSettingProps<string>> = (props) => {
  const label = useCostContext().selectedPatientTypeOption.text;
  return <Setting isVisible={Boolean(props.filterValue)} label='Patient Type' value={label} />;
};

type option = { submodule: string; value: string; filterValue: string; text: string };

const options: option[] = [
  {
    submodule: 'CostPerCase',
    value: 'Inpatient (Cost per Case)',
    filterValue: 'Inpatient',
    text: 'Inpatient (Cost per Case)'
  },
  {
    submodule: 'CostPerDay',
    value: 'Inpatient (Cost per Day)',
    filterValue: 'Inpatient',
    text: 'Inpatient (Cost per Day)'
  },
  {
    submodule: 'CostPerCase',
    value: 'Observation (Cost per Case)',
    filterValue: 'Observation',
    text: 'Observation (Cost per Case)'
  },
  {
    submodule: 'CostPerCase',
    value: 'Outpatient (Cost per Case)',
    filterValue: 'Outpatient',
    text: 'Outpatient (Cost per Case)'
  },
  {
    submodule: 'CostPerCase',
    value: 'Emergency',
    filterValue: 'Emergency',
    text: 'Emergency (Cost per Case)'
  }
];

export const defaultOption = options[0];

export const patientType: IFilterConfig<string> = {
  Control: PatientTypeControl,
  controlWeight: 5.1,
  defaultValue: defaultOption.filterValue,
  param: 'sphPatientType',
  loadFromParams(params, setFilterValue) {
    // In June 2023, Cost was moved to the Marts, and the old param
    // changed from "inpatientOutpatient" to "sphPatientType".
    // This mapping allows old user views to continue working, despite the param name change.
    // Note the old system didn't have "Emergency" patient types.
    if (params.inpatientOutpatient) {
      const mapping = {
        '0': 'Outpatient',
        '1': 'Inpatient', // cost per day
        '2': 'Observation',
        '3': 'Inpatient' // cost per encounter
      };
      params.sphPatientType = mapping[params.inpatientOutpatient as keyof typeof mapping];
    }
    setFilterValue(params.sphPatientType);
  },
  Setting: PatientTypeSetting,
  settingWeight: 5.1
};

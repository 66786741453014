import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { useCostContext } from 'cost/data/costContext';
import { DropDown, DropDownValue, Form } from '@strata/tempo/lib';
import { costDriver } from './costDriver';

const CostComponentControl: FC<IFilterControlProps<string[]>> = ({
  filterValue,
  setFilterValue
}) => {
  const costContext = useCostContext();

  if (costContext.filterGroup.filters.componentOrDriver.computed?.isDrivers) {
    return null;
  }

  const handleChange = (val: DropDownValue) => {
    setFilterValue(val as string[]);
    costContext.filterGroup.filters.costDriver.setFilterValue(costDriver.defaultValue); // Side effect of any change to components
  };

  return (
    <Form.Item label='StrataSphere® Cost Component Rollup'>
      <DropDown multiSelect items={options} value={filterValue} onChange={handleChange} />
    </Form.Item>
  );
};

const CostComponentRollupSetting: FC<IFilterSettingProps<string[]>> = ({ filterValue }) => {
  const settingValue = filterValue.length === options.length ? 'All' : filterValue.join(', ');
  return <Setting label='StrataSphere® Cost Component Rollup' value={settingValue} />;
};

const options = [
  { value: 'Benefits', text: 'Benefits' },
  { value: 'Building and Equipment', text: 'Building and Equipment' },
  { value: 'Drugs', text: 'Drugs' },
  { value: 'Implants', text: 'Implants' },
  { value: 'Salaries', text: 'Salaries' },
  { value: 'Supplies', text: 'Supplies' },
  { value: 'Other', text: 'Other (see user guide for detail)' }
];

export const componentRollup: IFilterConfig<string[]> = {
  Control: CostComponentControl,
  controlOptions: options,
  controlWeight: 5.11,
  defaultValue: options.map((entry) => entry.value), // selects all
  param: 'sphCostComponentRollup',
  loadFromParams(params, setFilterValue) {
    if (params.sphCostComponentRollup) {
      setFilterValue(params.sphCostComponentRollup);
    }
  },
  Setting: CostComponentRollupSetting,
  settingWeight: 5.11
};

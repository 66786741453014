import React, { FC } from 'react';
import { Button, DataGrid } from '@strata/tempo/lib';
import { MetricDataName } from 'shared/data/IBaseMetric';
import UserScore from 'shared/user-score/UserScore';
import useComparisonTable from './useComparisonTable';

import { IPairedMetricsAndComparisons } from 'shared/data/pairedMetricsAndComparisons';

import CsvExporter from './CsvExporter';

interface IComparisonTableProps {
  data: IPairedMetricsAndComparisons[];
  disableCategoryColumn?: boolean;
  disableControls?: boolean;
  isLoading: boolean;
  sortable?: boolean;
  onInsightPanelClick?(value: MetricDataName): void;
}

const ComparisonTable: FC<IComparisonTableProps> = (props: IComparisonTableProps) => {
  const { tableData } = useComparisonTable(props.data);

  return (
    <div className='metric-table'>
      {!props.disableControls && <CsvExporter data={tableData} isLoading={props.isLoading} />}
      <DataGrid
        sortField={props.sortable ? 'name' : ''}
        sortOrder={1}
        value={tableData}
        paginator={!props.disableControls}
        density={'normal'}
        loading={props.isLoading}
      >
        <DataGrid.Column
          key='name'
          width={350}
          header='Metric Name'
          field='name'
          sortable={props.sortable}
          body={({ name }) => <div style={{ width: 300 }}>{name}</div>}
        />

        {props.disableCategoryColumn ? (
          <React.Fragment key='category' />
        ) : (
          <DataGrid.Column
            field='category'
            key='category'
            width={120}
            header='Category'
            sortable={props.sortable}
            sortField='category'
            body={({ category }) => <>{category}</>}
          />
        )}

        <DataGrid.Column
          field='value'
          key='user'
          width={120}
          header='My Average Value'
          body={({ value }) => <>{value}</>}
        />
        <DataGrid.Column
          key='percentilerank'
          width={120}
          header='Percentile Rank'
          sortable={props.sortable}
          sortField='rank'
          body={({ metric, metricResult }) =>
            metric && metricResult ? (
              <UserScore metric={metric} metricResult={metricResult} />
            ) : (
              <></>
            )
          }
        />
        <DataGrid.Column
          key='count'
          width={120}
          header='Comparison Count'
          sortable={props.sortable}
          sortField='cohortSize'
          body={({ cohortSize }) => <>{cohortSize}</>}
        />
        <DataGrid.Column
          key='25'
          width={120}
          header='25th Percentile'
          body={({ q25 }) => <>{q25}</>}
        />
        <DataGrid.Column
          key='median'
          width={120}
          header='Median'
          body={({ median }) => <>{median}</>}
        />
        <DataGrid.Column
          key='75'
          width={120}
          header='75th Percentile'
          body={({ q75 }) => <>{q75}</>}
        />
        {props.disableControls ? (
          <React.Fragment key='insightpanel' />
        ) : (
          <DataGrid.Column
            key='insightpanel'
            align='right'
            width={120}
            body={({ metricResult }) => (
              <Button
                type='link'
                disabled={!metricResult}
                onClick={() => {
                  if (props.onInsightPanelClick) {
                    props.onInsightPanelClick(metricResult.dataName);
                  }
                }}
              >
                Insight Panel
              </Button>
            )}
          />
        )}
      </DataGrid>
    </div>
  );
};

export default ComparisonTable;

import React, { BaseSyntheticEvent, PropsWithChildren, useState } from 'react';
import { Modal } from '@strata/tempo/lib';
import { IClientData } from './data/clientDataService';

// Maps TabPane labels to things
interface IModuleMap<T = string> {
  Cost: T;
  'Labor Expense': T;
  Overhead: T;
  'Service Line': T;
}

interface IAdvertisementProps {
  clientData?: IClientData;
}

const productionDataMap: IModuleMap<keyof IClientData> = {
  Cost: 'isProductionCost',
  'Labor Expense': 'isProductionLabor',
  Overhead: 'isProductionOverhead',
  'Service Line': 'isProductionCost'
};

const onboardingDataMap: IModuleMap<keyof IClientData> = {
  Cost: 'isSpecialAccessCost',
  'Labor Expense': 'isSpecialAccessLabor',
  Overhead: 'isSpecialAccessOverhead',
  'Service Line': 'isSpecialAccessCost'
};

const advertisementMap: IModuleMap<{ title?: string; message: string; imgSrc: string }> = {
  Cost: {
    title: 'You do not currently have access to Cost Compare.',
    message:
      'Access to Cost Compare requires that your organization has completed implementation of StrataJazz Cost Accounting that includes payroll data, your organization has at least one hospital in its network (versus clinics or other facilities), and has implemented supply-based costing (L2 or higher supply score). If you believe your organization meets these criteria or you would like to learn more, please contact stratasphere@stratadecision.com.',
    imgSrc: `${process.env.PUBLIC_URL}/img/costscreen.png`
  },
  'Labor Expense': {
    title: 'You do not currently have access to Labor Expense Compare.',
    message:
      'Access to Labor Expense Compare requires that your organization has completed implementation of StrataJazz Management Reporting and Cost Accounting, and has at least one hospital in its network (versus clinics or other facilities). If you believe your organization meets these criteria, or you would like to learn more, please contact stratasphere@stratadecision.com.',
    imgSrc: `${process.env.PUBLIC_URL}/img/laborscreen.png`
  },
  Overhead: {
    title: 'You do not currently have access to Overhead Compare.',
    message:
      'Access to Overhead Compare requires that your organization has completed implementation of StrataJazz Cost Accounting, at least one StrataJazz Financial Planning module, and has at least one hospital in its network (versus clinics or other facilities). If you believe your organization meets these criteria, or you would like to learn more, please contact stratasphere@stratadecision.com.',
    imgSrc: `${process.env.PUBLIC_URL}/img/overheadscreen.png`
  },
  'Service Line': {
    title: 'You do not currently have access to Service Line Compare.',
    message:
      'Access to Service Line Compare requires that your organization has completed implementation of StrataJazz Cost Accounting that includes payroll data, your organization has at least one hospital in its network (versus clinics or other facilities), and has implemented supply-based costing (L2 or higher supply score). If you believe your organization meets these criteria or you would like to learn more, please contact stratasphere@stratadecision.com.',
    imgSrc: `${process.env.PUBLIC_URL}/img/servicelinescreen.png`
  }
};

/*
Displays an Advertisement for a Compare Module
(when the user clicks on a disabled TabPane child component).
 */
const Advertisement: React.FC<PropsWithChildren<IAdvertisementProps>> = (
  props: PropsWithChildren<IAdvertisementProps>
) => {
  const [isVisible, setIsVisible] = useState(false);
  const [module, setModule] = useState<keyof IModuleMap>('Cost');

  const ad = advertisementMap[module];

  const handleChildTabClick = (event: BaseSyntheticEvent) => {
    if (!props.clientData) {
      return null;
    }

    const tabPaneKey = event.target.innerHTML as keyof IModuleMap;
    if (!(tabPaneKey in productionDataMap)) {
      return;
    }

    const moduleIsNotOnboardingNorProductionReady =
      !props.clientData[productionDataMap[tabPaneKey]] &&
      !props.clientData[onboardingDataMap[tabPaneKey]];

    if (moduleIsNotOnboardingNorProductionReady) {
      setIsVisible(true);
      setModule(tabPaneKey);
    }
  };

  return (
    <div onMouseUp={handleChildTabClick}>
      <Modal
        title='No Access'
        width='large'
        footer={null}
        visible={isVisible}
        onCancel={() => setIsVisible(false)}
      >
        {ad.title && (
          <p>
            <strong>{ad.title}</strong>
          </p>
        )}
        <p>{ad.message}</p>
        <img src={ad.imgSrc} width='100%' alt={ad.title} />
      </Modal>
      {props.children}
    </div>
  );
};

export default Advertisement;

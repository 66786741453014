import { IFilterConfig } from 'shared/filters/IFilter';
const options = [
  { value: 'All Overhead', text: 'All Rollups' },
  { value: 'Administration', text: 'Administration' },
  { value: 'Dietary Services', text: 'Dietary Services' },
  { value: 'Environmental Services', text: 'Environmental Services' },
  { value: 'Finance', text: 'Finance' },
  { value: 'Information Technology', text: 'Information Technology' },
  {
    value: 'Quality and Performance Improvement',
    text: 'Quality and Performance Improvement'
  },
  { value: 'Revenue Cycle', text: 'Revenue Cycle' },
  { value: 'Supply Chain', text: 'Supply Chain' },
  { value: 'Talent/HR', text: 'Talent/HR' }
];

export const departmentRollup: IFilterConfig<string> = {
  controlLabel: 'StrataSphere® Department Rollup',
  controlType: 'dropdown',
  controlOptions: options,
  controlWeight: 4,
  defaultValue: options[0].value,
  param: 'sphDepartmentRollupOverhead',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.sphDepartmentRollupOverhead);
  },
  settingLabel: 'StrataSphere® Department Rollup',
  settingWeight: 4
};

import React, { FC, useEffect } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { Form, Text, TreeDropDown } from '@strata/tempo/lib';
import msdrgTreeDropdownConfig from 'cost/data/msdrgTreeDropdownConfig';
import { useCostContext } from 'cost/data/costContext';

const MsdrgCodeControl: FC<IFilterControlProps<string>> = ({ filterValue, setFilterValue }) => {
  const costContext = useCostContext();

  const disabled = Boolean(costContext.filterGroup.filters.serviceLine.filterValue);

  // A Bug in AntD 4.16.x prevents clearing values in TreeSelect.
  // To get around this, as AntD is a tempo dependency, we're
  // manually handling the click.
  useEffect(() => {
    const el = '.ant-tree-select .ant-select-clear svg';
    const handleElClick = () => {
      setFilterValue('');
    };
    document.body.querySelector(el)?.addEventListener('click', handleElClick);
    return () => {
      document.body.querySelector(el)?.removeEventListener('click', handleElClick);
    };
  }, [filterValue]);

  return (
    <Form.Item label='MS-DRG Code'>
      {disabled && (
        <Text size='small' color='secondary'>
          MS-DRG Code filter not applicable with Service Line filter.
        </Text>
      )}
      <TreeDropDown
        allowClear
        key='msdrgCodeCost'
        disabled={disabled}
        treeData={msdrgTreeDropdownConfig}
        value={filterValue}
        onSelect={(val) => {
          setFilterValue((val as string) ?? '');
        }}
      />
    </Form.Item>
  );
};

const MsdrgCodeSetting: FC<IFilterSettingProps<string>> = ({ filterValue }) => {
  let settingValue;
  for (const entry of msdrgTreeDropdownConfig) {
    const match = entry.children.find(({ value }) => value === filterValue);
    if (match) {
      settingValue = match.title;
      break;
    }
  }
  return <Setting isVisible={Boolean(settingValue)} label='MS-DRG Code' value={settingValue} />;
};

export const msdrgCode: IFilterConfig<string> = {
  Control: MsdrgCodeControl,
  controlWeight: 5.2,
  defaultValue: '',
  param: 'sphMsdrgCode',
  loadFromParams(params, setFilterValue) {
    // In June 2023, Cost was moved to the Marts, and the old param
    // changed from "msdrgCode" to "sphMsdrgCode".
    // This expression allows old user views to continue working, despite the param name change.
    setFilterValue(params.msdrgCode || params.sphMsdrgCode || '');
  },
  Setting: MsdrgCodeSetting,
  settingWeight: 5.2
};

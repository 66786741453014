import React, { FC, createContext, useContext, useState } from 'react';

type Visualization = 'Line Chart' | 'Table' | Distribution;
type Distribution = 'Beeswarm' | 'Histogram';

interface IVisualizationContext {
  selectedVisualization: Visualization;
  setSelectedVisualization(selectedVisualization: Visualization): void;
  selectedDistribution: Distribution;
  setSelectedDistribution(d: Distribution): void;
}

const context = createContext({} as IVisualizationContext);
const useVisualizationContext = (): IVisualizationContext => useContext(context);

const VisualizationProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const [selectedVisualization, setSelectedVisualization] = useState<Visualization>('Line Chart');
  const [selectedDistribution, setSelectedDistribution] = useState<Distribution>('Beeswarm');

  const provided = {
    selectedVisualization,
    setSelectedVisualization,
    selectedDistribution,
    setSelectedDistribution
  };

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

export { useVisualizationContext, VisualizationProvider };
export type { Visualization, Distribution };

import React from 'react';
import { Text } from '@strata/tempo/lib';
import { format } from 'd3';
import WarningIcon from '@strata/tempo/lib/icon/WarningIcon';
import TrophyIcon from '@strata/tempo/lib/icon/TrophyIcon';

class RankMetrics extends React.Component {
  determineVisualSign = (goodBound, userPercentileRank) => {
    if (goodBound === 'lower') {
      if (userPercentileRank <= 0.25) {
        return 'good';
      } else if (userPercentileRank >= 0.75) {
        return 'bad';
      }
    } else if (goodBound === 'upper') {
      if (userPercentileRank <= 0.25) {
        return 'bad';
      } else if (userPercentileRank >= 0.75) {
        return 'good';
      }
    }
    return '';
  };
  renderPercentile = () => {
    let status;
    let iconRender;
    switch (this.determineVisualSign(this.props.metric.GoodBound, this.props.metricResult.userPercentileRank)) {
      case 'good':
        status = 'You are leading in this metric.';
        iconRender = <TrophyIcon style={{ marginRight: 3 }} color='blue-700' />;
        break;
      case 'bad':
        status = 'You are underperforming in this metric.';
        iconRender = <WarningIcon style={{ marginRight: 3 }} color='orange-500' />;
        break;
      default:
    }
    return (
      <Text>
        {iconRender}This value ranks <Text strong>{(this.props.metricResult.userPercentileRank * 100).toFixed(0)}%</Text> among your cohort of{' '}
        <Text strong>{this.props.metricResult.allAverages.length - 1}</Text> entities. {status}
      </Text>
    );
  };
  render() {
    const { metric, metricResult } = this.props;
    const userAverage = metric.Format.tooltip.includes('%') ? metricResult.userAverage / 100 : metricResult.userAverage;
    return (
      <div style={{ marginBottom: 20, padding: 12 }}>
        <Text.Heading level={4}>Rank Metrics</Text.Heading>
        <Text>
          Your average value over this time period is <Text strong>{format(metric.Format.tooltip)(userAverage)}</Text>.
        </Text>
        <br />
        {this.renderPercentile()}
      </div>
    );
  }
}

export default RankMetrics;

// Tempo charting colors
// https://tempo.dev.stratanetwork.net/style/color
const tempoChartColors = {
  series1: '#00A1B5',
  series2: '#004D40',
  series3: '#EF5350',
  series4: '#7B1FA2',
  series5: '#1E88E5',
  series6: '#AD1457',
  series7: '#A1887F',
  series8: '#1A237E',
  highlight: '#C3E5EC',
  outlier: '#B9B9B9'
};

const wash = (hexColor: string) => `${hexColor}11`;

const style = {
  user: {
    stroke: tempoChartColors.series3,
    strokeWidth: 2
  },
  cohort: {
    interquartile: {
      fill: wash(tempoChartColors.series4),
      stroke: tempoChartColors.series4,
      strokeWidth: 1
    },
    median: {
      stroke: tempoChartColors.series1,
      strokeWidth: 2
    }
  },
  highlight: tempoChartColors.highlight,
  outlier: tempoChartColors.outlier
};

export { tempoChartColors, style, wash };

import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { DropDown, DropDownValue, Form } from '@strata/tempo/lib';
import { useCostContext } from 'cost/data/costContext';
import { componentRollup } from './componentRollup';

const CostDriverControl: FC<IFilterControlProps<string[]>> = ({ filterValue, setFilterValue }) => {
  const costContext = useCostContext();

  if (costContext.filterGroup.filters.componentOrDriver.computed?.isComponents) {
    return null;
  }

  const handleChange = (val: DropDownValue) => {
    setFilterValue(val as string[]);
    costContext.filterGroup.filters.componentRollup.setFilterValue(componentRollup.defaultValue); // Side effect of any change to drivers
  };

  return (
    <Form.Item label='StrataSphere® Cost Driver'>
      <DropDown multiSelect items={options} value={filterValue} onChange={handleChange} />
    </Form.Item>
  );
};

const CostDriverSetting: FC<IFilterSettingProps<string[]>> = ({ filterValue }) => {
  const settingValue = filterValue.length === options.length ? 'All' : filterValue.join(', ');
  return <Setting label='StrataSphere® Cost Driver' value={settingValue} />;
};

const options = [
  { value: 'Anesthesia and Recovery', text: 'Anesthesia and Recovery' },
  { value: 'Imaging', text: 'Imaging' },
  { value: 'Laboratory', text: 'Laboratory' },
  { value: 'LOS', text: 'LOS' },
  { value: 'OR Time', text: 'OR Time' },
  { value: 'Pharmacy', text: 'Pharmacy' },
  { value: 'Supplies', text: 'Supplies' },
  { value: 'Other', text: 'Other (see user guide for detail)' }
];

export const costDriver: IFilterConfig<string[]> = {
  controlWeight: 5.12,
  Control: CostDriverControl,
  defaultValue: options.map((o) => o.value),
  param: 'sphChargeCodeCostDriver',
  loadFromParams(params, setFilterValue) {
    if (params.sphChargeCodeCostDriver) {
      setFilterValue(params.sphChargeCodeCostDriver);
    }
  },
  Setting: CostDriverSetting,
  settingWeight: 5.12
};

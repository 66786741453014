import React, { FC } from 'react';
import CompareDrawer from 'shared/drawers/compare-drawer/CompareDrawer';
import DrawerFilters from 'shared/drawers/compare-drawer/DrawerFilters';
import { useCostContext } from 'cost/data/costContext';
import { Spacing } from '@strata/tempo/lib';

const CostDrawer: FC = () => {
  const costContext = useCostContext();

  const handleRun = async () => {
    if (!costContext.filterGroup.ready) {
      return;
    }
    costContext.compareDrawer.close();
    costContext.costComparisons.load();
  };

  return (
    <CompareDrawer
      visible={costContext.compareDrawer.isOpen}
      onClose={costContext.compareDrawer.close}
      onRun={handleRun}
      hasSelectedMetrics
      hasLoadedMetricResults={costContext.costComparisons.loadingDone}
      pleaseWait={!costContext.filterGroup.ready}
    >
      <Spacing padding='12px'>
        <DrawerFilters
          filterGroup={costContext.filterGroup}
          filterScope={costContext.filterGroup.filters.entity.computed?.scope}
        />
      </Spacing>
    </CompareDrawer>
  );
};

export default CostDrawer;

import React, { FC } from 'react';
import ReactPDF, { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { ISelectedItem } from 'kpi/data/kpiContext';
import Font = ReactPDF.Font;
import { tempoChartColors } from '../../charts/style';
import { getData } from './DataSet';

interface IStrataSphereReportProps {
  item: ISelectedItem;
  legalTerms: string;
  monthlyTrendDataUrl: string;
  histogramDataUrl: string;
}

const StrataSphereReport: FC<IStrataSphereReportProps> = (props) => {
  Font.register({
    family: 'Roboto',
    src: `${process.env.PUBLIC_URL}/Roboto-Regular.ttf`
  });

  const styles = StyleSheet.create({
    header: {
      backgroundColor: '#005f7a',
      color: '#fff',
      fontSize: 10,
      padding: 20,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-end',
      justifyContent: 'space-between'
    },
    footer: {
      color: '#aaa',
      position: 'absolute',
      fontSize: 8,
      bottom: 30,
      left: 30,
      right: 30,
      textAlign: 'center'
    },
    page: {
      backgroundColor: '#e4e4e4',
      fontFamily: 'Roboto'
    },
    section: {
      flexGrow: 0,
      fontSize: 12,
      margin: 10,
      padding: 10
    },
    title: {
      fontSize: 24
    },
    metricName: {
      fontSize: 16
    },
    metricFilters: {
      fontSize: 10
    },
    headerImage: {
      width: 150
    },
    legend: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-around',
      marginTop: 30,
      marginBottom: 8,
      marginLeft: 30,
      marginRight: 30
    },
    item: {
      display: 'flex',
      flexDirection: 'row',
      width: 'auto',
      marginLeft: 10,
      marginRight: 10,
      fontSize: 8
    },
    swatch: {
      width: 12,
      height: 8,
      marginRight: 8
    },
    orange: {
      backgroundColor: tempoChartColors.series3
    },
    blue: {
      backgroundColor: tempoChartColors.series5
    },
    purple: {
      backgroundColor: tempoChartColors.series4
    },
    chartContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      justifyContent: 'space-between',
      flexWrap: 'wrap'
    },
    left: {
      width: '35%'
    },
    right: {
      width: '60%'
    }
  });

  const DataSet = getData(props.item.metric, props.item.comparison).map(({ name, stat }) => (
    <>
      <Text key={name + '1'} style={{ marginTop: 10, fontSize: 9, color: '#888' }}>
        {name}
      </Text>
      <Text key={name + '2'} style={{ fontSize: 10 }}>
        {stat}
      </Text>
    </>
  ));

  const settingString = document.querySelector<HTMLElement>(
    '.filter-group-settings .filter-description .settings'
  )?.innerText;

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View fixed style={styles.header}>
          <div>
            <Text style={styles.title}>StrataSphere Report</Text>
            <Text>
              Confidential Trade Secret and Proprietary Information of Strata Decision Technology,
              LLC
            </Text>
          </div>
          <Image
            style={styles.headerImage}
            src={`${process.env.PUBLIC_URL}/img/stratalogowhite-full.png`}
          />
        </View>
        <View style={styles.section}>
          <Text style={styles.metricName}>{props.item.metric.Friendly_Name}</Text>
          <Text style={styles.metricFilters}>{settingString}</Text>

          <div style={styles.chartContainer}>
            <div style={styles.left}>{DataSet}</div>
            <div style={styles.right}>
              <View style={styles.legend}>
                <div style={styles.item}>
                  <div style={{ ...styles.swatch, ...styles.orange }} />
                  <Text>You</Text>
                </div>
                <div style={styles.item}>
                  <div style={{ ...styles.swatch, ...styles.purple }} />
                  <Text>P25</Text>
                </div>
                <div style={styles.item}>
                  <div style={{ ...styles.swatch, ...styles.blue }} />
                  <Text>Median</Text>
                </div>
                <div style={styles.item}>
                  <div style={{ ...styles.swatch, ...styles.purple }} />
                  <Text>P75</Text>
                </div>
              </View>
              <Image src={props.monthlyTrendDataUrl} />
            </div>
          </div>
          <div style={styles.chartContainer}>
            <div style={styles.left} />
            <div style={styles.right}>
              <View style={styles.legend}>
                <div style={styles.item}>
                  <div style={{ ...styles.swatch, ...styles.orange }} />
                  <Text>Frequency distribution of values over selected timeline</Text>
                </div>
              </View>
              <Image src={props.histogramDataUrl} />
            </div>
          </div>
        </View>
        <Text fixed style={styles.footer}>
          {props.legalTerms}
        </Text>
      </Page>
    </Document>
  );
};

export default StrataSphereReport;

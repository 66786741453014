// TODO deprecate and use new tempo comp
// eslint-disable-next-line no-use-before-define
import React from 'react';
import PropTypes from 'prop-types';
import { Text, Icon, Tag } from '@strata/tempo/lib';
import { format } from 'd3';
import TrophyIcon from '@strata/tempo/lib/icon/TrophyIcon';
import WarningIcon from '@strata/tempo/lib/icon/WarningIcon';

class MetricFlag extends React.Component {
  render() {
    let { costDataResults } = this.props;
    let resultable = true;
    if (!costDataResults?.comparison) {
      resultable = false;
    }
    if (
      this.checkNoSL(costDataResults) ||
      this.checkNoCohort(costDataResults) ||
      this.checkNoUser(costDataResults)
    ) {
      resultable = false;
    }
    const borderRight = this.props.withBorder ? '1px solid #aaa' : '';
    return (
      <>
        <div style={{ height: 75, width: 125, minWidth: 125, margin: 10 }}>
          {/*<Tag level={6} color='secondary'>*/}
          {/*  {this.props.header}*/}
          {/*</Tag>*/}
          <Text color='secondary' size='small'>
            {costDataResults?.metric.Friendly_Name.replace('per Case', '')
              .replace('per Day', '')
              .replace('Avg', '')
              .replace('Average', '')}
          </Text>
          <Text.Heading level={1}>
            <span style={{ fontWeight: 500 }}>
              {resultable
                ? format(costDataResults.metric.Format.tooltip)(
                    costDataResults.comparison.userAverage
                  )
                : '--'}
            </span>
          </Text.Heading>
          {resultable ? this.renderPercentile(costDataResults) : null}
        </div>
        {borderRight ? <div style={{ borderRight, height: 75 }} /> : null}
      </>
    );
  }
  determineVisualSign = (goodBound, userPercentileRank) => {
    if (userPercentileRank == null) {
      return;
    }

    if (goodBound === 'lower') {
      if (userPercentileRank <= 0.25) {
        return 'good';
      } else if (userPercentileRank >= 0.75) {
        return 'bad';
      }
    } else if (goodBound === 'upper') {
      if (userPercentileRank <= 0.25) {
        return 'bad';
      } else if (userPercentileRank >= 0.75) {
        return 'good';
      }
    }
    return '';
  };
  checkNoSL = (k) => {
    return k?.comparison?.incompleteReason === 'NoSLApply';
  };
  checkNoCohort = (k) => {
    return k?.comparison?.incompleteReason === 'MissingCohort';
  };
  checkNoUser = (k) => {
    return k?.comparison?.incompleteReason === 'MissingUser';
  };
  renderPercentile = (k) => {
    if (k.comparison.userPercentileRank == null) {
      return null;
    }

    let color;
    let icon;
    switch (this.determineVisualSign(k.metric.GoodBound, k.comparison.userPercentileRank)) {
      case 'good':
        color = 'success';
        icon = <TrophyIcon color={color} />;
        break;
      case 'bad':
        color = 'error';
        icon = <WarningIcon color={color} />;
        break;
      default:
        icon = null;
    }
    return (
      <Text color={color} size='medium' strong>
        {icon}
        {`${(k.comparison.userPercentileRank * 100).toFixed(0)}%`}
      </Text>
    );
  };
}

MetricFlag.propTypes = {
  costDataResults: PropTypes.object,
  withBorder: PropTypes.bool,
  header: PropTypes.string
};

export default MetricFlag;

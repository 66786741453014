import React, { FC } from 'react';
import { useServiceLineContext } from './data/ServiceLineContext';

import TaskBar from 'shared/taskbar/TaskBar';

const ServiceLineTaskBar: FC = () => {
  const slContext = useServiceLineContext();
  const isLoading = slContext.serviceLineTable.loading || slContext.careFamilyTable.loading;
  const handleCancelLoad = () => {
    slContext.serviceLineTable.cancelLoad();
    slContext.careFamilyTable.cancelLoad();
  };

  return (
    <TaskBar
      loading={isLoading}
      metricDrawerButtonText='Filters'
      openMetricDrawer={slContext.drawer.open}
      cancelMetricResultsLoad={handleCancelLoad}
    />
  );
};

export default ServiceLineTaskBar;

import { appConfig, getSecureService } from '@strata/core/lib';
import {
  ISphDepartmentMapping,
  ISphJobCodeMapping,
  ISphGlAccountMapping,
  ISphPayCodeMapping,
  ISphPayorMapping
} from './ISphMapping';

const { httpGet, httpDownloadAndDisplayFile } = getSecureService(appConfig.apiUrl);

export const mappingsService = {
  export(useDemoDb = false): void {
    const flag = useDemoDb ? '?useDemoDb=true' : ''; // Tells the API to use the db (instead of the Excel spreadsheet)
    httpDownloadAndDisplayFile(`/mappings/export${flag}`, undefined, 'SPH_Mappings');
  },
  fetchDepartmentMappings(useDemoDb = false): Promise<ISphDepartmentMapping[]> {
    const flag = useDemoDb ? '?useDemoDb=true' : '';
    return httpGet(`/mappings/departments${flag}`);
  },
  fetchJobCodeMappings(useDemoDb = false): Promise<ISphJobCodeMapping[]> {
    const flag = useDemoDb ? '?useDemoDb=true' : '';
    return httpGet(`/mappings/job-codes${flag}`);
  },
  fetchGlAccountMappings(useDemoDb = false): Promise<ISphGlAccountMapping[]> {
    const flag = useDemoDb ? '?useDemoDb=true' : '';
    return httpGet(`/mappings/gl-accounts${flag}`);
  },
  fetchPayCodeMappings(useDemoDb = false): Promise<ISphPayCodeMapping[]> {
    const flag = useDemoDb ? '?useDemoDb=true' : '';
    return httpGet(`/mappings/pay-codes${flag}`);
  },
  fetchPayorMappings(useDemoDb = false): Promise<ISphPayorMapping[]> {
    const flag = useDemoDb ? '?useDemoDb=true' : '';
    return httpGet(`/mappings/payors${flag}`);
  }
};

import { useOidcSession } from '@strata/core/lib';
import { useAlert } from 'shared/utils';

interface IUseAlertErrorAndLogout {
  title?: string;
  content?: string;
}

const useLogoutNow = () => {
  const session = useOidcSession();
  const alert = useAlert();

  return (options?: IUseAlertErrorAndLogout) =>
    alert({
      alertType: 'error',
      title: options?.title || 'Signed Out',
      content: options?.content || 'You are no longer signed in.',
      onClose() {
        session.logout();
      }
    });
};

export { useLogoutNow };

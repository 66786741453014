import React from 'react';
import { Text, Button, Modal } from '@strata/tempo/lib';
import MathJax from 'react-mathjax';

class FormulaDefinition extends React.Component {
  state = {
    modalOpen: false
  };
  openModal = () => {
    this.setState({ modalOpen: true });
  };
  closeModal = () => {
    this.setState({ modalOpen: false });
  };
  render() {
    let { definition } = this.props;
    return (
      <div style={{ marginTop: 10, marginBottom: 10, padding: 12 }}>
        <Text.Heading level={4}>Metric Formula Definition</Text.Heading>
        <Button onClick={this.openModal}>View Formula</Button>
        <Modal width='large' title='Formula Definition' visible={this.state.modalOpen} footer={null} onCancel={this.closeModal}>
          <MathJax.Provider>
            <div style={{ width: '100%', overflowX: 'auto' }}>
              <MathJax.Node formula={definition} />
            </div>
          </MathJax.Provider>
        </Modal>
      </div>
    );
  }
}

export default FormulaDefinition;

import React, { FC, createContext, useContext, useEffect } from 'react';
import useDrawer, { IUseDrawer } from 'shared/drawers/data/useDrawer';
import { laborSnowflakeComparisonService } from './laborComparisonService';
import useSelectableComparisons, {
  IUseSelectableComparisons
} from 'shared/comparisons/data/useSelectableComparisons';
import useSelectableMetrics, { IUseSelectableMetrics } from 'shared/data/useSelectableMetrics';
import pairMetricsAndComparisons, {
  IPairedMetricsAndComparisons
} from 'shared/data/pairedMetricsAndComparisons';
import IBaseMetric, { MetricDataName } from 'shared/data/IBaseMetric';
import { IFilterGroup } from 'shared/filters/IFilter';
import { LaborFilterKeys, useLaborFilterGroup } from 'labor/filters/useLaborFilterGroup';
import { useUiConfigContext } from 'shared/data/useUiConfigContext';
import { ISelectedItem } from '../../kpi/data/kpiContext';

export interface ILaborContext {
  compareDrawer: IUseDrawer;
  insightDrawer: IUseDrawer;
  filterGroup: IFilterGroup<LaborFilterKeys>;
  loadComparisons: () => void;
  selectableComparisons: IUseSelectableComparisons;
  selectableMetrics: IUseSelectableMetrics<IBaseMetric>;
  // computed
  selectableItems: IPairedMetricsAndComparisons[];
  selectedItem?: ISelectedItem;
  outOfScopeMetrics: MetricDataName[];
}

const context = createContext({} as ILaborContext);

const LaborProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const compareDrawer = useDrawer();
  const filterGroup = useLaborFilterGroup();
  const insightDrawer = useDrawer();
  const selectableComparisons = useSelectableComparisons(
    laborSnowflakeComparisonService,
    filterGroup
  );
  const uiConfigContext = useUiConfigContext();
  const selectableMetrics = useSelectableMetrics(uiConfigContext.laborMetrics);
  const selectableItems = pairMetricsAndComparisons(selectableMetrics, selectableComparisons);

  const isFilteringOnDepartment = filterGroup.filters.departmentRollup.filterValue !== 'ALL';
  const outOfScopeMetrics = isFilteringOnDepartment
    ? selectableMetrics.metrics
        .filter((entry) => !entry.Scope.includes('SphDepartmentRollup'))
        .map((entry) => entry.Data_Name)
    : [];

  const provided = {
    compareDrawer,
    filterGroup,
    insightDrawer,
    loadComparisons: () => {
      const eligibleMetrics = selectableMetrics.selected.filter(
        (entry) => !outOfScopeMetrics.includes(entry)
      );
      selectableComparisons.load(eligibleMetrics);
    },
    outOfScopeMetrics,
    selectableComparisons,
    selectableMetrics,
    selectableItems,
    selectedItem: selectableItems.find(
      (item) => item.comparison?.dataName === selectableComparisons.selected
    ) as ISelectedItem
  };

  useEffect(() => {
    if (provided.selectableItems.find((item) => !item.comparison)) {
      selectableComparisons.setLoadingDone(false);
    }
  }, [provided.selectableItems]); // eslint-disable-line react-hooks/exhaustive-deps

  // If the labor entity filter changes to System scope, then Labor Department needs to be reset.
  const isSystemScope = filterGroup.filters.entity.computed?.isSystemScope;
  useEffect(() => {
    filterGroup.filters.departmentRollup.setFilterValue('ALL');
    filterGroup.filters.clinicalVsNonclinical.setFilterValue('ALL');
    filterGroup.filters.jobCodeRollup.setFilterValue('ALL');
  }, [isSystemScope]);

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

const useLaborContext = (): ILaborContext => useContext(context);

export { useLaborContext, LaborProvider };

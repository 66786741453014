import React, { useEffect } from 'react';
import { DataGrid, Text } from '@strata/tempo/lib';
import { useMappingContext } from '../data/mappingContext';
import { useRowCountCalculator } from './useRowCountCalculator';

const PayorDataGrid = () => {
  const { payor } = useMappingContext();

  useEffect(() => {
    if (!payor.data.length) {
      payor.load();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const rowCount = useRowCountCalculator();

  return (
    <DataGrid
      dataKey='payor'
      exportFilename='StrataSphere_Payor_Mappings'
      loading={!payor.loaded}
      paginator
      paginatorRight={
        <Text size='small'>
          To update your mappings please contact{' '}
          <a href='mailto:stratasphere@stratadecision.com'>stratasphere@stratadecision.com</a>
        </Text>
      }
      rows={rowCount}
      sortField='payor'
      sortOrder={1}
      value={payor.data}
    >
      <DataGrid.Column field='payor' header='Payor' sortable filter width={200} />
      <DataGrid.Column field='payorGroupName' header='Payor Group' sortable filter width={200} />
      <DataGrid.Column
        field='financialClassName'
        header='Financial Class'
        sortable
        filter
        width={200}
      />
      <DataGrid.Column
        field='insurancePlanCode'
        header='Insurance Plan Code'
        sortable
        filter
        width={200}
      />
      <DataGrid.Column field='name' header='Insurance Plan' sortable filter width={200} />
      <DataGrid.Column
        field='contractGrouping'
        header='Contract Group'
        sortable
        filter
        width={200}
      />
      <DataGrid.Column field='sphPayorType' header='SPH Payor Type' sortable filter width={200} />
      <DataGrid.Column field='sphPayorGroup' header='SPH Payor Group' sortable filter width={200} />
    </DataGrid>
  );
};

export default PayorDataGrid;

import { IFilterConfig } from 'shared/filters/IFilter';

export const hospitalType: IFilterConfig<string[]> = {
  controlLabel: 'Hospital Type',
  controlOptionsFromUiConfig: {
    source: 'Entity',
    fieldname: 'entitytype'
  },
  controlType: 'checkboxgroup',
  controlWeight: 13,
  defaultValue: [],
  param: 'hospitalType',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.hospitalType);
  },
  scope: 'entity',
  settingLabel: 'Hospital Type',
  settingWeight: 13
};

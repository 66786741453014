// Note this is a special filter that isn't controlled directly.
// It's controlled by the patientType.tsx filter!
// (This filter's value is side effect of choosing a patient type.)

import { IFilterConfig } from 'shared/filters/IFilter';

const defaultValue = 'CostPerCase';

export const submodule: IFilterConfig<string> = {
  defaultValue,
  param: 'submodule',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.submodule || defaultValue);
  }
};

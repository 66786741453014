import React, { FC } from 'react';
import { Modal, Text } from '@strata/tempo/lib';

interface IDeleteUserViewModalProps {
  id?: string;
  onCancel(): void;
  onDelete(): void;
  visible: boolean;
}

const ConfirmDelete: FC<IDeleteUserViewModalProps> = (props: IDeleteUserViewModalProps) => {
  return (
    <Modal
      width='normal'
      title='Delete'
      visible={props.visible}
      onCancel={props.onCancel}
      okText='Delete'
      onOk={props.onDelete}
    >
      <Text size='large' strong>
        Are you sure you want to delete this configuration?
      </Text>
    </Modal>
  );
};

export default ConfirmDelete;

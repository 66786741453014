import { Spacing, Text } from '@strata/tempo/lib';
import React from 'react';

const innerText =
  'This Information is Confidential and Proprietary to Strata Decision Technology, LLC and may not be distributed to any third party';

const LegalNotice = () => {
  return (
    <Spacing hAlign='center' padding='2em'>
      <Text size='small' color='secondary'>
        {innerText}
      </Text>
    </Spacing>
  );
};

export default LegalNotice;
export { innerText };

import React, { FC } from 'react';
import { IFilterSettingProps } from 'shared/filters/IFilter';
import dayjs from 'dayjs';
import { DATE_NUMBER_FORMAT, FRIENDLY_DATE_FORMAT } from 'shared/data/DateString';
import Setting from 'shared/filters/settings/individual/Setting';
import { ITimelineData } from './index';

export const TimelineSetting: FC<IFilterSettingProps<ITimelineData>> = ({ filterValue }) => {
  const { startDate, endDate } = filterValue;

  if (!(startDate && endDate)) {
    return null;
  }

  const getFriendlyDate = (date: string) =>
    dayjs(date, DATE_NUMBER_FORMAT).format(FRIENDLY_DATE_FORMAT);

  return (
    <>
      <Setting
        label='Timeline'
        value={`${getFriendlyDate(startDate)} to ${getFriendlyDate(endDate)}`}
      />
    </>
  );
};

import React, { FC } from 'react';
import { IFilterConfig, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';

const CensusRegionSetting: FC<IFilterSettingProps<string[]>> = ({ filterValue }) => {
  return (
    <Setting
      isVisible={filterValue.length > 0}
      label='Census Region'
      value={filterValue.join(', ')}
    />
  );
};

export const censusRegion: IFilterConfig<string[]> = {
  controlLabel: 'Census Region',
  controlOptionsFromUiConfig: {
    source: 'Entity', // either works! They're identical
    fieldname: 'censusregion'
  },
  controlType: 'checkboxgroup',
  controlWeight: 10,
  defaultValue: [],
  param: 'censusRegion',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.censusRegion);
  },
  Setting: CensusRegionSetting,
  settingWeight: 10
};

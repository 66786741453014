import React, { FC, useState } from 'react';
import { Button, Input, Modal } from '@strata/tempo/lib';
import { ISelectedItem } from 'kpi/data/kpiContext';
import StrataSphereReport from './StrataSphereReport';
import { useStratasphereReportContext } from './stratasphereReportContext';
import { useLegalTermsContext } from '../../legal/useLegalTermsContext';
import { PDFDownloadLink } from '@react-pdf/renderer';

interface ISavePdfModal {
  item: ISelectedItem;
  visible: boolean;
  onCancel: () => void;
}

const SavePdfModal: FC<ISavePdfModal> = ({ item, visible, onCancel }) => {
  const stratasphereReportContext = useStratasphereReportContext();
  const legalTermsContext = useLegalTermsContext();

  const doc = (
    <StrataSphereReport
      item={item}
      legalTerms={legalTermsContext.terms.join(' ')}
      monthlyTrendDataUrl={stratasphereReportContext.monthlyTrendDataUrl}
      histogramDataUrl={stratasphereReportContext.histogramDataUrl}
    />
  );

  const [filename, setFilename] = useState('StrataSphere_Report');

  return (
    <Modal
      title='Save PDF'
      visible={visible}
      onCancel={onCancel}
      footer={
        <>
          <Button type={'secondary'} onClick={onCancel}>
            Cancel
          </Button>
          <PDFDownloadLink
            document={doc}
            fileName={filename + '.pdf'}
            onClick={() => setTimeout(onCancel, 3000) /* close the modal */}
          >
            &nbsp;
            <Button type={'primary'}>Save</Button>
          </PDFDownloadLink>
        </>
      }
    >
      <Input
        width={'100%'}
        value={filename}
        placeholder='Your PDF name'
        onChange={(e) => setFilename(e.target.value)}
      />
    </Modal>
  );
};

export default SavePdfModal;

import React, { useEffect } from 'react';
import { useAlert } from 'shared/utils/useAlert';
import { useLogoutNow } from 'shared/utils/useLogoutNow';

const CompareErrorBoundary: React.FC = (props) => {
  const alert = useAlert();
  const alertErrorAndLogout = useLogoutNow();

  useEffect(() => {
    window.addEventListener('unhandledrejection', handleRejection);
    return () => {
      window.removeEventListener('unhandledrejection', handleRejection);
    };
  });

  const handleRejection = (event: PromiseRejectionEvent) => {
    // Ignore errors from user-aborted ajax requests
    if (event.reason.name === 'AbortError') {
      return;
    }

    switch (event.reason.status) {
      case 404:
        alert({
          title: 'Not Found',
          content: 'The item you requested has moved or no longer exists.'
        });
        break;
      case 403:
        alertErrorAndLogout({
          title: 'Insufficient Privileges',
          content:
            'Your organization has not granted you access to StrataSphere® Compare. Contact your system administrator to request access.'
        });
        break;
      case 401:
        alertErrorAndLogout();
        break;
      default:
        alert({
          content: 'An unknown error has occurred.'
        });
    }
  };

  return <>{props.children}</>;
};

export default CompareErrorBoundary;

import { useState } from 'react';

export const useTaskList = (): ITaskList => {
  const [list, setList] = useState<ITask[]>([]);
  const makeTaskId = () => btoa(Math.random().toString()); // not a true guid, but close enough for our purposes

  return {
    makeTaskId,
    makeTask(id = makeTaskId()) {
      setList((prevList) => [...prevList, { id, done: false }]);
      return id;
    },
    completeTask(id) {
      setList((prevList) => [...prevList.filter((task) => task.id !== id), { id, done: true }]);
    },
    done: list.length > 0 && list.every((item) => item.done),
    size: list.length
  };
};

interface ITask {
  id: string;
  done: boolean;
}

export interface ITaskList {
  makeTask(id: string): void;
  makeTaskId(): string;
  completeTask(id: string): void;
  done: boolean;
  size: number;
}

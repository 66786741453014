import React, { FC, createContext, useContext, useState } from 'react';

export interface IPdfContext {
  monthlyTrendDataUrl: string;
  histogramDataUrl: string;
  setDataUrls: (monthlyTrendEl: HTMLCanvasElement, histogramEl: HTMLCanvasElement) => void;
}

const context = createContext({} as IPdfContext);

const StratasphereReportProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const [monthlyTrendDataUrl, setMonthlyTrendDataUrl] = useState('');
  const [histogramDataUrl, setHistogramDataUrl] = useState('');
  const provided = {
    monthlyTrendDataUrl,
    histogramDataUrl,
    async setDataUrls(monthlyTrendEl: HTMLCanvasElement, histogramEl: HTMLCanvasElement) {
      setMonthlyTrendDataUrl(monthlyTrendEl.toDataURL('image/png'));
      setHistogramDataUrl(histogramEl.toDataURL('image/png'));
    }
  };

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

const useStratasphereReportContext = (): IPdfContext => useContext(context);

export { useStratasphereReportContext, StratasphereReportProvider };

import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MetricDataName } from 'shared/data/IBaseMetric';
import { ICacheableStandardComparison } from 'shared/data/IStandardComparison';
import { IComparisonService } from 'shared/comparisons/data/comparisonService';
import { IFilterGroup } from 'shared/filters';
import { makeUid } from 'shared/utils';
import { useClientDataContext } from 'shared/data/useClientDataContext';
import { useUiConfigContext } from '../../data/useUiConfigContext';
import { IDimMetric } from '../../data/uiConfigService';

export interface IUseSelectableComparisons {
  comparisons: ICacheableStandardComparison[];
  loading: boolean;
  loadingCount: number;
  loadingDone: boolean;
  loadingProgress: number;
  loadingRequests: Array<Promise<ICacheableStandardComparison>>;
  selected: MetricDataName | null;
  select(id: MetricDataName | null): void;
  setLoadingDone: Dispatch<SetStateAction<boolean>>;
  load(ids: MetricDataName[]): void;
  cancelLoad(): void;
}

const useSelectableComparisons = (
  comparisonService: IComparisonService,
  filterGroup: IFilterGroup<any> // eslint-disable-line @typescript-eslint/no-explicit-any
): IUseSelectableComparisons => {
  const clientDataContext = useClientDataContext();
  const uiConfigContext = useUiConfigContext();

  const [comparisons, setComparisons] = useState([] as ICacheableStandardComparison[]);
  const [selected, setSelected] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingCount, setLoadingCount] = useState(0);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loadingDone, setLoadingDone] = useState(false);
  const [loadingRequests, setLoadingRequests] = useState(
    [] as Array<Promise<ICacheableStandardComparison>>
  );
  const [abortControllers, setAbortControllers] = useState<AbortController[]>([]);

  // If the user changes a Filter Parameter,
  // flag that new comparisons should be loaded
  useEffect(() => {
    setLoadingDone(false);
  }, [filterGroup.token]);

  return {
    comparisons,
    loading,
    loadingCount,
    loadingDone,
    loadingRequests,
    loadingProgress,
    async load(metricIds) {
      const controllers = metricIds.map((_) => new AbortController());
      setAbortControllers(controllers);
      setLoading(true);
      setLoadingCount(metricIds.length);
      setLoadingProgress(0);
      setComparisons([]);

      const comparisonId = makeUid(); // This id is used by DataDog to help create our app's usage Dashboard

      const requests = metricIds.map((metricId, index) => {
        return comparisonService
          .fetchComparison(
            comparisonId,
            controllers[index].signal,
            filterGroup?.params,
            filterGroup?.token,
            String(clientDataContext.clientData.strataId),
            uiConfigContext.metrics.find((m) => m.metricAbbr === metricId) as IDimMetric
          )
          .then((comparison: ICacheableStandardComparison) => {
            if (!comparison.fromCache) {
              setComparisons((entries) => entries.concat(comparison));
              setLoadingCount((count) => {
                const newCount = count - 1;
                setLoadingProgress((metricIds.length - newCount) / metricIds.length);
                return newCount;
              });
            }
            return comparison;
          })
          .catch((errorMessage) => {
            console.warn(errorMessage);
            return {} as ICacheableStandardComparison;
          });
      });

      setLoadingRequests(requests);

      Promise.all(requests).then((comparisons) => {
        // Pulling too many metrics from cache introduces several rendering issues. It's too much all at once!
        // To mitigate, we're delaying setting the data until the drawer is closed,
        // and we're setting all cached comparisons at once.
        // (Comparisons NOT pulled from cache were set in the code above, after the single-request finished!)
        setTimeout(() => {
          // Set any-and-all CACHED Comparisons in one action (to mitigate a rendering issue)
          const cachedComparisons = comparisons.filter((entry) => entry.fromCache);
          setComparisons((prevComparisons) => prevComparisons.concat(cachedComparisons));
          setLoading(false);
          setLoadingCount(0);
          setLoadingProgress(1);
          setLoadingDone(true);
          setLoadingRequests([]);
        }, 100);
      });
    },
    cancelLoad() {
      abortControllers.forEach((controller) => {
        controller.abort();
      });

      setLoading(false);
      setLoadingCount(0);
      setLoadingRequests([]);
    },
    selected,
    select(id: MetricDataName) {
      setSelected(id);
    },
    setLoadingDone
  };
};

useSelectableComparisons.returnType = {
  comparisons: [] as ICacheableStandardComparison[],
  loading: false,
  loadingCount: 0,
  loadingDone: false,
  loadingProgress: 0,
  loadingRequests: [],
  load: () => undefined,
  cancelLoad: () => undefined,
  selected: null,
  select: () => undefined,
  setLoadingDone: () => undefined
} as IUseSelectableComparisons;

export default useSelectableComparisons;

import React, { FC } from 'react';
import './ProgressBar.scss';

interface IProgressBarProps {
  visible: boolean;
  progress?: number;
}

const ProgressBar: FC<IProgressBarProps> = (props) => {
  const width = computeWidth(props.progress);

  return (
    <div className={`component-progress-bar ${props.visible && 'visible'}`}>
      <div className='progress-bar-background' style={{ width }} />
      <div className='progress-bar-dot' style={{ left: width }} />
    </div>
  );
};

/*
 Provides an immediate visual sense of progress for the user.
 (Eg, the progress bar immediately appears to have completed
 xx% of total work.)
 */
const INITIAL_PROGRESS_PERCENTAGE = 20;

const computeWidth = (progress = 0) =>
  `${(INITIAL_PROGRESS_PERCENTAGE + progress * (100 - INITIAL_PROGRESS_PERCENTAGE)).toFixed(0)}%`;

export default ProgressBar;

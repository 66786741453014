import React, { useEffect } from 'react';
import { DataGrid, Text } from '@strata/tempo/lib';
import { useMappingContext } from '../data/mappingContext';
import { useRowCountCalculator } from './useRowCountCalculator';

const GlAccountDataGrid = () => {
  const { glAccount } = useMappingContext();

  useEffect(() => {
    if (!glAccount.loaded) {
      glAccount.load();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const rowCount = useRowCountCalculator();

  return (
    <DataGrid
      dataKey='glAccountCode'
      exportFilename='StrataSphere_GL_Account_Mappings'
      loading={!glAccount.loaded}
      paginator
      paginatorRight={
        <Text size='small'>
          To update your mappings please contact{' '}
          <a href='mailto:stratasphere@stratadecision.com'>stratasphere@stratadecision.com</a>
        </Text>
      }
      rows={rowCount}
      sortField='accountCode'
      sortOrder={1}
      value={glAccount.data}
    >
      <DataGrid.Column field='accountCode' header='Account Code' sortable filter width={130} />
      <DataGrid.Column field='description' header='Description' sortable filter width={200} />
      <DataGrid.Column field='glRollup' header='GL Rollup' sortable filter width={200} />
      <DataGrid.Column
        header='Is SPH Cost Model'
        sortable
        filter
        width={200}
        customCellValue={(cell) => (cell.row.isSphCostModel ? 'Yes' : 'No')}
      />
      <DataGrid.Column field='sphLineItem' header='SPH Line Item' sortable filter width={200} />
      <DataGrid.Column field='sphCategory' header='SPH Category' sortable filter width={200} />
      <DataGrid.Column
        field='sphCostComponent'
        header='SPH Cost Component'
        sortable
        filter
        width={200}
      />
      <DataGrid.Column field='sphSection' header='SPH Section' sortable filter width={200} />
      <DataGrid.Column field='sphStatement' header='SPH Statement' sortable filter width={200} />
      <DataGrid.Column
        field='spAccountRollupCategory'
        header='SP Account Rollup Category'
        sortable
        filter
        width={200}
      />
      <DataGrid.Column
        field='spAccountRollupName'
        header='SP Account Rollup Name'
        sortable
        filter
        width={200}
      />
      <DataGrid.Column
        field='obDollarsFinancialReporting'
        header='OB Dollars Financial Reporting'
        sortable
        filter
        width={200}
      />
      <DataGrid.Column
        field='dssAccountRollup1Name'
        header='DSS Account Rollup 1 Name'
        sortable
        filter
        width={200}
      />
    </DataGrid>
  );
};

export default GlAccountDataGrid;

// eslint-disable-next-line no-use-before-define
import React from 'react';
import Chart from './Chart';
import * as plot from '@observablehq/plot';
import PropTypes from 'prop-types';
import Controls from './ChartBeeswarm.Controls';
import { style } from './style';

const ChartHistogram = (props) => {
  const { userAverage, allAverages } = props.data;

  const options = {
    y: {
      label: 'Frequency'
    },
    x: {
      tickFormat: props.format.axis,
      transform: props.format.axis.includes('%') ? (d) => d / 100 : undefined
    },
    marks: [
      plot.ruleY([0], { stroke: '#ddd' }),
      plot.rectY(
        allAverages,
        plot.binX(
          {
            y: 'count'
          },
          {
            thresholds: 7,
            mixBlendMode: 'multiply',
            stroke: style.cohort.interquartile.stroke,
            fill: style.cohort.interquartile.fill
          }
        )
      ),
      plot.ruleX([userAverage], {
        stroke: style.user.stroke,
        strokeWidth: style.user.strokeWidth
      })
    ],
    ...props.options
  };

  return (
    <>
      <Controls isOnboarding={props.isOnboarding} />
      <Chart className='histogram-plot' options={options} canvas={props.canvas} />
    </>
  );
};

ChartHistogram.Controls = Controls;

ChartHistogram.propTypes = {
  canvas: PropTypes.bool,
  data: PropTypes.object,
  format: PropTypes.object,
  options: PropTypes.object,
  isOnboarding: PropTypes.bool
};

export default ChartHistogram;

import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps } from 'shared/filters/IFilter';
import { DropDown, DropDownValue, Form, Text } from '@strata/tempo/lib';
import { useLaborContext } from 'labor/data/laborContext';

const DepartmentRollupControl: FC<IFilterControlProps<string>> = ({
  filterValue,
  setFilterValue
}) => {
  const laborContext = useLaborContext();
  const handleChange = (val: DropDownValue) => {
    setFilterValue((val as string) ?? 'ALL');
    laborContext.filterGroup.filters.clinicalVsNonclinical.setFilterValue('ALL');
    laborContext.filterGroup.filters.jobCodeRollup.setFilterValue('ALL');
  };

  const isDisabled = laborContext.filterGroup.filters.entity.computed?.isSystemScope;

  return (
    <>
      <Text.Heading level={4}>Labor Expense Filters</Text.Heading>
      <Form.Item label='StrataSphere® Department Rollup'>
        <DropDown
          allowClear
          value={filterValue}
          items={options}
          onChange={handleChange}
          disabled={isDisabled}
        />
      </Form.Item>
    </>
  );
};

const options = [
  { value: 'ALL', text: 'All' },
  { value: 'Administration', text: 'Administration' },
  { value: 'Advanced Imaging', text: 'Advanced Imaging' },
  { value: 'Behavioral', text: 'Behavioral' },
  { value: 'Care Management', text: 'Care Management' },
  { value: 'Clinical Laboratory', text: 'Clinical Laboratory' },
  { value: 'Clinics', text: 'Clinics' },
  {
    value: 'Critical And Intensive Care',
    text: 'Critical And Intensive Care'
  },
  { value: 'Dietary Services', text: 'Dietary Services' },
  { value: 'Emergency', text: 'Emergency' },
  { value: 'Environmental Services', text: 'Environmental Services' },
  { value: 'Facilities', text: 'Facilities' },
  { value: 'Finance', text: 'Finance' },
  { value: 'Hospitalists', text: 'Hospitalists' },
  { value: 'Information Technology', text: 'Information Technology' },
  { value: 'Medical Education', text: 'Medical Education' },
  { value: 'Non-Operating Revenue', text: 'Non-Operating Revenue' },
  { value: 'Nursing Administration', text: 'Nursing Administration' },
  { value: 'Obstetrics', text: 'Obstetrics' },
  { value: 'Oncology', text: 'Oncology' },
  { value: 'Patient Care Unit', text: 'Patient Care Unit' },
  { value: 'Patient Support', text: 'Patient Support' },
  { value: 'Pharmacy', text: 'Pharmacy' },
  {
    value: 'Post-Acute Care And Hospice',
    text: 'Post-Acute Care And Hospice'
  },
  { value: 'Quality And Pi', text: 'Quality And Pi' },
  { value: 'Rehab/Therapy', text: 'Rehab/Therapy' },
  { value: 'Research', text: 'Research' },
  { value: 'Revenue Cycle', text: 'Revenue Cycle' },
  { value: 'Specialty Labs', text: 'Specialty Labs' },
  { value: 'Standard Imaging', text: 'Standard Imaging' },
  { value: 'Supply Chain', text: 'Supply Chain' },
  { value: 'Surgical Services', text: 'Surgical Services' },
  { value: 'Talent/Hr', text: 'Talent/HR' },
  { value: 'Transplant', text: 'Transplant' }
];

export const departmentRollup: IFilterConfig<string> = {
  Control: DepartmentRollupControl,
  controlWeight: 4,
  defaultValue: 'ALL',
  param: 'sphDepartmentRollup',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.sphDepartmentRollup);
  },
  settingLabel: 'StrataSphere® Department Rollup',
  settingWeight: 4
};

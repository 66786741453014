import React, { FC } from 'react';
import { useOverheadContext } from './data/overheadContext';

import TaskBar from 'shared/taskbar/TaskBar';

const OverheadTaskBar: FC = () => {
  const overheadContext = useOverheadContext();
  const loadingProgress = overheadContext.comparisons.loading ? 0 : 1;

  return (
    <TaskBar
      loading={overheadContext.comparisons.loading}
      loadingProgress={loadingProgress}
      metricDrawerButtonText='Filters'
      openMetricDrawer={overheadContext.compareDrawer.open}
      cancelMetricResultsLoad={overheadContext.comparisons.cancelLoad}
    />
  );
};

export default OverheadTaskBar;

import React from 'react';
import { DataGrid } from '@strata/tempo/lib';
// eslint-disable-next-line
// @ts-ignore
import { format } from 'd3';
import IBaseMetric from '../../data/IBaseMetric';
import { ICacheableStandardComparison } from '../../data/IStandardComparison';

interface IDataSet {
  metric: any;
  metricResult: any;
}

const DataSet: React.FC<IDataSet> = (props) => {
  return (
    <div id='bm-data-set'>
      <DataGrid value={getData(props.metric, props.metricResult)} density={'normal'}>
        <DataGrid.Column
          field='name'
          header='Name'
          width={200}
          body={(rowData) => <strong>{rowData.name}</strong>}
        ></DataGrid.Column>
        <DataGrid.Column
          field='stat'
          header='Value'
          width={200}
          body={(rowData) => <strong>{rowData.stat}</strong>}
        ></DataGrid.Column>
      </DataGrid>
    </div>
  );
};

export default DataSet;

export const getData = (metric: IBaseMetric, comparison: ICacheableStandardComparison) => {
  const maybeDivideBy100 = (val: number) => {
    return metric.Format.tooltip?.includes('%') ? val / 100 : val;
  };

  return [
    {
      name: 'My Average Value',
      stat: format(metric.Format.tooltip)(maybeDivideBy100(comparison.userAverage))
    },
    {
      name: '25th Percentile',
      stat: format(metric.Format.tooltip)(maybeDivideBy100(comparison.stats.q25))
    },
    {
      name: 'Median',
      stat: format(metric.Format.tooltip)(maybeDivideBy100(comparison.stats.median))
    },
    {
      name: '75th Percentile',
      stat: format(metric.Format.tooltip)(maybeDivideBy100(comparison.stats.q75))
    }
  ];
};

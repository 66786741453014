import React, { FC, useState } from 'react';
import InsightPanel from './InsightPanel';
import { useLegalTermsContext } from 'shared/legal/useLegalTermsContext';
import SavePdfModal from './SavePdfModal';
import { IKpiContext } from 'kpi/data/kpiContext';
import { ILaborContext } from 'labor/data/laborContext';
import { Button, Drawer } from '@strata/tempo/lib';

interface IInsightDrawer {
  moduleContext: IKpiContext | ILaborContext;
}

const InsightDrawer: FC<IInsightDrawer> = (props) => {
  const { terms } = useLegalTermsContext();
  const { insightDrawer, selectedItem } = props.moduleContext;

  const [modalIsVisible, setModalIsVisible] = useState(false);
  const openModal = () => setModalIsVisible(true);
  const closeModal = () => setModalIsVisible(false);

  const handleCloseDrawer = () => {
    props.moduleContext.selectableComparisons.select(null);
    insightDrawer.close();
  };

  if (!selectedItem) {
    return null;
  }

  return (
    <Drawer
      footer={
        <div className='bm-ip-export'>
          <Button type='primary' onClick={openModal}>
            Export PDF
          </Button>
        </div>
      }
      removeBodyPadding
      title={selectedItem.metric.Friendly_Name}
      visible={insightDrawer.isOpen}
      onClose={handleCloseDrawer}
    >
      <InsightPanel
        legalTerms={terms}
        metric={selectedItem.metric}
        comparison={selectedItem.comparison}
      />
      <SavePdfModal item={selectedItem} visible={modalIsVisible} onCancel={closeModal} />
    </Drawer>
  );
};

export default InsightDrawer;

import React, { FC } from 'react';
import { Card, LayoutGrid, Spacing } from '@strata/tempo/lib';

import ComparisonCard from 'shared/comparisons/cards/ComparisonCard';
import { useOverheadContext } from './data/overheadContext';
import ComparisonTable from 'shared/comparisons/table/ComparisonTable';

const OverheadComparisons: FC = () => {
  const overheadContext = useOverheadContext();

  return (
    <>
      <LayoutGrid removeMargin>
        <LayoutGrid.Row>
          <Spacing padding={'0 12px'}>
            <Card removeBodyPadding>
              <ComparisonTable
                data={overheadContext.items}
                disableCategoryColumn
                disableControls
                isLoading={overheadContext.comparisons.loading}
              />
            </Card>
          </Spacing>
        </LayoutGrid.Row>
        <LayoutGrid.Row>
          {overheadContext.items.map((entry, i) => (
            <LayoutGrid.Column key={i} spanSmall={12} spanMedium={6} spanLarge={6} span={6}>
              <ComparisonCard
                forceVisualization='Line Chart'
                hideUserScore
                isSelected={false}
                isLoading={false}
                metric={entry.metric}
                metricResult={entry.comparison}
              />
              <ComparisonCard
                forceVisualization='Histogram'
                hideUserScore
                isSelected={false}
                isLoading={false}
                metric={entry.metric}
                metricResult={entry.comparison}
              />
            </LayoutGrid.Column>
          ))}
        </LayoutGrid.Row>
      </LayoutGrid>
    </>
  );
};

export default OverheadComparisons;

import React, { FC } from 'react';
import { IFilterConfig, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';

const AmcTypeSetting: FC<IFilterSettingProps<string[]>> = ({ filterValue }) => {
  const settingValue =
    filterValue[0] === '0' ? 'Non-Academic Medical Centers' : 'Academic Medical Centers';

  return <Setting isVisible={filterValue.length === 1} label='AMC Type' value={settingValue} />;
};

export const amcType: IFilterConfig<string[]> = {
  controlLabel: 'AMC Type',
  controlOptionsFromUiConfig: {
    source: 'Entity',
    fieldname: 'isamc'
  },
  controlType: 'checkboxgroup',
  controlWeight: 8,
  defaultValue: [],
  param: 'isAMC',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.isAMC);
  },
  scope: 'entity',
  Setting: AmcTypeSetting,
  settingWeight: 8
};

import React, { FC } from 'react';
import { Button, Text } from '@strata/tempo/lib';
import FundIcon from '@strata/tempo/lib/icon/FundIcon';

interface IGetStartedProps {
  onStart(): void;
}

const GetStarted: FC<IGetStartedProps> = (props: IGetStartedProps) => {
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        maxWidth: 400,
        margin: 'auto'
      }}
    >
      <Text.Heading level={2}>No Metrics Selected</Text.Heading>
      <Text>Select metrics to compare.</Text>
      <div style={{ height: 20 }} />
      <Button type='primary' icon={<FundIcon />} onClick={props.onStart}>
        Select Metrics
      </Button>
    </div>
  );
};

export default GetStarted;

import React, { FC } from 'react';
import ComparisonCard from 'shared/comparisons/cards/ComparisonCard';
import { useServiceLineContext } from 'serviceline/data/ServiceLineContext';
import { IStandardComparison } from '../../shared/data/IStandardComparison';

const ServiceLineLosCard: FC = () => {
  const slContext = useServiceLineContext();
  const patientTypeLabel = slContext.filterGroup.filters.patientType.computed?.friendlyLabel;
  const isInpatientType = slContext.filterGroup.filters.patientType.filterValue === 'Inpatient';
  const { loadingDone, data } = slContext.getCurrentTableContext();

  let metricResult;
  if (isInpatientType) {
    metricResult = slContext.selectedTableRow?.value.los;
  }
  if (loadingDone && !data.length) {
    metricResult = { incomplete: true, incompleteReason: 'MissingCohort' } as IStandardComparison;
  }

  const messageNoDataLoaded = isInpatientType
    ? ''
    : `LOS does not apply to ${patientTypeLabel.toLowerCase()}`;

  return (
    <ComparisonCard
      chartOptions={{ marginLeft: 56 }}
      height={410}
      hideUserData={slContext.isOnboarding}
      isLoading={slContext.serviceLineTable.loading || slContext.careFamilyTable.loading}
      forceVisualization='Line Chart'
      messageNoDataLoaded={messageNoDataLoaded}
      metric={{
        Category: slContext.selectedTableRow?.value.name || 'Service Line',
        Data_Name: 'los',
        Friendly_Name: `LOS Monthly Trend for ${patientTypeLabel}`,
        Format: {
          type: 'number',
          sigDig: 1,
          axis: ',.1f',
          tooltip: ',.1f'
        },
        GoodBound: 'lower'
      }}
      metricResult={metricResult}
      withControls
    />
  );
};

export default ServiceLineLosCard;

import React, { useEffect } from 'react';
import { DataGrid, Text } from '@strata/tempo/lib';
import { useMappingContext } from '../data/mappingContext';
import { useRowCountCalculator } from './useRowCountCalculator';

const JobCodeDataGrid = () => {
  const { jobCode } = useMappingContext();

  useEffect(() => {
    if (!jobCode.loaded) {
      jobCode.load();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const rowCount = useRowCountCalculator();

  return (
    <DataGrid
      dataKey='jobCode'
      exportFilename='StrataSphere_Job_Code_Mappings'
      loading={!jobCode.loaded}
      paginator
      paginatorRight={
        <Text size='small'>
          To update your mappings please contact{' '}
          <a href='mailto:stratasphere@stratadecision.com'>stratasphere@stratadecision.com</a>
        </Text>
      }
      rows={rowCount}
      sortField='jobCode'
      sortOrder={1}
      value={jobCode.data}
    >
      <DataGrid.Column field='jobCode' header='Job Code' sortable filter width={80} />
      <DataGrid.Column field='description' header='Description' sortable filter width={200} />
      <DataGrid.Column field='jobCodeGroup' header='Job Code Group' sortable filter width={200} />
      <DataGrid.Column
        field='sphJobCodeRollupName'
        header='SPH Job Code Rollup'
        sortable
        filter
        width={200}
      />
      <DataGrid.Column
        field='sphCostComponent'
        header='SPH Cost Component'
        sortable
        filter
        width={200}
      />
    </DataGrid>
  );
};

export default JobCodeDataGrid;

import React, { FC, useEffect } from 'react';
import { ICacheableStandardComparison } from 'shared/data/IStandardComparison';
import IBaseMetric from 'shared/data/IBaseMetric';
import RankMetrics from 'old/src/components/RankMetrics/RankMetrics';
import ChartMonthlyTrend from 'shared/charts/ChartMonthlyTrend';
import ChartHistogram from 'shared/charts/ChartHistogram';
import DataSet from './DataSet';
import FormulaDefinition from 'old/src/components/FormulaDefinition/FormulaDefinition';
import { useStratasphereReportContext } from './stratasphereReportContext';

interface IInsightPanelProps {
  legalTerms: string[];
  metric: IBaseMetric;
  comparison: ICacheableStandardComparison;
}

const InsightPanel: FC<IInsightPanelProps> = (props: IInsightPanelProps) => {
  const stratasphereReportContext = useStratasphereReportContext();

  useEffect(() => {
    // we're waiting for PlotJS to render the canvas...
    // then creating base64 strings (data urls) for the PDF.
    const interval = setInterval(() => {
      const monthlyTrendEl = document.querySelector<HTMLCanvasElement>(
        '.ant-drawer-content .line-plot-canvas canvas'
      );
      const histogramEl = document.querySelector<HTMLCanvasElement>(
        '.ant-drawer-content .histogram-container canvas'
      );
      if (monthlyTrendEl && histogramEl) {
        stratasphereReportContext.setDataUrls(monthlyTrendEl, histogramEl);
        clearInterval(interval);
      }
    }, 50);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='bm-insights-internal' id='bm-benchmarking-insights'>
      <RankMetrics metric={props.metric} metricResult={props.comparison} />
      <div style={{ position: 'relative', width: '100%', height: 280, paddingBottom: 20 }}>
        <ChartMonthlyTrend
          data={props.comparison}
          format={props.metric.Format}
          withControls
          options={{ marginLeft: 60, heightOffset: -30 }}
        />
      </div>
      <div style={{ position: 'absolute', width: '100%', height: 280, left: -10000 }}>
        <ChartMonthlyTrend
          canvas
          data={props.comparison}
          format={props.metric.Format}
          withControls
          options={{ marginLeft: 60, heightOffset: -30 }}
        />
      </div>
      <hr />
      <div
        className='histogram-container'
        style={{
          position: 'relative',
          width: 560,
          height: 280,
          paddingBottom: 30,
          paddingLeft: 20
        }}
      >
        <ChartHistogram
          canvas
          data={props.comparison}
          format={props.metric.Format}
          options={{ marginRight: 20 }}
        />
      </div>
      <DataSet metric={props.metric} metricResult={props.comparison} />
      <FormulaDefinition definition={props.metric.JaxFormula} />
    </div>
  );
};

export default InsightPanel;

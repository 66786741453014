import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters';
import Setting from 'shared/filters/settings/individual/Setting';
import { Form, Switch, Text } from '@strata/tempo/lib';
import { useGlobalFilterContext } from 'shared/filters';

const CostClientSetting: FC<IFilterSettingProps<boolean>> = ({ filterValue }) => {
  return <Setting isVisible={!!filterValue} label='Cohort is Comparable in Cost' value='Yes' />;
};

const CostClientControl: FC<IFilterControlProps<boolean>> = ({ filterValue, setFilterValue }) => {
  const label = useGlobalFilterContext().filterGroup.filters.kpiEntity.computed?.isSystemScope
    ? 'Display Only Health Systems in Cost'
    : 'Display Only Hospitals in Cost';

  return (
    <Form.Item label={label}>
      <Switch checked={filterValue} onClick={setFilterValue} />
    </Form.Item>
  );
};

export const costClient: IFilterConfig<boolean> = {
  Control: CostClientControl,
  controlWeight: 7,
  defaultValue: false,
  param: 'isCostClient',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.isCostClient);
  },
  Setting: CostClientSetting,
  settingWeight: 7
};

import React, { FC } from 'react';
import { Spacing, Tag, Text } from '@strata/tempo/lib';
import TrophyIcon from '@strata/tempo/lib/icon/TrophyIcon';
import WarningIcon from '@strata/tempo/lib/icon/WarningIcon';
import './AggregateUserScore.css';
import { IStandardComparison } from 'shared/data/IStandardComparison';
import IBaseMetric from 'shared/data/IBaseMetric';
import { getUserScore, UserScoreType } from 'shared/user-score/UserScore';

interface IAggregateUserScoreProps {
  isLoading: boolean;
  metrics: Array<IBaseMetric>;
  metricResults: IStandardComparison[];
}

const AggregateUserScore: FC<IAggregateUserScoreProps> = (props: IAggregateUserScoreProps) => {
  const counts = props.metricResults.reduce(
    (counts, result) => {
      const metric = props.metrics.find((entry) => entry.Data_Name === result.dataName);
      const score = getUserScore(
        metric?.GoodBound || 'n/a',
        result.userPercentileRank,
        result.cohortMonthlyTrend,
        result.userMonthlyTrend
      );

      if (score.type === UserScoreType.strong) ++counts.strong;
      if (score.type === UserScoreType.weak) ++counts.weak;

      return counts;
    },
    { strong: 0, weak: 0 }
  );

  return (
    <Spacing background={'gray-100'} margin='16px 0' itemSpacing={8} hAlign='center'>
      <Text color='success' size='small' strong>
        <TrophyIcon color='success' />
        &nbsp;
        {props.isLoading ? '...' : counts.strong} LEADING
      </Text>
      <Tag> | </Tag>
      <Text color='error' size='small' strong>
        <WarningIcon color='error' />
        &nbsp;
        {props.isLoading ? '...' : counts.weak} UNDERPERFORMING
      </Text>
    </Spacing>
  );
};

export default AggregateUserScore;

import { useLegalTermsContext } from './useLegalTermsContext';
import { Modal } from '@strata/tempo/lib';
import { service } from './service';

const useLegalPopup = () => {
  const { ready, setTermsAreAccepted, terms, termsAreAccepted } = useLegalTermsContext();

  if (ready && !termsAreAccepted) {
    Modal.alert({
      alertType: 'info',
      content: terms.join(' '),
      onClose: () => {
        service.saveLegalTermsAcceptance();
        setTermsAreAccepted(true);
      },
      title: undefined
    });
  }
};

export default useLegalPopup;

import React, { FC } from 'react';
import { Card, Spacing, Tag, Text } from '@strata/tempo/lib';
import IncompleteCard from 'shared/comparisons/cards/IncompleteCard';
import ServiceLineEmptyCard from './ServiceLineEmptyCard';
import UserScore from 'shared/user-score/UserScore';
import ChartMonthlyAverages from './ChartMonthlyAverages';

import '../costbar.old.css';
import IBaseMetric from 'shared/data/IBaseMetric';
import { useServiceLineContext } from 'serviceline2/data/ServiceLineContext';
import Legend, { ILegendItemProps } from 'shared/charts/Legend';
import { IStandardComparison } from '../../shared/data/IStandardComparison';
import { IComparison, ISlCfAverage, ISlCfTrend } from '../../shared/comparisons/data/IComparison';

const ServiceLineEncounterCard: FC = (props) => {
  const slContext = useServiceLineContext();
  const name = slContext.selectedTableRow?.value;
  const selectedTableRowType = slContext.selectedTableRow?.type;

  const selectedSlCfAverages =
    selectedTableRowType === 'Care Family'
      ? slContext.careFamilyTable.data?.averages?.filter((e) => e.name === name)
      : slContext.serviceLineTable.data?.averages?.filter((e) => e.name === name);

  const background = !selectedSlCfAverages ? 'gray-100' : undefined;

  // TODO handle incomlete

  const renderVolumeUserScore = () => {
    // if (!volume || volume.length < 2) {
    //   return;
    // }
    return null; // TODO handle UserScore, pass in any IAverages and UserScore gets a valueKey prop where I can declare the value property, eg losValue or value or costValue, etc.
    // return (
    //   <UserScore
    //     metric={{ GoodBound: 'upper' } as IBaseMetric}
    //     metricResult={volume}
    //     withCohortSize
    //   />
    // );
  };

  const renderCmiUserScore = () => {
    // if (cmi?.length < 3) {
    //   return;
    // }
    return null;
    // return (
    //   <UserScore metric={{ GoodBound: 'upper' } as IBaseMetric} metricResult={cmi} withCohortSize />
    // );
  };

  const renderLegend = () => {
    const legendItems: ILegendItemProps[] = [
      {
        text: 'You',
        color: 'series3'
      },
      {
        text: 'Cohort',
        color: 'outlier'
      }
    ];

    return <Legend items={legendItems} />;
  };

  const renderChart = (valueKey: string, data?: ISlCfAverage[]) => {
    // if (data?.incompleteReason) {
    //   return <IncompleteCard reason={data.incompleteReason} />;
    // }
    const valueAccessor = (entry: ISlCfAverage) => {
      return entry?.[valueKey as keyof ISlCfAverage];
    };
    return data ? (
      <ChartMonthlyAverages
        data={data}
        valueAccessor={valueAccessor}
        options={{ marginRight: 24 }}
      />
    ) : (
      <ServiceLineEmptyCard />
    );
  };

  return (
    <>
      <Card
        removeBodyPadding
        loading={slContext.serviceLineTable.loading || slContext.careFamilyTable.loading}
      >
        <Spacing column height={190} background={background} padding='8px'>
          <Spacing column padding='8px'>
            <Spacing hAlign='space-between' margin='0 0 8px 0' width={'100%'} vAlign='center'>
              <Text.Heading level={4} ellipsis={{ rows: 1 }}>
                <Tag color='blue-700'>{name || 'Service Line'}</Tag>
              </Text.Heading>
              {renderVolumeUserScore()}
            </Spacing>
            <Text.Heading level={4} strong={false} ellipsis={{ rows: 3 }}>
              Average Monthly Patient Volume for{' '}
              {slContext.filterGroup.filters.patientType.computed?.friendlyLabel}
            </Text.Heading>
          </Spacing>
          {renderLegend()}
          {renderChart('volumeValue', selectedSlCfAverages)}
        </Spacing>
      </Card>
      <Card
        removeBodyPadding
        loading={slContext.serviceLineTable.loading || slContext.careFamilyTable.loading}
      >
        <Spacing column height={194} background={background} padding='8px'>
          <Spacing column padding='8px'>
            <Spacing hAlign='space-between' margin='0 0 8px 0'>
              <Text.Heading level={4} ellipsis={{ rows: 1 }}>
                <Tag color='blue-700'>{name || 'Service Line'}</Tag>
              </Text.Heading>
              {renderCmiUserScore()}
            </Spacing>
            <Text.Heading level={4} strong={false} ellipsis={{ rows: 3 }}>
              Average MS-DRG Case Mix Index for{' '}
              {slContext.filterGroup.filters.patientType.computed?.friendlyLabel}
            </Text.Heading>
          </Spacing>
          {renderLegend()}
          {renderChart('cmiValue', selectedSlCfAverages)}
        </Spacing>
      </Card>
    </>
  );
};

export default ServiceLineEncounterCard;

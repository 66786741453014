import { appConfig, getSecureService } from '@strata/core/lib';

const { httpGet } = getSecureService(appConfig.apiUrl);

export const hospitalEntitiesService = {
  fetchAvailableKpiEntities: (): Promise<string[]> => httpGet<string[]>(`/available-entities/kpi`),
  fetchAvailableCostEntities: (): Promise<string[]> =>
    httpGet<string[]>(`/available-entities/cost`),
  fetchAvailableLaborEntities: (): Promise<string[]> =>
    httpGet<string[]>(`/available-entities/labor`),
  fetchOverheadAvailability: (): Promise<boolean> =>
    httpGet<boolean>(`/available-entities/overhead`)
};

import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { service } from './service';

interface ILegalTermsContext {
  ready: boolean;
  setTermsAreAccepted: (val: boolean) => void;
  terms: string[];
  termsAreAccepted: boolean;
}
const context = createContext({} as ILegalTermsContext);

const LegalTermsProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const [terms, setTerms] = useState<string[]>(['']);
  const [termsAreAccepted, setTermsAreAccepted] = useState(false);
  const [ready, setReady] = useState(false);

  const providedValue = {
    ready,
    setTermsAreAccepted,
    terms,
    termsAreAccepted
  };

  useEffect(() => {
    service
      .fetchDisclaimer()
      .then(setTerms)
      .then(service.fetchLegalTermsAcceptanceStatus)
      .then((status) => {
        setTermsAreAccepted(status);
        setReady(true);
      });
  }, []);

  return <context.Provider value={providedValue}>{props.children}</context.Provider>;
};

const useLegalTermsContext = () => useContext(context);

export { useLegalTermsContext, LegalTermsProvider };

import React from 'react';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { uiConfigService, IUiConfig, IDimMetric } from './uiConfigService';
import IKpiMetric, { KpiMetricCategory, KpiMetricScope } from 'kpi/data/IKpiMetric';
import ILaborMetric, { LaborMetricCategory, LaborMetricScope } from 'labor/data/ILaborMetric';
import { ICostMetric } from 'cost/data/ICostMetric';
import { IOverheadMetric } from 'overhead/data/IOverheadMetric';
import { MetricGoodBound } from './IBaseMetric';

interface IUiConfigContext extends IUiConfig {
  kpiMetrics: IKpiMetric[];
  laborMetrics: ILaborMetric[];
  costMetrics: ICostMetric[];
  overheadMetrics: IOverheadMetric[];
}

const context = createContext({} as IUiConfigContext);

const UiConfigProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const [uiConfig, setUiConfig] = useState<IUiConfig>({
    metrics: [],
    cohortFilterOptions: []
  });

  const providedValue = {
    ...uiConfig,
    kpiMetrics: uiConfig.metrics.filter((m) => m.moduleId === 1).map(dimMetricToKpiMetric),
    laborMetrics: uiConfig.metrics.filter((m) => m.moduleId === 3).map(dimMetricToLaborMetric),
    costMetrics: uiConfig.metrics.filter((m) => m.moduleId === 2).map(dimMetricToCostMetric),
    overheadMetrics: uiConfig.metrics.filter((m) => m.moduleId === 4).map(dimMetricToOverheadMetric)
  };

  (window as any).uiConfig = providedValue;

  useEffect(() => {
    const fetchData = async () => {
      const data = await uiConfigService.fetchUiConfig();
      setUiConfig(data);
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return <context.Provider value={providedValue}>{props.children}</context.Provider>;
};

const useUiConfigContext = () => useContext(context);

export { useUiConfigContext, UiConfigProvider };

/* *****************************
Helpers for Legacy Code: maps new metrics from the db to old ones that were hard coded
TODO remove these remnants after we remove the feature flag for using UiConfig Metrics.
 */
const dimMetricToKpiMetric = (m: IDimMetric): IKpiMetric => {
  return {
    dlId: m.metricId,
    dlAbbr: m.metricAbbr,
    Category: m.metricCategory as KpiMetricCategory,
    Data_Name: m.metricAbbr,
    Friendly_Name: m.metricName,
    Format: {
      min: m.axisMin,
      stepSize: m.axisStep,
      axis: m.axisFormat,
      tooltip: m.tooltipFormat
    },
    GoodBound: (m.goodRanking as MetricGoodBound) ?? ('n/a' as MetricGoodBound),
    Scope: m.levelsOfDetail as KpiMetricScope[],
    JaxFormula: m.formula.replace('\\\\', '\\').replace('\\\\', '\\')
  };
};
const dimMetricToLaborMetric = (m: IDimMetric): ILaborMetric => {
  return {
    dlId: m.metricId,
    dlAbbr: m.metricAbbr,
    Category: m.metricCategory as LaborMetricCategory,
    Data_Name: m.metricAbbr,
    Friendly_Name: m.metricName,
    Format: {
      min: m.axisMin,
      stepSize: m.axisStep,
      axis: m.axisFormat,
      tooltip: m.tooltipFormat
    },
    GoodBound: (m.goodRanking as MetricGoodBound) ?? ('n/a' as MetricGoodBound),
    Scope: m.levelsOfDetail as LaborMetricScope[],
    JaxFormula: m.formula.replace('\\\\', '\\').replace('\\\\', '\\')
  };
};
const dimMetricToCostMetric = (m: IDimMetric): ICostMetric => {
  return {
    weight: m.weight,
    submodule: m.submodule as string,
    Category: m.metricCategory as LaborMetricCategory,
    Data_Name: m.metricAbbr,
    Friendly_Name: m.metricName,
    Format: {
      min: m.axisMin,
      stepSize: m.axisStep,
      axis: m.axisFormat,
      tooltip: m.tooltipFormat
    },
    GoodBound: (m.goodRanking as MetricGoodBound) ?? ('n/a' as MetricGoodBound),
    applicableRollups: (() => {
      if (['SPHTotCost_Case', 'SPHTotCost_Day'].includes(m.metricAbbr)) {
        return [
          'All',
          'Benefits',
          'Building and Equipment',
          'Drugs',
          'Implants',
          'Other',
          'Salaries',
          'Supplies'
        ];
      }
      return ['All'];
    })(),
    applicableDrivers: (() => {
      if (['SPHTotCost_Case', 'SPHTotCost_Day'].includes(m.metricAbbr)) {
        return [
          'Anesthesia and Recovery',
          'Imaging',
          'Laboratory',
          'LOS',
          'OR Time',
          'Other',
          'Pharmacy',
          'Supplies',
          'ALL'
        ];
      }
      return ['ALL'];
    })()
  };
};
const dimMetricToOverheadMetric = (m: IDimMetric): IOverheadMetric => {
  return {
    dlId: m.metricId,
    dlAbbr: m.metricAbbr,
    Category: m.metricCategory,
    Data_Name: m.metricAbbr,
    Friendly_Name: m.metricName,
    Format: {
      min: m.axisMin,
      stepSize: m.axisStep,
      axis: m.axisFormat,
      tooltip: m.tooltipFormat
    },
    GoodBound: (m.goodRanking as MetricGoodBound) ?? ('n/a' as MetricGoodBound),
    department: '',
    id: ''
  };
};

import React, { FC } from 'react';
import CompareDrawer from 'shared/drawers/compare-drawer/CompareDrawer';
import DrawerFilters from 'shared/drawers/compare-drawer/DrawerFilters';
import { Spacing } from '@strata/tempo/lib';
import { useServiceLineContext } from './data/ServiceLineContext';

const ServiceLineDrawer: FC = () => {
  const slContext = useServiceLineContext();

  const handleRun = async () => {
    if (!slContext.filterGroup.ready) {
      return;
    }
    slContext.drawer.close();
    slContext.setSelectedTableRow(undefined);
    slContext.serviceLineTable.load();
  };

  const isDoneLoading =
    slContext.serviceLineTable.loadingDone && slContext.careFamilyTable.loadingDone;

  return (
    <CompareDrawer
      hasLoadedMetricResults={isDoneLoading}
      hasSelectedMetrics
      onClose={slContext.drawer.close}
      onRun={handleRun}
      pleaseWait={!slContext.filterGroup.ready}
      visible={slContext.drawer.isOpen}
    >
      <Spacing padding='12px'>
        <DrawerFilters
          filterGroup={slContext.filterGroup}
          filterScope={slContext.filterGroup.filters.entity.computed?.scope}
        />
      </Spacing>
    </CompareDrawer>
  );
};

export default ServiceLineDrawer;

import React, { FC } from 'react';
import { useCostContext } from './data/costContext';

import TaskBar from 'shared/taskbar/TaskBar';

const CostTaskBar: FC = () => {
  const costContext = useCostContext();

  return (
    <TaskBar
      loading={costContext.costComparisons.loading}
      metricDrawerButtonText='Filters'
      openMetricDrawer={costContext.compareDrawer.open}
      cancelMetricResultsLoad={costContext.costComparisons.cancelLoad}
    />
  );
};

export default CostTaskBar;

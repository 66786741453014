import React, { FC } from 'react';
import { IFilterConfig, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';

const UrbanRuralSetting: FC<IFilterSettingProps<string[]>> = ({ filterValue }) => {
  const [firstValue] = filterValue;
  const settingValue = firstValue === '0' ? 'Rural' : 'Urban';

  return <Setting isVisible={filterValue.length === 1} label='Urban/Rural' value={settingValue} />;
};

export const urbanRural: IFilterConfig<string[]> = {
  controlLabel: 'Urban/Rural',
  controlOptionsFromUiConfig: {
    source: 'Entity',
    fieldname: 'isurban'
  },
  controlType: 'checkboxgroup',
  controlWeight: 9,
  defaultValue: [],
  param: 'urbanRural',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.urbanRural);
  },
  scope: 'entity',
  Setting: UrbanRuralSetting,
  settingWeight: 9
};

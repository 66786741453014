// eslint-disable-next-line no-use-before-define
import React from 'react';
import * as plot from '@observablehq/plot';
import PropTypes from 'prop-types';

import Chart from 'shared/charts/Chart';
import { style } from 'shared/charts/style';

const ChartMonthlyAverages = (props) => {
  let valueAccessor = props.valueAccessor || ((entry) => entry?.value);

  const cohortAverages = props.data
    .filter((entry) => !entry.isUser)
    .map(valueAccessor)
    .sort((a, b) => a.value - b.value);
  const userAverage = [props.data.find((entry) => entry.isUser)].map(valueAccessor);

  const options = {
    x: {
      ticks: 5
    },
    heightOffset: 0,
    marks: [
      plot.dotX(cohortAverages, {
        r: 10,
        fill: style.outlier,
        stroke: '#ffffff44',
        strokeWidth: 1,
        title: (d) => d
      }),
      plot.dotX(userAverage, {
        r: 10,
        fill: style.user.stroke,
        stroke: '#ffffff44',
        strokeWidth: 1,
        title: (d) => d
      })
    ],
    ...props.options
  };

  return <Chart className='averages-plot' options={options} />;
};

ChartMonthlyAverages.propTypes = {
  data: PropTypes.object,
  format: PropTypes.object,
  options: PropTypes.object,
  withControls: PropTypes.bool,
  valueAccessor: PropTypes.func
};

export default ChartMonthlyAverages;

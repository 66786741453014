import React, { FC } from 'react';
import dayjs from 'dayjs';
import { DATE_NUMBER_FORMAT, FRIENDLY_DATE_FORMAT } from 'shared/data/DateString';
import { ITimelineData } from './index';

interface ITimelineWarningProps {
  timeline: ITimelineData;
}

const TimelineWarning: FC<ITimelineWarningProps> = (props) => {
  if (props.timeline.endDate !== props.timeline.prelimEndDate) {
    return null;
  }

  const friendlyMonth = dayjs(props.timeline.endDate, DATE_NUMBER_FORMAT).format(
    FRIENDLY_DATE_FORMAT
  );

  return (
    <span>
      Data for {friendlyMonth} are preliminary. Organizations may not have completed their monthly
      close process yet. Interpret data from these months with caution.
    </span>
  );
};

export default TimelineWarning;

// eslint-disable-next-line no-use-before-define
import React, { useState } from 'react';
import Chart from './Chart';
import * as plot from '@observablehq/plot';
import { format, utcFormat } from 'd3';

import { style } from './style';
import PropTypes from 'prop-types';
import ChartMonthlyTrendControls from './ChartMonthlyTrend.Controls';

const ChartMonthlyTrend = (props) => {
  const userData = props.data.userMonthlyTrend
    .map((entry) => {
      return {
        ...entry,
        plotDate: `${String(entry.dateId).slice(0, 4)}-${String(entry.dateId).slice(-2)}`
      };
    })
    .sort(orderByPlotDate);

  const cohortData = props.data.cohortMonthlyTrend
    .map((entry) => {
      return {
        ...entry,
        plotDate: `${String(entry.dateId).slice(0, 4)}-${String(entry.dateId).slice(-2)}`
      };
    })
    .sort(orderByPlotDate);

  const getEarliestAndLatest = (listOne, listTwo) => {
    const earliest = listOne[0]?.dateId < listTwo[0]?.dateId ? listOne[0] : listTwo[0];
    const latest =
      listOne[listOne.length - 1]?.dateId > listTwo[listTwo.length - 1]?.dateId
        ? listOne[listOne.length - 1]
        : listTwo[listTwo.length - 1];
    return [earliest, latest];
  };

  const getMonthDifference = (startDate, endDate) => {
    return (
      endDate.getMonth() -
      startDate.getMonth() +
      12 * (endDate.getFullYear() - startDate.getFullYear())
    );
  };

  const [earliestEntry, latestEntry] = getEarliestAndLatest(userData, cohortData);

  const dateDomain = [
    new Date(`${earliestEntry?.plotDate}-01`),
    new Date(`${latestEntry?.plotDate}-01`)
  ];

  const numMonths = getMonthDifference(dateDomain[0], dateDomain[1]) + 1;

  const barData = cohortData.map((entry) => ({
    ...entry,
    ...userData.find((u) => u.plotDate === entry.plotDate)
  }));

  const defaultVisibleMarks = {
    you: true,
    p25: true,
    p75: true,
    median: true
  };

  const [visibleMarks, setVisibleMarks] = useState(defaultVisibleMarks);

  const toggleVisibleMark = (key) => {
    if (!props.withControls) {
      return;
    }
    setVisibleMarks((prevState) => ({
      ...prevState,
      [key]: !visibleMarks[key]
    }));
  };

  const availableMarks = {
    youLine: plot.line(userData, {
      ...style.user,
      x: 'plotDate',
      y: 'value'
    }),
    iqrArea: plot.areaY(cohortData, {
      ...style.cohort.interquartile,
      x: 'plotDate',
      y1: 'q25',
      y2: 'q75',
      mixBlendMode: 'multiply'
    }),
    p25Line: plot.line(cohortData, {
      stroke: style.cohort.interquartile.stroke,
      x: 'plotDate',
      y: 'q25'
    }),
    p75Line: plot.line(cohortData, {
      stroke: style.cohort.interquartile.stroke,
      x: 'plotDate',
      y: 'q75'
    }),
    medianLine: plot.line(cohortData, {
      ...style.cohort.median,
      x: 'plotDate',
      y: 'median'
    })
  };

  const options = {
    y: {
      tickFormat: props.format.axis,
      transform: props.format.axis.includes('%') ? (d) => d / 100 : undefined
    },
    x: {
      ticks: Math.min(10, numMonths),
      tickRotate: -45,
      tickFormat: (date) => utcFormat('%m/%Y')(date),
      domain: dateDomain
    },
    heightOffset: -25,
    marks: [
      visibleMarks.p25 && visibleMarks.p75 ? availableMarks.iqrArea : null,
      visibleMarks.p25 && !visibleMarks.p75 ? availableMarks.p25Line : null,
      !visibleMarks.p25 && visibleMarks.p75 ? availableMarks.p75Line : null,
      visibleMarks.median ? availableMarks.medianLine : null,
      visibleMarks.you ? availableMarks.youLine : null,
      // invisible bars that handle Hover Text:
      plot.barX(barData, {
        x1: (d, i) => barData[i > 0 ? i - 1 : i].plotDate + (i > 0 ? '-15' : ''),
        x2: (d) => d.plotDate + '-15',
        title: ({ value, q25, q75, median, plotDate }) => {
          const friendly = (entry) => {
            if (typeof entry === 'undefined' || entry === null) {
              return '--';
            }
            if (props.format.tooltip.includes('%')) entry = entry / 100;
            return format(props.format.tooltip)(entry);
          };

          const header = `${utcFormat('%B %Y')(new Date(plotDate))}`;
          const content = [
            visibleMarks.you && `You: ${friendly(value)}`,
            visibleMarks.p25 && `25th%: ${friendly(q25)}`,
            visibleMarks.median && `Median: ${friendly(median)}`,
            visibleMarks.p75 && `75th%: ${friendly(q75)}`
          ]
            .filter((entry) => !!entry)
            .join(' | ');

          return `${header}\n${content}`;
        },
        fill: props.canvas ? '#ffffff00' : style.highlight,
        mixBlendMode: 'multiply'
      })
    ],
    marginBottom: 40,
    ...props.options
  };

  return (
    <>
      <ChartMonthlyTrendControls
        isShowingYou={visibleMarks.you}
        isShowingP25={visibleMarks.p25}
        isShowingP75={visibleMarks.p75}
        isShowingMedian={visibleMarks.median}
        onYouClick={() => toggleVisibleMark('you')}
        onP25Click={() => toggleVisibleMark('p25')}
        onP75Click={() => toggleVisibleMark('p75')}
        onMedianClick={() => toggleVisibleMark('median')}
        isOnboarding={props.isOnboarding}
      />
      <Chart
        className={props.canvas ? 'line-plot-canvas' : 'line-plot'}
        options={options}
        canvas={props.canvas}
      />
    </>
  );
};

ChartMonthlyTrend.propTypes = {
  canvas: PropTypes.bool,
  data: PropTypes.object,
  format: PropTypes.object,
  options: PropTypes.object,
  withControls: PropTypes.bool,
  isOnboarding: PropTypes.bool
};

export default ChartMonthlyTrend;

const orderByPlotDate = (a, b) => new Date(a.plotDate).getTime() - new Date(b.plotDate).getTime();

import React, { useEffect } from 'react';
import { DataGrid, Text } from '@strata/tempo/lib';
import { useMappingContext } from '../data/mappingContext';
import { useRowCountCalculator } from './useRowCountCalculator';

const DepartmentsDataGrid = () => {
  const { department } = useMappingContext();

  useEffect(() => {
    if (!department.loaded) {
      department.load();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const rowCount = useRowCountCalculator();

  return (
    <DataGrid
      dataKey='description'
      exportFilename='StrataSphere_Department_Mappings'
      loading={!department.loaded}
      paginator
      paginatorRight={
        <Text size='small'>
          To update your mappings please contact{' '}
          <a href='mailto:stratasphere@stratadecision.com'>stratasphere@stratadecision.com</a>
        </Text>
      }
      rows={rowCount}
      sortField='description'
      sortOrder={1}
      value={department.data}
    >
      <DataGrid.Column
        field='departmentCode'
        header='Department Code'
        sortable
        filter
        width={200}
      />
      <DataGrid.Column field='description' header='Description' sortable filter width={200} />
      <DataGrid.Column
        field='sphDepartmentRollupName'
        filter
        header='SPH Department Rollup'
        sortable
        width={200}
      />
    </DataGrid>
  );
};

export default DepartmentsDataGrid;

// eslint-disable-next-line no-use-before-define
import React from 'react';
import PropTypes from 'prop-types';
import { Card, Spacing, Text } from '@strata/tempo/lib';
import Controls from './CostTypeCard.Controls';
import ChartBoxWhisker from 'shared/charts/ChartBoxWhisker';

const CostByDriverCard = (props) => {
  const forTotalCostMetrics = (entry) => {
    const { dataName } = entry.comparison;
    return ['sphTotCost_Case', 'sphTotCost_Day'].includes(dataName);
  };

  const cohortData = props.data
    .filter(forTotalCostMetrics)
    .reduce((reduction, { comparison, rollupName }) => {
      const entries =
        comparison.allAverages?.map((value) => ({
          type: labelMap[rollupName],
          value
        })) ?? [];

      reduction.push(...entries.filter((entry) => entry.value !== comparison.userAverage));

      return reduction;
    }, []);

  const userData = props.data.filter(forTotalCostMetrics).map(({ comparison, rollupName }) => {
    const type = labelMap[rollupName];

    return {
      type,
      value: comparison.userAverage,
      isUser: true
    };
  });

  const plotOptions = {
    heightOffset: -25,
    marginLeft: 50,
    x: {
      tickRotate: -15,
      tickFormat: (type) => type?.substring(3)
    },
    y: {
      tickFormat: '$.1s'
    }
  };

  return (
    <Card height={400} removeBodyPadding loading={props.isLoading}>
      <Spacing column height={400} padding='8px'>
        <Spacing column padding='8px'>
          <Spacing margin='0 0 8px 0' hAlign={'space-between'}>
            <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
              Cost Driver Comparison (Total Cost)
            </Text.Heading>
          </Spacing>
        </Spacing>
        <Controls withOutliers={props.withOutliers} isOnboarding={props.isOnboarding} />
        <ChartBoxWhisker
          x='type'
          y='value'
          data={{ cohortData, userData }}
          plotOptions={plotOptions}
          showOutliers={props.withOutliers}
        />
      </Spacing>
    </Card>
  );
};

CostByDriverCard.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      allAverages: PropTypes.arrayOf(PropTypes.number),
      dataName: PropTypes.string,
      userAverage: PropTypes.number
    })
  ),
  withOutliers: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default CostByDriverCard;

const labelMap = {
  'Anesthesia and Recovery': '1. A & R',
  Imaging: '2. Imaging',
  Laboratory: '3. Laboratory',
  LOS: '4. Length of Stay',
  'OR Time': '5. OR Time',
  Pharmacy: '6. Pharmacy',
  Supplies: '7. Supplies',
  Other: '8. Other'
};

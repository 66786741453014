import { useEffect, useState } from 'react';
import { toParams, toToken, IFilterGroup, useFilter } from 'shared/filters';
import {
  compareWithinOwnHealthSystem,
  costEntity,
  kpiEntity,
  laborEntity,
  timeline,
  amcType,
  bedSize,
  censusRegion,
  healthSystemType,
  hospitalType,
  operatingExpense,
  outlierExclusion,
  systemOperatingExpense,
  urbanRural
} from 'shared/filters/config';
import { useLogoutNow, useTaskList, useAlert } from 'shared/utils';
import { useHistory, useLocation } from 'react-router-dom';
import { hospitalEntitiesService } from './config/entity/entityService';
import { useClientDataContext } from '../data/useClientDataContext';

export const useGlobalFilterGroup = (): IFilterGroup<GlobalFilterKeys> => {
  const taskList = useTaskList();
  const logoutNow = useLogoutNow();
  const location = useLocation();
  const history = useHistory();
  const alert = useAlert();
  const clientData = useClientDataContext();

  // Create the filters. Filters with an async setup are also
  // registering a Task and marking it Done once they are set up.
  const filters = {
    compareWithinOwnHealthSystem: useFilter(compareWithinOwnHealthSystem),
    costEntity: useFilter(costEntity, taskList, 'costentity'),
    kpiEntity: useFilter(kpiEntity, taskList, 'kpientity'),
    laborEntity: useFilter(laborEntity, taskList, 'laborentity'),
    timeline: useFilter(timeline, taskList, 'timeline'),
    amcType: useFilter(amcType),
    bedSize: useFilter(bedSize),
    censusRegion: useFilter(censusRegion),
    healthSystemType: useFilter(healthSystemType),
    hospitalType: useFilter(hospitalType),
    operatingExpense: useFilter(operatingExpense),
    outlierExclusion: useFilter(outlierExclusion),
    systemOperatingExpense: useFilter(systemOperatingExpense),
    urbanRural: useFilter(urbanRural)
  };

  const hasKpiEntityData = filters.kpiEntity.filterData.options?.length > 0;
  const hasCostEntityData = filters.costEntity.filterData.options?.length > 0;
  const hasLaborEntityData = filters.laborEntity.filterData.options?.length > 0;
  const [hasOverheadData, setHasOverheadData] = useState(false);
  useEffect(() => {
    const taskId = 'hasOverheadData';
    taskList.makeTask(taskId);
    hospitalEntitiesService.fetchOverheadAvailability().then((response: boolean) => {
      setHasOverheadData(response);
      taskList.completeTask(taskId);
    });
  }, []);

  const hasSomeEntityData =
    hasKpiEntityData || hasCostEntityData || hasLaborEntityData || hasOverheadData;

  useEffect(() => {
    if (!taskList.done || !clientData.clientData) {
      return;
    }

    // Log the user has no entity data
    if (!hasSomeEntityData) {
      logoutNow({
        title: 'Cannot Find System',
        content:
          'StrataSphere® Compare could not find your health system in our data. Contact your system administrator.'
      });
    }

    // If the user is on a path WITHOUT entity data (and they're production ready),
    // redirect them to a path WITH entity data.
    // (And alert them to why we redirected them!)

    const { isProductionLabor, isProductionCost, isProductionOverhead } = clientData.clientData;

    const shouldDoPathFiltering = {
      '/kpi': true,
      '/cost': isProductionCost,
      '/labor': isProductionLabor,
      '/overhead': isProductionOverhead,
      '/serviceline': isProductionCost
    };

    if (!shouldDoPathFiltering[location.pathname as keyof typeof shouldDoPathFiltering]) {
      return;
    }

    const pathFilter = {
      '/kpi': hasKpiEntityData,
      '/cost': hasCostEntityData,
      '/labor': hasLaborEntityData,
      '/overhead': hasOverheadData,
      '/serviceline': hasCostEntityData
    };

    if (!pathFilter[location.pathname as keyof typeof pathFilter]) {
      alert({
        alertType: 'error',
        content: `StrataSphere® Compare could not find your health system in our ${location.pathname.replace(
          '/',
          ''
        )} data. Contact your system administrator.`,
        title: 'Cannot Find System'
      });

      const [pathWithData] = Object.entries(pathFilter).find(([_, hasData]) => hasData) as [
        string,
        boolean
      ];

      history.replace(pathWithData);
    }
  }, [taskList.done, hasSomeEntityData, logoutNow, location.pathname, clientData.clientData]);

  return {
    filters,
    params: toParams(filters),
    token: toToken(filters),
    ready: taskList.done
  };
};

export type GlobalFilterKeys =
  | 'compareWithinOwnHealthSystem'
  | 'kpiEntity'
  | 'costEntity'
  | 'laborEntity'
  | 'timeline'
  | 'amcType'
  | 'bedSize'
  | 'censusRegion'
  | 'healthSystemType'
  | 'hospitalType'
  | 'operatingExpense'
  | 'outlierExclusion'
  | 'systemOperatingExpense'
  | 'urbanRural';

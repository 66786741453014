import React, { FC } from 'react';
import { ActionBar, Divider, Input, LayoutGrid, RadioChangeEvent, Toggle } from '@strata/tempo/lib';

import ServiceLineTable from './ServiceLineTable';
import { useServiceLineContext } from './data/ServiceLineContext';
import ServiceLineEncounterCard from './cards/ServiceLineEncounterCard';
import ServiceLineLosCard from './cards/ServiceLineLosCard';
import ServiceLineCostCard from './cards/ServiceLineCostCard';

const ServiceLineComparisons: FC = () => {
  const slContext = useServiceLineContext();
  const columnProps = {
    span: 4,
    spanLarge: 4,
    spanMedium: 4,
    spanSmall: 12
  };

  const patientTypeLabel = slContext.filterGroup.filters.patientType.computed?.friendlyLabel;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    slContext.setTableFilter(e.target.value);
  };

  const handleDiffTypeChange = (e: RadioChangeEvent) => {
    slContext.setDiffType(e.target.value);
  };

  return (
    <>
      <LayoutGrid removeMargin>
        <LayoutGrid.Row>
          <LayoutGrid.Column {...columnProps}>
            <ServiceLineEncounterCard />
          </LayoutGrid.Column>
          <LayoutGrid.Column {...columnProps}>
            <ServiceLineLosCard />
          </LayoutGrid.Column>
          <LayoutGrid.Column {...columnProps}>
            <ServiceLineCostCard />
          </LayoutGrid.Column>
        </LayoutGrid.Row>
      </LayoutGrid>
      <Divider extended={false} />
      {/*<ActionBar*/}
      {/*  filters={<Input search width={300} onChange={handleSearch} debounce />}*/}
      {/*  viewToggle={*/}
      {/*    <Toggle*/}
      {/*      items={[*/}
      {/*        { value: 'absolute', name: 'Absolute deltas' },*/}
      {/*        { value: 'percentage', name: 'Percentage deltas' }*/}
      {/*      ]}*/}
      {/*      defaultValue={'absolute'}*/}
      {/*      onChange={handleDiffTypeChange}*/}
      {/*    />*/}
      {/*  }*/}
      {/*/>*/}
      <ActionBar
        viewToggle={
          <Toggle
            items={[
              { value: 'absolute', name: 'Absolute deltas' },
              { value: 'percentage', name: 'Percentage deltas' }
            ]}
            defaultValue={'absolute'}
            onChange={handleDiffTypeChange}
          />
        }
      />
      <ServiceLineTable />
    </>
  );
};

export default ServiceLineComparisons;

import { useEffect, useState } from 'react';
import { IConfig } from './IConfig';
import { useConfig } from './useConfig';
import { configService } from './configService';
import { useOidcSession } from '@strata/core/lib';
import { useClientDataContext } from '../../data/useClientDataContext';
import { useFlagsContext } from '../../flags/useFlagsContext';

const useConfigCollection = () => {
  const [entries, setEntries] = useState<IConfig[]>([]);
  const [ready, setReady] = useState(false);
  const session = useOidcSession();
  const clientDataContext = useClientDataContext();
  const flags = useFlagsContext();

  const { makeConfig } = useConfig();

  const load = () => {
    configService
      .fetch()
      .then(filterSdtEntries)
      .then(setEntries)
      .then(() => setReady(true));
  };

  const provided = {
    entries,
    setEntries,
    save(name: string, id?: string) {
      const config = makeConfig(name, id);
      configService.save(config).then(() => {
        // If an id wasn't passed, it's a new view
        // and if it's new, we want to refresh the config entries
        // so the user sees their new view if they open the Load View modal again.
        if (!id) {
          load();
        }
      });
      return config.id;
    },
    delete(id: string) {
      configService.delete(id);
      setEntries((configs) => configs.filter((config) => config.id !== id));
    },
    ready
  };

  const filterSdtEntries = (entries: IConfig[]) => {
    if (session.isStrata) {
      return entries;
    }

    return entries.filter((entry) => !entry.savedBy?.includes('sdt\\'));
  };

  useEffect(() => {
    if (clientDataContext.clientData) {
      load();
    }
  }, [clientDataContext.clientData]);

  return provided;
};

export { useConfigCollection };

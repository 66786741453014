import React, { FC } from 'react';
import Layout from '@strata/tempo/lib/layout';
import { Navbar } from '@strata/navbar/lib';

import { PopupProvider } from 'shared/utils/usePopupContext';

import CompareErrorBoundary from 'shared/CompareErrorBoundary';
import CompareHeader from './CompareHeader';
import Navigation from './Navigation';
import GlobalContextProvider from './GlobalContextProvider';

const CompareLayout: FC = () => {
  return (
    <PopupProvider>
      <CompareErrorBoundary>
        <Layout>
          <Layout.Nav>
            <Navbar />
          </Layout.Nav>
          <Layout.Content>
            <GlobalContextProvider>
              <CompareHeader />
              <Navigation />
            </GlobalContextProvider>
          </Layout.Content>
        </Layout>
      </CompareErrorBoundary>
    </PopupProvider>
  );
};

export default CompareLayout;

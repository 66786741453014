import React, { FC } from 'react';
import { Spacing, Tabs } from '@strata/tempo/lib';
import CompareDrawer from 'shared/drawers/compare-drawer/CompareDrawer';
import MetricSelectorTabPane from 'shared/drawers/compare-drawer/MetricSelectorTabPane';
import DrawerFilters from 'shared/drawers/compare-drawer/DrawerFilters';
import { useLaborContext } from 'labor/data/laborContext';

const LaborDrawer: FC = () => {
  const laborContext = useLaborContext();

  const handleRun = async () => {
    if (!laborContext.filterGroup.ready) {
      return;
    }

    laborContext.compareDrawer.close();
    const eligibleMetrics = laborContext.selectableMetrics.selected.filter(
      (entry) => !laborContext.outOfScopeMetrics.includes(entry)
    );
    laborContext.selectableComparisons.load(eligibleMetrics);
  };

  return (
    <CompareDrawer
      visible={laborContext.compareDrawer.isOpen}
      onClose={laborContext.compareDrawer.close}
      onRun={handleRun}
      hasSelectedMetrics={!!laborContext.selectableMetrics.selected.length}
      hasLoadedMetricResults={laborContext.selectableComparisons.loadingDone}
      pleaseWait={!laborContext.filterGroup.ready}
      withMetrics
    >
      <Tabs
        defaultActiveKey='1'
        items={[
          {
            key: '1',
            label: 'Metrics',
            children: <MetricSelectorTabPane selectableMetrics={laborContext.selectableMetrics} />
          },
          {
            key: '2',
            label: 'Filters',
            children: (
              <Spacing padding='12px'>
                <DrawerFilters
                  filterGroup={laborContext.filterGroup}
                  filterScope={laborContext.filterGroup.filters.entity.computed?.scope}
                />
              </Spacing>
            )
          }
        ]}
      />
    </CompareDrawer>
  );
};

export default LaborDrawer;

import { IFilterConfig } from 'shared/filters/IFilter';

export const operatingExpense: IFilterConfig<string[]> = {
  controlLabel: 'Operating Expense',
  controlOptionsFromUiConfig: {
    source: 'Entity',
    fieldname: 'operatingexpense'
  },
  controlType: 'checkboxgroup',
  controlWeight: 12,
  defaultValue: [],
  param: 'operatingExpense',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.operatingExpense);
  },
  scope: 'entity',
  settingLabel: 'Operating Expense',
  settingWeight: 12
};

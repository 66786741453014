import { useState } from 'react';
import { ISphMapping } from './ISphMapping';
import { useFlagsContext } from 'shared/flags/useFlagsContext';

export interface IUseMappingReturn {
  data: ISphMapping[];
  load(): Promise<void>;
  loaded: boolean;
}

export interface IUseMapping {
  (loader: (useDemoDb: boolean) => Promise<ISphMapping[]>): IUseMappingReturn;
}

export const useMapping: IUseMapping = (fetchData) => {
  const flags = useFlagsContext();
  const [data, setData] = useState<ISphMapping[]>([]);
  const [loaded, setLoaded] = useState(false);

  return {
    data,
    load: () =>
      loaded
        ? Promise.resolve()
        : fetchData(flags.useDemoDb).then((data) => {
            setData(data);
            setLoaded(true);
          }),
    loaded
  };
};

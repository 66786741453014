import React, { FC } from 'react';
import ComparisonCard2 from 'shared/comparisons/cards/ComparisonCard2';
import { useServiceLineContext } from 'serviceline2/data/ServiceLineContext';
import { ISlCfAverage, ISlCfTrend } from '../../shared/comparisons/data/IComparison';

const ServiceLineLosCard: FC = () => {
  const slContext = useServiceLineContext();
  const patientTypeLabel = slContext.filterGroup.filters.patientType.computed?.friendlyLabel;

  const isInpatientType = slContext.filterGroup.filters.patientType.filterValue === '3';

  const selectedTableRowName = slContext.selectedTableRow?.value;
  let trends;
  if (isInpatientType) {
    trends =
      slContext.selectedTableRow?.type === 'Care Family'
        ? slContext.careFamilyTable.data.trends?.filter((e) => e.name === selectedTableRowName)
        : slContext.serviceLineTable.data.trends?.filter((e) => e.name === selectedTableRowName);
  }

  const messageNoDataLoaded = isInpatientType
    ? ''
    : `LOS does not apply to ${patientTypeLabel.toLowerCase()}`;

  return (
    <ComparisonCard2
      averageValueAccessor={(entry: ISlCfAverage) => ({
        isUser: entry.isUser,
        value: entry.losValue
      })}
      trendValueAccessor={(entry: ISlCfTrend) => ({
        dateId: entry.dateId,
        isUser: entry.isUser,
        q1: entry.losQ1,
        q2: entry.losQ2,
        q3: entry.losQ3
      })}
      chartOptions={{ marginLeft: 56 }}
      trends={trends}
      height={410}
      isLoading={slContext.serviceLineTable.loading || slContext.careFamilyTable.loading}
      messageNoDataLoaded={messageNoDataLoaded}
      metric={{
        Category: slContext.selectedTableRow?.value ?? 'Service Line',
        Data_Name: 'los',
        Friendly_Name: `LOS Monthly Trend for ${patientTypeLabel}`,
        Format: {
          type: 'number',
          sigDig: 1,
          axis: ',.1s',
          tooltip: ',.1s'
        },
        GoodBound: 'lower'
      }}
      visualization='Trend'
      withControls
    />
  );
};

export default ServiceLineLosCard;

import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '@strata/tempo/lib/tabs';
import { Switch, Route, Redirect } from 'react-router-dom';

import Kpi from 'kpi/Kpi';
import Cost from 'cost/Cost';
import Labor from 'labor/Labor';
import Overhead from 'overhead/Overhead';
import ServiceLineModule from 'serviceline/ServiceLineModule';
import ServiceLineModuleNoCalcEngineExperiment from 'serviceline2/ServiceLineModule';

import Advertisement from 'shared/Advertisement';

import { useClientDataContext } from 'shared/data/useClientDataContext';
import useLegalPopup from 'shared/legal/useLegalPopup';
import { useFlagsContext } from 'shared/flags/useFlagsContext';

const Navigation: FC = () => {
  const clientDataContext = useClientDataContext();
  const location = useLocation();
  const flagsContext = useFlagsContext();

  useLegalPopup();

  return (
    <>
      <Switch>
        <Route path='/'>
          <Advertisement clientData={clientDataContext.clientData}>
            <Tabs
              activeKey={location.pathname}
              items={[
                { key: '/kpi', label: 'KPI', href: '/kpi' },
                { key: '/cost', label: 'Cost', href: '/cost' },
                { key: '/labor', label: 'Labor Expense', href: '/labor' },
                { key: '/overhead', label: 'Overhead', href: '/overhead' },
                { key: '/serviceline', label: 'Service Line', href: '/serviceline' }
              ]}
            />
          </Advertisement>
          <Switch>
            <Redirect to='/kpi' from='/' exact />
            <Route path='/kpi' exact key='1' component={Kpi} />
            <Route path='/cost' exact key='2' component={Cost} />
            <Route path='/labor' key='3' component={Labor} />
            <Route path='/overhead' key='4' component={Overhead} />
            {flagsContext.noCalcEngine ? (
              <Route
                path='/serviceline'
                key='5'
                component={ServiceLineModuleNoCalcEngineExperiment}
              />
            ) : (
              <Route path='/serviceline' key='5' component={ServiceLineModule} />
            )}
          </Switch>
        </Route>
      </Switch>
    </>
  );
};

export default Navigation;

import React, { createContext, FC, useContext, useEffect, useState } from 'react';
import { IConfig } from './IConfig';
import { useConfig } from './useConfig';
import { DEFAULT_VIEW_NAME, useView } from './useView';
import { useConfigCollection } from './useConfigCollection';
import { useKpiContext } from 'kpi/data/kpiContext';
import { useLaborContext } from 'labor/data/laborContext';
import { useOidcSession } from '@strata/core/lib';
import { useCostContext } from '../../../cost/data/costContext';
import { useOverheadContext } from '../../../overhead/data/overheadContext';

type ViewModalNames = 'save' | 'load';

interface IViewContext {
  activeModal?: ViewModalNames;
  name: string;
  configEntries: IConfig[];
  delete(id: string): void;
  load(id: string): void;
  saveAs(name: string, idToOverwrite?: string): void;
  openModal(name: ViewModalNames): void;
  closeModal(): void;
  shouldShowOnlyMyViews: boolean;
  toggleShouldShowOnlyMyViews(): void;
}

const context = createContext({} as IViewContext);

const ViewProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const session = useOidcSession();
  const [activeModal, setActiveModal] = useState<ViewModalNames>();
  const [shouldFetchComparisons, setShouldFetchComparisons] = useState(false);
  const [shouldShowOnlyMyViews, setShouldShowOnlyMyViews] = useState(false);
  const configCollection = useConfigCollection();
  const view = useView();
  const kpiContext = useKpiContext();
  const laborContext = useLaborContext();
  const costContext = useCostContext();
  const overheadContext = useOverheadContext();

  const filteredConfigEntries = configCollection.entries.filter((entry) => {
    return shouldShowOnlyMyViews ? entry.savedBy === session.userName : true;
  });

  const provided = {
    activeModal,
    name: view.name,
    configEntries: filteredConfigEntries,
    delete(id: string) {
      configCollection.delete(id);
      if (view.id === id) {
        view.setId('');
        view.setName(DEFAULT_VIEW_NAME);
      }
      provided.closeModal();
    },
    load(id: string) {
      view.setId(id); // This triggers the side effect written below, that runs the view config.
      provided.closeModal();
    },
    saveAs(name: string, idToOverwrite?: string) {
      let finalId;
      if (idToOverwrite) {
        // The user is electing to Save As and overwrite another view they created.
        finalId = idToOverwrite;
        // To prevent a bug, remove the config we're overwriting
        configCollection.setEntries((entries) =>
          entries.filter((entry) => entry.id !== idToOverwrite)
        );
      } else if (view.name === name) {
        // The user is simply Saving an update to their existing view. (Save not Save As)
        finalId = view.id;
      } else {
        // The user is creating a new view
        finalId = undefined;
      }
      const id = configCollection.save(name, finalId);
      // Calling view.setId puts the id into the url, making it bookmarkable.
      view.setId(id);
      view.setName(name);
      provided.closeModal();
    },
    openModal: (name: ViewModalNames) => setActiveModal(name),
    closeModal: () => setActiveModal(undefined),
    shouldShowOnlyMyViews,
    toggleShouldShowOnlyMyViews: () => setShouldShowOnlyMyViews((val) => !val)
  };

  const { loadConfig } = useConfig();

  const loadViewConfig = () => {
    const config = configCollection.entries.find((c) => c.id === view.id);
    if (config) {
      loadConfig(config);
      view.setName(config.name);
      setShouldFetchComparisons(true);
    }
  };
  const fetchComparisons = () => {
    if (shouldFetchComparisons) {
      kpiContext.loadComparisons();
      laborContext.loadComparisons();
      costContext.costComparisons.load();
      overheadContext.comparisons.load();
      setShouldFetchComparisons(false);
    }
  };

  useEffect(loadViewConfig, [configCollection.ready, view.id]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(fetchComparisons, [shouldFetchComparisons]); // eslint-disable-line react-hooks/exhaustive-deps

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

const useViewContext = () => useContext(context);

export { ViewProvider, useViewContext };

import { appConfig, getSecureService } from '@strata/core/lib';
import { IConfig, IConfigLegacy } from './IConfig';

const { httpGet, httpPost, httpDelete } = getSecureService(appConfig.apiUrl);

export const configService = {
  fetch(): Promise<IConfig[]> {
    return httpGet<IConfigLegacy[]>('/user-views').then((data) =>
      data.map((entry) => ({
        id: entry.configGUID,
        name: entry.benchmarkName,
        json: entry.configString,
        savedAt: entry.savedAt,
        savedBy: entry.userName
      }))
    );
  },

  save(config: IConfig): Promise<void> {
    return httpPost(`/user-views/${config.id}`, {
      benchmarkName: config.name,
      configGUID: config.id,
      configString: config.json
    });
  },

  delete(configGUID: string): Promise<void> {
    return httpDelete<void>(`/user-views/${configGUID}`);
  }
};

import { CohortStats, IStandardComparison } from 'shared/data/IStandardComparison';

export class ComparisonTransforms {
  static multiplyValuesBy100(comparison: IStandardComparison) {
    const x100 = (x: number) => x * 100;
    _transformComparisonValues(comparison, x100);
  }
}

const _transformComparisonValues = (
  comparison: IStandardComparison,
  transform: (value: number) => number
) => {
  const statsTransform = <T extends CohortStats>(stats: T): T => ({
    ...stats,
    q25: transform(stats.q25),
    q75: transform(stats.q75),
    median: transform(stats.median)
  });

  if (comparison.incomplete) {
    return;
  }

  comparison.allAverages = comparison.allAverages.map(transform);
  comparison.cohortMonthlyTrend = comparison.cohortMonthlyTrend.map(statsTransform);
  comparison.allStats = statsTransform(comparison.allStats ?? {});
  comparison.stats = statsTransform(comparison.stats ?? {});
  comparison.userAverage = transform(comparison.userAverage);
  comparison.userMonthlyTrend = comparison.userMonthlyTrend.map((t) => ({
    ...t,
    value: transform(t.value)
  }));
};

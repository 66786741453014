import React, { FC, useEffect, useState } from 'react';
import { DataGrid, DataHeaderCell, DataHeaderGroup, DataHeaderRow, Text } from '@strata/tempo/lib';
import { useServiceLineContext } from './data/ServiceLineContext';
import { IDataGridSelectionEvent } from '@strata/tempo/lib/datagrid/DataGrid';
import {
  datadogIdentifier,
  asISlCfTableRows,
  formatMoney,
  formatMoneyToNumber,
  ISlCfTableRow
} from './data/serviceLineService';
import { logger } from '@strata/logging/lib';

const CareFamilyTable: FC = (props) => {
  const slContext = useServiceLineContext();

  const handleSelection = (e: IDataGridSelectionEvent) => {
    if (!e.value) {
      return;
    }

    logger.log('click', 'selected carefamily table row', {
      comparisonId: datadogIdentifier.getId()
    });

    slContext.setSelectedTableRow({
      value: e.value.name,
      type: 'Care Family'
    });
  };

  const tableData = asISlCfTableRows(slContext.careFamilyTable.data?.averages ?? []).filter(
    (row) => {
      // Note: this table should NEVER filter on the user's minimum volume setting!
      // (Only the Service Line table should do this!)
      const includesFilterText = (column: string | number) =>
        String(column).toLowerCase().includes(slContext.tableFilter.toLowerCase());
      return Object.values(row).some(includesFilterText);
    }
  );

  const emptyTableData = !tableData.length && slContext.careFamilyTable.loadingDone;
  const emptyMessage = emptyTableData ? 'Missing Cohort' : 'Loading';

  const getTableRowFromName = (name: string) => tableData.find((entry) => entry.name === name);

  const getLosRowData = (field: string) => (rowData: ISlCfTableRow) =>
    slContext.filterGroup.filters.patientType.filterValue === '3'
      ? rowData[field as keyof typeof rowData]
      : '--';

  // Select the first row every time the data loads
  useEffect(() => {
    const firstRow = tableData[0];
    if (slContext.careFamilyTable.loadingDone) {
      if (!firstRow) {
        return slContext.setSelectedTableRow(undefined);
      }

      slContext.setSelectedTableRow({
        value: firstRow.name,
        type: 'Care Family'
      });
    }
  }, [slContext.careFamilyTable.loadingDone]);

  return (
    <div className='care-family-table '>
      <DataGrid
        columnHeaderGroup={
          <DataHeaderGroup>
            <DataHeaderRow>
              <DataHeaderCell align='left' width={200} field='name' header='Care Family' sortable />
              <DataHeaderCell
                width={120}
                isGap
                field='volumeUser'
                sortable
                header='Your Average Monthly Volume'
              />
              <DataHeaderCell isGap />
              <DataHeaderCell
                colSpan={1}
                width={90}
                field='cmiUser'
                header='Your Average CMI'
                align='right'
                sortable
              />
              <DataHeaderCell
                field='cmiMedian'
                header='Cohort Median CMI'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell isGap />
              <DataHeaderCell
                field='losUser'
                header='Your Average LOS'
                align='right'
                width={90}
                sortable
              />
              <DataHeaderCell
                field='losMedian'
                header='Cohort Median LOS'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell
                field={slContext.diffType === 'absolute' ? 'losDiff' : 'losDiffPercentage'}
                header='LOS &#120491;'
                align='right'
                width={90}
                sortable
              />
              <DataHeaderCell isGap />
              <DataHeaderCell
                field='costUser'
                header='Your Average Direct Cost'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell
                field='costMedian'
                header='Cohort Median Direct Cost'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell
                field={slContext.diffType === 'absolute' ? 'costDiff' : 'costDiffPercentage'}
                header='Cost &#120491;'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell isGap colSpan={2} width={50} />
            </DataHeaderRow>
          </DataHeaderGroup>
        }
        dataKey='name'
        density='minimal'
        emptyMessage={emptyMessage}
        itemName='Care Families'
        loading={slContext.careFamilyTable.loading}
        onSelectionChange={handleSelection}
        selection={getTableRowFromName(slContext.selectedTableRow?.value ?? '')}
        sortOrder={-1}
        sortField='volumeUser'
        value={tableData}
      >
        <DataGrid.Column
          key='name'
          width={150}
          header='Care Family'
          field='name'
          sortable
          align='left'
        />
        <DataGrid.Column key='volume' field='volumeUser' width={120} align='right' />
        <DataGrid.GapColumn />
        <DataGrid.Column key='cmi' field='cmiUser' width={70} align='right' />
        <DataGrid.Column key='cmiMedian' field='cmiMedian' width={130} align='right' />
        <DataGrid.GapColumn />
        <DataGrid.Column
          key='los'
          field='losUser'
          width={90}
          align='right'
          body={getLosRowData('losUser')}
        />
        <DataGrid.Column
          key='losMedian'
          field='losMedian'
          width={120}
          align='right'
          body={getLosRowData('losMedian')}
        />
        {slContext.diffType === 'absolute' ? (
          <DataGrid.Column
            key='losDiff'
            field='losDiff'
            width={90}
            align='right'
            body={({ losDiff, losUser, losMedian }) => {
              if (slContext.filterGroup.filters.patientType.filterValue !== '3') {
                return '--';
              }
              return (
                <Text
                  color={
                    formatMoneyToNumber(losUser) <= formatMoneyToNumber(losMedian)
                      ? 'success'
                      : 'error'
                  }
                >
                  {losDiff}
                </Text>
              );
            }}
          />
        ) : (
          <DataGrid.Column
            key='losDiffPercentage'
            field='losDiffPercentage'
            width={90}
            align='right'
            body={({ losDiffPercentage, losUser, losMedian }) => {
              if (slContext.filterGroup.filters.patientType.filterValue !== '3') {
                return '--';
              }
              return (
                <Text
                  color={
                    formatMoneyToNumber(losUser) <= formatMoneyToNumber(losMedian)
                      ? 'success'
                      : 'error'
                  }
                >
                  {Number(losDiffPercentage).toFixed(2) + '%'}
                </Text>
              );
            }}
          />
        )}
        <DataGrid.GapColumn />
        <DataGrid.Column
          key='cost'
          field='costUser'
          width={120}
          align='right'
          body={({ costUser }) => {
            return formatMoney(costUser);
          }}
        />
        <DataGrid.Column
          key='costMedian'
          field='costMedian'
          width={120}
          align='right'
          body={({ costMedian }) => {
            return formatMoney(costMedian);
          }}
        />
        {slContext.diffType === 'absolute' ? (
          <DataGrid.Column
            key='costDiff'
            field='costDiff'
            width={120}
            align='right'
            body={({ costDiff, costUser, costMedian }) => {
              return (
                <Text color={costUser <= costMedian ? 'success' : 'error'}>
                  {formatMoney(costDiff)}
                </Text>
              );
            }}
          />
        ) : (
          <DataGrid.Column
            key='costDiffPercentage'
            field='costDiffPercentage'
            width={120}
            align='right'
            body={({ costDiffPercentage, costUser, costMedian }) => {
              return (
                <Text color={costUser <= costMedian ? 'success' : 'error'}>
                  {Number(costDiffPercentage).toFixed(2) + '%'}
                </Text>
              );
            }}
          />
        )}
        <DataGrid.EmptyColumn />
        <DataGrid.SelectionColumn selectionMode='single' />
      </DataGrid>
    </div>
  );
};

export default CareFamilyTable;

import { appConfig, getSecureService } from '@strata/core/lib';

const { httpGet } = getSecureService(appConfig.apiUrl);

interface ITimelineDefaultData {
  defaultEndDate: number;
  defaultStartDate: number;
  histStartDate: number;
  prelimEndDate: number;
}

export const service = {
  fetchTimelineDefaults: (): Promise<ITimelineDefaultData> => {
    return httpGet<ITimelineDefaultData>('/filters/defaults/timelines');
  }
};

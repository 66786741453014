import React, { FC, useEffect } from 'react';

import AggregateUserScore from 'shared/user-score/AggregateUserScore';
import FilterGroupSettings from 'shared/filters/FilterGroupSettings';
import InsightDrawer from 'shared/drawers/insight-drawer/InsightDrawer';
import LegalNotice from 'shared/LegalNotice';
import TaskBar from 'shared/taskbar/TaskBar';

import LaborDrawer from './drawer/LaborDrawer';
import { useLaborContext } from './data/laborContext';
import LaborComparisons from './LaborComparisons';
import { ICacheableStandardComparison } from '../shared/data/IStandardComparison';

const Labor: FC = () => {
  const laborContext = useLaborContext();

  useEffect(() => {
    const comparisonIsOutOfSyncWithFilters = (x: ICacheableStandardComparison) =>
      x.token !== laborContext.filterGroup.token;

    if (laborContext.selectableComparisons.comparisons.some(comparisonIsOutOfSyncWithFilters)) {
      laborContext.loadComparisons();
    }
  }, []);

  return (
    <>
      <TaskBar
        loading={laborContext.selectableComparisons.loading}
        loadingProgress={laborContext.selectableComparisons.loadingProgress}
        metricDrawerButtonText='Metrics & Filters'
        openMetricDrawer={laborContext.compareDrawer.open}
        cancelMetricResultsLoad={laborContext.selectableComparisons.cancelLoad}
      />
      <LaborDrawer />
      <InsightDrawer moduleContext={laborContext} />
      <FilterGroupSettings
        filterGroup={laborContext.filterGroup}
        filterScope={laborContext.filterGroup.filters.entity.computed?.scope}
        withViewToggle
      />
      <AggregateUserScore
        isLoading={laborContext.selectableComparisons.loading}
        metrics={laborContext.selectableMetrics.metrics.filter((metric) =>
          laborContext.selectableMetrics.selected.includes(metric.Data_Name)
        )}
        metricResults={laborContext.selectableComparisons.comparisons.filter((c) =>
          laborContext.selectableMetrics.selected.includes(c.dataName)
        )}
      />
      <LaborComparisons />
      <LegalNotice />
    </>
  );
};

export default Labor;

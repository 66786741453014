import React, { FC, useEffect } from 'react';

import AggregateUserScore from 'shared/user-score/AggregateUserScore';
import FilterGroupSettings from 'shared/filters/FilterGroupSettings';
import KpiDrawer from './drawer/KpiDrawer';
import InsightDrawer from 'shared/drawers/insight-drawer/InsightDrawer';
import LegalNotice from 'shared/LegalNotice';
import TaskBar from 'shared/taskbar/TaskBar';

import KpiComparisons from './KpiComparisons';
import { useKpiContext } from './data/kpiContext';
import { ICacheableStandardComparison } from '../shared/data/IStandardComparison';

const Kpi: FC = () => {
  const kpiContext = useKpiContext();

  useEffect(() => {
    const comparisonIsOutOfSyncWithFilters = (x: ICacheableStandardComparison) =>
      x.token !== kpiContext.filterGroup.token;

    if (kpiContext.selectableComparisons.comparisons.some(comparisonIsOutOfSyncWithFilters)) {
      kpiContext.loadComparisons();
    }
  }, []);

  return (
    <>
      <TaskBar
        loading={kpiContext.selectableComparisons.loading}
        loadingProgress={kpiContext.selectableComparisons.loadingProgress}
        metricDrawerButtonText='Metrics & Filters'
        openMetricDrawer={kpiContext.compareDrawer.open}
        cancelMetricResultsLoad={kpiContext.selectableComparisons.cancelLoad}
      />
      <KpiDrawer />
      <InsightDrawer moduleContext={kpiContext} />
      <FilterGroupSettings
        filterGroup={kpiContext.filterGroup}
        filterScope={kpiContext.filterGroup.filters.entity.computed?.scope}
        withViewToggle
      />
      <AggregateUserScore
        isLoading={kpiContext.selectableComparisons.loading}
        metrics={kpiContext.selectableMetrics.metrics.filter((metric) =>
          kpiContext.selectableMetrics.selected.includes(metric.Data_Name)
        )}
        metricResults={kpiContext.selectableComparisons.comparisons.filter((c) =>
          kpiContext.selectableMetrics.selected.includes(c.dataName)
        )}
      />
      <KpiComparisons />
      <LegalNotice />
    </>
  );
};

export default Kpi;

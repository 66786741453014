export default [
  {
    'disabled': true,
    'key': 'mdc-0',
    'title': '0 - Pre-MDC',
    'value': 'mdc-0',
    'children': [
      {
        'value': '001',
        'title': '001 - HEART TRANSPLANT OR IMPLANT OF HEART ASSIST SYSTEM W MCC',
        'key': '001'
      },
      {
        'value': '002',
        'title': '002 - HEART TRANSPLANT OR IMPLANT OF HEART ASSIST SYSTEM W-O MCC',
        'key': '002'
      },
      {
        'value': '003',
        'title': '003 - ECMO OR TRACH W MV GREATER THAN 96 HRS OR PDX EXC FACE MOUTH AND NECK W MAJ O.R.',
        'key': '003'
      },
      {
        'value': '004',
        'title': '004 - TRACH W MV GREATER THAN 96 HRS OR PDX EXC FACE MOUTH AND NECK W-O MAJ O.R.',
        'key': '004'
      },
      {
        'value': '005',
        'title': '005 - LIVER TRANSPLANT W MCC OR INTESTINAL TRANSPLANT',
        'key': '005'
      },
      {
        'value': '006',
        'title': '006 - LIVER TRANSPLANT W-O MCC',
        'key': '006'
      },
      {
        'value': '007',
        'title': '007 - LUNG TRANSPLANT',
        'key': '007'
      },
      {
        'value': '008',
        'title': '008 - SIMULTANEOUS PANCREAS-KIDNEY TRANSPLANT',
        'key': '008'
      },
      {
        'value': '010',
        'title': '010 - PANCREAS TRANSPLANT',
        'key': '010'
      },
      {
        'value': '011',
        'title': '011 - TRACHEOSTOMY FOR FACEMOUTH AND NECK DIAGNOSES W MCC',
        'key': '011'
      },
      {
        'value': '012',
        'title': '012 - TRACHEOSTOMY FOR FACEMOUTH AND NECK DIAGNOSES W CC',
        'key': '012'
      },
      {
        'value': '013',
        'title': '013 - TRACHEOSTOMY FOR FACEMOUTH AND NECK DIAGNOSES W-O CC-MCC',
        'key': '013'
      },
      {
        'value': '014',
        'title': '014 - ALLOGENEIC BONE MARROW TRANSPLANT',
        'key': '014'
      },
      {
        'value': '016',
        'title': '016 - AUTOLOGOUS BONE MARROW TRANSPLANT W CC-MCC',
        'key': '016'
      },
      {
        'value': '017',
        'title': '017 - AUTOLOGOUS BONE MARROW TRANSPLANT W-O CC-MCC',
        'key': '017'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-1',
    'title': '1 - Diseases & Disorders of the Nervous System',
    'value': 'mdc-1',
    'children': [
      {
        'value': '020',
        'title': '020 - INTRACRANIAL VASCULAR PROCEDURES W PDX HEMORRHAGE W MCC',
        'key': '020'
      },
      {
        'value': '021',
        'title': '021 - INTRACRANIAL VASCULAR PROCEDURES W PDX HEMORRHAGE W CC',
        'key': '021'
      },
      {
        'value': '022',
        'title': '022 - INTRACRANIAL VASCULAR PROCEDURES W PDX HEMORRHAGE W-O CC-MCC',
        'key': '022'
      },
      {
        'value': '023',
        'title': '023 - CRANIOTOMY W MAJOR DEVICE IMPLANT OR ACUTE CNS PDX W MCC OR CHEMOTHERAPY IMPLANT OR EPILEPSY W NEUROSTIMULATOR',
        'key': '023'
      },
      {
        'value': '024',
        'title': '024 - CRANIO W MAJOR DEV IMPL-ACUTE COMPLEX CNS PDX W-O MCC',
        'key': '024'
      },
      {
        'value': '025',
        'title': '025 - CRANIOTOMY AND ENDOVASCULAR INTRACRANIAL PROCEDURES W MCC',
        'key': '025'
      },
      {
        'value': '026',
        'title': '026 - CRANIOTOMY AND ENDOVASCULAR INTRACRANIAL PROCEDURES W CC',
        'key': '026'
      },
      {
        'value': '027',
        'title': '027 - CRANIOTOMY AND ENDOVASCULAR INTRACRANIAL PROCEDURES W-O CC-MCC',
        'key': '027'
      },
      {
        'value': '028',
        'title': '028 - SPINAL PROCEDURES W MCC',
        'key': '028'
      },
      {
        'value': '029',
        'title': '029 - SPINAL PROCEDURES W CC OR SPINAL NEUROSTIMULATORS',
        'key': '029'
      },
      {
        'value': '030',
        'title': '030 - SPINAL PROCEDURES W-O CC-MCC',
        'key': '030'
      },
      {
        'value': '031',
        'title': '031 - VENTRICULAR SHUNT PROCEDURES W MCC',
        'key': '031'
      },
      {
        'value': '032',
        'title': '032 - VENTRICULAR SHUNT PROCEDURES W CC',
        'key': '032'
      },
      {
        'value': '033',
        'title': '033 - VENTRICULAR SHUNT PROCEDURES W-O CC-MCC',
        'key': '033'
      },
      {
        'value': '034',
        'title': '034 - CAROTID ARTERY STENT PROCEDURE W MCC',
        'key': '034'
      },
      {
        'value': '035',
        'title': '035 - CAROTID ARTERY STENT PROCEDURE W CC',
        'key': '035'
      },
      {
        'value': '036',
        'title': '036 - CAROTID ARTERY STENT PROCEDURE W-O CC-MCC',
        'key': '036'
      },
      {
        'value': '037',
        'title': '037 - EXTRACRANIAL PROCEDURES W MCC',
        'key': '037'
      },
      {
        'value': '038',
        'title': '038 - EXTRACRANIAL PROCEDURES W CC',
        'key': '038'
      },
      {
        'value': '039',
        'title': '039 - EXTRACRANIAL PROCEDURES W-O CC-MCC',
        'key': '039'
      },
      {
        'value': '040',
        'title': '040 - PERIPH-CRANIAL NERVE AND OTHER NERV SYST PROC W MCC',
        'key': '040'
      },
      {
        'value': '041',
        'title': '041 - PERIPH-CRANIAL NERVE AND OTHER NERV SYST PROC W CC OR PERIPH NEUROSTIM',
        'key': '041'
      },
      {
        'value': '042',
        'title': '042 - PERIPH-CRANIAL NERVE AND OTHER NERV SYST PROC W-O CC-MCC',
        'key': '042'
      },
      {
        'value': '052',
        'title': '052 - SPINAL DISORDERS AND INJURIES W CC-MCC',
        'key': '052'
      },
      {
        'value': '053',
        'title': '053 - SPINAL DISORDERS AND INJURIES W-O CC-MCC',
        'key': '053'
      },
      {
        'value': '054',
        'title': '054 - NERVOUS SYSTEM NEOPLASMS W MCC',
        'key': '054'
      },
      {
        'value': '055',
        'title': '055 - NERVOUS SYSTEM NEOPLASMS W-O MCC',
        'key': '055'
      },
      {
        'value': '056',
        'title': '056 - DEGENERATIVE NERVOUS SYSTEM DISORDERS W MCC',
        'key': '056'
      },
      {
        'value': '057',
        'title': '057 - DEGENERATIVE NERVOUS SYSTEM DISORDERS W-O MCC',
        'key': '057'
      },
      {
        'value': '058',
        'title': '058 - MULTIPLE SCLEROSIS AND CEREBELLAR ATAXIA W MCC',
        'key': '058'
      },
      {
        'value': '059',
        'title': '059 - MULTIPLE SCLEROSIS AND CEREBELLAR ATAXIA W CC',
        'key': '059'
      },
      {
        'value': '060',
        'title': '060 - MULTIPLE SCLEROSIS AND CEREBELLAR ATAXIA W-O CC-MCC',
        'key': '060'
      },
      {
        'value': '061',
        'title': '061 - ISCHEMIC STROKE PRECEREBRAL OCCLUSION OR TRANSIENT ISCHEMIA W THROMBOLYTIC AGENT W MCC',
        'key': '061'
      },
      {
        'value': '062',
        'title': '062 - ISCHEMIC STROKE PRECEREBRAL OCCLUSION OR TRANSIENT ISCHEMIA W THROMBOLYTIC AGENT W CC',
        'key': '062'
      },
      {
        'value': '063',
        'title': '063 - ISCHEMIC STROKE PRECEREBRAL OCCLUSION OR TRANSIENT ISCHEMIA W THROMBOLYTIC AGENT W-O CC-MCC',
        'key': '063'
      },
      {
        'value': '064',
        'title': '064 - INTRACRANIAL HEMORRHAGE OR CEREBRAL INFARCTION W MCC',
        'key': '064'
      },
      {
        'value': '065',
        'title': '065 - INTRACRANIAL HEMORRHAGE OR CEREBRAL INFARCTION W CC OR TPA IN 24 HRS',
        'key': '065'
      },
      {
        'value': '066',
        'title': '066 - INTRACRANIAL HEMORRHAGE OR CEREBRAL INFARCTION W-O CC-MCC',
        'key': '066'
      },
      {
        'value': '067',
        'title': '067 - NONSPECIFIC CVA AND PRECEREBRAL OCCLUSION W-O INFARCT W MCC',
        'key': '067'
      },
      {
        'value': '068',
        'title': '068 - NONSPECIFIC CVA AND PRECEREBRAL OCCLUSION W-O INFARCT W-O MCC',
        'key': '068'
      },
      {
        'value': '069',
        'title': '069 - TRANSIENT ISCHEMIA W-O THROMBOLYTIC',
        'key': '069'
      },
      {
        'value': '070',
        'title': '070 - NONSPECIFIC CEREBROVASCULAR DISORDERS W MCC',
        'key': '070'
      },
      {
        'value': '071',
        'title': '071 - NONSPECIFIC CEREBROVASCULAR DISORDERS W CC',
        'key': '071'
      },
      {
        'value': '072',
        'title': '072 - NONSPECIFIC CEREBROVASCULAR DISORDERS W-O CC-MCC',
        'key': '072'
      },
      {
        'value': '073',
        'title': '073 - CRANIAL AND PERIPHERAL NERVE DISORDERS W MCC',
        'key': '073'
      },
      {
        'value': '074',
        'title': '074 - CRANIAL AND PERIPHERAL NERVE DISORDERS W-O MCC',
        'key': '074'
      },
      {
        'value': '075',
        'title': '075 - VIRAL MENINGITIS W CC-MCC',
        'key': '075'
      },
      {
        'value': '076',
        'title': '076 - VIRAL MENINGITIS W-O CC-MCC',
        'key': '076'
      },
      {
        'value': '077',
        'title': '077 - HYPERTENSIVE ENCEPHALOPATHY W MCC',
        'key': '077'
      },
      {
        'value': '078',
        'title': '078 - HYPERTENSIVE ENCEPHALOPATHY W CC',
        'key': '078'
      },
      {
        'value': '079',
        'title': '079 - HYPERTENSIVE ENCEPHALOPATHY W-O CC-MCC',
        'key': '079'
      },
      {
        'value': '080',
        'title': '080 - NONTRAUMATIC STUPOR AND COMA W MCC',
        'key': '080'
      },
      {
        'value': '081',
        'title': '081 - NONTRAUMATIC STUPOR AND COMA W-O MCC',
        'key': '081'
      },
      {
        'value': '082',
        'title': '082 - TRAUMATIC STUPOR AND COMA COMA GREATER THAN 1 HR W MCC',
        'key': '082'
      },
      {
        'value': '083',
        'title': '083 - TRAUMATIC STUPOR AND COMA COMA GREATER THAN 1 HR W CC',
        'key': '083'
      },
      {
        'value': '084',
        'title': '084 - TRAUMATIC STUPOR AND COMA COMA GREATER THAN 1 HR W-O CC-MCC',
        'key': '084'
      },
      {
        'value': '085',
        'title': '085 - TRAUMATIC STUPOR AND COMA COMA LESS THAN 1 HR W MCC',
        'key': '085'
      },
      {
        'value': '086',
        'title': '086 - TRAUMATIC STUPOR AND COMA COMA LESS THAN 1 HR W CC',
        'key': '086'
      },
      {
        'value': '087',
        'title': '087 - TRAUMATIC STUPOR AND COMA COMA LESS THAN 1 HR W-O CC-MCC',
        'key': '087'
      },
      {
        'value': '088',
        'title': '088 - CONCUSSION W MCC',
        'key': '088'
      },
      {
        'value': '089',
        'title': '089 - CONCUSSION W CC',
        'key': '089'
      },
      {
        'value': '090',
        'title': '090 - CONCUSSION W-O CC-MCC',
        'key': '090'
      },
      {
        'value': '091',
        'title': '091 - OTHER DISORDERS OF NERVOUS SYSTEM W MCC',
        'key': '091'
      },
      {
        'value': '092',
        'title': '092 - OTHER DISORDERS OF NERVOUS SYSTEM W CC',
        'key': '092'
      },
      {
        'value': '093',
        'title': '093 - OTHER DISORDERS OF NERVOUS SYSTEM W-O CC-MCC',
        'key': '093'
      },
      {
        'value': '094',
        'title': '094 - BACTERIAL AND TUBERCULOUS INFECTIONS OF NERVOUS SYSTEM W MCC',
        'key': '094'
      },
      {
        'value': '095',
        'title': '095 - BACTERIAL AND TUBERCULOUS INFECTIONS OF NERVOUS SYSTEM W CC',
        'key': '095'
      },
      {
        'value': '096',
        'title': '096 - BACTERIAL AND TUBERCULOUS INFECTIONS OF NERVOUS SYSTEM W-O CC-MCC',
        'key': '096'
      },
      {
        'value': '097',
        'title': '097 - NON-BACTERIAL INFECT OF NERVOUS SYS EXC VIRAL MENINGITIS W MCC',
        'key': '097'
      },
      {
        'value': '098',
        'title': '098 - NON-BACTERIAL INFECT OF NERVOUS SYS EXC VIRAL MENINGITIS W CC',
        'key': '098'
      },
      {
        'value': '099',
        'title': '099 - NON-BACTERIAL INFECT OF NERVOUS SYS EXC VIRAL MENINGITIS W-O CC-MCC',
        'key': '099'
      },
      {
        'value': '100',
        'title': '100 - SEIZURES W MCC',
        'key': '100'
      },
      {
        'value': '101',
        'title': '101 - SEIZURES W-O MCC',
        'key': '101'
      },
      {
        'value': '102',
        'title': '102 - HEADACHES W MCC',
        'key': '102'
      },
      {
        'value': '103',
        'title': '103 - HEADACHES W-O MCC',
        'key': '103'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-2',
    'title': '2 - Diseases & Disorders of the Eye',
    'value': 'mdc-2',
    'children': [
      {
        'value': '113',
        'title': '113 - ORBITAL PROCEDURES W CC-MCC',
        'key': '113'
      },
      {
        'value': '114',
        'title': '114 - ORBITAL PROCEDURES W-O CC-MCC',
        'key': '114'
      },
      {
        'value': '115',
        'title': '115 - EXTRAOCULAR PROCEDURES EXCEPT ORBIT',
        'key': '115'
      },
      {
        'value': '116',
        'title': '116 - INTRAOCULAR PROCEDURES W CC-MCC',
        'key': '116'
      },
      {
        'value': '117',
        'title': '117 - INTRAOCULAR PROCEDURES W-O CC-MCC',
        'key': '117'
      },
      {
        'value': '121',
        'title': '121 - ACUTE MAJOR EYE INFECTIONS W CC-MCC',
        'key': '121'
      },
      {
        'value': '122',
        'title': '122 - ACUTE MAJOR EYE INFECTIONS W-O CC-MCC',
        'key': '122'
      },
      {
        'value': '123',
        'title': '123 - NEUROLOGICAL EYE DISORDERS',
        'key': '123'
      },
      {
        'value': '124',
        'title': '124 - OTHER DISORDERS OF THE EYE W MCC',
        'key': '124'
      },
      {
        'value': '125',
        'title': '125 - OTHER DISORDERS OF THE EYE W-O MCC',
        'key': '125'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-3',
    'title': '3 - Diseases & Disorders of the Ear, Nose, Mouth & Throat',
    'value': 'mdc-3',
    'children': [
      {
        'value': '129',
        'title': '129 - MAJOR HEAD AND NECK PROCEDURES W CC-MCC OR MAJOR DEVICE',
        'key': '129'
      },
      {
        'value': '130',
        'title': '130 - MAJOR HEAD AND NECK PROCEDURES W-O CC-MCC',
        'key': '130'
      },
      {
        'value': '131',
        'title': '131 - CRANIAL-FACIAL PROCEDURES W CC-MCC',
        'key': '131'
      },
      {
        'value': '132',
        'title': '132 - CRANIAL-FACIAL PROCEDURES W-O CC-MCC',
        'key': '132'
      },
      {
        'value': '133',
        'title': '133 - OTHER EAR NOSE MOUTH AND THROAT O.R. PROCEDURES W CC-MCC',
        'key': '133'
      },
      {
        'value': '134',
        'title': '134 - OTHER EAR NOSE MOUTH AND THROAT O.R. PROCEDURES W-O CC-MCC',
        'key': '134'
      },
      {
        'value': '135',
        'title': '135 - SINUS AND MASTOID PROCEDURES W CC-MCC',
        'key': '135'
      },
      {
        'value': '136',
        'title': '136 - SINUS AND MASTOID PROCEDURES W-O CC-MCC',
        'key': '136'
      },
      {
        'value': '137',
        'title': '137 - MOUTH PROCEDURES W CC-MCC',
        'key': '137'
      },
      {
        'value': '138',
        'title': '138 - MOUTH PROCEDURES W-O CC-MCC',
        'key': '138'
      },
      {
        'value': '139',
        'title': '139 - SALIVARY GLAND PROCEDURES',
        'key': '139'
      },
      {
        'value': '146',
        'title': '146 - EAR NOSE MOUTH AND THROAT MALIGNANCY W MCC',
        'key': '146'
      },
      {
        'value': '147',
        'title': '147 - EAR NOSE MOUTH AND THROAT MALIGNANCY W CC',
        'key': '147'
      },
      {
        'value': '148',
        'title': '148 - EAR NOSE MOUTH AND THROAT MALIGNANCY W-O CC-MCC',
        'key': '148'
      },
      {
        'value': '149',
        'title': '149 - DYSEQUILIBRIUM',
        'key': '149'
      },
      {
        'value': '150',
        'title': '150 - EPISTAXIS W MCC',
        'key': '150'
      },
      {
        'value': '151',
        'title': '151 - EPISTAXIS W-O MCC',
        'key': '151'
      },
      {
        'value': '152',
        'title': '152 - OTITIS MEDIA AND URI W MCC',
        'key': '152'
      },
      {
        'value': '153',
        'title': '153 - OTITIS MEDIA AND URI W-O MCC',
        'key': '153'
      },
      {
        'value': '154',
        'title': '154 - OTHER EAR NOSE MOUTH AND THROAT DIAGNOSES W MCC',
        'key': '154'
      },
      {
        'value': '155',
        'title': '155 - OTHER EAR NOSE MOUTH AND THROAT DIAGNOSES W CC',
        'key': '155'
      },
      {
        'value': '156',
        'title': '156 - OTHER EAR NOSE MOUTH AND THROAT DIAGNOSES W-O CC-MCC',
        'key': '156'
      },
      {
        'value': '157',
        'title': '157 - DENTAL AND ORAL DISEASES W MCC',
        'key': '157'
      },
      {
        'value': '158',
        'title': '158 - DENTAL AND ORAL DISEASES W CC',
        'key': '158'
      },
      {
        'value': '159',
        'title': '159 - DENTAL AND ORAL DISEASES W-O CC-MCC',
        'key': '159'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-4',
    'title': '4 - Diseases & Disorders of the Respiratory System',
    'value': 'mdc-4',
    'children': [
      {
        'value': '163',
        'title': '163 - MAJOR CHEST PROCEDURES W MCC',
        'key': '163'
      },
      {
        'value': '164',
        'title': '164 - MAJOR CHEST PROCEDURES W CC',
        'key': '164'
      },
      {
        'value': '165',
        'title': '165 - MAJOR CHEST PROCEDURES W-O CC-MCC',
        'key': '165'
      },
      {
        'value': '166',
        'title': '166 - OTHER RESP SYSTEM O.R. PROCEDURES W MCC',
        'key': '166'
      },
      {
        'value': '167',
        'title': '167 - OTHER RESP SYSTEM O.R. PROCEDURES W CC',
        'key': '167'
      },
      {
        'value': '168',
        'title': '168 - OTHER RESP SYSTEM O.R. PROCEDURES W-O CC-MCC',
        'key': '168'
      },
      {
        'value': '175',
        'title': '175 - PULMONARY EMBOLISM W MCC',
        'key': '175'
      },
      {
        'value': '176',
        'title': '176 - PULMONARY EMBOLISM W-O MCC',
        'key': '176'
      },
      {
        'value': '177',
        'title': '177 - RESPIRATORY INFECTIONS AND INFLAMMATIONS W MCC',
        'key': '177'
      },
      {
        'value': '178',
        'title': '178 - RESPIRATORY INFECTIONS AND INFLAMMATIONS W CC',
        'key': '178'
      },
      {
        'value': '179',
        'title': '179 - RESPIRATORY INFECTIONS AND INFLAMMATIONS W-O CC-MCC',
        'key': '179'
      },
      {
        'value': '180',
        'title': '180 - RESPIRATORY NEOPLASMS W MCC',
        'key': '180'
      },
      {
        'value': '181',
        'title': '181 - RESPIRATORY NEOPLASMS W CC',
        'key': '181'
      },
      {
        'value': '182',
        'title': '182 - RESPIRATORY NEOPLASMS W-O CC-MCC',
        'key': '182'
      },
      {
        'value': '183',
        'title': '183 - MAJOR CHEST TRAUMA W MCC',
        'key': '183'
      },
      {
        'value': '184',
        'title': '184 - MAJOR CHEST TRAUMA W CC',
        'key': '184'
      },
      {
        'value': '185',
        'title': '185 - MAJOR CHEST TRAUMA W-O CC-MCC',
        'key': '185'
      },
      {
        'value': '186',
        'title': '186 - PLEURAL EFFUSION W MCC',
        'key': '186'
      },
      {
        'value': '187',
        'title': '187 - PLEURAL EFFUSION W CC',
        'key': '187'
      },
      {
        'value': '188',
        'title': '188 - PLEURAL EFFUSION W-O CC-MCC',
        'key': '188'
      },
      {
        'value': '189',
        'title': '189 - PULMONARY EDEMA AND RESPIRATORY FAILURE',
        'key': '189'
      },
      {
        'value': '190',
        'title': '190 - CHRONIC OBSTRUCTIVE PULMONARY DISEASE W MCC',
        'key': '190'
      },
      {
        'value': '191',
        'title': '191 - CHRONIC OBSTRUCTIVE PULMONARY DISEASE W CC',
        'key': '191'
      },
      {
        'value': '192',
        'title': '192 - CHRONIC OBSTRUCTIVE PULMONARY DISEASE W-O CC-MCC',
        'key': '192'
      },
      {
        'value': '193',
        'title': '193 - SIMPLE PNEUMONIA AND PLEURISY W MCC',
        'key': '193'
      },
      {
        'value': '194',
        'title': '194 - SIMPLE PNEUMONIA AND PLEURISY W CC',
        'key': '194'
      },
      {
        'value': '195',
        'title': '195 - SIMPLE PNEUMONIA AND PLEURISY W-O CC-MCC',
        'key': '195'
      },
      {
        'value': '196',
        'title': '196 - INTERSTITIAL LUNG DISEASE W MCC',
        'key': '196'
      },
      {
        'value': '197',
        'title': '197 - INTERSTITIAL LUNG DISEASE W CC',
        'key': '197'
      },
      {
        'value': '198',
        'title': '198 - INTERSTITIAL LUNG DISEASE W-O CC-MCC',
        'key': '198'
      },
      {
        'value': '199',
        'title': '199 - PNEUMOTHORAX W MCC',
        'key': '199'
      },
      {
        'value': '200',
        'title': '200 - PNEUMOTHORAX W CC',
        'key': '200'
      },
      {
        'value': '201',
        'title': '201 - PNEUMOTHORAX W-O CC-MCC',
        'key': '201'
      },
      {
        'value': '202',
        'title': '202 - BRONCHITIS AND ASTHMA W CC-MCC',
        'key': '202'
      },
      {
        'value': '203',
        'title': '203 - BRONCHITIS AND ASTHMA W-O CC-MCC',
        'key': '203'
      },
      {
        'value': '204',
        'title': '204 - RESPIRATORY SIGNS AND SYMPTOMS',
        'key': '204'
      },
      {
        'value': '205',
        'title': '205 - OTHER RESPIRATORY SYSTEM DIAGNOSES W MCC',
        'key': '205'
      },
      {
        'value': '206',
        'title': '206 - OTHER RESPIRATORY SYSTEM DIAGNOSES W-O MCC',
        'key': '206'
      },
      {
        'value': '207',
        'title': '207 - RESPIRATORY SYSTEM DIAGNOSIS W VENTILATOR SUPPORT GREATER THAN 96 HOURS',
        'key': '207'
      },
      {
        'value': '208',
        'title': '208 - RESPIRATORY SYSTEM DIAGNOSIS W VENTILATOR SUPPORT LESS THAN =96 HOURS',
        'key': '208'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-5',
    'title': '5 - Diseases & Disorders of the Circulatory System',
    'value': 'mdc-5',
    'children': [
      {
        'value': '215',
        'title': '215 - OTHER HEART ASSIST SYSTEM IMPLANT',
        'key': '215'
      },
      {
        'value': '216',
        'title': '216 - CARDIAC VALVE AND OTH MAJ CARDIOTHORACIC PROC W CARD CATH W MCC',
        'key': '216'
      },
      {
        'value': '217',
        'title': '217 - CARDIAC VALVE AND OTH MAJ CARDIOTHORACIC PROC W CARD CATH W CC',
        'key': '217'
      },
      {
        'value': '218',
        'title': '218 - CARDIAC VALVE AND OTH MAJ CARDIOTHORACIC PROC W CARD CATH W-O CC-MCC',
        'key': '218'
      },
      {
        'value': '219',
        'title': '219 - CARDIAC VALVE AND OTH MAJ CARDIOTHORACIC PROC W-O CARD CATH W MCC',
        'key': '219'
      },
      {
        'value': '220',
        'title': '220 - CARDIAC VALVE AND OTH MAJ CARDIOTHORACIC PROC W-O CARD CATH W CC',
        'key': '220'
      },
      {
        'value': '221',
        'title': '221 - CARDIAC VALVE AND OTH MAJ CARDIOTHORACIC PROC W-O CARD CATH W-O CC-MCC',
        'key': '221'
      },
      {
        'value': '222',
        'title': '222 - CARDIAC DEFIB IMPLANT W CARDIAC CATH W AMI-HF-SHOCK W MCC',
        'key': '222'
      },
      {
        'value': '223',
        'title': '223 - CARDIAC DEFIB IMPLANT W CARDIAC CATH W AMI-HF-SHOCK W-O MCC',
        'key': '223'
      },
      {
        'value': '224',
        'title': '224 - CARDIAC DEFIB IMPLANT W CARDIAC CATH W-O AMI-HF-SHOCK W MCC',
        'key': '224'
      },
      {
        'value': '225',
        'title': '225 - CARDIAC DEFIB IMPLANT W CARDIAC CATH W-O AMI-HF-SHOCK W-O MCC',
        'key': '225'
      },
      {
        'value': '226',
        'title': '226 - CARDIAC DEFIBRILLATOR IMPLANT W-O CARDIAC CATH W MCC',
        'key': '226'
      },
      {
        'value': '227',
        'title': '227 - CARDIAC DEFIBRILLATOR IMPLANT W-O CARDIAC CATH W-O MCC',
        'key': '227'
      },
      {
        'value': '228',
        'title': '228 - OTHER CARDIOTHORACIC PROCEDURES W MCC',
        'key': '228'
      },
      {
        'value': '229',
        'title': '229 - OTHER CARDIOTHORACIC PROCEDURES W-O MCC',
        'key': '229'
      },
      {
        'value': '230',
        'title': '230 - OTHER CARDIOTHORACIC PROCEDURES W-O CC-MCC',
        'key': '230'
      },
      {
        'value': '231',
        'title': '231 - CORONARY BYPASS W PTCA W MCC',
        'key': '231'
      },
      {
        'value': '232',
        'title': '232 - CORONARY BYPASS W PTCA W-O MCC',
        'key': '232'
      },
      {
        'value': '233',
        'title': '233 - CORONARY BYPASS W CARDIAC CATH W MCC',
        'key': '233'
      },
      {
        'value': '234',
        'title': '234 - CORONARY BYPASS W CARDIAC CATH W-O MCC',
        'key': '234'
      },
      {
        'value': '235',
        'title': '235 - CORONARY BYPASS W-O CARDIAC CATH W MCC',
        'key': '235'
      },
      {
        'value': '236',
        'title': '236 - CORONARY BYPASS W-O CARDIAC CATH W-O MCC',
        'key': '236'
      },
      {
        'value': '237',
        'title': '237 - MAJOR CARDIOVASC PROCEDURES W MCC',
        'key': '237'
      },
      {
        'value': '238',
        'title': '238 - MAJOR CARDIOVASC PROCEDURES W-O MCC',
        'key': '238'
      },
      {
        'value': '239',
        'title': '239 - AMPUTATION FOR CIRC SYS DISORDERS EXC UPPER LIMB AND TOE W MCC',
        'key': '239'
      },
      {
        'value': '240',
        'title': '240 - AMPUTATION FOR CIRC SYS DISORDERS EXC UPPER LIMB AND TOE W CC',
        'key': '240'
      },
      {
        'value': '241',
        'title': '241 - AMPUTATION FOR CIRC SYS DISORDERS EXC UPPER LIMB AND TOE W-O CC-MCC',
        'key': '241'
      },
      {
        'value': '242',
        'title': '242 - PERMANENT CARDIAC PACEMAKER IMPLANT W MCC',
        'key': '242'
      },
      {
        'value': '243',
        'title': '243 - PERMANENT CARDIAC PACEMAKER IMPLANT W CC',
        'key': '243'
      },
      {
        'value': '244',
        'title': '244 - PERMANENT CARDIAC PACEMAKER IMPLANT W-O CC-MCC',
        'key': '244'
      },
      {
        'value': '245',
        'title': '245 - AICD GENERATOR PROCEDURES',
        'key': '245'
      },
      {
        'value': '246',
        'title': '246 - PERCUTANEOUS CARDIOVASCULAR PROCEDURES W DRUG-ELUTING STENT W MCC OR 4+ ARTERIES OR STENTS',
        'key': '246'
      },
      {
        'value': '247',
        'title': '247 - PERC CARDIOVASC PROC W DRUG-ELUTING STENT W-O MCC',
        'key': '247'
      },
      {
        'value': '248',
        'title': '248 - PERCUTANEOUS CARDIOVASCULAR PROCEDURES W NON-DRUG-ELUTING STENT W MCC OR 4+ ARTERIES OR STENTS',
        'key': '248'
      },
      {
        'value': '249',
        'title': '249 - PERC CARDIOVASC PROC W NON-DRUG-ELUTING STENT W-O MCC',
        'key': '249'
      },
      {
        'value': '250',
        'title': '250 - PERC CARDIOVASC PROC W-O CORONARY ARTERY STENT W MCC',
        'key': '250'
      },
      {
        'value': '251',
        'title': '251 - PERC CARDIOVASC PROC W-O CORONARY ARTERY STENT W-O MCC',
        'key': '251'
      },
      {
        'value': '252',
        'title': '252 - OTHER VASCULAR PROCEDURES W MCC',
        'key': '252'
      },
      {
        'value': '253',
        'title': '253 - OTHER VASCULAR PROCEDURES W CC',
        'key': '253'
      },
      {
        'value': '254',
        'title': '254 - OTHER VASCULAR PROCEDURES W-O CC-MCC',
        'key': '254'
      },
      {
        'value': '255',
        'title': '255 - UPPER LIMB AND TOE AMPUTATION FOR CIRC SYSTEM DISORDERS W MCC',
        'key': '255'
      },
      {
        'value': '256',
        'title': '256 - UPPER LIMB AND TOE AMPUTATION FOR CIRC SYSTEM DISORDERS W CC',
        'key': '256'
      },
      {
        'value': '257',
        'title': '257 - UPPER LIMB AND TOE AMPUTATION FOR CIRC SYSTEM DISORDERS W-O CC-MCC',
        'key': '257'
      },
      {
        'value': '258',
        'title': '258 - CARDIAC PACEMAKER DEVICE REPLACEMENT W MCC',
        'key': '258'
      },
      {
        'value': '259',
        'title': '259 - CARDIAC PACEMAKER DEVICE REPLACEMENT W-O MCC',
        'key': '259'
      },
      {
        'value': '260',
        'title': '260 - CARDIAC PACEMAKER REVISION EXCEPT DEVICE REPLACEMENT W MCC',
        'key': '260'
      },
      {
        'value': '261',
        'title': '261 - CARDIAC PACEMAKER REVISION EXCEPT DEVICE REPLACEMENT W CC',
        'key': '261'
      },
      {
        'value': '262',
        'title': '262 - CARDIAC PACEMAKER REVISION EXCEPT DEVICE REPLACEMENT W-O CC-MCC',
        'key': '262'
      },
      {
        'value': '263',
        'title': '263 - VEIN LIGATION AND STRIPPING',
        'key': '263'
      },
      {
        'value': '264',
        'title': '264 - OTHER CIRCULATORY SYSTEM O.R. PROCEDURES',
        'key': '264'
      },
      {
        'value': '265',
        'title': '265 - AICD LEAD PROCEDURES',
        'key': '265'
      },
      {
        'value': '266',
        'title': '266 - ENDOVASCULAR CARDIAC VALVE REPLACEMENT W MCC',
        'key': '266'
      },
      {
        'value': '267',
        'title': '267 - ENDOVASCULAR CARDIAC VALVE REPLACEMENT W-O MCC',
        'key': '267'
      },
      {
        'value': '268',
        'title': '268 - AORTIC AND HEART ASSIST PROCEDURES EXCEPT PULSATION BALLOON W MCC',
        'key': '268'
      },
      {
        'value': '269',
        'title': '269 - AORTIC AND HEART ASSIST PROCEDURES EXCEPT PULSATION BALLOON W-O MCC',
        'key': '269'
      },
      {
        'value': '270',
        'title': '270 - OTHER MAJOR CARDIOVASCULAR PROCEDURES W MCC',
        'key': '270'
      },
      {
        'value': '271',
        'title': '271 - OTHER MAJOR CARDIOVASCULAR PROCEDURES W CC',
        'key': '271'
      },
      {
        'value': '272',
        'title': '272 - OTHER MAJOR CARDIOVASCULAR PROCEDURES W-O CC-MCC',
        'key': '272'
      },
      {
        'value': '273',
        'title': '273 - PERCUTANEOUS INTRACARDIAC PROCEDURES W MCC',
        'key': '273'
      },
      {
        'value': '274',
        'title': '274 - PERCUTANEOUS INTRACARDIAC PROCEDURES W-O MCC',
        'key': '274'
      },
      {
        'value': '280',
        'title': '280 - ACUTE MYOCARDIAL INFARCTION DISCHARGED ALIVE W MCC',
        'key': '280'
      },
      {
        'value': '281',
        'title': '281 - ACUTE MYOCARDIAL INFARCTION DISCHARGED ALIVE W CC',
        'key': '281'
      },
      {
        'value': '282',
        'title': '282 - ACUTE MYOCARDIAL INFARCTION DISCHARGED ALIVE W-O CC-MCC',
        'key': '282'
      },
      {
        'value': '283',
        'title': '283 - ACUTE MYOCARDIAL INFARCTION EXPIRED W MCC',
        'key': '283'
      },
      {
        'value': '284',
        'title': '284 - ACUTE MYOCARDIAL INFARCTION EXPIRED W CC',
        'key': '284'
      },
      {
        'value': '285',
        'title': '285 - ACUTE MYOCARDIAL INFARCTION EXPIRED W-O CC-MCC',
        'key': '285'
      },
      {
        'value': '286',
        'title': '286 - CIRCULATORY DISORDERS EXCEPT AMI W CARD CATH W MCC',
        'key': '286'
      },
      {
        'value': '287',
        'title': '287 - CIRCULATORY DISORDERS EXCEPT AMI W CARD CATH W-O MCC',
        'key': '287'
      },
      {
        'value': '288',
        'title': '288 - ACUTE AND SUBACUTE ENDOCARDITIS W MCC',
        'key': '288'
      },
      {
        'value': '289',
        'title': '289 - ACUTE AND SUBACUTE ENDOCARDITIS W CC',
        'key': '289'
      },
      {
        'value': '290',
        'title': '290 - ACUTE AND SUBACUTE ENDOCARDITIS W-O CC-MCC',
        'key': '290'
      },
      {
        'value': '291',
        'title': '291 - HEART FAILURE AND SHOCK W MCC',
        'key': '291'
      },
      {
        'value': '292',
        'title': '292 - HEART FAILURE AND SHOCK W CC',
        'key': '292'
      },
      {
        'value': '293',
        'title': '293 - HEART FAILURE AND SHOCK W-O CC-MCC',
        'key': '293'
      },
      {
        'value': '294',
        'title': '294 - DEEP VEIN THROMBOPHLEBITIS W CC-MCC',
        'key': '294'
      },
      {
        'value': '295',
        'title': '295 - DEEP VEIN THROMBOPHLEBITIS W-O CC-MCC',
        'key': '295'
      },
      {
        'value': '296',
        'title': '296 - CARDIAC ARREST UNEXPLAINED W MCC',
        'key': '296'
      },
      {
        'value': '297',
        'title': '297 - CARDIAC ARREST UNEXPLAINED W CC',
        'key': '297'
      },
      {
        'value': '298',
        'title': '298 - CARDIAC ARREST UNEXPLAINED W-O CC-MCC',
        'key': '298'
      },
      {
        'value': '299',
        'title': '299 - PERIPHERAL VASCULAR DISORDERS W MCC',
        'key': '299'
      },
      {
        'value': '300',
        'title': '300 - PERIPHERAL VASCULAR DISORDERS W CC',
        'key': '300'
      },
      {
        'value': '301',
        'title': '301 - PERIPHERAL VASCULAR DISORDERS W-O CC-MCC',
        'key': '301'
      },
      {
        'value': '302',
        'title': '302 - ATHEROSCLEROSIS W MCC',
        'key': '302'
      },
      {
        'value': '303',
        'title': '303 - ATHEROSCLEROSIS W-O MCC',
        'key': '303'
      },
      {
        'value': '304',
        'title': '304 - HYPERTENSION W MCC',
        'key': '304'
      },
      {
        'value': '305',
        'title': '305 - HYPERTENSION W-O MCC',
        'key': '305'
      },
      {
        'value': '306',
        'title': '306 - CARDIAC CONGENITAL AND VALVULAR DISORDERS W MCC',
        'key': '306'
      },
      {
        'value': '307',
        'title': '307 - CARDIAC CONGENITAL AND VALVULAR DISORDERS W-O MCC',
        'key': '307'
      },
      {
        'value': '308',
        'title': '308 - CARDIAC ARRHYTHMIA AND CONDUCTION DISORDERS W MCC',
        'key': '308'
      },
      {
        'value': '309',
        'title': '309 - CARDIAC ARRHYTHMIA AND CONDUCTION DISORDERS W CC',
        'key': '309'
      },
      {
        'value': '310',
        'title': '310 - CARDIAC ARRHYTHMIA AND CONDUCTION DISORDERS W-O CC-MCC',
        'key': '310'
      },
      {
        'value': '311',
        'title': '311 - ANGINA PECTORIS',
        'key': '311'
      },
      {
        'value': '312',
        'title': '312 - SYNCOPE AND COLLAPSE',
        'key': '312'
      },
      {
        'value': '313',
        'title': '313 - CHEST PAIN',
        'key': '313'
      },
      {
        'value': '314',
        'title': '314 - OTHER CIRCULATORY SYSTEM DIAGNOSES W MCC',
        'key': '314'
      },
      {
        'value': '315',
        'title': '315 - OTHER CIRCULATORY SYSTEM DIAGNOSES W CC',
        'key': '315'
      },
      {
        'value': '316',
        'title': '316 - OTHER CIRCULATORY SYSTEM DIAGNOSES W-O CC-MCC',
        'key': '316'
      },
      {
        'value': '319',
        'title': '319 - OTHER ENDOVASCULAR CARDIAC VALVE PROCEDURES W MCC',
        'key': '319'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-6',
    'title': '6 - Diseases & Disorders of the Digestive System',
    'value': 'mdc-6',
    'children': [
      {
        'value': '326',
        'title': '326 - STOMACH ESOPHAGEAL AND DUODENAL PROC W MCC',
        'key': '326'
      },
      {
        'value': '327',
        'title': '327 - STOMACH ESOPHAGEAL AND DUODENAL PROC W CC',
        'key': '327'
      },
      {
        'value': '328',
        'title': '328 - STOMACH ESOPHAGEAL AND DUODENAL PROC W-O CC-MCC',
        'key': '328'
      },
      {
        'value': '329',
        'title': '329 - MAJOR SMALL AND LARGE BOWEL PROCEDURES W MCC',
        'key': '329'
      },
      {
        'value': '330',
        'title': '330 - MAJOR SMALL AND LARGE BOWEL PROCEDURES W CC',
        'key': '330'
      },
      {
        'value': '331',
        'title': '331 - MAJOR SMALL AND LARGE BOWEL PROCEDURES W-O CC-MCC',
        'key': '331'
      },
      {
        'value': '332',
        'title': '332 - RECTAL RESECTION W MCC',
        'key': '332'
      },
      {
        'value': '333',
        'title': '333 - RECTAL RESECTION W CC',
        'key': '333'
      },
      {
        'value': '334',
        'title': '334 - RECTAL RESECTION W-O CC-MCC',
        'key': '334'
      },
      {
        'value': '335',
        'title': '335 - PERITONEAL ADHESIOLYSIS W MCC',
        'key': '335'
      },
      {
        'value': '336',
        'title': '336 - PERITONEAL ADHESIOLYSIS W CC',
        'key': '336'
      },
      {
        'value': '337',
        'title': '337 - PERITONEAL ADHESIOLYSIS W-O CC-MCC',
        'key': '337'
      },
      {
        'value': '338',
        'title': '338 - APPENDECTOMY W COMPLICATED PRINCIPAL DIAG W MCC',
        'key': '338'
      },
      {
        'value': '339',
        'title': '339 - APPENDECTOMY W COMPLICATED PRINCIPAL DIAG W CC',
        'key': '339'
      },
      {
        'value': '340',
        'title': '340 - APPENDECTOMY W COMPLICATED PRINCIPAL DIAG W-O CC-MCC',
        'key': '340'
      },
      {
        'value': '341',
        'title': '341 - APPENDECTOMY W-O COMPLICATED PRINCIPAL DIAG W MCC',
        'key': '341'
      },
      {
        'value': '342',
        'title': '342 - APPENDECTOMY W-O COMPLICATED PRINCIPAL DIAG W CC',
        'key': '342'
      },
      {
        'value': '343',
        'title': '343 - APPENDECTOMY W-O COMPLICATED PRINCIPAL DIAG W-O CC-MCC',
        'key': '343'
      },
      {
        'value': '344',
        'title': '344 - MINOR SMALL AND LARGE BOWEL PROCEDURES W MCC',
        'key': '344'
      },
      {
        'value': '345',
        'title': '345 - MINOR SMALL AND LARGE BOWEL PROCEDURES W CC',
        'key': '345'
      },
      {
        'value': '346',
        'title': '346 - MINOR SMALL AND LARGE BOWEL PROCEDURES W-O CC-MCC',
        'key': '346'
      },
      {
        'value': '347',
        'title': '347 - ANAL AND STOMAL PROCEDURES W MCC',
        'key': '347'
      },
      {
        'value': '348',
        'title': '348 - ANAL AND STOMAL PROCEDURES W CC',
        'key': '348'
      },
      {
        'value': '349',
        'title': '349 - ANAL AND STOMAL PROCEDURES W-O CC-MCC',
        'key': '349'
      },
      {
        'value': '350',
        'title': '350 - INGUINAL AND FEMORAL HERNIA PROCEDURES W MCC',
        'key': '350'
      },
      {
        'value': '351',
        'title': '351 - INGUINAL AND FEMORAL HERNIA PROCEDURES W CC',
        'key': '351'
      },
      {
        'value': '352',
        'title': '352 - INGUINAL AND FEMORAL HERNIA PROCEDURES W-O CC-MCC',
        'key': '352'
      },
      {
        'value': '353',
        'title': '353 - HERNIA PROCEDURES EXCEPT INGUINAL AND FEMORAL W MCC',
        'key': '353'
      },
      {
        'value': '354',
        'title': '354 - HERNIA PROCEDURES EXCEPT INGUINAL AND FEMORAL W CC',
        'key': '354'
      },
      {
        'value': '355',
        'title': '355 - HERNIA PROCEDURES EXCEPT INGUINAL AND FEMORAL W-O CC-MCC',
        'key': '355'
      },
      {
        'value': '356',
        'title': '356 - OTHER DIGESTIVE SYSTEM O.R. PROCEDURES W MCC',
        'key': '356'
      },
      {
        'value': '357',
        'title': '357 - OTHER DIGESTIVE SYSTEM O.R. PROCEDURES W CC',
        'key': '357'
      },
      {
        'value': '358',
        'title': '358 - OTHER DIGESTIVE SYSTEM O.R. PROCEDURES W-O CC-MCC',
        'key': '358'
      },
      {
        'value': '368',
        'title': '368 - MAJOR ESOPHAGEAL DISORDERS W MCC',
        'key': '368'
      },
      {
        'value': '369',
        'title': '369 - MAJOR ESOPHAGEAL DISORDERS W CC',
        'key': '369'
      },
      {
        'value': '370',
        'title': '370 - MAJOR ESOPHAGEAL DISORDERS W-O CC-MCC',
        'key': '370'
      },
      {
        'value': '371',
        'title': '371 - MAJOR GASTROINTESTINAL DISORDERS AND PERITONEAL INFECTIONS W MCC',
        'key': '371'
      },
      {
        'value': '372',
        'title': '372 - MAJOR GASTROINTESTINAL DISORDERS AND PERITONEAL INFECTIONS W CC',
        'key': '372'
      },
      {
        'value': '373',
        'title': '373 - MAJOR GASTROINTESTINAL DISORDERS AND PERITONEAL INFECTIONS W-O CC-MCC',
        'key': '373'
      },
      {
        'value': '374',
        'title': '374 - DIGESTIVE MALIGNANCY W MCC',
        'key': '374'
      },
      {
        'value': '375',
        'title': '375 - DIGESTIVE MALIGNANCY W CC',
        'key': '375'
      },
      {
        'value': '376',
        'title': '376 - DIGESTIVE MALIGNANCY W-O CC-MCC',
        'key': '376'
      },
      {
        'value': '377',
        'title': '377 - G.I. HEMORRHAGE W MCC',
        'key': '377'
      },
      {
        'value': '378',
        'title': '378 - G.I. HEMORRHAGE W CC',
        'key': '378'
      },
      {
        'value': '379',
        'title': '379 - G.I. HEMORRHAGE W-O CC-MCC',
        'key': '379'
      },
      {
        'value': '380',
        'title': '380 - COMPLICATED PEPTIC ULCER W MCC',
        'key': '380'
      },
      {
        'value': '381',
        'title': '381 - COMPLICATED PEPTIC ULCER W CC',
        'key': '381'
      },
      {
        'value': '382',
        'title': '382 - COMPLICATED PEPTIC ULCER W-O CC-MCC',
        'key': '382'
      },
      {
        'value': '383',
        'title': '383 - UNCOMPLICATED PEPTIC ULCER W MCC',
        'key': '383'
      },
      {
        'value': '384',
        'title': '384 - UNCOMPLICATED PEPTIC ULCER W-O MCC',
        'key': '384'
      },
      {
        'value': '385',
        'title': '385 - INFLAMMATORY BOWEL DISEASE W MCC',
        'key': '385'
      },
      {
        'value': '386',
        'title': '386 - INFLAMMATORY BOWEL DISEASE W CC',
        'key': '386'
      },
      {
        'value': '387',
        'title': '387 - INFLAMMATORY BOWEL DISEASE W-O CC-MCC',
        'key': '387'
      },
      {
        'value': '388',
        'title': '388 - G.I. OBSTRUCTION W MCC',
        'key': '388'
      },
      {
        'value': '389',
        'title': '389 - G.I. OBSTRUCTION W CC',
        'key': '389'
      },
      {
        'value': '390',
        'title': '390 - G.I. OBSTRUCTION W-O CC-MCC',
        'key': '390'
      },
      {
        'value': '391',
        'title': '391 - ESOPHAGITIS GASTROENT AND MISC DIGEST DISORDERS W MCC',
        'key': '391'
      },
      {
        'value': '392',
        'title': '392 - ESOPHAGITIS GASTROENT AND MISC DIGEST DISORDERS W-O MCC',
        'key': '392'
      },
      {
        'value': '393',
        'title': '393 - OTHER DIGESTIVE SYSTEM DIAGNOSES W MCC',
        'key': '393'
      },
      {
        'value': '394',
        'title': '394 - OTHER DIGESTIVE SYSTEM DIAGNOSES W CC',
        'key': '394'
      },
      {
        'value': '395',
        'title': '395 - OTHER DIGESTIVE SYSTEM DIAGNOSES W-O CC-MCC',
        'key': '395'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-7',
    'title': '7 - Diseases & Disorders of the Hepatobiliary System & Pancreas',
    'value': 'mdc-7',
    'children': [
      {
        'value': '405',
        'title': '405 - PANCREAS LIVER AND SHUNT PROCEDURES W MCC',
        'key': '405'
      },
      {
        'value': '406',
        'title': '406 - PANCREAS LIVER AND SHUNT PROCEDURES W CC',
        'key': '406'
      },
      {
        'value': '407',
        'title': '407 - PANCREAS LIVER AND SHUNT PROCEDURES W-O CC-MCC',
        'key': '407'
      },
      {
        'value': '408',
        'title': '408 - BILIARY TRACT PROC EXCEPT ONLY CHOLECYST W OR W-O C.D.E. W MCC',
        'key': '408'
      },
      {
        'value': '409',
        'title': '409 - BILIARY TRACT PROC EXCEPT ONLY CHOLECYST W OR W-O C.D.E. W CC',
        'key': '409'
      },
      {
        'value': '410',
        'title': '410 - BILIARY TRACT PROC EXCEPT ONLY CHOLECYST W OR W-O C.D.E. W-O CC-MCC',
        'key': '410'
      },
      {
        'value': '411',
        'title': '411 - CHOLECYSTECTOMY W C.D.E. W MCC',
        'key': '411'
      },
      {
        'value': '412',
        'title': '412 - CHOLECYSTECTOMY W C.D.E. W CC',
        'key': '412'
      },
      {
        'value': '413',
        'title': '413 - CHOLECYSTECTOMY W C.D.E. W-O CC-MCC',
        'key': '413'
      },
      {
        'value': '414',
        'title': '414 - CHOLECYSTECTOMY EXCEPT BY LAPAROSCOPE W-O C.D.E. W MCC',
        'key': '414'
      },
      {
        'value': '415',
        'title': '415 - CHOLECYSTECTOMY EXCEPT BY LAPAROSCOPE W-O C.D.E. W CC',
        'key': '415'
      },
      {
        'value': '416',
        'title': '416 - CHOLECYSTECTOMY EXCEPT BY LAPAROSCOPE W-O C.D.E. W-O CC-MCC',
        'key': '416'
      },
      {
        'value': '417',
        'title': '417 - LAPAROSCOPIC CHOLECYSTECTOMY W-O C.D.E. W MCC',
        'key': '417'
      },
      {
        'value': '418',
        'title': '418 - LAPAROSCOPIC CHOLECYSTECTOMY W-O C.D.E. W CC',
        'key': '418'
      },
      {
        'value': '419',
        'title': '419 - LAPAROSCOPIC CHOLECYSTECTOMY W-O C.D.E. W-O CC-MCC',
        'key': '419'
      },
      {
        'value': '420',
        'title': '420 - HEPATOBILIARY DIAGNOSTIC PROCEDURES W MCC',
        'key': '420'
      },
      {
        'value': '421',
        'title': '421 - HEPATOBILIARY DIAGNOSTIC PROCEDURES W CC',
        'key': '421'
      },
      {
        'value': '422',
        'title': '422 - HEPATOBILIARY DIAGNOSTIC PROCEDURES W-O CC-MCC',
        'key': '422'
      },
      {
        'value': '423',
        'title': '423 - OTHER HEPATOBILIARY OR PANCREAS O.R. PROCEDURES W MCC',
        'key': '423'
      },
      {
        'value': '424',
        'title': '424 - OTHER HEPATOBILIARY OR PANCREAS O.R. PROCEDURES W CC',
        'key': '424'
      },
      {
        'value': '425',
        'title': '425 - OTHER HEPATOBILIARY OR PANCREAS O.R. PROCEDURES W-O CC-MCC',
        'key': '425'
      },
      {
        'value': '432',
        'title': '432 - CIRRHOSIS AND ALCOHOLIC HEPATITIS W MCC',
        'key': '432'
      },
      {
        'value': '433',
        'title': '433 - CIRRHOSIS AND ALCOHOLIC HEPATITIS W CC',
        'key': '433'
      },
      {
        'value': '434',
        'title': '434 - CIRRHOSIS AND ALCOHOLIC HEPATITIS W-O CC-MCC',
        'key': '434'
      },
      {
        'value': '435',
        'title': '435 - MALIGNANCY OF HEPATOBILIARY SYSTEM OR PANCREAS W MCC',
        'key': '435'
      },
      {
        'value': '436',
        'title': '436 - MALIGNANCY OF HEPATOBILIARY SYSTEM OR PANCREAS W CC',
        'key': '436'
      },
      {
        'value': '437',
        'title': '437 - MALIGNANCY OF HEPATOBILIARY SYSTEM OR PANCREAS W-O CC-MCC',
        'key': '437'
      },
      {
        'value': '438',
        'title': '438 - DISORDERS OF PANCREAS EXCEPT MALIGNANCY W MCC',
        'key': '438'
      },
      {
        'value': '439',
        'title': '439 - DISORDERS OF PANCREAS EXCEPT MALIGNANCY W CC',
        'key': '439'
      },
      {
        'value': '440',
        'title': '440 - DISORDERS OF PANCREAS EXCEPT MALIGNANCY W-O CC-MCC',
        'key': '440'
      },
      {
        'value': '441',
        'title': '441 - DISORDERS OF LIVER EXCEPT MALIGCIRRALC HEPA W MCC',
        'key': '441'
      },
      {
        'value': '442',
        'title': '442 - DISORDERS OF LIVER EXCEPT MALIGCIRRALC HEPA W CC',
        'key': '442'
      },
      {
        'value': '443',
        'title': '443 - DISORDERS OF LIVER EXCEPT MALIGCIRRALC HEPA W-O CC-MCC',
        'key': '443'
      },
      {
        'value': '444',
        'title': '444 - DISORDERS OF THE BILIARY TRACT W MCC',
        'key': '444'
      },
      {
        'value': '445',
        'title': '445 - DISORDERS OF THE BILIARY TRACT W CC',
        'key': '445'
      },
      {
        'value': '446',
        'title': '446 - DISORDERS OF THE BILIARY TRACT W-O CC-MCC',
        'key': '446'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-8',
    'title': '8 - Diseases & Disorders of the Musculoskeletal System & Connective Tissue',
    'value': 'mdc-8',
    'children': [
      {
        'value': '453',
        'title': '453 - COMBINED ANTERIOR-POSTERIOR SPINAL FUSION W MCC',
        'key': '453'
      },
      {
        'value': '454',
        'title': '454 - COMBINED ANTERIOR-POSTERIOR SPINAL FUSION W CC',
        'key': '454'
      },
      {
        'value': '455',
        'title': '455 - COMBINED ANTERIOR-POSTERIOR SPINAL FUSION W-O CC-MCC',
        'key': '455'
      },
      {
        'value': '456',
        'title': '456 - SPINAL FUS EXC CERV W SPINAL CURV-MALIG-INFEC OR EXT FUS W MCC',
        'key': '456'
      },
      {
        'value': '457',
        'title': '457 - SPINAL FUS EXC CERV W SPINAL CURV-MALIG-INFEC OR EXT FUS W CC',
        'key': '457'
      },
      {
        'value': '458',
        'title': '458 - SPINAL FUS EXC CERV W SPINAL CURV-MALIG-INFEC OR EXT FUS W-O CC-MCC',
        'key': '458'
      },
      {
        'value': '459',
        'title': '459 - SPINAL FUSION EXCEPT CERVICAL W MCC',
        'key': '459'
      },
      {
        'value': '460',
        'title': '460 - SPINAL FUSION EXCEPT CERVICAL W-O MCC',
        'key': '460'
      },
      {
        'value': '461',
        'title': '461 - BILATERAL OR MULTIPLE MAJOR JOINT PROCS OF LOWER EXTREMITY W MCC',
        'key': '461'
      },
      {
        'value': '462',
        'title': '462 - BILATERAL OR MULTIPLE MAJOR JOINT PROCS OF LOWER EXTREMITY W-O MCC',
        'key': '462'
      },
      {
        'value': '463',
        'title': '463 - WND DEBRID AND SKN GRFT EXC HAND FOR MUSCULO-CONN TISS DIS W MCC',
        'key': '463'
      },
      {
        'value': '464',
        'title': '464 - WND DEBRID AND SKN GRFT EXC HAND FOR MUSCULO-CONN TISS DIS W CC',
        'key': '464'
      },
      {
        'value': '465',
        'title': '465 - WND DEBRID AND SKN GRFT EXC HAND FOR MUSCULO-CONN TISS DIS W-O CC-MCC',
        'key': '465'
      },
      {
        'value': '466',
        'title': '466 - REVISION OF HIP OR KNEE REPLACEMENT W MCC',
        'key': '466'
      },
      {
        'value': '467',
        'title': '467 - REVISION OF HIP OR KNEE REPLACEMENT W CC',
        'key': '467'
      },
      {
        'value': '468',
        'title': '468 - REVISION OF HIP OR KNEE REPLACEMENT W-O CC-MCC',
        'key': '468'
      },
      {
        'value': '469',
        'title': '469 - MAJOR HIP AND KNEE JOINT REPLACEMENT OR REATTACHMENT OF LOWER EXTREMITY W MCC OR TOTAL ANKLE REPLACEMENT',
        'key': '469'
      },
      {
        'value': '470',
        'title': '470 - MAJOR HIP AND KNEE JOINT REPLACEMENT OR REATTACHMENT OF LOWER EXTREMITY W-O MCC',
        'key': '470'
      },
      {
        'value': '471',
        'title': '471 - CERVICAL SPINAL FUSION W MCC',
        'key': '471'
      },
      {
        'value': '472',
        'title': '472 - CERVICAL SPINAL FUSION W CC',
        'key': '472'
      },
      {
        'value': '473',
        'title': '473 - CERVICAL SPINAL FUSION W-O CC-MCC',
        'key': '473'
      },
      {
        'value': '474',
        'title': '474 - AMPUTATION FOR MUSCULOSKELETAL SYS AND CONN TISSUE DIS W MCC',
        'key': '474'
      },
      {
        'value': '475',
        'title': '475 - AMPUTATION FOR MUSCULOSKELETAL SYS AND CONN TISSUE DIS W CC',
        'key': '475'
      },
      {
        'value': '476',
        'title': '476 - AMPUTATION FOR MUSCULOSKELETAL SYS AND CONN TISSUE DIS W-O CC-MCC',
        'key': '476'
      },
      {
        'value': '477',
        'title': '477 - BIOPSIES OF MUSCULOSKELETAL SYSTEM AND CONNECTIVE TISSUE W MCC',
        'key': '477'
      },
      {
        'value': '478',
        'title': '478 - BIOPSIES OF MUSCULOSKELETAL SYSTEM AND CONNECTIVE TISSUE W CC',
        'key': '478'
      },
      {
        'value': '479',
        'title': '479 - BIOPSIES OF MUSCULOSKELETAL SYSTEM AND CONNECTIVE TISSUE W-O CC-MCC',
        'key': '479'
      },
      {
        'value': '480',
        'title': '480 - HIP AND FEMUR PROCEDURES EXCEPT MAJOR JOINT W MCC',
        'key': '480'
      },
      {
        'value': '481',
        'title': '481 - HIP AND FEMUR PROCEDURES EXCEPT MAJOR JOINT W CC',
        'key': '481'
      },
      {
        'value': '482',
        'title': '482 - HIP AND FEMUR PROCEDURES EXCEPT MAJOR JOINT W-O CC-MCC',
        'key': '482'
      },
      {
        'value': '483',
        'title': '483 - MAJOR JOINT-LIMB REATTACHMENT PROCEDURE OF UPPER EXTREMITIES',
        'key': '483'
      },
      {
        'value': '484',
        'title': '484 - MAJOR JOINT LIMB REATTACHMENT PROC OF UPPER EXTREMITY W-O CC-MCC',
        'key': '484'
      },
      {
        'value': '485',
        'title': '485 - KNEE PROCEDURES W PDX OF INFECTION W MCC',
        'key': '485'
      },
      {
        'value': '486',
        'title': '486 - KNEE PROCEDURES W PDX OF INFECTION W CC',
        'key': '486'
      },
      {
        'value': '487',
        'title': '487 - KNEE PROCEDURES W PDX OF INFECTION W-O CC-MCC',
        'key': '487'
      },
      {
        'value': '488',
        'title': '488 - KNEE PROCEDURES W-O PDX OF INFECTION W CC-MCC',
        'key': '488'
      },
      {
        'value': '489',
        'title': '489 - KNEE PROCEDURES W-O PDX OF INFECTION W-O CC-MCC',
        'key': '489'
      },
      {
        'value': '490',
        'title': '490 - BACK NECK PROC EXC SPINAL FUSION W CC-MCC OR DISC DEVICE-NEUROSTIM',
        'key': '490'
      },
      {
        'value': '491',
        'title': '491 - BACK NECK PROC EXC SPINAL FUSION W-O CC-MCC',
        'key': '491'
      },
      {
        'value': '492',
        'title': '492 - LOWER EXTREM AND HUMER PROC EXCEPT HIPFOOTFEMUR W MCC',
        'key': '492'
      },
      {
        'value': '493',
        'title': '493 - LOWER EXTREM AND HUMER PROC EXCEPT HIPFOOTFEMUR W CC',
        'key': '493'
      },
      {
        'value': '494',
        'title': '494 - LOWER EXTREM AND HUMER PROC EXCEPT HIPFOOTFEMUR W-O CC-MCC',
        'key': '494'
      },
      {
        'value': '495',
        'title': '495 - LOCAL EXCISION AND REMOVAL INT FIX DEVICES EXC HIP AND FEMUR W MCC',
        'key': '495'
      },
      {
        'value': '496',
        'title': '496 - LOCAL EXCISION AND REMOVAL INT FIX DEVICES EXC HIP AND FEMUR W CC',
        'key': '496'
      },
      {
        'value': '497',
        'title': '497 - LOCAL EXCISION AND REMOVAL INT FIX DEVICES EXC HIP AND FEMUR W-O CC-MCC',
        'key': '497'
      },
      {
        'value': '498',
        'title': '498 - LOCAL EXCISION AND REMOVAL INT FIX DEVICES OF HIP AND FEMUR W CC-MCC',
        'key': '498'
      },
      {
        'value': '499',
        'title': '499 - LOCAL EXCISION AND REMOVAL INT FIX DEVICES OF HIP AND FEMUR W-O CC-MCC',
        'key': '499'
      },
      {
        'value': '500',
        'title': '500 - SOFT TISSUE PROCEDURES W MCC',
        'key': '500'
      },
      {
        'value': '501',
        'title': '501 - SOFT TISSUE PROCEDURES W CC',
        'key': '501'
      },
      {
        'value': '502',
        'title': '502 - SOFT TISSUE PROCEDURES W-O CC-MCC',
        'key': '502'
      },
      {
        'value': '503',
        'title': '503 - FOOT PROCEDURES W MCC',
        'key': '503'
      },
      {
        'value': '504',
        'title': '504 - FOOT PROCEDURES W CC',
        'key': '504'
      },
      {
        'value': '505',
        'title': '505 - FOOT PROCEDURES W-O CC-MCC',
        'key': '505'
      },
      {
        'value': '506',
        'title': '506 - MAJOR THUMB OR JOINT PROCEDURES',
        'key': '506'
      },
      {
        'value': '507',
        'title': '507 - MAJOR SHOULDER OR ELBOW JOINT PROCEDURES W CC-MCC',
        'key': '507'
      },
      {
        'value': '508',
        'title': '508 - MAJOR SHOULDER OR ELBOW JOINT PROCEDURES W-O CC-MCC',
        'key': '508'
      },
      {
        'value': '509',
        'title': '509 - ARTHROSCOPY',
        'key': '509'
      },
      {
        'value': '510',
        'title': '510 - SHOULDERELBOW OR FOREARM PROCEXC MAJOR JOINT PROC W MCC',
        'key': '510'
      },
      {
        'value': '511',
        'title': '511 - SHOULDERELBOW OR FOREARM PROCEXC MAJOR JOINT PROC W CC',
        'key': '511'
      },
      {
        'value': '512',
        'title': '512 - SHOULDERELBOW OR FOREARM PROCEXC MAJOR JOINT PROC W-O CC-MCC',
        'key': '512'
      },
      {
        'value': '513',
        'title': '513 - HAND OR WRIST PROC EXCEPT MAJOR THUMB OR JOINT PROC W CC-MCC',
        'key': '513'
      },
      {
        'value': '514',
        'title': '514 - HAND OR WRIST PROC EXCEPT MAJOR THUMB OR JOINT PROC W-O CC-MCC',
        'key': '514'
      },
      {
        'value': '515',
        'title': '515 - OTHER MUSCULOSKELET SYS AND CONN TISS O.R. PROC W MCC',
        'key': '515'
      },
      {
        'value': '516',
        'title': '516 - OTHER MUSCULOSKELET SYS AND CONN TISS O.R. PROC W CC',
        'key': '516'
      },
      {
        'value': '517',
        'title': '517 - OTHER MUSCULOSKELET SYS AND CONN TISS O.R. PROC W-O CC-MCC',
        'key': '517'
      },
      {
        'value': '518',
        'title': '518 - BACK AND NECK PROC EXC SPINAL FUSION W MCC OR DISC DEVICE-NEUROSTIM',
        'key': '518'
      },
      {
        'value': '519',
        'title': '519 - BACK AND NECK PROC EXC SPINAL FUSION W CC',
        'key': '519'
      },
      {
        'value': '520',
        'title': '520 - BACK AND NECK PROC EXC SPINAL FUSION W-O CC-MCC',
        'key': '520'
      },
      {
        'value': '533',
        'title': '533 - FRACTURES OF FEMUR W MCC',
        'key': '533'
      },
      {
        'value': '534',
        'title': '534 - FRACTURES OF FEMUR W-O MCC',
        'key': '534'
      },
      {
        'value': '535',
        'title': '535 - FRACTURES OF HIP AND PELVIS W MCC',
        'key': '535'
      },
      {
        'value': '536',
        'title': '536 - FRACTURES OF HIP AND PELVIS W-O MCC',
        'key': '536'
      },
      {
        'value': '537',
        'title': '537 - SPRAINS STRAINS AND DISLOCATIONS OF HIP PELVIS AND THIGH W CC-MCC',
        'key': '537'
      },
      {
        'value': '538',
        'title': '538 - SPRAINS STRAINS AND DISLOCATIONS OF HIP PELVIS AND THIGH W-O CC-MCC',
        'key': '538'
      },
      {
        'value': '539',
        'title': '539 - OSTEOMYELITIS W MCC',
        'key': '539'
      },
      {
        'value': '540',
        'title': '540 - OSTEOMYELITIS W CC',
        'key': '540'
      },
      {
        'value': '541',
        'title': '541 - OSTEOMYELITIS W-O CC-MCC',
        'key': '541'
      },
      {
        'value': '542',
        'title': '542 - PATHOLOGICAL FRACTURES AND MUSCULOSKELET AND CONN TISS MALIG W MCC',
        'key': '542'
      },
      {
        'value': '543',
        'title': '543 - PATHOLOGICAL FRACTURES AND MUSCULOSKELET AND CONN TISS MALIG W CC',
        'key': '543'
      },
      {
        'value': '544',
        'title': '544 - PATHOLOGICAL FRACTURES AND MUSCULOSKELET AND CONN TISS MALIG W-O CC-MCC',
        'key': '544'
      },
      {
        'value': '545',
        'title': '545 - CONNECTIVE TISSUE DISORDERS W MCC',
        'key': '545'
      },
      {
        'value': '546',
        'title': '546 - CONNECTIVE TISSUE DISORDERS W CC',
        'key': '546'
      },
      {
        'value': '547',
        'title': '547 - CONNECTIVE TISSUE DISORDERS W-O CC-MCC',
        'key': '547'
      },
      {
        'value': '548',
        'title': '548 - SEPTIC ARTHRITIS W MCC',
        'key': '548'
      },
      {
        'value': '549',
        'title': '549 - SEPTIC ARTHRITIS W CC',
        'key': '549'
      },
      {
        'value': '550',
        'title': '550 - SEPTIC ARTHRITIS W-O CC-MCC',
        'key': '550'
      },
      {
        'value': '551',
        'title': '551 - MEDICAL BACK PROBLEMS W MCC',
        'key': '551'
      },
      {
        'value': '552',
        'title': '552 - MEDICAL BACK PROBLEMS W-O MCC',
        'key': '552'
      },
      {
        'value': '553',
        'title': '553 - BONE DISEASES AND ARTHROPATHIES W MCC',
        'key': '553'
      },
      {
        'value': '554',
        'title': '554 - BONE DISEASES AND ARTHROPATHIES W-O MCC',
        'key': '554'
      },
      {
        'value': '555',
        'title': '555 - SIGNS AND SYMPTOMS OF MUSCULOSKELETAL SYSTEM AND CONN TISSUE W MCC',
        'key': '555'
      },
      {
        'value': '556',
        'title': '556 - SIGNS AND SYMPTOMS OF MUSCULOSKELETAL SYSTEM AND CONN TISSUE W-O MCC',
        'key': '556'
      },
      {
        'value': '557',
        'title': '557 - TENDONITIS MYOSITIS AND BURSITIS W MCC',
        'key': '557'
      },
      {
        'value': '558',
        'title': '558 - TENDONITIS MYOSITIS AND BURSITIS W-O MCC',
        'key': '558'
      },
      {
        'value': '559',
        'title': '559 - AFTERCARE MUSCULOSKELETAL SYSTEM AND CONNECTIVE TISSUE W MCC',
        'key': '559'
      },
      {
        'value': '560',
        'title': '560 - AFTERCARE MUSCULOSKELETAL SYSTEM AND CONNECTIVE TISSUE W CC',
        'key': '560'
      },
      {
        'value': '561',
        'title': '561 - AFTERCARE MUSCULOSKELETAL SYSTEM AND CONNECTIVE TISSUE W-O CC-MCC',
        'key': '561'
      },
      {
        'value': '562',
        'title': '562 - FX SPRN STRN AND DISL EXCEPT FEMUR HIP PELVIS AND THIGH W MCC',
        'key': '562'
      },
      {
        'value': '563',
        'title': '563 - FX SPRN STRN AND DISL EXCEPT FEMUR HIP PELVIS AND THIGH W-O MCC',
        'key': '563'
      },
      {
        'value': '564',
        'title': '564 - OTHER MUSCULOSKELETAL SYS AND CONNECTIVE TISSUE DIAGNOSES W MCC',
        'key': '564'
      },
      {
        'value': '565',
        'title': '565 - OTHER MUSCULOSKELETAL SYS AND CONNECTIVE TISSUE DIAGNOSES W CC',
        'key': '565'
      },
      {
        'value': '566',
        'title': '566 - OTHER MUSCULOSKELETAL SYS AND CONNECTIVE TISSUE DIAGNOSES W-O CC-MCC',
        'key': '566'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-9',
    'title': '9 - Diseases & Disorders of the Skin, Subcutaneous Tissue & Breast',
    'value': 'mdc-9',
    'children': [
      {
        'value': '570',
        'title': '570 - SKIN DEBRIDEMENT W MCC',
        'key': '570'
      },
      {
        'value': '571',
        'title': '571 - SKIN DEBRIDEMENT W CC',
        'key': '571'
      },
      {
        'value': '572',
        'title': '572 - SKIN DEBRIDEMENT W-O CC-MCC',
        'key': '572'
      },
      {
        'value': '573',
        'title': '573 - SKIN GRAFT FOR SKIN ULCER OR CELLULITIS W MCC',
        'key': '573'
      },
      {
        'value': '574',
        'title': '574 - SKIN GRAFT FOR SKIN ULCER OR CELLULITIS W CC',
        'key': '574'
      },
      {
        'value': '575',
        'title': '575 - SKIN GRAFT FOR SKIN ULCER OR CELLULITIS W-O CC-MCC',
        'key': '575'
      },
      {
        'value': '576',
        'title': '576 - SKIN GRAFT EXC FOR SKIN ULCER OR CELLULITIS W MCC',
        'key': '576'
      },
      {
        'value': '577',
        'title': '577 - SKIN GRAFT EXC FOR SKIN ULCER OR CELLULITIS W CC',
        'key': '577'
      },
      {
        'value': '578',
        'title': '578 - SKIN GRAFT EXC FOR SKIN ULCER OR CELLULITIS W-O CC-MCC',
        'key': '578'
      },
      {
        'value': '579',
        'title': '579 - OTHER SKIN SUBCUT TISS AND BREAST PROC W MCC',
        'key': '579'
      },
      {
        'value': '580',
        'title': '580 - OTHER SKIN SUBCUT TISS AND BREAST PROC W CC',
        'key': '580'
      },
      {
        'value': '581',
        'title': '581 - OTHER SKIN SUBCUT TISS AND BREAST PROC W-O CC-MCC',
        'key': '581'
      },
      {
        'value': '582',
        'title': '582 - MASTECTOMY FOR MALIGNANCY W CC-MCC',
        'key': '582'
      },
      {
        'value': '583',
        'title': '583 - MASTECTOMY FOR MALIGNANCY W-O CC-MCC',
        'key': '583'
      },
      {
        'value': '584',
        'title': '584 - BREAST BIOPSY LOCAL EXCISION AND OTHER BREAST PROCEDURES W CC-MCC',
        'key': '584'
      },
      {
        'value': '585',
        'title': '585 - BREAST BIOPSY LOCAL EXCISION AND OTHER BREAST PROCEDURES W-O CC-MCC',
        'key': '585'
      },
      {
        'value': '592',
        'title': '592 - SKIN ULCERS W MCC',
        'key': '592'
      },
      {
        'value': '593',
        'title': '593 - SKIN ULCERS W CC',
        'key': '593'
      },
      {
        'value': '594',
        'title': '594 - SKIN ULCERS W-O CC-MCC',
        'key': '594'
      },
      {
        'value': '595',
        'title': '595 - MAJOR SKIN DISORDERS W MCC',
        'key': '595'
      },
      {
        'value': '596',
        'title': '596 - MAJOR SKIN DISORDERS W-O MCC',
        'key': '596'
      },
      {
        'value': '597',
        'title': '597 - MALIGNANT BREAST DISORDERS W MCC',
        'key': '597'
      },
      {
        'value': '598',
        'title': '598 - MALIGNANT BREAST DISORDERS W CC',
        'key': '598'
      },
      {
        'value': '599',
        'title': '599 - MALIGNANT BREAST DISORDERS W-O CC-MCC',
        'key': '599'
      },
      {
        'value': '600',
        'title': '600 - NON-MALIGNANT BREAST DISORDERS W CC-MCC',
        'key': '600'
      },
      {
        'value': '601',
        'title': '601 - NON-MALIGNANT BREAST DISORDERS W-O CC-MCC',
        'key': '601'
      },
      {
        'value': '602',
        'title': '602 - CELLULITIS W MCC',
        'key': '602'
      },
      {
        'value': '603',
        'title': '603 - CELLULITIS W-O MCC',
        'key': '603'
      },
      {
        'value': '604',
        'title': '604 - TRAUMA TO THE SKIN SUBCUT TISS AND BREAST W MCC',
        'key': '604'
      },
      {
        'value': '605',
        'title': '605 - TRAUMA TO THE SKIN SUBCUT TISS AND BREAST W-O MCC',
        'key': '605'
      },
      {
        'value': '606',
        'title': '606 - MINOR SKIN DISORDERS W MCC',
        'key': '606'
      },
      {
        'value': '607',
        'title': '607 - MINOR SKIN DISORDERS W-O MCC',
        'key': '607'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-10',
    'title': '10 - Endocrine, Nutritional & Metabolic Diseases & Disorders',
    'value': 'mdc-10',
    'children': [
      {
        'value': '614',
        'title': '614 - ADRENAL AND PITUITARY PROCEDURES W CC-MCC',
        'key': '614'
      },
      {
        'value': '615',
        'title': '615 - ADRENAL AND PITUITARY PROCEDURES W-O CC-MCC',
        'key': '615'
      },
      {
        'value': '616',
        'title': '616 - AMPUTAT OF LOWER LIMB FOR ENDOCRINENUTRITAND METABOL DIS W MCC',
        'key': '616'
      },
      {
        'value': '617',
        'title': '617 - AMPUTAT OF LOWER LIMB FOR ENDOCRINENUTRITAND METABOL DIS W CC',
        'key': '617'
      },
      {
        'value': '618',
        'title': '618 - AMPUTAT OF LOWER LIMB FOR ENDOCRINENUTRITAND METABOL DIS W-O CC-MCC',
        'key': '618'
      },
      {
        'value': '619',
        'title': '619 - O.R. PROCEDURES FOR OBESITY W MCC',
        'key': '619'
      },
      {
        'value': '620',
        'title': '620 - O.R. PROCEDURES FOR OBESITY W CC',
        'key': '620'
      },
      {
        'value': '621',
        'title': '621 - O.R. PROCEDURES FOR OBESITY W-O CC-MCC',
        'key': '621'
      },
      {
        'value': '622',
        'title': '622 - SKIN GRAFTS AND WOUND DEBRID FOR ENDOC NUTRIT AND METAB DIS W MCC',
        'key': '622'
      },
      {
        'value': '623',
        'title': '623 - SKIN GRAFTS AND WOUND DEBRID FOR ENDOC NUTRIT AND METAB DIS W CC',
        'key': '623'
      },
      {
        'value': '624',
        'title': '624 - SKIN GRAFTS AND WOUND DEBRID FOR ENDOC NUTRIT AND METAB DIS W-O CC-MCC',
        'key': '624'
      },
      {
        'value': '625',
        'title': '625 - THYROID PARATHYROID AND THYROGLOSSAL PROCEDURES W MCC',
        'key': '625'
      },
      {
        'value': '626',
        'title': '626 - THYROID PARATHYROID AND THYROGLOSSAL PROCEDURES W CC',
        'key': '626'
      },
      {
        'value': '627',
        'title': '627 - THYROID PARATHYROID AND THYROGLOSSAL PROCEDURES W-O CC-MCC',
        'key': '627'
      },
      {
        'value': '628',
        'title': '628 - OTHER ENDOCRINE NUTRIT AND METAB O.R. PROC W MCC',
        'key': '628'
      },
      {
        'value': '629',
        'title': '629 - OTHER ENDOCRINE NUTRIT AND METAB O.R. PROC W CC',
        'key': '629'
      },
      {
        'value': '630',
        'title': '630 - OTHER ENDOCRINE NUTRIT AND METAB O.R. PROC W-O CC-MCC',
        'key': '630'
      },
      {
        'value': '637',
        'title': '637 - DIABETES W MCC',
        'key': '637'
      },
      {
        'value': '638',
        'title': '638 - DIABETES W CC',
        'key': '638'
      },
      {
        'value': '639',
        'title': '639 - DIABETES W-O CC-MCC',
        'key': '639'
      },
      {
        'value': '640',
        'title': '640 - MISC DISORDERS OF NUTRITIONMETABOLISMFLUIDS-ELECTROLYTES W MCC',
        'key': '640'
      },
      {
        'value': '641',
        'title': '641 - MISC DISORDERS OF NUTRITIONMETABOLISMFLUIDS-ELECTROLYTES W-O MCC',
        'key': '641'
      },
      {
        'value': '642',
        'title': '642 - INBORN AND OTHER DISORDERS OF METABOLISM',
        'key': '642'
      },
      {
        'value': '643',
        'title': '643 - ENDOCRINE DISORDERS W MCC',
        'key': '643'
      },
      {
        'value': '644',
        'title': '644 - ENDOCRINE DISORDERS W CC',
        'key': '644'
      },
      {
        'value': '645',
        'title': '645 - ENDOCRINE DISORDERS W-O CC-MCC',
        'key': '645'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-11',
    'title': '11 - Diseases & Disorders of the Kidney & Urinary Tract',
    'value': 'mdc-11',
    'children': [
      {
        'value': '652',
        'title': '652 - KIDNEY TRANSPLANT',
        'key': '652'
      },
      {
        'value': '653',
        'title': '653 - MAJOR BLADDER PROCEDURES W MCC',
        'key': '653'
      },
      {
        'value': '654',
        'title': '654 - MAJOR BLADDER PROCEDURES W CC',
        'key': '654'
      },
      {
        'value': '655',
        'title': '655 - MAJOR BLADDER PROCEDURES W-O CC-MCC',
        'key': '655'
      },
      {
        'value': '656',
        'title': '656 - KIDNEY AND URETER PROCEDURES FOR NEOPLASM W MCC',
        'key': '656'
      },
      {
        'value': '657',
        'title': '657 - KIDNEY AND URETER PROCEDURES FOR NEOPLASM W CC',
        'key': '657'
      },
      {
        'value': '658',
        'title': '658 - KIDNEY AND URETER PROCEDURES FOR NEOPLASM W-O CC-MCC',
        'key': '658'
      },
      {
        'value': '659',
        'title': '659 - KIDNEY AND URETER PROCEDURES FOR NON-NEOPLASM W MCC',
        'key': '659'
      },
      {
        'value': '660',
        'title': '660 - KIDNEY AND URETER PROCEDURES FOR NON-NEOPLASM W CC',
        'key': '660'
      },
      {
        'value': '661',
        'title': '661 - KIDNEY AND URETER PROCEDURES FOR NON-NEOPLASM W-O CC-MCC',
        'key': '661'
      },
      {
        'value': '662',
        'title': '662 - MINOR BLADDER PROCEDURES W MCC',
        'key': '662'
      },
      {
        'value': '663',
        'title': '663 - MINOR BLADDER PROCEDURES W CC',
        'key': '663'
      },
      {
        'value': '664',
        'title': '664 - MINOR BLADDER PROCEDURES W-O CC-MCC',
        'key': '664'
      },
      {
        'value': '665',
        'title': '665 - PROSTATECTOMY W MCC',
        'key': '665'
      },
      {
        'value': '666',
        'title': '666 - PROSTATECTOMY W CC',
        'key': '666'
      },
      {
        'value': '667',
        'title': '667 - PROSTATECTOMY W-O CC-MCC',
        'key': '667'
      },
      {
        'value': '668',
        'title': '668 - TRANSURETHRAL PROCEDURES W MCC',
        'key': '668'
      },
      {
        'value': '669',
        'title': '669 - TRANSURETHRAL PROCEDURES W CC',
        'key': '669'
      },
      {
        'value': '670',
        'title': '670 - TRANSURETHRAL PROCEDURES W-O CC-MCC',
        'key': '670'
      },
      {
        'value': '671',
        'title': '671 - URETHRAL PROCEDURES W CC-MCC',
        'key': '671'
      },
      {
        'value': '672',
        'title': '672 - URETHRAL PROCEDURES W-O CC-MCC',
        'key': '672'
      },
      {
        'value': '673',
        'title': '673 - OTHER KIDNEY AND URINARY TRACT PROCEDURES W MCC',
        'key': '673'
      },
      {
        'value': '674',
        'title': '674 - OTHER KIDNEY AND URINARY TRACT PROCEDURES W CC',
        'key': '674'
      },
      {
        'value': '675',
        'title': '675 - OTHER KIDNEY AND URINARY TRACT PROCEDURES W-O CC-MCC',
        'key': '675'
      },
      {
        'value': '682',
        'title': '682 - RENAL FAILURE W MCC',
        'key': '682'
      },
      {
        'value': '683',
        'title': '683 - RENAL FAILURE W CC',
        'key': '683'
      },
      {
        'value': '684',
        'title': '684 - RENAL FAILURE W-O CC-MCC',
        'key': '684'
      },
      {
        'value': '685',
        'title': '685 - ADMIT FOR RENAL DIALYSIS',
        'key': '685'
      },
      {
        'value': '686',
        'title': '686 - KIDNEY AND URINARY TRACT NEOPLASMS W MCC',
        'key': '686'
      },
      {
        'value': '687',
        'title': '687 - KIDNEY AND URINARY TRACT NEOPLASMS W CC',
        'key': '687'
      },
      {
        'value': '688',
        'title': '688 - KIDNEY AND URINARY TRACT NEOPLASMS W-O CC-MCC',
        'key': '688'
      },
      {
        'value': '689',
        'title': '689 - KIDNEY AND URINARY TRACT INFECTIONS W MCC',
        'key': '689'
      },
      {
        'value': '690',
        'title': '690 - KIDNEY AND URINARY TRACT INFECTIONS W-O MCC',
        'key': '690'
      },
      {
        'value': '691',
        'title': '691 - URINARY STONES W ESW LITHOTRIPSY W CC-MCC',
        'key': '691'
      },
      {
        'value': '692',
        'title': '692 - URINARY STONES W ESW LITHOTRIPSY W-O CC-MCC',
        'key': '692'
      },
      {
        'value': '693',
        'title': '693 - URINARY STONES W-O ESW LITHOTRIPSY W MCC',
        'key': '693'
      },
      {
        'value': '694',
        'title': '694 - URINARY STONES W-O ESW LITHOTRIPSY W-O MCC',
        'key': '694'
      },
      {
        'value': '695',
        'title': '695 - KIDNEY AND URINARY TRACT SIGNS AND SYMPTOMS W MCC',
        'key': '695'
      },
      {
        'value': '696',
        'title': '696 - KIDNEY AND URINARY TRACT SIGNS AND SYMPTOMS W-O MCC',
        'key': '696'
      },
      {
        'value': '697',
        'title': '697 - URETHRAL STRICTURE',
        'key': '697'
      },
      {
        'value': '698',
        'title': '698 - OTHER KIDNEY AND URINARY TRACT DIAGNOSES W MCC',
        'key': '698'
      },
      {
        'value': '699',
        'title': '699 - OTHER KIDNEY AND URINARY TRACT DIAGNOSES W CC',
        'key': '699'
      },
      {
        'value': '700',
        'title': '700 - OTHER KIDNEY AND URINARY TRACT DIAGNOSES W-O CC-MCC',
        'key': '700'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-12',
    'title': '12 - Diseases & Disorders of the Male Reproductive System',
    'value': 'mdc-12',
    'children': [
      {
        'value': '707',
        'title': '707 - MAJOR MALE PELVIC PROCEDURES W CC-MCC',
        'key': '707'
      },
      {
        'value': '708',
        'title': '708 - MAJOR MALE PELVIC PROCEDURES W-O CC-MCC',
        'key': '708'
      },
      {
        'value': '709',
        'title': '709 - PENIS PROCEDURES W CC-MCC',
        'key': '709'
      },
      {
        'value': '710',
        'title': '710 - PENIS PROCEDURES W-O CC-MCC',
        'key': '710'
      },
      {
        'value': '711',
        'title': '711 - TESTES PROCEDURES W CC-MCC',
        'key': '711'
      },
      {
        'value': '712',
        'title': '712 - TESTES PROCEDURES W-O CC-MCC',
        'key': '712'
      },
      {
        'value': '713',
        'title': '713 - TRANSURETHRAL PROSTATECTOMY W CC-MCC',
        'key': '713'
      },
      {
        'value': '714',
        'title': '714 - TRANSURETHRAL PROSTATECTOMY W-O CC-MCC',
        'key': '714'
      },
      {
        'value': '715',
        'title': '715 - OTHER MALE REPRODUCTIVE SYSTEM O.R. PROC FOR MALIGNANCY W CC-MCC',
        'key': '715'
      },
      {
        'value': '716',
        'title': '716 - OTHER MALE REPRODUCTIVE SYSTEM O.R. PROC FOR MALIGNANCY W-O CC-MCC',
        'key': '716'
      },
      {
        'value': '717',
        'title': '717 - OTHER MALE REPRODUCTIVE SYSTEM O.R. PROC EXC MALIGNANCY W CC-MCC',
        'key': '717'
      },
      {
        'value': '718',
        'title': '718 - OTHER MALE REPRODUCTIVE SYSTEM O.R. PROC EXC MALIGNANCY W-O CC-MCC',
        'key': '718'
      },
      {
        'value': '722',
        'title': '722 - MALIGNANCY MALE REPRODUCTIVE SYSTEM W MCC',
        'key': '722'
      },
      {
        'value': '723',
        'title': '723 - MALIGNANCY MALE REPRODUCTIVE SYSTEM W CC',
        'key': '723'
      },
      {
        'value': '724',
        'title': '724 - MALIGNANCY MALE REPRODUCTIVE SYSTEM W-O CC-MCC',
        'key': '724'
      },
      {
        'value': '725',
        'title': '725 - BENIGN PROSTATIC HYPERTROPHY W MCC',
        'key': '725'
      },
      {
        'value': '726',
        'title': '726 - BENIGN PROSTATIC HYPERTROPHY W-O MCC',
        'key': '726'
      },
      {
        'value': '727',
        'title': '727 - INFLAMMATION OF THE MALE REPRODUCTIVE SYSTEM W MCC',
        'key': '727'
      },
      {
        'value': '728',
        'title': '728 - INFLAMMATION OF THE MALE REPRODUCTIVE SYSTEM W-O MCC',
        'key': '728'
      },
      {
        'value': '729',
        'title': '729 - OTHER MALE REPRODUCTIVE SYSTEM DIAGNOSES W CC-MCC',
        'key': '729'
      },
      {
        'value': '730',
        'title': '730 - OTHER MALE REPRODUCTIVE SYSTEM DIAGNOSES W-O CC-MCC',
        'key': '730'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-13',
    'title': '13 - Diseases & Disorders of the Female Reproductive System',
    'value': 'mdc-13',
    'children': [
      {
        'value': '734',
        'title': '734 - PELVIC EVISCERATION RAD HYSTERECTOMY AND RAD VULVECTOMY W CC-MCC',
        'key': '734'
      },
      {
        'value': '735',
        'title': '735 - PELVIC EVISCERATION RAD HYSTERECTOMY AND RAD VULVECTOMY W-O CC-MCC',
        'key': '735'
      },
      {
        'value': '736',
        'title': '736 - UTERINE AND ADNEXA PROC FOR OVARIAN OR ADNEXAL MALIGNANCY W MCC',
        'key': '736'
      },
      {
        'value': '737',
        'title': '737 - UTERINE AND ADNEXA PROC FOR OVARIAN OR ADNEXAL MALIGNANCY W CC',
        'key': '737'
      },
      {
        'value': '738',
        'title': '738 - UTERINE AND ADNEXA PROC FOR OVARIAN OR ADNEXAL MALIGNANCY W-O CC-MCC',
        'key': '738'
      },
      {
        'value': '739',
        'title': '739 - UTERINEADNEXA PROC FOR NON-OVARIAN-ADNEXAL MALIG W MCC',
        'key': '739'
      },
      {
        'value': '740',
        'title': '740 - UTERINEADNEXA PROC FOR NON-OVARIAN-ADNEXAL MALIG W CC',
        'key': '740'
      },
      {
        'value': '741',
        'title': '741 - UTERINEADNEXA PROC FOR NON-OVARIAN-ADNEXAL MALIG W-O CC-MCC',
        'key': '741'
      },
      {
        'value': '742',
        'title': '742 - UTERINE AND ADNEXA PROC FOR NON-MALIGNANCY W CC-MCC',
        'key': '742'
      },
      {
        'value': '743',
        'title': '743 - UTERINE AND ADNEXA PROC FOR NON-MALIGNANCY W-O CC-MCC',
        'key': '743'
      },
      {
        'value': '744',
        'title': '744 - DANDC CONIZATION LAPAROSCOPY AND TUBAL INTERRUPTION W CC-MCC',
        'key': '744'
      },
      {
        'value': '745',
        'title': '745 - DANDC CONIZATION LAPAROSCOPY AND TUBAL INTERRUPTION W-O CC-MCC',
        'key': '745'
      },
      {
        'value': '746',
        'title': '746 - VAGINA CERVIX AND VULVA PROCEDURES W CC-MCC',
        'key': '746'
      },
      {
        'value': '747',
        'title': '747 - VAGINA CERVIX AND VULVA PROCEDURES W-O CC-MCC',
        'key': '747'
      },
      {
        'value': '748',
        'title': '748 - FEMALE REPRODUCTIVE SYSTEM RECONSTRUCTIVE PROCEDURES',
        'key': '748'
      },
      {
        'value': '749',
        'title': '749 - OTHER FEMALE REPRODUCTIVE SYSTEM O.R. PROCEDURES W CC-MCC',
        'key': '749'
      },
      {
        'value': '750',
        'title': '750 - OTHER FEMALE REPRODUCTIVE SYSTEM O.R. PROCEDURES W-O CC-MCC',
        'key': '750'
      },
      {
        'value': '754',
        'title': '754 - MALIGNANCY FEMALE REPRODUCTIVE SYSTEM W MCC',
        'key': '754'
      },
      {
        'value': '755',
        'title': '755 - MALIGNANCY FEMALE REPRODUCTIVE SYSTEM W CC',
        'key': '755'
      },
      {
        'value': '756',
        'title': '756 - MALIGNANCY FEMALE REPRODUCTIVE SYSTEM W-O CC-MCC',
        'key': '756'
      },
      {
        'value': '757',
        'title': '757 - INFECTIONS FEMALE REPRODUCTIVE SYSTEM W MCC',
        'key': '757'
      },
      {
        'value': '758',
        'title': '758 - INFECTIONS FEMALE REPRODUCTIVE SYSTEM W CC',
        'key': '758'
      },
      {
        'value': '759',
        'title': '759 - INFECTIONS FEMALE REPRODUCTIVE SYSTEM W-O CC-MCC',
        'key': '759'
      },
      {
        'value': '760',
        'title': '760 - MENSTRUAL AND OTHER FEMALE REPRODUCTIVE SYSTEM DISORDERS W CC-MCC',
        'key': '760'
      },
      {
        'value': '761',
        'title': '761 - MENSTRUAL AND OTHER FEMALE REPRODUCTIVE SYSTEM DISORDERS W-O CC-MCC',
        'key': '761'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-14',
    'title': '14 - Pregnancy, Childbirth & the Puerperium',
    'value': 'mdc-14',
    'children': [
      {
        'value': '768',
        'title': '768 - VAGINAL DELIVERY W O.R. PROC EXCEPT STERIL AND-OR DANDC',
        'key': '768'
      },
      {
        'value': '769',
        'title': '769 - POSTPARTUM AND POST ABORTION DIAGNOSES W O.R. PROCEDURE',
        'key': '769'
      },
      {
        'value': '770',
        'title': '770 - ABORTION W DANDC ASPIRATION CURETTAGE OR HYSTEROTOMY',
        'key': '770'
      },
      {
        'value': '776',
        'title': '776 - POSTPARTUM AND POST ABORTION DIAGNOSES W-O O.R. PROCEDURE',
        'key': '776'
      },
      {
        'value': '779',
        'title': '779 - ABORTION W-O DANDC',
        'key': '779'
      },
      {
        'value': '783',
        'title': '783 - CESAREAN SECTION W STERILIZATION W MCC',
        'key': '783'
      },
      {
        'value': '784',
        'title': '784 - CESAREAN SECTION W STERILIZATION W CC',
        'key': '784'
      },
      {
        'value': '785',
        'title': '785 - CESAREAN SECTION W STERILIZATION W-O CC-MCC',
        'key': '785'
      },
      {
        'value': '786',
        'title': '786 - CESAREAN SECTION W-O STERILIZATION W MCC',
        'key': '786'
      },
      {
        'value': '787',
        'title': '787 - CESAREAN SECTION W-O STERILIZATION W CC',
        'key': '787'
      },
      {
        'value': '788',
        'title': '788 - CESAREAN SECTION W-O STERILIZATION W-O CC-MCC',
        'key': '788'
      },
      {
        'value': '796',
        'title': '796 - VAGINAL DELIVERY W STERILIZATION-DANDC W MCC',
        'key': '796'
      },
      {
        'value': '797',
        'title': '797 - VAGINAL DELIVERY W STERILIZATION-DANDC W CC',
        'key': '797'
      },
      {
        'value': '798',
        'title': '798 - VAGINAL DELIVERY W STERILIZATION-DANDC W-O CC-MCC',
        'key': '798'
      },
      {
        'value': '805',
        'title': '805 - VAGINAL DELIVERY W-O STERILIZATION-DANDC W MCC',
        'key': '805'
      },
      {
        'value': '806',
        'title': '806 - VAGINAL DELIVERY W-O STERILIZATION-DANDC W CC',
        'key': '806'
      },
      {
        'value': '807',
        'title': '807 - VAGINAL DELIVERY W-O STERILIZATION-DANDC W-O CC-MCC',
        'key': '807'
      },
      {
        'value': '817',
        'title': '817 - OTHER ANTEPARTUM DIAGNOSES W O.R. PROCEDURE W MCC',
        'key': '817'
      },
      {
        'value': '818',
        'title': '818 - OTHER ANTEPARTUM DIAGNOSES W O.R. PROCEDURE W CC',
        'key': '818'
      },
      {
        'value': '819',
        'title': '819 - OTHER ANTEPARTUM DIAGNOSES W O.R. PROCEDURE W-O CC-MCC',
        'key': '819'
      },
      {
        'value': '831',
        'title': '831 - OTHER ANTEPARTUM DIAGNOSES W-O O.R. PROCEDURE W MCC',
        'key': '831'
      },
      {
        'value': '832',
        'title': '832 - OTHER ANTEPARTUM DIAGNOSES W-O O.R. PROCEDURE W CC',
        'key': '832'
      },
      {
        'value': '833',
        'title': '833 - OTHER ANTEPARTUM DIAGNOSES W-O O.R. PROCEDURE W-O CC-MCC',
        'key': '833'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-15',
    'title': '15 - Newborns & Other Neonates with Conditions Originating in Perinatal Period',
    'value': 'mdc-15',
    'children': [
      {
        'value': '789',
        'title': '789 - NEONATES DIED OR TRANSFERRED TO ANOTHER ACUTE CARE FACILITY',
        'key': '789'
      },
      {
        'value': '790',
        'title': '790 - EXTREME IMMATURITY OR RESPIRATORY DISTRESS SYNDROME NEONATE',
        'key': '790'
      },
      {
        'value': '791',
        'title': '791 - PREMATURITY W MAJOR PROBLEMS',
        'key': '791'
      },
      {
        'value': '792',
        'title': '792 - PREMATURITY W-O MAJOR PROBLEMS',
        'key': '792'
      },
      {
        'value': '793',
        'title': '793 - FULL TERM NEONATE W MAJOR PROBLEMS',
        'key': '793'
      },
      {
        'value': '794',
        'title': '794 - NEONATE W OTHER SIGNIFICANT PROBLEMS',
        'key': '794'
      },
      {
        'value': '795',
        'title': '795 - NORMAL NEWBORN',
        'key': '795'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-16',
    'title': '16 - Diseases & Disorders of Blood, Blood Forming Organs, Immunologic Disorders',
    'value': 'mdc-16',
    'children': [
      {
        'value': '799',
        'title': '799 - SPLENECTOMY W MCC',
        'key': '799'
      },
      {
        'value': '800',
        'title': '800 - SPLENECTOMY W CC',
        'key': '800'
      },
      {
        'value': '801',
        'title': '801 - SPLENECTOMY W-O CC-MCC',
        'key': '801'
      },
      {
        'value': '802',
        'title': '802 - OTHER O.R. PROC OF THE BLOOD AND BLOOD FORMING ORGANS W MCC',
        'key': '802'
      },
      {
        'value': '803',
        'title': '803 - OTHER O.R. PROC OF THE BLOOD AND BLOOD FORMING ORGANS W CC',
        'key': '803'
      },
      {
        'value': '804',
        'title': '804 - OTHER O.R. PROC OF THE BLOOD AND BLOOD FORMING ORGANS W-O CC-MCC',
        'key': '804'
      },
      {
        'value': '808',
        'title': '808 - MAJOR HEMATOL-IMMUN DIAG EXC SICKLE CELL CRISIS AND COAGUL W MCC',
        'key': '808'
      },
      {
        'value': '809',
        'title': '809 - MAJOR HEMATOL-IMMUN DIAG EXC SICKLE CELL CRISIS AND COAGUL W CC',
        'key': '809'
      },
      {
        'value': '810',
        'title': '810 - MAJOR HEMATOL-IMMUN DIAG EXC SICKLE CELL CRISIS AND COAGUL W-O CC-MCC',
        'key': '810'
      },
      {
        'value': '811',
        'title': '811 - RED BLOOD CELL DISORDERS W MCC',
        'key': '811'
      },
      {
        'value': '812',
        'title': '812 - RED BLOOD CELL DISORDERS W-O MCC',
        'key': '812'
      },
      {
        'value': '813',
        'title': '813 - COAGULATION DISORDERS',
        'key': '813'
      },
      {
        'value': '814',
        'title': '814 - RETICULOENDOTHELIAL AND IMMUNITY DISORDERS W MCC',
        'key': '814'
      },
      {
        'value': '815',
        'title': '815 - RETICULOENDOTHELIAL AND IMMUNITY DISORDERS W CC',
        'key': '815'
      },
      {
        'value': '816',
        'title': '816 - RETICULOENDOTHELIAL AND IMMUNITY DISORDERS W-O CC-MCC',
        'key': '816'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-17',
    'title': '17 - Myeloproliferative Diseases & Disorders, Poorly Differentiated Neoplasms',
    'value': 'mdc-17',
    'children': [
      {
        'value': '820',
        'title': '820 - LYMPHOMA AND LEUKEMIA W MAJOR O.R. PROCEDURE W MCC',
        'key': '820'
      },
      {
        'value': '821',
        'title': '821 - LYMPHOMA AND LEUKEMIA W MAJOR O.R. PROCEDURE W CC',
        'key': '821'
      },
      {
        'value': '822',
        'title': '822 - LYMPHOMA AND LEUKEMIA W MAJOR O.R. PROCEDURE W-O CC-MCC',
        'key': '822'
      },
      {
        'value': '823',
        'title': '823 - LYMPHOMA AND NON-ACUTE LEUKEMIA W OTHER PROC W MCC',
        'key': '823'
      },
      {
        'value': '824',
        'title': '824 - LYMPHOMA AND NON-ACUTE LEUKEMIA W OTHER PROC W CC',
        'key': '824'
      },
      {
        'value': '825',
        'title': '825 - LYMPHOMA AND NON-ACUTE LEUKEMIA W OTHER PROC W-O CC-MCC',
        'key': '825'
      },
      {
        'value': '826',
        'title': '826 - MYELOPROLIF DISORD OR POORLY DIFF NEOPL W MAJ O.R. PROC W MCC',
        'key': '826'
      },
      {
        'value': '827',
        'title': '827 - MYELOPROLIF DISORD OR POORLY DIFF NEOPL W MAJ O.R. PROC W CC',
        'key': '827'
      },
      {
        'value': '828',
        'title': '828 - MYELOPROLIF DISORD OR POORLY DIFF NEOPL W MAJ O.R. PROC W-O CC-MCC',
        'key': '828'
      },
      {
        'value': '829',
        'title': '829 - MYELOPROLIFERATIVE DISORDERS OR POORLY DIFFERENTIATED NEOPLASMS W OTHER  PROCEDURE W CC-MCC',
        'key': '829'
      },
      {
        'value': '830',
        'title': '830 - MYELOPROLIFERATIVE DISORDERS OR POORLY DIFFERENTIATED NEOPLASMS W OTHER  PROCEDURE W-O CC-MCC',
        'key': '830'
      },
      {
        'value': '834',
        'title': '834 - ACUTE LEUKEMIA W-O MAJOR O.R. PROCEDURE W MCC',
        'key': '834'
      },
      {
        'value': '835',
        'title': '835 - ACUTE LEUKEMIA W-O MAJOR O.R. PROCEDURE W CC',
        'key': '835'
      },
      {
        'value': '836',
        'title': '836 - ACUTE LEUKEMIA W-O MAJOR O.R. PROCEDURE W-O CC-MCC',
        'key': '836'
      },
      {
        'value': '837',
        'title': '837 - CHEMO W ACUTE LEUKEMIA AS SDX OR W HIGH DOSE CHEMO AGENT W MCC',
        'key': '837'
      },
      {
        'value': '838',
        'title': '838 - CHEMO W ACUTE LEUKEMIA AS SDX W CC OR HIGH DOSE CHEMO AGENT',
        'key': '838'
      },
      {
        'value': '839',
        'title': '839 - CHEMO W ACUTE LEUKEMIA AS SDX W-O CC-MCC',
        'key': '839'
      },
      {
        'value': '840',
        'title': '840 - LYMPHOMA AND NON-ACUTE LEUKEMIA W MCC',
        'key': '840'
      },
      {
        'value': '841',
        'title': '841 - LYMPHOMA AND NON-ACUTE LEUKEMIA W CC',
        'key': '841'
      },
      {
        'value': '842',
        'title': '842 - LYMPHOMA AND NON-ACUTE LEUKEMIA W-O CC-MCC',
        'key': '842'
      },
      {
        'value': '843',
        'title': '843 - OTHER MYELOPROLIF DIS OR POORLY DIFF NEOPL DIAG W MCC',
        'key': '843'
      },
      {
        'value': '844',
        'title': '844 - OTHER MYELOPROLIF DIS OR POORLY DIFF NEOPL DIAG W CC',
        'key': '844'
      },
      {
        'value': '845',
        'title': '845 - OTHER MYELOPROLIF DIS OR POORLY DIFF NEOPL DIAG W-O CC-MCC',
        'key': '845'
      },
      {
        'value': '846',
        'title': '846 - CHEMOTHERAPY W-O ACUTE LEUKEMIA AS SECONDARY DIAGNOSIS W MCC',
        'key': '846'
      },
      {
        'value': '847',
        'title': '847 - CHEMOTHERAPY W-O ACUTE LEUKEMIA AS SECONDARY DIAGNOSIS W CC',
        'key': '847'
      },
      {
        'value': '848',
        'title': '848 - CHEMOTHERAPY W-O ACUTE LEUKEMIA AS SECONDARY DIAGNOSIS W-O CC-MCC',
        'key': '848'
      },
      {
        'value': '849',
        'title': '849 - RADIOTHERAPY',
        'key': '849'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-18',
    'title': '18 - Infectious & Parasitic Diseases, Systemic or Unspecified Sites',
    'value': 'mdc-18',
    'children': [
      {
        'value': '853',
        'title': '853 - INFECTIOUS AND PARASITIC DISEASES W O.R. PROCEDURE W MCC',
        'key': '853'
      },
      {
        'value': '854',
        'title': '854 - INFECTIOUS AND PARASITIC DISEASES W O.R. PROCEDURE W CC',
        'key': '854'
      },
      {
        'value': '855',
        'title': '855 - INFECTIOUS AND PARASITIC DISEASES W O.R. PROCEDURE W-O CC-MCC',
        'key': '855'
      },
      {
        'value': '856',
        'title': '856 - POSTOPERATIVE OR POST-TRAUMATIC INFECTIONS W O.R. PROC W MCC',
        'key': '856'
      },
      {
        'value': '857',
        'title': '857 - POSTOPERATIVE OR POST-TRAUMATIC INFECTIONS W O.R. PROC W CC',
        'key': '857'
      },
      {
        'value': '858',
        'title': '858 - POSTOPERATIVE OR POST-TRAUMATIC INFECTIONS W O.R. PROC W-O CC-MCC',
        'key': '858'
      },
      {
        'value': '862',
        'title': '862 - POSTOPERATIVE AND POST-TRAUMATIC INFECTIONS W MCC',
        'key': '862'
      },
      {
        'value': '863',
        'title': '863 - POSTOPERATIVE AND POST-TRAUMATIC INFECTIONS W-O MCC',
        'key': '863'
      },
      {
        'value': '864',
        'title': '864 - FEVER',
        'key': '864'
      },
      {
        'value': '865',
        'title': '865 - VIRAL ILLNESS W MCC',
        'key': '865'
      },
      {
        'value': '866',
        'title': '866 - VIRAL ILLNESS W-O MCC',
        'key': '866'
      },
      {
        'value': '867',
        'title': '867 - OTHER INFECTIOUS AND PARASITIC DISEASES DIAGNOSES W MCC',
        'key': '867'
      },
      {
        'value': '868',
        'title': '868 - OTHER INFECTIOUS AND PARASITIC DISEASES DIAGNOSES W CC',
        'key': '868'
      },
      {
        'value': '869',
        'title': '869 - OTHER INFECTIOUS AND PARASITIC DISEASES DIAGNOSES W-O CC-MCC',
        'key': '869'
      },
      {
        'value': '870',
        'title': '870 - SEPTICEMIA OR SEVERE SEPSIS W MV GREATER THAN 96 HOURS',
        'key': '870'
      },
      {
        'value': '871',
        'title': '871 - SEPTICEMIA OR SEVERE SEPSIS W-O MV GREATER THAN 96 HOURS W MCC',
        'key': '871'
      },
      {
        'value': '872',
        'title': '872 - SEPTICEMIA OR SEVERE SEPSIS W-O MV GREATER THAN 96 HOURS W-O MCC',
        'key': '872'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-19',
    'title': '19 - Mental Diseases & Disorders',
    'value': 'mdc-19',
    'children': [
      {
        'value': '876',
        'title': '876 - O.R. PROCEDURE W PRINCIPAL DIAGNOSES OF MENTAL ILLNESS',
        'key': '876'
      },
      {
        'value': '880',
        'title': '880 - ACUTE ADJUSTMENT REACTION AND PSYCHOSOCIAL DYSFUNCTION',
        'key': '880'
      },
      {
        'value': '881',
        'title': '881 - DEPRESSIVE NEUROSES',
        'key': '881'
      },
      {
        'value': '882',
        'title': '882 - NEUROSES EXCEPT DEPRESSIVE',
        'key': '882'
      },
      {
        'value': '883',
        'title': '883 - DISORDERS OF PERSONALITY AND IMPULSE CONTROL',
        'key': '883'
      },
      {
        'value': '884',
        'title': '884 - ORGANIC DISTURBANCES AND INTELLECTUAL DISABILITY',
        'key': '884'
      },
      {
        'value': '885',
        'title': '885 - PSYCHOSES',
        'key': '885'
      },
      {
        'value': '886',
        'title': '886 - BEHAVIORAL AND DEVELOPMENTAL DISORDERS',
        'key': '886'
      },
      {
        'value': '887',
        'title': '887 - OTHER MENTAL DISORDER DIAGNOSES',
        'key': '887'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-20',
    'title': '20 - Alcohol/Drug Use & Alcohol/Drug Induced Organic Mental Disorders',
    'value': 'mdc-20',
    'children': [
      {
        'value': '894',
        'title': '894 - ALCOHOL-DRUG ABUSE OR DEPENDENCE LEFT AMA',
        'key': '894'
      },
      {
        'value': '895',
        'title': '895 - ALCOHOL-DRUG ABUSE OR DEPENDENCE W REHABILITATION THERAPY',
        'key': '895'
      },
      {
        'value': '896',
        'title': '896 - ALCOHOL-DRUG ABUSE OR DEPENDENCE W-O REHABILITATION THERAPY W MCC',
        'key': '896'
      },
      {
        'value': '897',
        'title': '897 - ALCOHOL-DRUG ABUSE OR DEPENDENCE W-O REHABILITATION THERAPY W-O MCC',
        'key': '897'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-21',
    'title': '21 - Injuries, Poisonings & Toxic Effects of Drugs',
    'value': 'mdc-21',
    'children': [
      {
        'value': '901',
        'title': '901 - WOUND DEBRIDEMENTS FOR INJURIES W MCC',
        'key': '901'
      },
      {
        'value': '902',
        'title': '902 - WOUND DEBRIDEMENTS FOR INJURIES W CC',
        'key': '902'
      },
      {
        'value': '903',
        'title': '903 - WOUND DEBRIDEMENTS FOR INJURIES W-O CC-MCC',
        'key': '903'
      },
      {
        'value': '904',
        'title': '904 - SKIN GRAFTS FOR INJURIES W CC-MCC',
        'key': '904'
      },
      {
        'value': '905',
        'title': '905 - SKIN GRAFTS FOR INJURIES W-O CC-MCC',
        'key': '905'
      },
      {
        'value': '906',
        'title': '906 - HAND PROCEDURES FOR INJURIES',
        'key': '906'
      },
      {
        'value': '907',
        'title': '907 - OTHER O.R. PROCEDURES FOR INJURIES W MCC',
        'key': '907'
      },
      {
        'value': '908',
        'title': '908 - OTHER O.R. PROCEDURES FOR INJURIES W CC',
        'key': '908'
      },
      {
        'value': '909',
        'title': '909 - OTHER O.R. PROCEDURES FOR INJURIES W-O CC-MCC',
        'key': '909'
      },
      {
        'value': '913',
        'title': '913 - TRAUMATIC INJURY W MCC',
        'key': '913'
      },
      {
        'value': '914',
        'title': '914 - TRAUMATIC INJURY W-O MCC',
        'key': '914'
      },
      {
        'value': '915',
        'title': '915 - ALLERGIC REACTIONS W MCC',
        'key': '915'
      },
      {
        'value': '916',
        'title': '916 - ALLERGIC REACTIONS W-O MCC',
        'key': '916'
      },
      {
        'value': '917',
        'title': '917 - POISONING AND TOXIC EFFECTS OF DRUGS W MCC',
        'key': '917'
      },
      {
        'value': '918',
        'title': '918 - POISONING AND TOXIC EFFECTS OF DRUGS W-O MCC',
        'key': '918'
      },
      {
        'value': '919',
        'title': '919 - COMPLICATIONS OF TREATMENT W MCC',
        'key': '919'
      },
      {
        'value': '920',
        'title': '920 - COMPLICATIONS OF TREATMENT W CC',
        'key': '920'
      },
      {
        'value': '921',
        'title': '921 - COMPLICATIONS OF TREATMENT W-O CC-MCC',
        'key': '921'
      },
      {
        'value': '922',
        'title': '922 - OTHER INJURY POISONING AND TOXIC EFFECT DIAG W MCC',
        'key': '922'
      },
      {
        'value': '923',
        'title': '923 - OTHER INJURY POISONING AND TOXIC EFFECT DIAG W-O MCC',
        'key': '923'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-22',
    'title': '22 - Burns',
    'value': 'mdc-22',
    'children': [
      {
        'value': '927',
        'title': '927 - EXTENSIVE BURNS OR FULL THICKNESS BURNS W MV GREATER THAN 96 HRS W SKIN GRAFT',
        'key': '927'
      },
      {
        'value': '928',
        'title': '928 - FULL THICKNESS BURN W SKIN GRAFT OR INHAL INJ W CC-MCC',
        'key': '928'
      },
      {
        'value': '929',
        'title': '929 - FULL THICKNESS BURN W SKIN GRAFT OR INHAL INJ W-O CC-MCC',
        'key': '929'
      },
      {
        'value': '933',
        'title': '933 - EXTENSIVE BURNS OR FULL THICKNESS BURNS W MV GREATER THAN 96 HRS W-O SKIN GRAFT',
        'key': '933'
      },
      {
        'value': '934',
        'title': '934 - FULL THICKNESS BURN W-O SKIN GRFT OR INHAL INJ',
        'key': '934'
      },
      {
        'value': '935',
        'title': '935 - NON-EXTENSIVE BURNS',
        'key': '935'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-23',
    'title': '23 - Factors Influencing Health Status & Other Contacts with Health Services',
    'value': 'mdc-23',
    'children': [
      {
        'value': '939',
        'title': '939 - O.R. PROC W DIAGNOSES OF OTHER CONTACT W HEALTH SERVICES W MCC',
        'key': '939'
      },
      {
        'value': '940',
        'title': '940 - O.R. PROC W DIAGNOSES OF OTHER CONTACT W HEALTH SERVICES W CC',
        'key': '940'
      },
      {
        'value': '941',
        'title': '941 - O.R. PROC W DIAGNOSES OF OTHER CONTACT W HEALTH SERVICES W-O CC-MCC',
        'key': '941'
      },
      {
        'value': '945',
        'title': '945 - REHABILITATION W CC-MCC',
        'key': '945'
      },
      {
        'value': '946',
        'title': '946 - REHABILITATION W-O CC-MCC',
        'key': '946'
      },
      {
        'value': '947',
        'title': '947 - SIGNS AND SYMPTOMS W MCC',
        'key': '947'
      },
      {
        'value': '948',
        'title': '948 - SIGNS AND SYMPTOMS W-O MCC',
        'key': '948'
      },
      {
        'value': '949',
        'title': '949 - AFTERCARE W CC-MCC',
        'key': '949'
      },
      {
        'value': '950',
        'title': '950 - AFTERCARE W-O CC-MCC',
        'key': '950'
      },
      {
        'value': '951',
        'title': '951 - OTHER FACTORS INFLUENCING HEALTH STATUS',
        'key': '951'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-24',
    'title': '24 - Multiple Significant Trauma',
    'value': 'mdc-24',
    'children': [
      {
        'value': '955',
        'title': '955 - CRANIOTOMY FOR MULTIPLE SIGNIFICANT TRAUMA',
        'key': '955'
      },
      {
        'value': '956',
        'title': '956 - LIMB REATTACHMENT HIP AND FEMUR PROC FOR MULTIPLE SIGNIFICANT TRAUMA',
        'key': '956'
      },
      {
        'value': '957',
        'title': '957 - OTHER O.R. PROCEDURES FOR MULTIPLE SIGNIFICANT TRAUMA W MCC',
        'key': '957'
      },
      {
        'value': '958',
        'title': '958 - OTHER O.R. PROCEDURES FOR MULTIPLE SIGNIFICANT TRAUMA W CC',
        'key': '958'
      },
      {
        'value': '959',
        'title': '959 - OTHER O.R. PROCEDURES FOR MULTIPLE SIGNIFICANT TRAUMA W-O CC-MCC',
        'key': '959'
      },
      {
        'value': '963',
        'title': '963 - OTHER MULTIPLE SIGNIFICANT TRAUMA W MCC',
        'key': '963'
      },
      {
        'value': '964',
        'title': '964 - OTHER MULTIPLE SIGNIFICANT TRAUMA W CC',
        'key': '964'
      },
      {
        'value': '965',
        'title': '965 - OTHER MULTIPLE SIGNIFICANT TRAUMA W-O CC-MCC',
        'key': '965'
      }
    ]
  },
  {
    'disabled': true,
    'key': 'mdc-25',
    'title': '25 - Human Immunodeficiency Virus Infections',
    'value': 'mdc-25',
    'children': [
      {
        'value': '969',
        'title': '969 - HIV W EXTENSIVE O.R. PROCEDURE W MCC',
        'key': '969'
      },
      {
        'value': '970',
        'title': '970 - HIV W EXTENSIVE O.R. PROCEDURE W-O MCC',
        'key': '970'
      },
      {
        'value': '974',
        'title': '974 - HIV W MAJOR RELATED CONDITION W MCC',
        'key': '974'
      },
      {
        'value': '975',
        'title': '975 - HIV W MAJOR RELATED CONDITION W CC',
        'key': '975'
      },
      {
        'value': '976',
        'title': '976 - HIV W MAJOR RELATED CONDITION W-O CC-MCC',
        'key': '976'
      },
      {
        'value': '977',
        'title': '977 - HIV W OR W-O OTHER RELATED CONDITION',
        'key': '977'
      }
    ]
  }
];
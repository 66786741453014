import {MetricDataName} from '../../data/IBaseMetric';

export interface IConfigLegacy {
  benchmarkName: string;
  configGUID: string;
  configString: string;
  savedAt: string;
  userName: string;
  userGuid: string;
}

export interface IConfig {
  id: string;
  json: string;
  name: string;
  savedAt?: string;
  savedBy?: string;
}

export interface IV2ConfigJsonDecoded {
  version: number;
  global: {
    filters: any;
  },
  kpi: {
    filters: any;
    metrics: string[];
  };
  cost: {
    filters: any;
  };
  labor: {
    filters: any;
    metrics: string[];
  };
  overhead: {
    filters: any;
  },
  serviceline: {
    filters: any;
  }
}

export interface ILegacyUserViewConfig {
  enabledKPIs: MetricDataName[];
  enabledLabor: MetricDataName[];
  filters: any;
}

export interface ILegacyConfigJsonDecoded {
  filters: {
    bedSize: string[];
    careFamily: string;
    censusRegion: string[];
    clinicalvNonclinical: string;
    compareHealthSystem: boolean;
    department: string; // Cost's department
    endDate: string;
    fiscalYearEnd: string[];
    healthSystemType: string[];
    hospitalType: string[];
    inpatientOutpatient: string;
    isAMC: string[];
    isCostClient: boolean;
    kpiShortName: string; // never used
    msdrgCode: string;
    operatingExpense: string[];
    outlierExclusionActive: boolean;
    outlierExclusionThreshold: number;
    selectedEntity: string; // this is a special string that has a | delimiter between hospital system | entity
    selectedCostEntity: string;
    serviceLine: string;
    sphCostComponentRollup: string[];
    sphDepartmentRollup: string; // Labor's department rollup
    sphDepartmentRollupOverhead: string;
    sphJobCodeRollup: string;
    startDate: string;
    systemOperatingExpense: string[];
    urbanRural: number[];
    hasMedicalGroup: boolean;
  },
  enabledKPIs: string[]; // metric data names
  enabledLabor: string[]; // metric data names
}

// TODO!!!
// Be sure to update this object whenever a module
// gets a new filter, so legacy user views can supply a default
// value for it!!
export const LEGACY_FILTER_DEFAULTS = {
  bedSize: [],
  careFamily: '',
  censusRegion: [],
  clinicalvNonclinical: '',
  compareHealthSystem: false,
  department: '', // Cost's department
  endDate: '',
  fiscalYearEnd: [],
  healthSystemType: [],
  hospitalType: [],
  inpatientOutpatient: '', // cost module's patient type
  isAMC: [],
  isCostClient: false,
  kpiShortName: '', // never used
  minimumVolume: 0, // New filter for service line module
  msdrgCode: '',
  operatingExpense: [],
  outlierExclusionActive: false,
  outlierExclusionThreshold: 2,
  patientType: '3', // service line module's patient type
  selectedEntity: '', // this is a special string that has a | delimiter between hospital system | entity
  selectedCostEntity: '',
  serviceLine: '',
  sphCostComponentRollup: [],
  sphDepartmentRollup: '', // Labor's department rollup
  sphDepartmentRollupOverhead: '',
  sphJobCodeRollup: '',
  startDate: '',
  systemOperatingExpense: [],
  urbanRural: [],
  hasMedicalGroup: false
};
/*
Legacy Example:
{
    "filters": {
        "bedSize": [],
        "careFamily": "",
        "censusRegion": [],
        "clinicalvNonclinical": "ALL",
        "compareHealthSystem": false,
        "department": "",
        "endDate": "09|2021",
        "fiscalYearEnd": [],
        "healthSystemType": [],
        "hospitalType": [],
        "inpatientOutpatient": "1",
        "isAMC": [],
        "isCostClient": false,
        "kpiShortName": "",
        "msdrgCode": "",
        "operatingExpense": [],
        "outlierExclusionActive": false,
        "outlierExclusionThreshold": 2,
        "selectedEntity": "Strata Health System | Northern Valley Lakefield Medical Center",
        "selectedCostEntity": "Strata Health System | Northern Valley Lakefield Medical Center",
        "serviceLine": "",
        "sphCostComponentRollup": [
            "Benefits",
            "Building and Equipment",
            "Drugs",
            "Implants",
            "Salaries",
            "Supplies",
            "Other"
        ],
        "sphDepartmentRollup": "ALL",
        "sphDepartmentRollupOverhead": "All Rollups",
        "sphJobCodeRollup": "ALL",
        "startDate": "01|2019",
        "systemOperatingExpense": [],
        "urbanRural": [],
        "hasMedicalGroup": false
    },
    "enabledKPIs": [
        "OperatingExpense_AdjustedDischarge"
    ],
    "enabledLabor": []
}
 */
import React from 'react';
import { Button, Card, Spacing, Tag, Text } from '@strata/tempo/lib';

interface ISelectableMetricCard {
  active: boolean;
  Category?: string;
  Data_Name: string;
  Friendly_Name: string;
  onToggle(dataName: string): void;
}

const SelectableMetricCard: React.FC<ISelectableMetricCard> = (props: ISelectableMetricCard) => {
  const UNSAFE_props = {
    style: {
      marginBottom: 0,
      marginTop: 0,
      width: '100%'
    }
  };
  return (
    <Card removeBodyPadding {...UNSAFE_props}>
      <Spacing column background={props.active ? undefined : 'gray-100'}>
        <Spacing column padding='8px' style={{ flexGrow: 1 }}>
          <Spacing hAlign='space-between' margin='0 0 2px 0'>
            <Tag color='blue-700'>{props.Category}</Tag>
            <Button
              onClick={() => props.onToggle(props.Data_Name)}
              type={props.active ? 'tertiary' : 'secondary'}
            >
              {props.active ? 'Remove' : 'Add'}
            </Button>
          </Spacing>
          <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
            {props.Friendly_Name}
          </Text.Heading>
        </Spacing>
      </Spacing>
    </Card>
  );
};

export default SelectableMetricCard;

import React, { FC, createContext, useContext, useState, Dispatch, SetStateAction } from 'react';

import useDrawer, { IUseDrawer } from 'shared/drawers/data/useDrawer';
import {
  ServiceLineFilterKeys,
  useServiceLineFilterGroup
} from 'serviceline2/filters/useServiceLineFilterGroup';
import { IFilterGroup } from 'shared/filters/IFilter';

import { ISelectedTableRow } from 'serviceline2/ServiceLineTable';
import { fetchServiceLines, fetchCareFamilies } from './serviceLineService';
import useServiceLineTable, { IUseServiceLineTable } from './useServiceLineTable';
import { useClientDataContext } from 'shared/data/useClientDataContext';
import { IComparison, ISlCfAverage, ISlCfTrend } from 'shared/comparisons/data/IComparison';

export interface IServiceLineModuleContext {
  careFamilyTable: IUseServiceLineTable;
  diffType: DiffType;
  drawer: IUseDrawer;
  filterGroup: IFilterGroup<ServiceLineFilterKeys>;
  selectedTableRow?: ISelectedTableRow;
  serviceLineTable: IUseServiceLineTable;
  setDiffType: Dispatch<SetStateAction<DiffType>>;
  setSelectedTableRow: Dispatch<SetStateAction<ISelectedTableRow | undefined>>;
  setTableFilter: Dispatch<SetStateAction<string>>;
  tableFilter: string;
}

export type DiffType = 'absolute' | 'percentage';

const context = createContext({} as IServiceLineModuleContext);

const ServiceLineProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const clientDataContext = useClientDataContext();
  const filterGroup = useServiceLineFilterGroup();

  const [selectedTableRow, setSelectedTableRow] = useState<ISelectedTableRow | undefined>();
  const [tableFilter, setTableFilter] = useState<string>('');
  const [diffType, setDiffType] = useState<DiffType>('absolute');

  const serviceLineTable = useServiceLineTable(
    fetchServiceLines,
    filterGroup,
    setSelectedTableRow,
    clientDataContext.clientData?.databaseGuid
  );

  const careFamilyTable = useServiceLineTable(
    fetchCareFamilies,
    filterGroup,
    setSelectedTableRow,
    clientDataContext.clientData?.databaseGuid
  );

  const provided = {
    diffType,
    drawer: useDrawer(),
    serviceLineTable,
    careFamilyTable,
    filterGroup,
    selectedTableRow,
    setDiffType,
    setSelectedTableRow,
    setTableFilter,
    tableFilter
  };

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

const useServiceLineContext = (): IServiceLineModuleContext => useContext(context);

export { useServiceLineContext, ServiceLineProvider };

import React, { FC } from 'react';
import { IFilterControlProps } from 'shared/filters/IFilter';
import dayjs, { Dayjs } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { DATE_NUMBER_FORMAT } from 'shared/data/DateString';
import { DatePicker, Form, Text } from '@strata/tempo/lib';
import TimelineWarning from './TimelineWarning';
import { ITimelineData } from './index';

dayjs.extend(isBetween);

(window as any).dayjs = dayjs;
(window as any).DATE_NUMBER_FORMAT = DATE_NUMBER_FORMAT;

/**
 * This is an example of a "multi-control" filter
 * who's filterValue is an object (that holds values for each control)!
 */
export const TimelineControl: FC<IFilterControlProps<ITimelineData>> = ({
  filterValue,
  setFilterValue
}) => {
  const startMoment = dayjs(filterValue.startDate, DATE_NUMBER_FORMAT);
  const endMoment = dayjs(filterValue.endDate, DATE_NUMBER_FORMAT);

  const handleChange = (date: Dayjs | null, key: keyof ITimelineData) => {
    if (!date) return;
    setFilterValue((prevValue) => ({
      ...prevValue,
      [key]: date.format(DATE_NUMBER_FORMAT)
    }));
  };

  const handleStartDateChange = (date: Dayjs | null) => handleChange(date, 'startDate');
  const handleEndDateChange = (date: Dayjs | null) => handleChange(date, 'endDate');

  const absurdStartDate = (date: Dayjs | null): boolean => {
    const earliest = dayjs(filterValue.histStartDate, DATE_NUMBER_FORMAT);
    const latest = dayjs(filterValue.endDate, DATE_NUMBER_FORMAT).subtract(1, 'month');
    return !dateIsBetween(date, earliest, latest);
  };

  const absurdEndDate = (date: Dayjs | null): boolean => {
    const earliest = dayjs(filterValue.startDate, DATE_NUMBER_FORMAT).add(1, 'month');
    const latest = dayjs(filterValue.prelimEndDate, DATE_NUMBER_FORMAT);
    return !dateIsBetween(date, earliest, latest);
  };

  return (
    <>
      <Text.Heading level={4}>Timeline</Text.Heading>
      <Form.Item label='Start Month'>
        <DatePicker.MonthPicker
          allowClear={false}
          onChange={handleStartDateChange}
          disabledDate={absurdStartDate}
          placeholder='Start Month'
          value={startMoment}
        />
      </Form.Item>
      <Form.Item label='End Month'>
        <DatePicker.MonthPicker
          allowClear={false}
          onChange={handleEndDateChange}
          disabledDate={absurdEndDate}
          placeholder='End Month'
          value={endMoment}
        />
        <Text size='small' color='error'>
          <TimelineWarning timeline={filterValue} />
        </Text>
      </Form.Item>
    </>
  );
};

/* *******
Private helpers
 ****** */
const dateIsBetween = (date: Dayjs | null, earliest: Dayjs, latest: Dayjs) =>
  date ? date.isBetween(earliest, latest, 'date', '[]') : false;

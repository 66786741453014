import React from 'react';
import { createContext, FC, useContext, useEffect, useState } from 'react';
import { useLogoutNow } from 'shared/utils/useLogoutNow';
import { clientDataService, IClientData } from './clientDataService';
import { useHistory, useLocation } from 'react-router-dom';

interface IClientDataContext {
  clientData: IClientData;
}

const context = createContext({} as IClientDataContext);

const ClientDataProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const alertErrorAndLogout = useLogoutNow();
  const [clientData, setClientData] = useState<IClientData>();

  const providedValue = {
    clientData
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await clientDataService.fetchClientDataFromUiSystemFlags();

      // Clients who have not signed the dua will not return system flags
      if (!data.systemFlags) {
        alertErrorAndLogout({
          content:
            "Your organization doesn't currently have access to StrataSphere® Compare. Talk with a Strata team member to learn how to get free access."
        });
        return;
      }

      if (!data.systemFlags.isCompare) {
        alertErrorAndLogout({
          content:
            "We're currently preparing your StrataSphere® Compare data, and we'll schedule a demo with you as soon as it's ready."
        });
        return;
      }

      const clientData: IClientData = {
        ...(data.systemFlags as Omit<IClientData, 'databaseGuid'>),
        databaseGuid: data.databaseGuid
      };

      setClientData(clientData);
    };

    fetchData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!clientData) {
      return;
    }

    const guardRoute = (pathname: string, isEnabled: boolean) => {
      if (location.pathname.includes(pathname) && !isEnabled) history.replace('/kpi');
    };

    guardRoute('/cost', clientData.isProductionCost || clientData.isSpecialAccessCost);
    guardRoute('/labor', clientData.isProductionLabor);
    guardRoute('/overhead', clientData.isProductionOverhead);
    guardRoute('/serviceline', clientData.isProductionCost || clientData.isSpecialAccessCost);
  }, [clientData, location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <context.Provider value={providedValue as IClientDataContext}>
      {props.children}
    </context.Provider>
  );
};

const useClientDataContext = () => useContext(context);

export { useClientDataContext, ClientDataProvider };

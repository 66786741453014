import {
  IConfig,
  ILegacyConfigJsonDecoded,
  IV2ConfigJsonDecoded,
  LEGACY_FILTER_DEFAULTS
} from './IConfig';

export const decodeConfig = (config: IConfig): IV2ConfigJsonDecoded => {
  const configData = JSON.parse(config.json);
  switch (configData.version) {
    case 2:
      // If config data changes beyond v2, handle transformations
      // in each filter's config file, within its loadFromParams method.
      return configData;
    default:
      return decodeLegacy(configData);
  }
};

function decodeLegacy(data: ILegacyConfigJsonDecoded): IV2ConfigJsonDecoded {
  // These filters were stored as 'string[]' (in the distant past)
  // but are now read as 'string'
  const filtersWithLegacyTypes = [
    'careFamily',
    'clinicalvNonclinical',
    'department',
    'inpatientOutpatient',
    'selectedEntity',
    'selectedCostEntity',
    'serviceLine',
    'sphDepartmentRollup',
    'sphDepartmentRollupOverhead',
    'sphJobCodeRollup'
  ];

  // Some clients have ancient user configs that are missing some filters.
  // Ensure the defaults are set for any missing filters:
  data.filters = {
    ...LEGACY_FILTER_DEFAULTS,
    ...data.filters
  };

  // Iterate over the filters and fix those with legacy value-types
  Object.entries(data.filters)
    .filter(([key]) => filtersWithLegacyTypes.includes(key as keyof unknown))
    .forEach(([key, val]) => {
      const isWrongType = val instanceof Array;
      if (isWrongType) {
        // eslint-disable-next-line
        // @ts-ignore
        data.filters[key] = val[0] || '';
      }
    });

  return {
    version: 1,
    global: {
      filters: data.filters
    },
    kpi: {
      filters: data.filters,
      metrics: data.enabledKPIs
    },
    labor: {
      filters: data.filters,
      metrics: data.enabledLabor
    },
    cost: {
      filters: data.filters
    },
    overhead: {
      filters: data.filters
    },
    serviceline: {
      filters: data.filters
    }
  };
}

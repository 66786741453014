import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { fetchCareFamilies, fetchServiceLines, ISlCfTableRow } from './serviceLineService';
import { ServiceLineFilterKeys } from 'serviceline2/filters/useServiceLineFilterGroup';
import { IFilterGroup } from '../../shared/filters';
import { ISelectedTableRow } from '../ServiceLineTable';
import { IComparison, ISlCfAverage, ISlCfTrend } from '../../shared/comparisons/data/IComparison';

export interface IUseServiceLineTable {
  data: IComparison<ISlCfAverage, ISlCfTrend>;
  loading: boolean;
  loadingDone: boolean;
  loadingRequest: Promise<void> | undefined;
  load(serviceLineName?: string): void;
  cancelLoad(): void;
}

const useServiceLineTable = (
  fetchSlCfData: typeof fetchServiceLines | typeof fetchCareFamilies,
  filterGroup: IFilterGroup<ServiceLineFilterKeys>,
  setSelectedTableRow: Dispatch<SetStateAction<ISelectedTableRow | undefined>>,
  databaseGuid: string
): IUseServiceLineTable => {
  const [data, setData] = useState<IComparison<ISlCfAverage, ISlCfTrend>>(
    {} as IComparison<ISlCfAverage, ISlCfTrend>
  );
  const [loading, setLoading] = useState(false);
  const [loadingDone, setLoadingDone] = useState(false);
  const [loadingRequest, setLoadingRequest] = useState<Promise<void>>();
  const [abortController, setAbortController] = useState<AbortController>();

  // If the user changes a Filter Parameter,
  // flag that new comparisons should be loaded
  useEffect(() => {
    setLoadingDone(false);
  }, [filterGroup.token]);

  const load = (serviceLineName = '') => {
    const controller = new AbortController();
    setAbortController(controller);

    setData({} as IComparison<ISlCfAverage, ISlCfTrend>);
    setLoading(true);
    setLoadingDone(false);

    const request = fetchSlCfData(
      filterGroup,
      databaseGuid,
      controller.signal,
      serviceLineName
    ).then(setData);
    setLoadingRequest(request);

    request.finally(() => {
      setLoading(false);
      setLoadingDone(true);
      setLoadingRequest(undefined);
    });

    return request;
  };

  const cancelLoad = () => {
    console.log('cancelling load!');
    abortController?.abort();
    setLoading(false);
    setLoadingDone(true);
    setLoadingRequest(undefined);
  };

  return {
    data,
    loading,
    loadingDone,
    loadingRequest,
    load,
    cancelLoad
  };
};

useServiceLineTable.returnType = {
  data: {} as IComparison<ISlCfAverage, ISlCfTrend>,
  loading: false,
  loadingDone: false,
  loadingRequest: undefined,
  load: () => undefined,
  cancelLoad: () => undefined
} as IUseServiceLineTable;

export default useServiceLineTable;

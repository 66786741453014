import React, { FC } from 'react';
import { Spacing, Text } from '@strata/tempo/lib';

const ServiceLineEmptyCard: FC = () => {
  return (
    <Spacing vAlign={'center'} hAlign={'center'}>
      <Text size='large' color='secondary'>
        No Data Loaded
      </Text>
    </Spacing>
  );
};
export default ServiceLineEmptyCard;

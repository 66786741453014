import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import { DropDown, DropDownValue, Form, Text } from '@strata/tempo/lib';
import Setting from 'shared/filters/settings/individual/Setting';
import { useCostContext } from 'cost/data/costContext';
import { useKpiContext } from 'kpi/data/kpiContext';
import { useSg2GroupingsContext } from 'shared/data/useSg2GroupingsContext';

const ServiceLineControl: FC<IFilterControlProps<string>> = ({ filterValue, setFilterValue }) => {
  const costContext = useCostContext();
  const kpiContext = useKpiContext();
  const sg2Groupings = useSg2GroupingsContext();

  const isDisabled = Boolean(costContext.filterGroup.filters.msdrgCode.filterValue);

  const handleChange = (val: DropDownValue) => {
    setFilterValue((val as string) ?? '');
    costContext.filterGroup.filters.careFamily.setFilterValue('');

    kpiContext.filterGroup.filters.serviceLine.setFilterValue((val as string) ?? '');
    kpiContext.filterGroup.filters.careFamily.setFilterValue('');
  };

  return (
    <>
      <Text.Heading level={4}>Service Line Filters</Text.Heading>
      <Form.Item label='Service Line'>
        {isDisabled && (
          <Text size='small' strong>
            Service Lines are not applicable with the MSDRG Code filter.
          </Text>
        )}
        <DropDown
          allowClear
          disabled={isDisabled}
          items={sg2Groupings.getServiceLineOptions()}
          value={filterValue}
          onChange={handleChange}
          showSearch
        />
      </Form.Item>
    </>
  );
};

const ServiceLineSetting: FC<IFilterSettingProps<string>> = ({ filterValue }) => {
  const costContext = useCostContext();
  const value = `${filterValue} service line with ${
    costContext.filterGroup.filters.careFamily.filterValue || 'no'
  } care family filters applied.`;
  return <Setting isVisible={Boolean(filterValue)} label='Service Line' value={value} />;
};

export const serviceLine: IFilterConfig<string> = {
  Control: ServiceLineControl,
  controlWeight: 4,
  defaultValue: '',
  param: 'serviceLine',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.serviceLine);
  },
  Setting: ServiceLineSetting,
  settingWeight: 4
};

import { useKpiContext } from 'kpi/data/kpiContext';
import { useCostContext } from 'cost/data/costContext';
import { useLaborContext } from 'labor/data/laborContext';
import { useOverheadContext } from 'overhead/data/overheadContext';
import {IConfig, IV2ConfigJsonDecoded} from './IConfig';
import { decodeConfig } from './configJsonDecoder';
import {IFilterDictionary, useGlobalFilterContext} from 'shared/filters';
import { useServiceLineContext } from 'serviceline/data/ServiceLineContext';
import { toParams } from 'shared/filters';
import { cloneDeep } from 'lodash-es';

export const useConfig = () => {
  const globalFilterContext = useGlobalFilterContext();
  const kpiContext = useKpiContext();
  const costContext = useCostContext();
  const laborContext = useLaborContext();
  const overheadContext = useOverheadContext();
  const servicelineContext = useServiceLineContext();

  const makeConfig = (name: string, id: string = makeConfigId()) => {
    // If the user saves an enddate that matches the default end date, save an empty end date
    // Empty End Dates will load the default end date in the future months.
    // TODO improve this.  There should be a way for a filter to have flagged values
    // Eg, if timeline.endDate = '', it should resolve to the defaultEndDate
    // Each IFilterConfig should include a key/value pair of [flag]:(flaggedValue) => mappedValue.
    // That would simplify this code block here, because we could call filter.setFilterValue(newVal) ONCE
    // and it would propagate to all other modules.  And wouldn't break the UI by setting a flagged value (because the filter component would know how to render it)
    const globalFilters = cloneDeep(globalFilterContext.filterGroup.filters);
    const kpiFilters = cloneDeep(kpiContext.filterGroup.filters);
    const costFilters = cloneDeep(costContext.filterGroup.filters);
    const laborFilters = cloneDeep(laborContext.filterGroup.filters);
    const overheadFilters = cloneDeep(overheadContext.filterGroup.filters);
    const servicelineFilters = cloneDeep(servicelineContext.filterGroup.filters);

    if (globalFilters.timeline.filterValue.endDate === globalFilters.timeline.filterData.defaultEndDate) {
      globalFilters.timeline.param.endDate = '';
      kpiFilters.timeline.param.endDate = '';
      costFilters.timeline.param.endDate = '';
      laborFilters.timeline.param.endDate = '';
      overheadFilters.timeline.param.endDate = '';
      servicelineFilters.timeline.param.endDate = '';
    }

    const data: IV2ConfigJsonDecoded = {
      version: 2,
      global: {
        filters: toParams(globalFilters)
      },
      kpi: {
        filters: toParams(kpiFilters),
        metrics: kpiContext.selectableMetrics.selected
      },
      cost: {
        filters: toParams(costFilters)
      },
      labor: {
        filters: toParams(laborFilters),
        metrics: laborContext.selectableMetrics.selected
      },
      overhead: {
        filters: toParams(overheadFilters)
      },
      serviceline: {
        filters: toParams(servicelineFilters)
      }
    };

    return {
      id,
      name,
      json: JSON.stringify(data)
    } as IConfig;
  };

  const loadConfig = (config: IConfig) => {
    const settings = decodeConfig(config);
    const load = (filters: IFilterDictionary<any>, params: any) => {
      if (!filters || !params) return;
      Object.values(filters).forEach(filter => {
        filter.loadFromParams(params);
      });
    }

    // Set the v2 filter values from the config
    load(globalFilterContext.filterGroup.filters, settings.global.filters);
    load(kpiContext.filterGroup.filters, settings.kpi.filters);
    load(laborContext.filterGroup.filters, settings.labor.filters);
    load(costContext.filterGroup.filters, settings.cost.filters);
    load(overheadContext.filterGroup.filters, settings.overhead.filters);
    load(servicelineContext.filterGroup.filters, settings.serviceline?.filters);

    // Select the metrics from the config
    kpiContext.selectableMetrics.select(settings.kpi.metrics);
    laborContext.selectableMetrics.select(settings.labor.metrics);
  }

  return {
    makeConfig,
    loadConfig,
  };
};

export function makeConfigId(): string {
  const rng = (size: number) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < size; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  return [8, 4, 4, 4, 10].map(rng).join('-');
}

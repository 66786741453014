import { useState } from 'react';
import IBaseMetric, { MetricDataName } from './IBaseMetric';

export interface IUseSelectableMetrics<T extends IBaseMetric> {
  metrics: T[];
  selected: MetricDataName[];
  select(ids: MetricDataName[]): void;
  selectAll(): void;
  removeAll(): void;
}

const useSelectableMetrics = <T extends IBaseMetric>(
  items: T[],
  preselected: MetricDataName[] = []
): IUseSelectableMetrics<T> => {
  const [selected, setSelected] = useState(preselected);

  return {
    metrics: items,
    selected,
    select(ids: MetricDataName[] = []) {
      setSelected(ids);
    },
    selectAll() {
      setSelected(items.map((item) => item.Data_Name));
    },
    removeAll() {
      setSelected([]);
    }
  };
};

useSelectableMetrics.returnType = {
  metrics: [],
  selected: [] as MetricDataName[],
  select: () => undefined,
  selectAll: () => undefined,
  removeAll: () => undefined
};

export default useSelectableMetrics;

import React, { FC, useEffect } from 'react';
import CostTaskBar from './CostTaskBar';
import CostDrawer from './drawer/CostDrawer';
import CostComparisons from './CostComparisons';
import { useCostContext } from 'cost/data/costContext';
import LegalNotice from '../shared/LegalNotice';
import FilterGroupSettings from '../shared/filters/FilterGroupSettings';
import { useClientDataContext } from '../shared/data/useClientDataContext';
import { ICacheableCostComparison } from './data/costComparisonService';

const Cost: FC = () => {
  const costContext = useCostContext();
  const clientDataContext = useClientDataContext();

  useEffect(() => {
    costContext.costComparisons.cancelLoad();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Automatically Load comparisons
  // when the cost filters and client data is initialized.
  useEffect(() => {
    if (!costContext.filterGroup.ready || !clientDataContext.clientData) {
      return;
    }

    // If the user doesn't have production cost data, and isn't onboarding the cost module, do not attempt to load data.
    const { isProductionCost, isSpecialAccessCost } = clientDataContext.clientData;
    if (!(isProductionCost || isSpecialAccessCost)) {
      return;
    }

    const filterToken = costContext.filterGroup.token;
    const comparisonIsOutOfSyncWithFilters = (x: any) => x.token !== filterToken;
    const { comparisons, load } = costContext.costComparisons;
    const isOutOfSync = comparisons.some(comparisonIsOutOfSyncWithFilters);
    const isNotLoaded = comparisons.length === 0;

    if (isNotLoaded || isOutOfSync) {
      load();
    }
  }, [costContext.filterGroup.ready, clientDataContext.clientData]); // eslint-disable-line react-hooks/exhaustive-deps

  // On page load, reload the data
  // if the filters have changed since the last visit.
  useEffect(() => {
    const comparisonIsOutOfSyncWithFilters = (x: ICacheableCostComparison) =>
      x.token !== costContext.filterGroup.token;

    if (costContext.costComparisons.comparisons.some(comparisonIsOutOfSyncWithFilters)) {
      costContext.costComparisons.load();
    }
  }, []);

  return (
    <>
      <CostTaskBar />
      <FilterGroupSettings
        filterGroup={costContext.filterGroup}
        filterScope={costContext.filterGroup.filters.entity.computed?.scope}
      />
      <CostComparisons />
      <CostDrawer />
      <LegalNotice />
    </>
  );
};

export default Cost;

import React, { FC, useEffect, useRef, useState } from 'react';
import {
  DataGrid,
  Button,
  Text,
  DataHeaderCell,
  DataHeaderRow,
  DataHeaderGroup
} from '@strata/tempo/lib';
import { useServiceLineContext } from './data/ServiceLineContext';
import { IDataGridSelectionEvent } from '@strata/tempo/lib/datagrid/DataGrid';
import {
  datadogIdentifier,
  formatMoney,
  formatMoneyToNumber,
  ISlCfData,
  ISlCfTableRow,
  slCfToTableRow,
  tableRowToSlCfData
} from './data/serviceLineService';
import CareFamilyTable from './CareFamilyTable';
import { logger } from '@strata/logging/lib';
import { formatPrettyWholeNumberToNumber } from './data/serviceLineService';

import './service-line-care-family-table.scss';
import MinusSquareIcon from '@strata/tempo/lib/icon/MinusSquareIcon';
import PlusSquareIcon from '@strata/tempo/lib/icon/PlusSquareIcon';

export interface ISelectedTableRow {
  value: ISlCfData;
  type: 'Care Family' | 'Service Line';
}

const ServiceLineTable: FC = (props) => {
  const slContext = useServiceLineContext();
  const ref = useRef<DataGrid>(null);

  const handleSelection = (e: IDataGridSelectionEvent) => {
    if (!e.value) {
      return;
    }

    // The value from the selected table row is ISlCfTableRow and we want to store ISlCFData
    // as this is more useful to other components, like the Monthly Trend charts.
    const selectedSlCfData = tableRowToSlCfData(e.value.name, slContext.serviceLineTable.data);

    logger.log('click', 'selected table row', {
      comparisonId: datadogIdentifier.getId(),
      rowName: e.value.name,
      rowType: 'service line'
    });

    slContext.setSelectedTableRow({
      value: selectedSlCfData,
      type: 'Service Line'
    });
  };

  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);
  const handleExpansion = async (e: any, rowData: ISlCfData) => {
    e.preventDefault();
    e.stopPropagation();
    if (expandedRowKeys.includes(rowData.name)) {
      setExpandedRowKeys([]);
      return;
    }

    setExpandedRowKeys([rowData.name]);
    slContext.careFamilyTable.load(rowData.name);
  };

  const tableData = slContext.serviceLineTable.data?.map(slCfToTableRow).filter((row) => {
    const rowIsAboveMinimumVolumeFilter = slContext.isOnboarding
      ? true
      : formatPrettyWholeNumberToNumber(row.volumeUser) >
        slContext.filterGroup.filters.minimumVolume.filterValue;

    const rowIncludesFilterText = Object.values(row).some((data) =>
      String(data).toLowerCase().includes(slContext.tableFilter.toLowerCase())
    );

    return rowIncludesFilterText && rowIsAboveMinimumVolumeFilter;
  });

  const emptyTableData = !tableData.length && slContext.serviceLineTable.loadingDone;
  const emptyMessage = emptyTableData ? 'Missing Cohort' : 'Loading';

  const getLosRowData = (field: string) => (rowData: ISlCfTableRow) => {
    return slContext.filterGroup.filters.patientType.filterValue === 'Inpatient'
      ? rowData[field as keyof typeof rowData]
      : '--';
  };

  // Select the first row every time the data loads
  useEffect(() => {
    const firstRow = tableData[0];
    if (slContext.serviceLineTable.loadingDone && firstRow) {
      setExpandedRowKeys([]);
      slContext.setSelectedTableRow({
        value: tableRowToSlCfData(firstRow.name, slContext.serviceLineTable.data),
        type: 'Service Line'
      });
    }
  }, [slContext.serviceLineTable.loadingDone]);

  return (
    <div className='service-line-table'>
      <DataGrid
        ref={ref}
        columnHeaderGroup={
          <DataHeaderGroup>
            <DataHeaderRow>
              <DataHeaderCell width={50} isGap />
              <DataHeaderCell width={150} isGap />
              <DataHeaderCell width={120} isGap />
              <DataHeaderCell isGap />
              <DataHeaderCell
                colSpan={2}
                header='MS-DRG Case Mix Index'
                align='center'
                width={210}
              />
              <DataHeaderCell isGap />
              <DataHeaderCell colSpan={3} header='Length of Stay' align='center' width={300} />
              <DataHeaderCell isGap />
              <DataHeaderCell
                colSpan={3}
                header='Direct Cost per Case'
                align='center'
                width={360}
              />
              <DataHeaderCell isGap colSpan={3} />
            </DataHeaderRow>
            <DataHeaderRow>
              <DataHeaderCell width={50} isGap />
              <DataHeaderCell
                align='left'
                width={150}
                field='name'
                header='Service Line'
                sortable
              />
              <DataHeaderCell
                align='right'
                field='volumeUser'
                header='Your Average Monthly Volume'
                width={120}
                sortable
              />
              <DataHeaderCell isGap />
              <DataHeaderCell
                field='cmiUser'
                header='Your Average CMI'
                align='right'
                width={90}
                sortable
              />
              <DataHeaderCell
                field='cmiMedian'
                header='Cohort Median CMI'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell isGap />
              <DataHeaderCell
                field='losUser'
                header='Your Average LOS'
                align='right'
                width={90}
                sortable
              />
              <DataHeaderCell
                field='losMedian'
                header='Cohort Median LOS'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell
                field={slContext.diffType === 'absolute' ? 'losDiff' : 'losDiffPercentage'}
                header='LOS &#120491;'
                align='right'
                width={90}
                sortable
              />
              <DataHeaderCell isGap />
              <DataHeaderCell
                field='costUser'
                header='Your Average Direct Cost'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell
                field='costMedian'
                header='Cohort Median Direct Cost'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell
                field={slContext.diffType === 'absolute' ? 'costDiff' : 'costDiffPercentage'}
                header='Cost &#120491;'
                align='right'
                width={120}
                sortable
              />
              <DataHeaderCell colSpan={3} isGap />
            </DataHeaderRow>
          </DataHeaderGroup>
        }
        dataKey='name'
        density='dense'
        expandedRowKeys={expandedRowKeys}
        itemName='Service Lines'
        loading={slContext.serviceLineTable.loading}
        onSelectionChange={handleSelection}
        rowExpansionTemplate={() => <CareFamilyTable />}
        selection={slContext.selectedTableRow?.value}
        sortOrder={-1}
        sortField='volumeUserUnformatted'
        value={tableData}
        scrollable
        scrollHeight='500px'
        emptyMessage={emptyMessage}
      >
        <DataGrid.Column
          width={50}
          body={(rowData: ISlCfData) => {
            const isExpanded = expandedRowKeys.includes(rowData.name);
            return (
              <Button
                {...{ title: isExpanded ? 'Hide Care Families' : 'Expand for Care Families' }}
                type='link'
                icon={isExpanded ? <MinusSquareIcon /> : <PlusSquareIcon />}
                onClick={(e) => handleExpansion(e, rowData)}
                {...{ className: isExpanded ? 'expanded' : '' }}
                logData={{ serviceLine: rowData.name }}
              />
            );
          }}
        />
        <DataGrid.Column
          key='name'
          width={150}
          header='Service Line'
          field='name'
          sortable
          align={'left'}
        />
        <DataGrid.Column
          field='volumeUser'
          key='volume'
          width={120}
          header='Average Monthly Volume'
          sortable
          align={'right'}
          body={({ volumeUser }) => {
            if (slContext.isOnboarding) {
              return '--';
            }
            return <Text>{volumeUser}</Text>;
          }}
        />
        <DataGrid.GapColumn />
        <DataGrid.Column
          field='cmiUser'
          key='cmi'
          width={90}
          header='MS-DRG Case Mix Index My CMI'
          sortable
          align={'right'}
          body={({ cmiUser }) => {
            if (
              slContext.isOnboarding ||
              slContext.filterGroup.filters.patientType.filterValue !== 'Inpatient'
            ) {
              return '--';
            }
            return <Text>{cmiUser}</Text>;
          }}
        />
        <DataGrid.Column
          field='cmiMedian'
          key='cmiMedian'
          width={120}
          header='MS-DRG Case Mix Index Median'
          sortable
          align={'right'}
          body={({ cmiMedian }) => {
            if (
              slContext.isOnboarding ||
              slContext.filterGroup.filters.patientType.filterValue !== 'Inpatient'
            ) {
              return '--';
            }
            return <Text>{cmiMedian}</Text>;
          }}
        />
        <DataGrid.GapColumn />
        <DataGrid.Column
          field='losUser'
          key='los'
          width={90}
          header='Length of Stay My LOS'
          sortable
          align={'right'}
          body={(rowData) => {
            if (slContext.isOnboarding) {
              return '--';
            }
            return getLosRowData('losUser')(rowData);
          }}
        />
        <DataGrid.Column
          field='losMedian'
          key='losMedian'
          width={120}
          header='Length of Stay Median'
          sortable
          align={'right'}
          body={getLosRowData('losMedian')}
        />
        {slContext.diffType === 'absolute' ? (
          <DataGrid.Column
            key='losDiff'
            field='losDiff'
            {...{ title: 'Length of Stay Delta' }}
            width={90}
            header='Length of Stay LOS &#120491;'
            sortable
            align={'right'}
            body={({ losDiff, losUser, losMedian }) => {
              if (
                slContext.isOnboarding ||
                slContext.filterGroup.filters.patientType.filterValue !== 'Inpatient'
              ) {
                return '--';
              }
              return (
                <Text
                  color={
                    formatMoneyToNumber(losUser) <= formatMoneyToNumber(losMedian)
                      ? 'success'
                      : 'error'
                  }
                >
                  {losDiff}
                </Text>
              );
            }}
          />
        ) : (
          <DataGrid.Column
            key='losDiffPercentage'
            field='losDiffPercentage'
            {...{ title: 'Length of Stay Delta as %' }}
            width={90}
            header='Length of Stay LOS &#120491;'
            sortable
            align={'right'}
            body={({ losDiffPercentage, losUser, losMedian }) => {
              if (
                slContext.isOnboarding ||
                slContext.filterGroup.filters.patientType.filterValue !== 'Inpatient'
              ) {
                return '--';
              }
              return (
                <Text
                  color={
                    formatMoneyToNumber(losUser) <= formatMoneyToNumber(losMedian)
                      ? 'success'
                      : 'error'
                  }
                >
                  {Number(losDiffPercentage).toFixed(2) + '%'}
                </Text>
              );
            }}
          />
        )}
        <DataGrid.GapColumn />
        <DataGrid.Column
          field='costUser'
          key='dirCost'
          width={120}
          header='Direct Cost My Cost'
          sortable
          align={'right'}
          body={({ costUser }) => {
            if (slContext.isOnboarding) {
              return '--';
            }
            return formatMoney(costUser);
          }}
        />
        <DataGrid.Column
          field='costMedian'
          key='dirCostMedian'
          width={120}
          header='Direct Cost Median'
          sortable
          align={'right'}
          body={({ costMedian }) => {
            return formatMoney(costMedian);
          }}
        />
        {slContext.diffType === 'absolute' ? (
          <DataGrid.Column
            key='costDiff'
            field='costDiff'
            width={120}
            header='Direct Cost Direct Cost &#120491;'
            {...{ title: 'Direct Cost Delta' }}
            sortable
            sortField='costDiff'
            align={'right'}
            body={({ costDiff, costUser, costMedian }) => {
              if (slContext.isOnboarding) {
                return '--';
              }
              return (
                <Text color={costUser <= costMedian ? 'success' : 'error'}>
                  {formatMoney(costDiff)}
                </Text>
              );
            }}
          />
        ) : (
          <DataGrid.Column
            key='costDiffPercentage'
            field='costDiffPercentage'
            width={120}
            header='Direct Cost Direct Cost &#120491; %'
            {...{ title: 'Direct Cost Delta %' }}
            sortable
            sortField='costDiffPercentage'
            align={'right'}
            body={({ costDiffPercentage, costUser, costMedian }) => {
              if (slContext.isOnboarding) {
                return '--';
              }
              return (
                <Text color={costUser <= costMedian ? 'success' : 'error'}>
                  {Number(costDiffPercentage).toFixed(2) + '%'}
                </Text>
              );
            }}
          />
        )}
        <DataGrid.GapColumn />
        <DataGrid.SelectionColumn selectionMode='single' />
        <DataGrid.GapColumn />
      </DataGrid>
    </div>
  );
};

export default ServiceLineTable;

import React, { FC } from 'react';

import { VisualizationProvider } from 'shared/data/visualizationContext';
import { KpiProvider } from 'kpi/data/kpiContext';
import { LaborProvider } from 'labor/data/laborContext';
import { CostProvider } from 'cost/data/costContext';
import { OverheadProvider } from 'overhead/data/overheadContext';
import { MappingProvider } from 'mappings/data/mappingContext';
import { GlobalFilterProvider } from 'shared/filters/useGlobalFilterContext';
import { LegalTermsProvider } from 'shared/legal/useLegalTermsContext';
import { ViewProvider } from 'shared/config/data/useViewContext';
import { ClientDataProvider } from 'shared/data/useClientDataContext';
import { FlagsProvider } from 'shared/flags/useFlagsContext';
import { ServiceLineProvider } from 'serviceline/data/ServiceLineContext';
import { ServiceLineProvider as ExperimentalServiceLineProvider } from 'serviceline2/data/ServiceLineContext';
import { Sg2GroupingsProvider } from '../data/useSg2GroupingsContext';
import { UiConfigProvider } from 'shared/data/useUiConfigContext';
import { StratasphereReportProvider } from '../drawers/insight-drawer/stratasphereReportContext';

const GlobalContextProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  return (
    <FlagsProvider>
      <UiConfigProvider>
        <ClientDataProvider>
          <GlobalFilterProvider>
            <LegalTermsProvider>
              <VisualizationProvider>
                <Sg2GroupingsProvider>
                  <KpiProvider>
                    <LaborProvider>
                      <CostProvider>
                        <OverheadProvider>
                          <ServiceLineProvider>
                            <ExperimentalServiceLineProvider>
                              <ViewProvider>
                                <MappingProvider>
                                  <StratasphereReportProvider>
                                    {props.children}
                                  </StratasphereReportProvider>
                                </MappingProvider>
                              </ViewProvider>
                            </ExperimentalServiceLineProvider>
                          </ServiceLineProvider>
                        </OverheadProvider>
                      </CostProvider>
                    </LaborProvider>
                  </KpiProvider>
                </Sg2GroupingsProvider>
              </VisualizationProvider>
            </LegalTermsProvider>
          </GlobalFilterProvider>
        </ClientDataProvider>
      </UiConfigProvider>
    </FlagsProvider>
  );
};

export default GlobalContextProvider;

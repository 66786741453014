import { useState } from 'react';

export interface IUseDrawer {
  isOpen: boolean;
  open(): void;
  close(): void;
}

const useDrawer = (): IUseDrawer => {
  const [isOpen, setIsOpen] = useState(false);

  return {
    isOpen,
    open: () => setIsOpen(() => true),
    close: () => setIsOpen(() => false)
  };
};

useDrawer.returnType = {
  isOpen: false,
  open: () => undefined,
  close: () => undefined
} as IUseDrawer;

export default useDrawer;

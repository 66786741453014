import React from 'react';
import { Button, Input } from '@strata/tempo/lib';

interface IMetricSelectorControlsProps {
  filterByText: string;
  onFilterByTextChange(newValue: string): void;
  onAddAll(): void;
  onRemoveAll(): void;
}
const MetricSelector: React.FC<IMetricSelectorControlsProps> = (
  props: IMetricSelectorControlsProps
) => {
  const UNSAFE_props = {
    allowClear: true
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onFilterByTextChange(event.target.value);
  };

  return (
    <div style={{ textAlign: 'center' }} className='bm-kpiorg-head'>
      <Input
        {...UNSAFE_props}
        onChange={handleOnChange}
        search
        value={props.filterByText}
        width={'100%'}
      />
      <br />
      <div style={{ height: 5 }} />
      <Button type={'tertiary'} onClick={props.onAddAll}>
        Add All
      </Button>
      <Button type={'tertiary'} onClick={props.onRemoveAll}>
        Remove All
      </Button>
    </div>
  );
};

export default MetricSelector;

import React, { FC, useEffect } from 'react';
import { FilterScope, IFilter, IFilterConfig, IFilterControlProps } from 'shared/filters/IFilter';
import { hospitalEntitiesService } from './entityService';
import { DropDown, Form } from '@strata/tempo/lib';
import { useKpiContext } from 'kpi/data/kpiContext';
import { useCostContext } from 'cost/data/costContext';
import { useLaborContext } from 'labor/data/laborContext';

const getItems = (entry: string) => ({
  text: entry.split(' | ')[1],
  value: entry
});

const useDropdownSync = (sourceModuleName: string) => {
  const kpiContext = useKpiContext();
  const costContext = useCostContext();
  const laborContext = useLaborContext();

  const kpiEntity = kpiContext.filterGroup.filters.entity.filterValue;
  const costEntity = costContext.filterGroup.filters.entity.filterValue;
  const laborEntity = laborContext.filterGroup.filters.entity.filterValue;

  const sync = (targetFilter: IFilter<string>, sourceFilter: IFilter<string>) => {
    if (targetFilter.filterData.options?.includes(sourceFilter.filterValue)) {
      targetFilter.setFilterValue(sourceFilter.filterValue);
    }
  };

  useEffect(() => {
    switch (sourceModuleName) {
      case 'kpi':
        sync(costContext.filterGroup.filters.entity, kpiContext.filterGroup.filters.entity);
        sync(laborContext.filterGroup.filters.entity, kpiContext.filterGroup.filters.entity);
        break;
      case 'cost':
        sync(kpiContext.filterGroup.filters.entity, costContext.filterGroup.filters.entity);
        sync(laborContext.filterGroup.filters.entity, costContext.filterGroup.filters.entity);
        break;
      case 'labor':
        sync(kpiContext.filterGroup.filters.entity, laborContext.filterGroup.filters.entity);
        sync(costContext.filterGroup.filters.entity, laborContext.filterGroup.filters.entity);
        break;
      default:
    }
  }, [kpiEntity, costEntity, laborEntity]);
};

const KpiEntityFilterControl: FC<IFilterControlProps<string>> = ({
  filterValue,
  setFilterValue,
  filterData
}) => {
  useDropdownSync('kpi');
  return (
    <Form.Item label='Customer/Entity'>
      <DropDown
        items={filterData.options?.map(getItems)}
        onChange={(val) => setFilterValue(val as string)}
        value={filterValue}
      />
    </Form.Item>
  );
};

const CostEntityFilterControl: FC<IFilterControlProps<string>> = ({
  filterValue,
  setFilterValue,
  filterData
}) => {
  useDropdownSync('cost');
  return (
    <Form.Item label='Customer/Entity'>
      <DropDown
        items={filterData.options?.map(getItems)}
        onChange={(val) => setFilterValue(val as string)}
        value={filterValue}
      />
    </Form.Item>
  );
};

const LaborEntityFilterControl: FC<IFilterControlProps<string>> = ({
  filterValue,
  setFilterValue,
  filterData
}) => {
  useDropdownSync('labor');
  return (
    <Form.Item label='Customer/Entity'>
      <DropDown
        items={filterData.options?.map(getItems)}
        onChange={(val) => setFilterValue(val as string)}
        value={filterValue}
      />
    </Form.Item>
  );
};

const sharedConfig: IFilterConfig<string> = {
  controlWeight: 2,
  defaultValue: '',
  param: 'selectedEntity',
  loadFromParams: () => {}, // eslint-disable-line
  computed: {
    entityName: (filterValue) => filterValue?.split(' |')[1],
    systemName: (filterValue) => filterValue?.split('| ')[0],
    scope: (filterValue): FilterScope =>
      filterValue?.split('| ')[1] === 'Health System' ? 'system' : 'entity',
    isSystemScope: (filterValue): boolean => filterValue?.split('| ')[1] === 'Health System',
    isEntityScope: (filterValue): boolean => filterValue?.split('| ')[1] !== 'Health System'
  },
  settingLabel: 'Customer/Entity',
  settingWeight: 0
};

export const kpiEntity: typeof sharedConfig = {
  ...sharedConfig,
  Control: KpiEntityFilterControl,
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.selectedEntity);
  },
  async setup() {
    const optionsData = await hospitalEntitiesService.fetchAvailableKpiEntities();
    return {
      data: { options: optionsData },
      value: optionsData.find((entry) => !entry.includes('| Health System'))
    };
  }
};

export const costEntity: typeof sharedConfig = {
  ...sharedConfig,
  Control: CostEntityFilterControl,
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.selectedEntity);
  },
  async setup() {
    const optionsData = await hospitalEntitiesService.fetchAvailableCostEntities();
    return {
      data: { options: optionsData },
      value: optionsData.find((entry) => !entry.includes('| Health System'))
    };
  }
};

export const laborEntity: typeof sharedConfig = {
  ...sharedConfig,
  Control: LaborEntityFilterControl,
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.selectedEntity);
  },
  async setup() {
    const optionsData = await hospitalEntitiesService.fetchAvailableLaborEntities();
    return {
      data: { options: optionsData },
      value: optionsData.find((entry) => !entry.includes('| Health System'))
    };
  }
};

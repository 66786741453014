import React, { FC } from 'react';
import { Spacing } from '@strata/tempo/lib';
import CompareDrawer from 'shared/drawers/compare-drawer/CompareDrawer';
import DrawerFilters from 'shared/drawers/compare-drawer/DrawerFilters';
import { useOverheadContext } from 'overhead/data/overheadContext';

const OverheadDrawer: FC = () => {
  const overheadContext = useOverheadContext();

  const handleRun = async () => {
    if (!overheadContext.filterGroup.ready) {
      return;
    }
    overheadContext.compareDrawer.close();
    overheadContext.comparisons.load();
  };

  return (
    <CompareDrawer
      visible={overheadContext.compareDrawer.isOpen}
      onClose={overheadContext.compareDrawer.close}
      onRun={handleRun}
      hasSelectedMetrics
      hasLoadedMetricResults={overheadContext.comparisons.loadingDone}
      pleaseWait={!overheadContext.filterGroup.ready}
    >
      <Spacing padding='12px'>
        <DrawerFilters filterGroup={overheadContext.filterGroup} filterScope='system' />
      </Spacing>
    </CompareDrawer>
  );
};

export default OverheadDrawer;

import React, { FC, useState } from 'react';
import { Button, Input, Modal } from '@strata/tempo/lib';
import { ITableRowData } from './useComparisonTable';
import { useLegalTermsContext } from 'shared/legal/useLegalTermsContext';
import DownloadIcon from '@strata/tempo/lib/icon/DownloadIcon';

interface ICsvExporterProps {
  isLoading: boolean;
  data: ITableRowData[];
}

const CsvExporter: FC<ICsvExporterProps> = (props: ICsvExporterProps) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [fileName, setFileName] = useState('StrataSphere_CSV');
  const legalTermsContext = useLegalTermsContext();

  const handleExport = () => {
    const columns: (keyof ITableRowData)[] = [
      'name',
      'category',
      'value',
      'rank',
      'cohortSize',
      'q25',
      'median',
      'q75'
    ];

    const exportableData = props.data.map((row) =>
      columns.map((column) => {
        let val = row[column];

        if (!val) {
          return null;
        }

        val = val.toString().replaceAll(',', '');

        // BEN-5552, Excel "formats" (mangles) CSV column data that starts with a + or -
        // To fix this, we put negative values in parentheses AND remove the negative sign.
        // (Excel will properly format the parens, and show the users a negative number.)
        // these minus signs are not the same char!
        if (val.startsWith('-') || val.startsWith('−')) {
          val = `(${val.substring(1)})`;
        }

        return val;
      })
    );

    const headers = [
      'Metric Name',
      'Category',
      'My Average Value',
      'Percentile Rank',
      'Comparison Count',
      '25th Percentile',
      'Median',
      '75th Percentile'
    ];

    const [legalLine1, legalLine2, legalLine3] = legalTermsContext.terms;

    exportableData.unshift(headers);
    exportableData.push(['', '', '', '', '', '', '', '']);
    exportableData.push(['', '', '', '', '', '', '', '']);
    exportableData.push([`"${legalLine1}"`, '', '', '', '', '', '', '']);
    exportableData.push([`"${legalLine2}"`, '', '', '', '', '', '', '']);
    exportableData.push([`"${legalLine3}"`, '', '', '', '', '', '', '']);

    const fileContent =
      'data:text/csv;charset=utf-8,' + exportableData.map((e) => e.join(',')).join('\n');

    const link = document.createElement('a');
    link.setAttribute('href', encodeURI(fileContent));
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link);
    link.click();

    setModalIsOpen(false);
  };

  return (
    <>
      <div style={{ textAlign: 'right', marginRight: 10 }}>
        <Button
          type='tertiary'
          icon={<DownloadIcon />}
          disabled={props.isLoading}
          onClick={() => setModalIsOpen(true)}
        >
          Download CSV
        </Button>
      </div>
      <Modal
        title='Save CSV'
        visible={modalIsOpen}
        onCancel={() => setModalIsOpen(false)}
        onOk={handleExport}
      >
        <Input
          width={'100%'}
          value={fileName}
          placeholder='StrataSphere_CSV'
          onPressEnter={handleExport}
          onChange={(event) => setFileName(event.target.value)}
        />
      </Modal>
    </>
  );
};
export default CsvExporter;

import { useWindowSize } from '@strata/tempo/lib';

const VERTICAL_PADDING = 75;

const useRowCountCalculator = () => {
  const { height } = useWindowSize();
  const headerHeight = 200;
  const footerHeight = 50;
  const rowHeight = 50;

  const maxTableBodyHeight = height - headerHeight - footerHeight - VERTICAL_PADDING * 2;

  return Math.floor(maxTableBodyHeight / rowHeight);
};

export { useRowCountCalculator };

import React, { FC } from 'react';
import { Card, Spacing, Tag, Text } from '@strata/tempo/lib';
import { IStandardComparison } from 'shared/data/IStandardComparison';
import IBaseMetric, { MetricDataName } from 'shared/data/IBaseMetric';
import IncompleteCard from './IncompleteCard';
import NoDataLoadedCard from './NoDataLoadedCard';
import { useVisualizationContext } from 'shared/data/visualizationContext';
import UserScore from 'shared/user-score/UserScore';
import { Visualization } from 'shared/data/visualizationContext';
import { IneligibleReason } from 'shared/data/IBaseComparison';

import ChartMonthlyTrend from 'shared/charts/ChartMonthlyTrend';
import ChartHistogram from 'shared/charts/ChartHistogram';
import { useInView } from 'react-intersection-observer';

import 'serviceline/costbar.old.css';

interface IComparisonCardProps {
  chartOptions?: any;
  forceVisualization?: Visualization;
  ineligibleReason?: IneligibleReason;
  isLoading?: boolean;
  isSelected?: boolean;
  height?: number;
  hideUserData?: boolean;
  hideUserScore?: boolean;
  messageNoDataLoaded?: string;
  metric: IBaseMetric;
  metricResult: IStandardComparison | undefined;
  onSelect?(value: MetricDataName): void;
  withControls?: boolean;
}

const ComparisonCard: FC<IComparisonCardProps> = (props: IComparisonCardProps) => {
  const visualizationContext = useVisualizationContext();
  const { ref, inView } = useInView();

  const background = props.isLoading || !props.metricResult ? 'gray-100' : undefined;

  let handleClick;
  if (props.metricResult && !props.metricResult.incompleteReason && props.onSelect) {
    handleClick = () => {
      props.onSelect?.(props.metric.Data_Name);
    };
  }

  const renderUserScore = () => {
    if (
      props.hideUserScore ||
      props.hideUserData ||
      props.isLoading ||
      !props.metricResult ||
      props.metricResult.incompleteReason
    ) {
      return;
    }

    return <UserScore metric={props.metric} metricResult={props.metricResult} withCohortSize />;
  };

  const renderPlotContent = () => {
    if (props.ineligibleReason) {
      return <IncompleteCard reason={props.ineligibleReason} />;
    }
    if (props.metricResult?.incompleteReason) {
      return <IncompleteCard reason={props.metricResult.incompleteReason} />;
    }
    if (!props.metricResult) {
      return <NoDataLoadedCard message={props.messageNoDataLoaded} />;
    } else {
      const visualization = props.forceVisualization || visualizationContext.selectedVisualization;

      const chartMap = {
        'Line Chart': (
          <ChartMonthlyTrend
            data={props.metricResult}
            format={props.metric.Format}
            withControls={props.withControls ?? false}
            options={props.chartOptions}
            isOnboarding={props.hideUserData}
          />
        ),
        Histogram: (
          <ChartHistogram
            data={props.metricResult}
            format={props.metric.Format}
            isOnboarding={props.hideUserData}
          />
        )
      };

      return chartMap[visualization as keyof typeof chartMap];
    }
  };

  return (
    <div ref={ref}>
      <Card
        removeBodyPadding
        selected={props.isSelected}
        onClick={handleClick}
        loading={props.isLoading}
      >
        <Spacing column height={props.height || 350} background={background} padding='8px'>
          <Spacing column padding='8px'>
            <Spacing hAlign='space-between' margin='0 0 8px 0' vAlign='center'>
              <Text.Heading ellipsis={{ rows: 1 }} marginBottom={0} level={4}>
                <Tag color='blue-700'>{props.metric.Category}</Tag>
              </Text.Heading>
              {renderUserScore()}
            </Spacing>
            <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
              {props.metric.Friendly_Name}
            </Text.Heading>
          </Spacing>
          {inView ? renderPlotContent() : null}
        </Spacing>
      </Card>
    </div>
  );
};

export default ComparisonCard;

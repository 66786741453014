import React from 'react';

interface IScrollKeeperProps {
  selector: string;
}

/**
 * Keeps the position of a containing element
 * whenever its inner-html is re-rendered.
 */
export default class ScrollKeeper extends React.Component<IScrollKeeperProps> {
  private ref = React.createRef<HTMLDivElement>();

  getSnapshotBeforeUpdate(prevProps: Readonly<never>): number | null {
    if (this.ref.current) {
      const $el = this.ref.current.closest(this.props.selector) as HTMLDivElement;
      return $el.scrollHeight - $el.scrollTop;
    }
    return null;
  }

  componentDidUpdate(prevProps: Readonly<never>, prevState: Readonly<never>, snapshot?: number) {
    if (snapshot && this.ref.current) {
      const $el = this.ref.current.closest(this.props.selector) as HTMLDivElement;
      $el.scrollTop = $el.scrollHeight - snapshot;
    }
  }

  render() {
    return <div ref={this.ref} />;
  }
}

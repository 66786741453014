import React, { FC } from 'react';
import { IFilterConfig, IFilterControlProps, IFilterSettingProps } from 'shared/filters/IFilter';
import Setting from 'shared/filters/settings/individual/Setting';
import { DropDown, Form } from '@strata/tempo/lib';

const CmiAdjustedControl: FC<IFilterControlProps<string>> = ({ filterValue, setFilterValue }) => (
  <Form.Item label='CMI Adjusted'>
    <DropDown
      items={options}
      value={filterValue}
      onChange={(val) => {
        setFilterValue((val as string) ?? '');
      }}
    />
  </Form.Item>
);

const CmiAdjustedSetting: FC<IFilterSettingProps<string>> = (props) => {
  const selectedOptionText = props.filterComputed?.selectedOptionText;
  return (
    <Setting
      isVisible={Boolean(props.filterValue)}
      label='CMI Adjusted'
      value={selectedOptionText}
    />
  );
};

const options = [
  { value: 'TODO foo', text: 'Yes' },
  { value: 'TODO bar', text: 'No' }
];

export const cmiAdjusted: IFilterConfig<string> = {
  computed: {
    selectedOptionText: (filterValue): string =>
      options.find((option) => option.value === filterValue)?.text || ''
  },
  Control: CmiAdjustedControl,
  controlWeight: 5.1,
  defaultValue: options[0].value,
  param: 'cmiAdjusted',
  loadFromParams(params, setFilterValue) {
    setFilterValue(params.cmiAdjusted);
  },
  Setting: CmiAdjustedSetting,
  settingWeight: 5.1
};

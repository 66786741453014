import React, { FC, useEffect, useState } from 'react';
import {
  IFilter,
  IFilterConfig,
  IFilterControlProps,
  IFilterData,
  IFilterSettingProps
} from './IFilter';
import * as UseFilterComponentFactory from './usefilterComponentFactory';
import { ITaskList } from 'shared/utils';

export const useFilter = <T,>(
  config: IFilterConfig<T>,
  taskList?: ITaskList,
  taskId?: string
): IFilter<T> => {
  const [filterValue, setFilterValue] = useState(config.defaultValue);
  const [filterData, setFilterData] = useState<Partial<IFilterData>>({});

  useEffect(() => {
    if (config.setup) {
      const { notReady, ready } = _createFilterReadinessHandlers(taskList, taskId);
      notReady();
      config.setup().then(({ data, value }) => {
        if (value) setFilterValue(value);
        if (data) setFilterData(data);
        ready();
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const computed = Object.entries(config.computed || {}).reduce(
    (computations = {}, [key, fn]) => ({
      ...computations,
      [key]: fn(filterValue)
    }),
    {} as typeof config.computed
  );

  const controlWithFilter: (WrappedComponent: FC<IFilterControlProps<T>>) => JSX.Element = (
    WrappedComponent
  ) => {
    return (
      <WrappedComponent
        filterData={filterData}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        filterConfig={config}
      />
    );
  };

  const settingWithFilter: (WrappedComponent: FC<IFilterSettingProps<T>>) => JSX.Element = (
    WrappedComponent
  ) => {
    return (
      <WrappedComponent filterValue={filterValue} filterConfig={config} filterComputed={computed} />
    );
  };

  const param =
    typeof config.param === 'string' ? { [config.param]: filterValue } : config.param(filterValue);

  return {
    computed,
    Control: controlWithFilter(config.Control ?? UseFilterComponentFactory.DefaultControl),
    controlWeight: config.controlWeight ?? 0,
    filterData,
    filterValue,
    param,
    loadFromParams: (params) => config.loadFromParams(params, setFilterValue, filterValue),
    scope: config.scope ?? 'all',
    setFilterValue,
    Setting: settingWithFilter(config.Setting ?? UseFilterComponentFactory.DefaultSetting),
    settingWeight: config.settingWeight ?? 0,
    settingHidden: !(config.Setting || config.settingWeight || config.settingLabel)
  };
};

/**
 * Creates "readiness" callbacks for Filters with (async) setup.
 * (Eg, a Dropdown Filter that fetches its own options, etc.)
 */
const _createFilterReadinessHandlers = (taskList?: ITaskList, taskId?: string) => {
  const notReady = taskList ? () => taskList.makeTask(taskId as string) : () => handleSetupError();
  const ready = taskList ? () => taskList.completeTask(taskId as string) : () => handleSetupError();

  const handleSetupError = () => {
    throw new Error(
      `This Filter was configured with async "setup" yet isn't properly instantiated in its FilterRepository! To fix this error, pass a TaskList & TaskId into the "useFilter" hook that makes this Filter.`
    );
  };

  return {
    ready,
    notReady
  };
};

import React, { FC, useState } from 'react';
import { Card, DataGrid, Spacing, Tag, Text } from '@strata/tempo/lib';
import UserScore from 'shared/user-score/UserScore';
import useComparisonTable from 'shared/comparisons/table/useComparisonTable';

import MetricFlag from './MetricFlag.Old';
import { IPairedMetricsAndCostComparisons } from 'cost/data/pairedMetricsAndComparisons';
import { useCostContext } from './data/costContext';

import './cost-table.scss';
import UpIcon from '@strata/tempo/lib/icon/UpIcon';
import DownIcon from '@strata/tempo/lib/icon/DownIcon';

interface ICostComparisonTableProps {
  data: IPairedMetricsAndCostComparisons[];
}
const CostTable: FC<ICostComparisonTableProps> = (props: ICostComparisonTableProps) => {
  const costContext = useCostContext();
  const { tableData } = useComparisonTable(props.data);
  const [isOpen, setIsOpen] = useState(false);

  (window as any).costContext = costContext;

  const renderTableSummary = () => {
    const summaryData = props.data.length ? props.data : new Array(7).fill(null);
    if (props.data.length) {
      summaryData.sort((a, b) => (a ? b.weight - a.weight : 0));
    }

    const getHeader = (i: number) => {
      return ['Total Cost', 'Direct Cost', '', '', 'Indirect Cost'][i];
    };

    return (
      <Spacing
        itemSpacing={8}
        column={false}
        vAlign='center'
        hAlign='space-between'
        padding={'10px 24px'}
        style={{ overflowX: 'auto', minHeight: 115 }}
      >
        {summaryData.map((entry, i) => {
          return (
            <MetricFlag
              costDataResults={entry}
              key={i}
              withBorder={[0, 3].includes(i)}
              header={getHeader(i)}
            />
          );
        })}
      </Spacing>
    );
  };
  const renderTableContent = () => {
    return (
      <div className='metric-table'>
        <DataGrid sortOrder={1} value={tableData} rows={10} density='normal'>
          <DataGrid.Column
            key='name'
            width={150}
            header='Metric Name'
            field='name'
            sortable
            body={({ name }) => <>{name}</>}
          />
          <DataGrid.Column
            key='user'
            width={120}
            header='My Average Value'
            body={({ value }) => <>{value}</>}
          />
          <DataGrid.Column
            key='percentilerank'
            width={120}
            header='Percentile Rank'
            body={({ metric, metricResult }) => {
              if (metric && metricResult) {
                return <UserScore metric={metric} metricResult={metricResult} />;
              } else {
                return <></>;
              }
            }}
          />
          <DataGrid.Column
            key='count'
            width={120}
            header='Comparison Count'
            sortable
            sortField='cohortSize'
            body={({ cohortSize }) => <>{cohortSize}</>}
          />
          <DataGrid.Column
            key='25'
            width={120}
            header='25th Percentile'
            body={({ q25 }) => <>{q25}</>}
          />
          <DataGrid.Column
            key='median'
            width={120}
            header='Median'
            body={({ median }) => <>{median}</>}
          />
          <DataGrid.Column
            key='75'
            width={120}
            header='75th Percentile'
            body={({ q75 }) => <>{q75}</>}
          />
        </DataGrid>
      </div>
    );
  };

  return (
    <div className='cost-table'>
      <Card removeBodyPadding>
        <Spacing column padding='8px'>
          <Spacing column padding={'8px'}>
            <Spacing margin='0 0 8px 0' hAlign={'space-between'}>
              <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
                {costContext.selectedPatientTypeOption.text}
              </Text.Heading>
            </Spacing>
          </Spacing>
          <Spacing column padding='0px'>
            {isOpen ? (
              <>
                {' '}
                {renderTableSummary()} {renderTableContent()}{' '}
              </>
            ) : (
              renderTableSummary()
            )}
          </Spacing>
          <div onClick={() => setIsOpen((val) => !val)} className='accordion-button'>
            <Spacing background={'gray-100'} padding='8px 16px' hAlign={'center'}>
              <Tag icon={isOpen ? <UpIcon /> : <DownIcon />} color='secondary'>
                Show {isOpen ? 'Less' : 'Table'}
              </Tag>
            </Spacing>
          </div>
        </Spacing>
      </Card>
    </div>
  );
};

export default CostTable;

import React, { FC } from 'react';
import { Spacing } from '@strata/tempo/lib';

interface IChartMonthlyTrendControlsProps {
  isShowingYou: boolean;
  isShowingP25: boolean;
  isShowingP75: boolean;
  isShowingMedian: boolean;
  onYouClick(): void;
  onP25Click(): void;
  onP75Click(): void;
  onMedianClick(): void;
  asControls: boolean;
  isOnboarding: boolean;
}

const Controls: FC<IChartMonthlyTrendControlsProps> = (props) => {
  const swatchClassNames = {
    you: `bm-bar-legend-container ${props.isShowingYou ? '' : 'strike'} ${
      props.asControls ? '' : 'pointer'
    }`,
    p25: `bm-bar-legend-container ${props.isShowingP25 ? '' : 'strike'} ${
      props.asControls ? '' : 'pointer'
    }`,
    p75: `bm-bar-legend-container ${props.isShowingP75 ? '' : 'strike'} ${
      props.asControls ? '' : 'pointer'
    }`,
    median: `bm-bar-legend-container ${props.isShowingMedian ? '' : 'strike'} ${
      props.asControls ? '' : 'pointer'
    }`
  };

  return (
    <Spacing itemSpacing={8} hAlign='center'>
      {props.isOnboarding ? (
        <></>
      ) : (
        <div className={swatchClassNames.you} onClick={props.onYouClick}>
          <div className='bm-bar-legend bm-bar-user' />
          You
        </div>
      )}
      <div className={swatchClassNames.median} onClick={props.onMedianClick}>
        <div className='bm-bar-legend bm-bar-median' />
        Median
      </div>
      <div className={swatchClassNames.p25} onClick={props.onP25Click}>
        <div className='bm-bar-legend bm-bar-inner' />
        25th percentile
      </div>
      <div className={swatchClassNames.p75} onClick={props.onP75Click}>
        <div className='bm-bar-legend bm-bar-inner' />
        75th percentile
      </div>
    </Spacing>
  );
};

export default Controls;

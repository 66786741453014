import { ICacheableStandardComparison, IStandardComparison } from 'shared/data/IStandardComparison';
import { ComparisonTransforms } from 'shared/utils/comparisonTransforms';
import { IDimMetric } from '../../shared/data/uiConfigService';
import { cacheableHttpPost } from 'shared/data';

export const laborSnowflakeComparisonService = {
  async fetchComparison(
    comparisonId: string,
    signal: AbortSignal,
    params: any,
    token: string,
    strataIdAsString: string,
    metric: IDimMetric
  ): Promise<ICacheableStandardComparison> {
    const getUrl = () => {
      const laborUrlsNew = {
        ENTITY: '/labor-comparisons/entities',
        SYSTEM: '/labor-comparisons/systems'
      };

      const isSystem = params.selectedEntity?.split(' | ')[1] === 'Health System';

      return isSystem ? laborUrlsNew.SYSTEM : laborUrlsNew.ENTITY;
    };

    const response = await cacheableHttpPost<IStandardComparison>(
      getUrl(),
      {
        ...params,
        strataId: parseInt(strataIdAsString, 10),
        comparisonId,
        metricId: metric.metricId
      },
      {
        signal
      }
    );

    // All percentage-based Labor metrics need to have their values multiplied by 100.
    if (metric.axisFormat.includes('%')) {
      ComparisonTransforms.multiplyValuesBy100(response);
    }

    return {
      ...response,
      token,
      dataName: metric.metricAbbr
    };
  }
};

import React, { FC } from 'react';
import { Spacing, Tabs } from '@strata/tempo/lib';

import CompareDrawer from 'shared/drawers/compare-drawer/CompareDrawer';
import MetricSelectorTabPane from 'shared/drawers/compare-drawer/MetricSelectorTabPane';
import DrawerFilters from 'shared/drawers/compare-drawer/DrawerFilters';

import { useKpiContext } from 'kpi/data/kpiContext';

const KpiDrawer: FC = () => {
  const kpiContext = useKpiContext();

  const handleRun = async () => {
    if (!kpiContext.filterGroup.ready) {
      return;
    }

    kpiContext.compareDrawer.close();
    kpiContext.loadComparisons();
  };

  return (
    <CompareDrawer
      visible={kpiContext.compareDrawer.isOpen}
      onClose={kpiContext.compareDrawer.close}
      onRun={handleRun}
      hasSelectedMetrics={!!kpiContext.selectableMetrics.selected.length}
      hasLoadedMetricResults={kpiContext.selectableComparisons.loadingDone}
      pleaseWait={!kpiContext.filterGroup.ready}
      withMetrics
    >
      <Tabs
        defaultActiveKey='1'
        items={[
          {
            key: '1',
            label: 'Metrics',
            children: <MetricSelectorTabPane selectableMetrics={kpiContext.selectableMetrics} />
          },
          {
            key: '2',
            label: 'Filters',
            children: (
              <Spacing padding='12px'>
                <DrawerFilters
                  filterGroup={kpiContext.filterGroup}
                  filterScope={kpiContext.filterGroup.filters.entity.computed?.scope}
                />
              </Spacing>
            )
          }
        ]}
      />
    </CompareDrawer>
  );
};

export default KpiDrawer;

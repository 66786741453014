import React, { FC, createContext, useContext, useState, Dispatch, SetStateAction } from 'react';

export interface IPopupContext {
  isShowing: boolean;
  setIsShowing: Dispatch<SetStateAction<boolean>>;
}

const context = createContext({} as IPopupContext);
const PopupProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const [isShowing, setIsShowing] = useState(false);

  const provided = {
    isShowing,
    setIsShowing
  };

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

const usePopupContext = (): IPopupContext => useContext(context);

export { usePopupContext, PopupProvider };

import React, { FC, useEffect } from 'react';
import ServiceLineTaskBar from './ServiceLineTaskBar';
import ServiceLineDrawer from './ServiceLineDrawer';
import ServiceLineComparisons from './ServiceLineComparisons';

import LegalNotice from 'shared/LegalNotice';
import FilterGroupSettings from 'shared/filters/FilterGroupSettings';
import { useServiceLineContext } from './data/ServiceLineContext';
import { useFlagsContext } from 'shared/flags/useFlagsContext';
import { useClientDataContext } from '../shared/data/useClientDataContext';

const ServiceLineModule: FC = () => {
  const serviceLineContext = useServiceLineContext();
  const clientDataContext = useClientDataContext();
  const flagsContext = useFlagsContext();

  useEffect(() => {
    if (!clientDataContext.clientData?.isProductionCost) {
      return;
    }

    if (serviceLineContext.filterGroup.ready) {
      serviceLineContext.serviceLineTable.load();
    }

    return serviceLineContext.serviceLineTable.cancelLoad;
  }, [serviceLineContext.filterGroup.ready, clientDataContext.clientData]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <ServiceLineTaskBar />
      <FilterGroupSettings
        filterGroup={serviceLineContext.filterGroup}
        filterScope={serviceLineContext.filterGroup.filters.entity.computed?.scope}
      />
      <ServiceLineComparisons />
      <ServiceLineDrawer />
      <LegalNotice />
    </>
  );
};

export default ServiceLineModule;

import React, { FC, PropsWithChildren } from 'react';
import { Drawer } from '@strata/tempo/lib';
import RunButton from 'shared/drawers/compare-drawer/RunButton';

import './CompareDrawer.scss';

interface ICompareDrawerProps {
  hasSelectedMetrics: boolean;
  hasLoadedMetricResults: boolean;
  onClose(): void;
  onRun(): void;
  pleaseWait?: boolean;
  visible: boolean;
  withMetrics?: boolean;
}

const CompareDrawer: FC<PropsWithChildren<ICompareDrawerProps>> = (
  props: PropsWithChildren<ICompareDrawerProps>
) => {
  const footer = (
    <RunButton
      hasSelectedMetrics={props.hasSelectedMetrics}
      hasLoadedMetricResults={props.hasLoadedMetricResults}
      pleaseWait={props.pleaseWait}
      onClick={props.hasLoadedMetricResults ? props.onClose : props.onRun}
    />
  );

  const title = props.withMetrics ? 'Metrics & Filters' : 'Filters';

  return (
    <Drawer
      onClose={props.onClose}
      title={title}
      removeBodyPadding
      closable={false}
      footer={footer}
      visible={props.visible}
      destroyOnClose
      placement='left'
    >
      {props.children}
    </Drawer>
  );
};

export default CompareDrawer;

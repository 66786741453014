import React, { FC } from 'react';
import { Card, Spacing, Tag, Text } from '@strata/tempo/lib';
import IncompleteCard from 'shared/comparisons/cards/IncompleteCard';
import ServiceLineEmptyCard from './ServiceLineEmptyCard';
import UserScore from 'shared/user-score/UserScore';
import ChartMonthlyAverages from './ChartMonthlyAverages';

import '../costbar.old.css';
import IBaseMetric from 'shared/data/IBaseMetric';
import { useServiceLineContext } from 'serviceline/data/ServiceLineContext';
import Legend, { ILegendItemProps } from 'shared/charts/Legend';
import { IStandardComparison } from '../../shared/data/IStandardComparison';
import NoDataLoadedCard from '../../shared/comparisons/cards/NoDataLoadedCard';

const ServiceLineEncounterCard: FC = (props) => {
  const slContext = useServiceLineContext();
  const name = slContext.selectedTableRow?.value.name;
  const selectedSlCfData = slContext.getCurrentTableContext().data.find((e) => e.name === name);
  const { loadingDone } = slContext.getCurrentTableContext();
  const isInpatientType = slContext.filterGroup.filters.patientType.filterValue === 'Inpatient';

  const volume = selectedSlCfData?.volume;
  const cmi = selectedSlCfData?.cmi;

  const background = !volume ? 'gray-100' : undefined;

  const renderVolumeUserScore = () => {
    if (!volume || volume.incomplete || slContext.isOnboarding) {
      return;
    }

    return (
      <UserScore
        metric={{ GoodBound: 'upper' } as IBaseMetric}
        metricResult={volume}
        withCohortSize
      />
    );
  };

  const renderCmiUserScore = () => {
    if (!cmi || cmi.incomplete || slContext.isOnboarding) {
      return;
    }

    return (
      <UserScore metric={{ GoodBound: 'upper' } as IBaseMetric} metricResult={cmi} withCohortSize />
    );
  };

  const renderLegend = () => {
    const legendItems: ILegendItemProps[] = [];
    if (!slContext.isOnboarding) {
      legendItems.push({
        text: 'You',
        color: 'series3'
      });
    }

    legendItems.push({
      text: 'Cohort',
      color: 'outlier'
    });

    return <Legend items={legendItems} />;
  };

  const renderChart = (data?: IStandardComparison) => {
    if (data?.incompleteReason) {
      return <IncompleteCard reason={data.incompleteReason} />;
    }
    if (!data && loadingDone) {
      return <IncompleteCard reason={'MissingCohort'} />;
    }
    return data ? (
      <ChartMonthlyAverages data={data} options={{ marginRight: 24 }} />
    ) : (
      <ServiceLineEmptyCard />
    );
  };

  const renderDoesNotApplyMessage = () => {
    const message = `CMI does not apply to ${slContext.filterGroup.filters.patientType.computed?.friendlyLabel?.toLowerCase()}`;
    return <NoDataLoadedCard message={message} />;
  };

  return (
    <>
      <Card
        removeBodyPadding
        loading={slContext.serviceLineTable.loading || slContext.careFamilyTable.loading}
      >
        <Spacing column height={190} background={background} padding='8px'>
          <Spacing column padding='8px'>
            <Spacing hAlign='space-between' margin='0 0 8px 0' width={'100%'} vAlign='center'>
              <Text.Heading level={3} ellipsis={{ rows: 1 }}>
                <Tag color='blue-700'>{name || 'Service Line'}</Tag>
              </Text.Heading>
              {renderVolumeUserScore()}
            </Spacing>
            <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
              Average Monthly Patient Volume for{' '}
              {slContext.filterGroup.filters.patientType.computed?.friendlyLabel}
            </Text.Heading>
          </Spacing>
          {renderLegend()}
          {renderChart(volume)}
        </Spacing>
      </Card>
      <Card
        removeBodyPadding
        loading={slContext.serviceLineTable.loading || slContext.careFamilyTable.loading}
      >
        <Spacing column height={194} background={background} padding='8px'>
          <Spacing column padding='8px'>
            <Spacing hAlign='space-between' margin='0 0 8px 0'>
              <Text.Heading level={3} ellipsis={{ rows: 1 }}>
                <Tag color='blue-700'>{name || 'Service Line'}</Tag>
              </Text.Heading>
              {renderCmiUserScore()}
            </Spacing>
            <Text.Heading level={3} strong={false} ellipsis={{ rows: 3 }}>
              Average MS-DRG Case Mix Index for{' '}
              {slContext.filterGroup.filters.patientType.computed?.friendlyLabel}
            </Text.Heading>
          </Spacing>
          {renderLegend()}
          {isInpatientType ? renderChart(cmi) : renderDoesNotApplyMessage()}
        </Spacing>
      </Card>
    </>
  );
};

export default ServiceLineEncounterCard;

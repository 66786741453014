import React, { FC, createContext, useContext, useState } from 'react';
import useDrawer, { IUseDrawer } from 'shared/drawers/data/useDrawer';

import useCostComparisons, { IUseCostComparisons } from 'cost/data/useCostComparisons';
import { costComparisonService } from './costComparisonService';

import useSelectableMetrics, { IUseSelectableMetrics } from 'shared/data/useSelectableMetrics';
import { useUiConfigContext } from 'shared/data/useUiConfigContext';

import {
  IPairedMetricsAndCostComparisons,
  pairMetricsAndComparisons
} from 'cost/data/pairedMetricsAndComparisons';
import { ICostMetric } from './ICostMetric';
import { CostFilterKeys, useCostFilterGroup } from 'cost/filters/useCostFilterGroup';
import { IFilterGroup } from 'shared/filters/IFilter';
import { useClientDataContext } from '../../shared/data/useClientDataContext';
import { defaultOption as defaultPatientTypeOption } from '../filters/config';

export interface ICostContext {
  compareDrawer: IUseDrawer;
  costComparisons: IUseCostComparisons;
  filterGroup: IFilterGroup<CostFilterKeys>;
  selectableMetrics: IUseSelectableMetrics<ICostMetric>;
  items: IPairedMetricsAndCostComparisons[];
  isOnboarding: boolean;
  isViewingCostComponents: boolean;
  isViewingCostDrivers: boolean;

  selectedPatientTypeOption: any;
  setSelectedPatientTypeOption: any;
}

const context = createContext({} as ICostContext);

const CostProvider: FC<JSX.ElementChildrenAttribute> = (props) => {
  const clientDataContext = useClientDataContext();
  const filterGroup = useCostFilterGroup();
  const costComparisons = useCostComparisons(costComparisonService, filterGroup);
  const uiConfigContext = useUiConfigContext();
  const selectableMetrics = useSelectableMetrics(uiConfigContext.costMetrics, ['SPHTotCost_Case']);

  const [selectedPatientTypeOption, setSelectedPatientTypeOption] =
    useState(defaultPatientTypeOption);

  const provided = {
    compareDrawer: useDrawer(),
    costComparisons,
    filterGroup,
    selectableMetrics,
    items: pairMetricsAndComparisons(selectableMetrics.metrics, costComparisons.comparisons),
    isOnboarding:
      clientDataContext.clientData?.isSpecialAccessCost &&
      !clientDataContext.clientData?.isProductionCost,
    isViewingCostComponents: filterGroup.filters.componentOrDriver.computed?.isComponents,
    isViewingCostDrivers: filterGroup.filters.componentOrDriver.computed?.isDrivers,
    selectedPatientTypeOption,
    setSelectedPatientTypeOption
  };

  return <context.Provider value={provided}>{props.children}</context.Provider>;
};

const useCostContext = (): ICostContext => useContext(context);

export { useCostContext, CostProvider };
